import { useStore } from "@/store";
//import { _firebase as $_firebase } from "@/model/firebase";
import { helper as $h } from "@/model/db/helper";
import { image as $image } from "@/model/db/image";
import { aws as $aws } from "@/model/db/aws";
import { project as $project } from "@/model/db/project";
import * as rosepetalModel from "rosepetal-model";


var $_firebase = rosepetalModel._firebase;

const events = {
    async get(e = false){
      let listEvents = []
      let events = $_firebase.firestore().collection('events')
      if(e.uid)events = events.where("payload.uid", "==", useStore().state.main.User.uid)
      if(e.api)events = events.where("source", "==", 'api')
      if(e.ui)events = events.where("source", "==", 'ui')
      if(e.type && e.type!='all')events = events.where("name", "==", e.type)
      if(e.dataset)events = events.where("payload.dataset", "==", e.dataset)
      if(e.isCritical)events = events.where("isCritical", "==", true)
      events = events.orderBy("createdAt", "desc")
      if(e.limit)events = events.limit(e.limit)
      let projectUsers = await $project.getUsers()
      await events.get()
              .then(snapshot => {
                    snapshot.forEach(async doc => {
                      let item = doc.data()
                      item.id  = doc.id;
                      if(item.name)item.nameSplit     = item.name.split(".")
                      if(item.name)item.type          = this.getEventTypes(item.name)
                      if(item.createdAt)item.created  = $h.getTimestampDate(item.createdAt.toDate(),'full')
                      if(item.createdAt)item.date     = $h.getTimestampDate(item.createdAt.toDate(),'date')
                      if(item?.payload?.uid && projectUsers?.users[item?.payload?.uid])item.user = projectUsers.users[item.payload.uid]
                      listEvents.push(item)
                    });
                  });


      if(e.preview && listEvents.length){
        for (let i = 0; i < listEvents.length; i++) {
          if(listEvents[i]?.payload?.imageId){
            let ePrev = await $image.previewB64(listEvents[i].payload.imageId)
            if(ePrev?.b64){ listEvents[i].preview = []; listEvents[i].preview.push({ name: ePrev?.image, src: ePrev?.b64}); }
          }
          if(listEvents[i]?.payload?.uploadedFiles?.files){
            let awsFiles = listEvents[i].payload.uploadedFiles.files.slice(0, 3);
            for (const file of awsFiles) {
              let ePrev = await $aws.getObject(file,"64")
              if(ePrev?.render){
                if(!listEvents[i].preview)listEvents[i].preview = [];
                listEvents[i].preview.push({ name: file.toString().split("/").pop(), src: ePrev?.render});
              }
            }  
          }
        }
      } 

      if(e.byDate && listEvents.length){
        let byDate = {}
        for (let i = 0; i < listEvents.length; i++) {
          console.log(listEvents[i].date)
          if(!byDate[listEvents[i].date])byDate[listEvents[i].date] = { date: listEvents[i].date, events: [] }
          byDate[listEvents[i].date].events.push(listEvents[i])
        }
        return byDate
      }   
            
      if(e.last && listEvents.length)return listEvents[0]
      return listEvents
    },
    async saveEvent (eName, payload, error=false){
      await $_firebase.firestore().collection('events').add({ name: eName, source: 'ui', payload: payload, isCritical: error, createdAt: $_firebase.firebase().firestore.FieldValue.serverTimestamp() });
    },
    async deleteEvent (eventId){ 
        await $_firebase.firestore().collection('events').doc(eventId).delete() 
    },
    async updateEvent(eventId, payload) {
      let upd = { "updatedAt": $_firebase.firebase().firestore.FieldValue.serverTimestamp() }
      if (payload.status) upd["status"] = payload.status
      if (payload.discard) upd["discard"] = Boolean(payload.discard)
      await $_firebase.firestore().collection('events').doc(eventId.toString()).update(upd);
    },
    async getLastOperation(){
      let lastOperation = ''
      let events     = $_firebase.firestore().collection('events').where("payload.operation", "!=", "").orderBy("payload.operation", "asc").orderBy("createdAt", "desc").limit(1)
      await events.get().then(snapshot => {
                    snapshot.forEach(async doc => {
                      let item = doc.data()
                      lastOperation = item.payload.operation
                    });
                  });
      return lastOperation
    },
    async getLastPipeline(){
      let lastPipeline = ''
      let events     = $_firebase.firestore().collection('events').where("payload.operationID", "!=", "").orderBy("payload.operationID", "asc").orderBy("createdAt", "desc").limit(1)
      await events.get().then(snapshot => {
                    snapshot.forEach(async doc => {
                      let item = doc.data()
                      lastPipeline = item.payload.operationID
                    });
                  });
      return lastPipeline
    },
    async discard(eventId){
      let resp = { status: "error", error: false }
      await this.updateEvent(eventId, { discard: true } ).then(() => { resp.status = "success" } )    
      return resp
    },
    getEventTypes(type=false){
      let types = [
                    { id: 'all' },
                    { id: 'user.login',  name: 'Login' },
                    { id: 'user.logout', name: 'Logout' },
                    { id: 'user.saveprofile', name: 'User profile update' },
                    { id: 'user.sendverifyemail', name: 'Send profile verification' },
                    { id: 'user.updatepass', name: 'Password update' },
                    { id: 'dataset.upload.zip', name: 'Images uploaded by zip file', icon: 'UploadCloudIcon' },
                    { id: 'dataset.upload.image', name: 'Uploaded a single image', icon: 'UploadCloudIcon' },
                    { id: 'dataset.tag.update', name: 'Dataset tag updated', icon: 'TagIcon' },
                    { id: 'dataset.create', name: 'Dataset creation', icon: "PlusCircleIcon"},
                    { id: 'dataset.update', name: 'Dataset updated', icon: "DatabaseIcon"},
                    { id: 'dataset.import.vertex', name: 'Importing the dataset to Rosepetal AI Storage', icon: 'CornerLeftUpIcon' },
                    { id: 'dataset.download', name: 'Dataset downloaded in Zip format', icon: 'DownloadIcon' },
                    { id: 'dataset.upload.s3', name: 'Importing the dataset to Rosepetal AI Storage', icon: 'CornerLeftUpIcon' },
                    { id: 'dataset.create.vertex', name: 'Dataset creation in Rosepetal AI Platform', icon: 'GitPullRequestIcon' },
                    { id: 'dataset.datadivision.random', name: 'Data division random assignment', icon: 'PieChartIcon' },
                    { id: 'api.dataset.generateCsv.response', name: 'Generate Csv for upload', icon: 'FileTextIcon' },
                  ]
      if(type)return this.getEventName(types,type)         
      return types
    },
    getEventName(e,t){
      e.forEach( type => { if(type.id==t)t = type });
      return t;   
    }
  };
  
  const install = app => {
    app.config.globalProperties.$event = events;
  };
  
  export { install as default, events as event };