<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 mt-2">
      <div class="col-span-12 mr-5">

        <!-- Filters -->
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <div class="inline-block text-2xl font-medium mr-4">{{$t('Datasets')}}</div>
          <div class="dropdown" data-placement="bottom-start">
            <button class="btn px-2 box text-gray-700 hover:bg-green-800 hover:text-white" @click="layout.create='MULTICLASS'">
              <span class="w-5 h-5 flex items-center justify-center"><PlusIcon class="w-4 h-4" /></span>
            </button>
          </div>
          <button class="btn px-2 box text-gray-700 ml-1 hover:bg-blue-500 hover:text-white" @click="load()">
            <span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span>
          </button>
          <div class="mx-auto text-gray-600" v-if="listOptions.value">
            <select class="w-60 form-select p-2 cursor-pointer bg-white align-top" :disabled="layout.loading?true:false"
              v-model="listOptions.value.type" :class="listOptions.value.type ? layout.loading?'bg-blue-500':'bg-blue-500 text-white' : 'text-gray-600'">
              <option value="">{{$t('All types')}}</option>
              <option value="MULTICLASS">{{$t('Multiclass')}}</option>
              <option value="imageObjectDetection">{{$t('Object detection')}}</option>
            </select>
            <select class="w-60 form-select p-2 cursor-pointer ml-2 bg-white align-top" :disabled="layout.loading?true:false"
              v-model="listOptions.value.trained" :class="listOptions.value.trained ? layout.loading?'bg-blue-500':'bg-blue-500 text-white' : 'text-gray-600'">
              <option value="">{{$t('All')}}</option>
              <option :value="true">{{$t('Trained')}}</option>
            </select>  
            <select class="w-32 form-select p-2 cursor-pointer ml-2 text-gray-600 bg-white align-top" v-model="listOptions.value.order" :disabled="layout.loading?true:false">
              <option value="dateDesc">Date desc</option>
              <option value="dateAsc">Date asc</option>
              <option value="name">Name</option>
              <option value="images">Images</option>
            </select> 
            <select class="w-32 form-select p-2 cursor-pointer ml-2 text-gray-600 bg-white align-top" v-model="listOptions.value.limit" :disabled="layout.loading?true:false">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select> 
            <button class="btn px-1 box bg-theme-2 ml-2 hover:bg-blue-500 text-white" @click="reset()" title="Reset Filters" 
              v-if="listOptions.value.type || listOptions.value.trained || listOptions.value.search.length">
              <span class="w-5 h-5 flex items-center justify-center"><TrashIcon class="w-4 h-4" /></span>
            </button>
          </div>
          <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0" v-if="listOptions.value">
            <div class="w-56 relative text-gray-700">
              <input type="text" class="form-control w-56 box pr-10 placeholder-theme-8" maxlength="25" :class="listOptions.value.search ? 'bg-theme-1 text-white' : ''"
                v-model="listOptions.value.search" placeholder="Search..." v-on:input="search()"  />
                <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" :class="listOptions.value.search ? 'text-white' : ''" />
            </div>
          </div>
        </div>
        <div v-if="listOptions.value" class="mt-5">
          <div class="text-gray-600 text-center mb-3 text-xs" v-if="layout?.searchMsg && listOptions?.value?.search && listOptions?.value?.search.length">
            {{$t('Showing results for')}} "<span class="font-bold">{{listOptions.value.search.toString()}}</span>"
          </div>
          <div class="text-gray-600 text-center text-xs" v-if="(listOptions.value.type || listOptions.value.trained) && (listOptions.value.limit>datasets.length) && datasets.length">
              {{$t('Showing')}} {{datasets.length}} {{$t('datasets')}}
          </div>
        </div>
        <!--/Filters --> 

        <!-- Create -->
        <div class="rpoverlayDialog" v-if="layout.create"></div>
        <div id="rpDialogBox" v-if="layout.create">
          <div class="box">
            <div class="px-6 py-8 pb-3">
              <div class="text-normal mb-2 font-medium text-lg">{{ $t('Dataset name') }}</div>
                <div class="relative">
                  <input type="text" id="createDsName" class="form-control py-3 px-4 border-transparent pr-10 bg-gray-200 text-gray-700" :value="'untitled_'" required />
                  <div class="text-xs pt-2 text-gray-600">{{ $t('Use up to 32 characters, which can include letters and numbers') }}.</div>
                </div>
                <div class="text-normal mt-5 font-medium text-lg text-center">{{ $t('A brief description of the goal') }}</div>
                <div class="mt-3"><textarea id="createDsDesc" class="form-control rounded border  bg-gray-200 text-gray-700 p-3 h-16 bg-white"></textarea></div>
                <div class="text-normal mt-5 font-medium text-lg text-center">{{ $t('Select the objective of your model') }}</div>
                <div class="flex flex-col sm:flex-row px-5 py-2 mt-3 items-center justify-center text-xs">
                    <div class="cursor-pointer mr-6" :class="layout.create=='MULTICLASS' ? 'text-green-600' : 'text-gray-400'" @click="layout.create='MULTICLASS'"> 
                      <div class="text-center w-20"> <ImageIcon class="w-14 h-14" /><div class="mt-2">{{$t('Image classification')}}</div></div>
                    </div>
                    <div class="cursor-pointer mr-4" :class="layout.create=='imageObjectDetection' ? 'text-green-600' : 'text-gray-400'" @click="layout.create='imageObjectDetection'"> 
                      <div class="text-center w-20"> <MaximizeIcon class="w-14 h-14" /><div class="mt-2">{{$t('Object Detection')}}</div></div> 
                    </div>
                    <div :class="layout.create=='OCR' ? 'text-green-600' : 'text-gray-400'"> 
                      <div class="text-center w-20"> <TypeIcon class="w-14 h-14" /><div class="mt-2">{{$t('OCR')}}<br>{{$t('coming soon')}}</div></div> 
                    </div>
                </div>
                <div class="flex flex-col sm:flex-row mt-2 p-5 items-center justify-center text-xs">
                  <button class="btn bg-gray-100 w-1/3 mr-2 py-3 bg-white" @click="layout.create=false">{{$t('Cancel')}}</button>
                  <button class="bg-green-600 text-white w-1/3 py-3 rounded font-normal" @click="create()">{{$t('Create')}}</button>
              </div>
            </div>
          </div>
        </div>
        <!-- /Create -->
        
        <!-- Loading -->
        <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-0 p-10 mt-10" v-if="layout.loading">
          <div class="flex items-center justify-center">  
            <img :src="require(`@/assets/images/rosepetal/icon/loading2.gif`)" class="w-20" />
          </div>
          <div class="mb-3 mt-2 text-gray-600 w-60 text-center font-medium">{{$t('Loading, please wait...')}}</div>
          <div class="col-span-12 intro-y" v-if="layout.reloadMsg">
            <div class="alert alert-dismissible show box bg-theme-6 text-white flex items-center w-100" role="alert">
                <LoadingIcon icon="oval" color="white" class="w-6 h-6 mr-2" /> {{$t('Changes have been detected, updating...')}}
            </div>
          </div>
        </div>
        <!-- /Loading -->

        <div v-else>

          <!-- List -->
          <div class="mt-8 grid grid-cols-12 gap-3 mb-20" v-if="datasets.length">
            <div v-for="(d) in datasets" :key="d.id" class="intro-y col-span-3 lg:col-span-4 xxl:col-span-3">
              <div class="box hover:bg-gray-100 lg:h-48 border-t-4" :class="d.trained ? 'border-green-500' : (tagCounter[d.id] && !tagCounter[d.id].count) ? 'border-red-300' : ' border-gray-500'">
                <div class="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                  <a :href="'/dataset/'+d.id">
                    <div class="w-16 h-16 xxl:w-20 xxl:h-20 image-fit lg:mr-1" v-if="d?.preview"><img :src="d?.preview" class="w-full h-full rounded-full zoom-in" /></div>
                    <div class="w-16 h-16 xxl:w-20 xxl:h-20 image-fit lg:mr-1" v-else><img class="rounded-full border border-gray-300 zoom-in" :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" /></div>
                  </a>
                  <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                    <a class="font-medium">{{d.name.slice(0, 15)}} <span v-if="(d.name.length>15)">...</span></a>
                    <div class="text-gray-600 text-xs mt-0.5">{{ this.helper.capitalize(d.typeName.toLowerCase()) }}</div>
                    <span v-if="d.createdDate" class="text-gray-600 text-xs mt-0.5 font-italic" style="font-size:11px">{{d.createdDate}}</span>
                  </div>
                  <div class="flex justify-end mt-5 text-xs" v-if="tagCounter[d.id]">
                    <Tippy class="w-12 h-12 rounded-full flex items-center justify-center border ml-2 zoom-in bg-white cursor-default" content="Images" :class="tagCounter[d.id] && !tagCounter[d.id].count ? 'text-red-600 border-red-200 hover:bg-red-200' : 'text-gray-600'">
                      <span>{{this.helper.formatScore(tagCounter[d.id].count)}}</span>
                    </Tippy>
                    <Tippy class="w-12 h-12 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-600 zoom-in bg-white cursor-default" content="Labelel" v-if="tagCounter[d.id] && tagCounter[d.id].count">
                      <span v-if="tagCounter[d.id]?.labeled">{{this.helper.formatScore(tagCounter[d.id].labeled)}}</span><span v-else>0</span>
                    </Tippy>
                    <Tippy class="w-12 h-12 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-600 zoom-in bg-white cursor-default" content="Unclassified" v-if="tagCounter[d.id] && tagCounter[d.id].count">
                      <span v-if="tagCounter[d.id].nolabel">{{this.helper.formatScore(tagCounter[d.id].nolabel)}}</span><span v-else>0</span>
                    </Tippy>
                  </div>
                  <div class="absolute right-0 top-0 mr-5 mt-3 dropdown">
                    <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"><MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300"/></a>
                    <div class="dropdown-menu w-40">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                        <a :href="'/dataset/'+d.id" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"><Edit2Icon class="w-4 h-4 mr-2" /> {{ $t('Edit') }}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap lg:flex-nowrap items-center justify-center p-5 pb-0 pt-2">
                  <div class="w-full lg:w-2/3 lg:mb-0 mr-auto">
                    <div v-if="(tagCounter[d.id] && tagCounter[d.id].count) && !d?.trained && tagCounter[d.id]?.progessPercent" class="mt-2">
                      <div class="flex text-gray-600 text-xs">
                        <div class="mr-auto">{{$t('Progress')}}</div>
                        <div>{{tagCounter[d.id]?.progessPercent}}%</div>
                      </div>
                      <div class="progress h-1 mt-1 mb-3">
                        <div class="progress-bar w-1/4 bg-theme-6" :class="tagCounter[d.id]?.progessPercent==25 ? 'w-1/4' : tagCounter[d.id]?.progessPercent==50 ? 'w-2/4' : tagCounter[d.id]?.progessPercent==75 ? 'w-3/4' : 'w-1/4'"
                            role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <!--
                    <div v-if="d.description && d?.trained" class="text-xs text-gray-600 mb-2">{{this.helper.capitalize(d.description.slice(0, 135))}}<span v-if="(d.description.length>135)">...</span></div>
                    -->
                  </div>
                  <div class="flex justify-end text-xs sm:mt-2" v-if="tagCounter[d.id]">
                    <div v-if="status[d.id]?.inProgress"><button class="btn bg-theme-26 text-white text-white py-1 font-normal"><LoadingIcon icon="oval" color="white" class="w-2 h-2 mr-2" /> {{ this.helper.capitalize(status[d.id]?.action) }} </button></div>
                    <div v-else>
                      <div v-if="d?.trained" title="Trained"><CodesandboxIcon class="w-10 h-10 text-green-600 lg:mr-5 lg:-mt-2" /></div> 
                      <div v-else-if="(tagCounter[d.id] && !tagCounter[d.id].count)"></div>
                      <!--<div v-else><button class="text-gray-800 py-1 px-2 font-normal"><CheckIcon class="w-4 h-4 mr-1" /> {{ $t('Ready') }}</button></div>-->
                    </div>
                  </div>
                </div>
                <!--
                <div v-if="d.description && !d?.trained" class="text-xs text-gray-500 px-5">{{this.helper.capitalize(d.description.slice(0, 60))}}<span v-if="(d.description.length>60)">...</span></div>
                -->
              </div>
            </div>
          </div>
          <div v-else class="text-center justify-center mt-20 text-gray-600">
              <SearchIcon class="w-12 h-12" /> <h2 class="text-lg truncate mt-2 font-medium">{{ $t('No results found') }}</h2>
              <div>{{ $t("Sorry, that filter combination has no results") }}</div>
              <div class="mt-4 text-blue-500 text-xs cursor-pointer" @click="reset()">{{$t('reset filters')}}</div> 
          </div>
          <!-- /List -->

        </div> 
      </div>
      
    </div>  
  </div>
</template>
<script>

import { defineComponent, onMounted, onUnmounted, ref, reactive, watch} from "vue";
import * as rosepetalModel from "rosepetal-model";
import cash from "cash-dom";

export default defineComponent({
  created () {
    this.helper = rosepetalModel.helper
  },
  setup() {

    const layout      = ref({loading: true, create: false, searchMsg: false });
    const datasets    = ref([])
    const tagCounter  = ref({})
    const status      = ref({})
    const listOptions = reactive({})

    const load = async () => { 
      layout.value.loading  = true
      tagCounter.value      = {}
      datasets.value        = await rosepetalModel.dataset.list( listOptions.value )
      layout.value.loading  = false
      if(Object.keys(datasets.value).length){
        console.log('lengh',Object.keys(datasets.value).length)
        for (let index in datasets.value) { 
          try { 
            if(index && datasets.value && datasets.value[index]){
              tagCounter.value[datasets.value[index].id] = await rosepetalModel.dataset.getTagsCounter(datasets.value[index].id) 
            }
          } catch (error) { console.log("load error: "+error); }
        }
        for (let index in datasets.value) { 
          try { 
            if(index && datasets.value && datasets.value[index]){
              status.value[datasets.value[index].id]     = await rosepetalModel.dataset.getStatus(datasets.value[index].id) 
            }
          } catch (error) { console.log("load error: "+error); }
        }
      }
    }   

    const create = async () => { 
      layout.value.loading  = true
      await rosepetalModel.dataset.create(
                                          { name: cash("#createDsName").val() ? cash("#createDsName").val() : "", 
                                            type: layout.value.create, 
                                            description: cash("#createDsDesc").val() ? cash("#createDsDesc").val() : "" 
                                          })
      layout.value.create = false
      await reset()
      await load()
    }  

    const reset = async ( resSearch = true ) => { 
      if(listOptions?.value?.search)var currentSearch = listOptions.value.search
      listOptions.value   = { type: "", trained: "", search: "", limit: 10, order: "dateDesc", preview: true }
      if(!resSearch && currentSearch)listOptions.value.search = currentSearch
      if(resSearch)layout.value.searchMsg = false
    }  

    const search = async () => { 
      layout.value.searchMsg = true
      await reset(false)
      await load()
    }  

    onMounted(async () => {
      rosepetalModel.other.hi()
      await reset()
      layout.value.listener = rosepetalModel._firebase.firestore().collection('dataset').onSnapshot((snapshot) => {
        const changes = snapshot.docChanges();
        changes.forEach(async (change) => {
          if (!change?._delegate?.doc?.metadata?.hasPendingWrites && change.type === 'modified'){
            layout.value.reloadMsg = true
            await load()
          }
        });
      })
    });

    onUnmounted( async () => {
      layout.value.listener()
    });

    watch( listOptions , async () => { await load() });

    return {
      layout,
      datasets,
      tagCounter,
      status,
      listOptions,
      load,
      reset,
      create,
      search
    };
  }
});
</script>
<style>
.table-layout{ table-layout: fixed }
.tableListDatasets td{ padding: 5px 8px !important; margin-bottom: 2px !important; } 
.tableListDatasets .preview img{ padding: 5px !important; opacity: 0.4; }   
.tableListDatasets .preview img:hover{ opacity: 1 !important; } 
</style>