module.exports = { 
	project:  		require('./src/project'),
	helper:  		require('./src/helper'),
	dataset:  		require('./src/dataset'),
	image:  		require('./src/image'),
	model:  		require('./src/model'),
	event:  		require('./src/event'),
	controller: 	require('./src/controller'),
	_firebase:  	require('./src/firebase'),
	aws:  			require('./src/aws'),
	openai:  		require('./src/openai'),
	nodeRed:  		require('./src/nodeRed'),
	ui:  			require('./src/ui'),
	other: 			require('./src/other'),
}

