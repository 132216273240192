import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import Toastify from "toastify-js";
import cash from "cash-dom";
import moment from 'moment';
import { useI18n } from 'vue-i18n'

const stc = require('string-to-color');

dayjs.extend(duration);

const helpers = {
  cutText(text, length) {
    if (text.split(" ").length > 1) {
      const string = text.substring(0, length);
      const splitText = string.split(" ");
      splitText.pop();
      return splitText.join(" ") + "...";
    } else {
      return text;
    }
  },
  formatDate(date, format) {
    return dayjs(date).format(format);
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, "");
    } else {
      return "";
    }
  },
  formatCurrency(number) {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, "");
      const rest = formattedNumber.length % 3;
      let currency = formattedNumber.substr(0, rest);
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
      let separator;

      if (thousand) {
        separator = rest ? "." : "";
        currency += separator + thousand.join(".");
      }

      return currency;
    } else {
      return "";
    }
  },
  timeAgo(time) {
    const date = new Date(
      (time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")
    );
    const diff = (new Date().getTime() - date.getTime()) / 1000;
    const dayDiff = Math.floor(diff / 86400);

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format("MMMM DD, YYYY");
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && "just now") ||
          (diff < 120 && "1 minute ago") ||
          (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
          (diff < 7200 && "1 hour ago") ||
          (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
      (dayDiff === 1 && "Yesterday") ||
      (dayDiff < 7 && dayDiff + " days ago") ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + " weeks ago")
    );
  },
  diffTimeByNow(time) {
    const startDate = dayjs(
      dayjs()
        .format("YYYY-MM-DD HH:mm:ss")
        .toString()
    );
    const endDate = dayjs(
      dayjs(time)
        .format("YYYY-MM-DD HH:mm:ss")
        .toString()
    );

    const duration = dayjs.duration(endDate.diff(startDate));
    const milliseconds = Math.floor(duration.asMilliseconds());

    const days = Math.round(milliseconds / 86400000);
    const hours = Math.round((milliseconds % 86400000) / 3600000);
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    );

    if (seconds < 30 && seconds >= 0) {
      minutes += 1;
    }

    return {
      days: days.toString().length < 2 ? "0" + days : days,
      hours: hours.toString().length < 2 ? "0" + hours : hours,
      minutes: minutes.toString().length < 2 ? "0" + minutes : minutes,
      seconds: seconds.toString().length < 2 ? "0" + seconds : seconds
    };
  },
  isset(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === "object" || Array.isArray(obj)) {
        return Object.keys(obj).length;
      } else {
        return obj.toString().length;
      }
    }

    return false;
  },
  hexToRgb(hex, opacity = false) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      if(!opacity)opacity = 1
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
    }
    throw new Error('Bad Hex');
  },
  StringtoHex(str) {
    /*
    var hash = 0;
    if (str.length === 0) return hash;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    var color = '#';
    for (var ii = 0; ii < 3; ii++) {
      var value = (hash >> (ii * 8)) & 255;
      color += ('00' + value.toString(16)).substr(-2);
    }*/
    return stc(str);
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  randomNumbers(from, to, length) {
    const numbers = [0];
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to));
    }
    return numbers;
  },
  isObject(obj){ return typeof obj == "object"  },
  formatScore(value) { let val = (value/1).toFixed(0).replace('.', ','); return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") },
  Notification (not) {
    Toastify({
       node: cash(not).clone().removeClass("hidden")[0],
       duration: 1500,
       newWindow: true,
       gravity: "top",
       position: "right",
       stopOnFocus: true
       }).showToast();
   },
   NotificationTxt (not) {
    let text            = ""; if(not.text)text = not.text;
    let transition      = "all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1)"; if(not.transition)transition = not.transition;
    let backgroundColor = "linear-gradient(to right, #fff, #fff)"; if(not.backgroundColor)backgroundColor = not.backgroundColor;
    let duration = 2500; if (not.duration) duration = not.duration;
    Toastify({
       text: text,
       transition: transition,
       backgroundColor: backgroundColor,
       duration: duration,
       newWindow: true,
       gravity: "top",
      position: not.position ? not.position : "right",
       stopOnFocus: true,
       className: "toastify-content flex",
       }).showToast();
   },
   getObjectProperty(objs,type=false) {
    let Objects = {}
    for (const obj of objs) {
      let path      = ""
      let props     = []
      let methods   = []
      let functions = []
      let actions   = []
      let componts  = []
      let include   = true
      if(type && type!=obj.type)include  = false
      /*if(c.id=='helper'){ console.log(c.component) }*/
      Object.getOwnPropertyNames(obj.component).sort().forEach(function(m) { 
        if(m=='__file')path = obj.component[m]
        if(m=='props')props.push(obj.component[m])
        if(m=='methods')methods.push(obj.component[m])
        if(m=='actions')actions.push(obj.component)
        if(typeof obj.component[m] == 'function' && m!='render' && m!='setup')functions.push(m);
        if(typeof m == 'function')functions.push(m);
        if(m=='components')componts.push(obj.component[m])
      });
      if(include){
        Objects[obj.id] = [];
        let newItem = { id: obj.id, path: path, props: props , methods: methods, functions: functions, component: obj.component, type: obj.type, actions: actions, components: componts }
        if(obj.link)newItem.link = obj.link;
        Objects[obj.id].push(newItem)
      }
    }
    return Objects;
   },
   getIcons(){ return ['ActivityIcon','AlignJustifyIcon','BoxIcon','BookmarkIcon','CpuIcon','GithubIcon','ClockIcon','DatabaseIcon','Edit3Icon','EditIcon','FlagIcon','GridIcon','HashIcon','ShoppingCartIcon','ToggleLeftIcon','PieChartIcon','TerminalIcon','TrendingUpIcon','TriangleIcon','UploadCloudIcon','UserIcon','UsersIcon']},
   getBrowsers(){ return ['chrome','edge','mozilla','opera','safari'] },
   getTimestampDate(d,type=false){
    let date = new Date(d); 
     if(type){
      if(type=='full')return moment(date).format("DD/MM/YYYY HH:mm:ss"); 
      else if(type=='date'){
        if(moment().format("DD/MM/YYYY")==moment(date).format("DD/MM/YYYY")){
          const {t} = useI18n(); return t('Today')
        }
        return moment(date).format("DD/MM/YYYY")
      }
      else if(type=='time')return moment(date).format("HH:mm:ss")
      else return moment(date).format("DD/MM/YYYY HH:mm:ss");
     }else{ return moment(date).format("DD/MM/YYYY HH:mm:ss"); } 
   },
   getFbDate(d){
    return this.getTimestampDate((d.seconds * 1000 + d.nanos / 1000000),'date')
   },
   getUnixDate(d){
    return moment.unix(d/1000).format("DD MMM YYYY hh:mm a")
   },
   orderParams(o){ return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {}); },
   sortArray(obj){ 
    return obj.sort((a,b)=> (a > b ? 1 : -1)) 
   },
   sortObject(obj,key){ 
    return obj.sort((a,b)=> (a[key] > b[key] ? 1 : -1)) 
   },
   dateRangeLabel(d){
    let date = d.toString().split(' - ')
    if(date[0] && date[1] && date[0]==date[1])return date[0]
    return d; 
   },
   dragElement(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if(elmnt){
      if (document.getElementById(elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }
   },
  getColors() {
    return ['96e061', 'ffff00', '00FFFF', '7FFFD4', 'F0FFFF', 'F5F5DC', 'FFE4C4', '000000', 'FFEBCD', '0000FF',
      '8A2BE2', 'A52A2A', 'DEB887', '5F9EA0', '7FFF00', 'D2691E', 'FF7F50', '6495ED', 'FFF8DC', 'DC143C',
      '00FFFF', '00008B', '008B8B', 'B8860B', 'A9A9A9', '006400', 'A9A9A9', 'BDB76B', '8B008B', '556B2F',
      'FF8C00', '9932CC', '8B0000', 'E9967A', '8FBC8F', '483D8B', '2F4F4F', '00CED1', '9400D3', 'FF1493',
      '00BFFF', '696969', '696969', '1E90FF', 'B22222', 'FFFAF0', '228B22', 'FF00FF', 'DCDCDC', 'F8F8FF',
      'FFD700', 'DAA520', '808080', '008000', 'ADFF2F', '808080', 'F0FFF0', 'FF69B4', 'CD5C5C', '4B0082',
    ];
   }
};

const install = app => {
  app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };
