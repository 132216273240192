<template>
  <div>
    <div class="grid  grid-cols-12 gap-6 mb-3" v-if="!curModel">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
        <button class="btn btn-outline-primary w-fill mr-2">
            <CpuIcon class="w-4 h-4 mr-2" /> {{ $t('Train a new Model') }}
        </button>
      </div>
    </div>
    <!-- List -->
    <div class="grid  grid-cols-12 gap-6 mb-10 mt-5" v-if="!curModel">
      <div class="intro-y col-span-12 lg:col-span-6" v-for="(model) in models" :key="model.modelID">
        <div class="box">
            <div class="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                <div class="w-12 h-12 lg:w-12 lg:h-12 image-fit lg:mr-1">
                    <div class="w-12 h-12 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 pt-1 text-center hover:bg-theme-17 bg-theme-6" @click="curModel=model.modelID" role="button" aria-expanded="false">
                        <span class="text-white text-3xl">{{  model.displayName.charAt(0) }}</span>
                    </div>
                </div>
                <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                    <a class="text-lg font-medium">{{ model.displayName }}</a> 
                    <div class="text-xs">{{ $t('ID') }}: {{ model.modelID }}</div>
                </div>
                <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                    <div class="box p-2 zoom-in bg-theme-7 ">
                        <div class="flex items-center" @click="curModel=model.modelID" title="Manage">
                            <div class="flex-none ml-auto relative"><SettingsIcon class="w-5 h-5" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap lg:flex-nowrap items-center justify-center p-5 mt-1">
                <div class="w-full lg:w-2/3 lg:pr-10 mb-4 lg:mb-0 mr-auto">
                    <div class="flex text-gray-600 text-xs" v-if="model.deploymentState">
                        <div class="mr-auto">{{ $t('Implementation status') }}:</div>
                        <div>{{  model.deploymentState }}</div>
                    </div>
                    <div class="flex text-gray-600 text-xs">
                        <div class="mr-auto">{{ $t('Model metadata') }}:</div>
                        <div v-if="model.modelMetadata=='imageClassificationModelMetadata'">Metadata for image classification models.</div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!-- End List -->

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">      
        <!-- Models -->
        <div :class="curModel ? '' : 'hidden'">
          <div v-for="(model) in models" :key="model.modelID">
              <div class="intro-y col-span-12 lg:col-span-6 mb-5" v-if="model.modelID == curModel">
                  <div class="box">
                      <div class="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                          <div class="w-12 h-12 lg:w-12 lg:h-12 image-fit lg:mr-1">
                              <div class="w-12 h-12 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 pt-1 text-center hover:bg-theme-17 bg-theme-6" @click="curModel=model.modelID" role="button" aria-expanded="false">
                                  <span class="text-white text-3xl">{{  model.displayName.charAt(0) }}</span>
                              </div>
                          </div>
                          <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                              <a class="text-lg font-medium">{{ model.displayName }}</a> 
                              <div class="text-xs">{{ $t('ID') }}: {{ model.modelID }}</div>
                          </div>
                          <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                              <div class="box p-2 zoom-in bg-theme-7 mr-2">
                                  <div class="flex items-center" v-on:click="ModelEvaluation = false; ModelMetadata = false; ModelInfo = true;" title="Model details">
                                      <div class="flex-none ml-auto relative"><InfoIcon class="w-5 h-5" /></div>
                                  </div>
                              </div>
                              <div class="box p-2 zoom-in bg-theme-13 mr-5">
                                  <div class="flex items-center" @click="ModelEvaluation = false; ModelMetadata = true; ModelInfo = false;" title="Metadata for image classification">
                                      <div class="flex-none ml-auto relative"><AlignLeftIcon class="w-5 h-5" /></div>
                                  </div>
                              </div>
                              <div class="box p-2 zoom-in bg-theme-23 mr-5">
                                  <div class="flex items-center" @click="getEvaluation(model.modelID)" title="Evaluate model">
                                      <div class="flex-none ml-auto relative"><CodepenIcon class="w-5 h-5" /></div>
                                  </div>
                              </div>
                              <div class="w-full sm:w-auto flex mt-4 sm:mt-0"><button class="btn btn-primary shadow-md mr-2" @click="curModel=false">{{ $t('Back') }}</button></div>
                          </div>
                      </div>
                      <div class="flex flex-wrap lg:flex-nowrap items-center justify-center p-5 mt-1">
                          <div class="w-full lg:w-1/3 lg:pr-10 mb-4 lg:mb-0 mr-auto">
                              <div class="flex text-gray-600 text-xs">
                                  <div class="mr-auto">{{ $t('Creation date') }}</div>
                                  <div>{{ this.helper.getUnixDate(model.createTime.seconds) }}</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="intro-y box " v-if="model.modelID == curModel">
                  <div class="p-5">
                    <div class="preview pb-5">
                      <!-- Model Evaluation -->
                      <div :class="ModelEvaluation ? '' : 'hidden'" class="mb-5">
                         <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 lg:col-span-7">      
                              <div class="text-base pb-3 font-bold">{{$t('Model evaluation')}}</div>
                              <div div class="inline-block align-top pr-10 w-64"> 
                                <div class="text-base">{{$t('Confidence limit')}}</div>
                                  <select class="form-select mt-2 sm:mr-2 cursor-pointer bg-gray-600 font-bold text-white w-40" v-if="ModelEvaluation[0] && ModelEvaluation[0].metrics && ModelEvaluation[0].metrics.precisionThreshold" @change="setConfidenceLimit($event)">
                                      <option v-for="(t,k) in ModelEvaluation[0].metrics.precisionThreshold" v-bind:key="k" :selected="k==confidenceLimit">{{k}}</option>
                                  </select>
                                  <div class="text-xs pt-2">{{$t('Modify the limit to see which confidence threshold works best for your model')}}.</div>
                                  <br>
                                  <a href="javascript:;" data-toggle="modal" data-target="#model-info-box" class="mr-1 mb-2"><InfoIcon class="w-4 h-4 mr-1" />{{ $t('More information') }}</a>
                              </div>
                              <div div class="inline-block align-top"> 
                                <table class="table table--sm w-full">
                                  <tbody>
                                      <tr class="hover:bg-gray-200">
                                        <td></td>
                                        <td class="border">{{$t('Efficiency')}}</td>
                                        <td class="border">{{$t('Precision')}}</td>
                                        <td class="border">{{$t('Recovery')}}</td>
                                        <td class="border">{{$t('Total images')}}</td>
                                        <td class="border">{{$t('elements')}}<br>{{$t('test')}}</td>
                                      </tr>
                                      <tr class="hover:bg-gray-200" v-for="me in ModelEvaluation" v-bind:key="me.id">
                                        <td class="border"><span v-if="me.displayName">{{ me.displayName }}</span><span v-else>{{$t('All tags')}}</span></td>
                                        <td class="border text-center" v-if="me.metrics && me.metrics.auPrc" :class="me.metrics.auPrc==1 ? 'bg-green-100' : 'bg-gray-200'">
                                          {{ me.metrics.auPrc }}
                                        </td>
                                        <td class="border text-center" v-if="me.metrics.precisionThreshold[confidenceLimit]" :class="me.metrics.precisionThreshold[confidenceLimit]==100 ? 'bg-green-100' : 'bg-gray-200'">
                                          {{me.metrics.precisionThreshold[confidenceLimit]}}%
                                        </td><td v-else class="border">-</td>
                                        <td class="border text-center" v-if="me.metrics.recallThreshold[confidenceLimit]" :class="me.metrics.recallThreshold[confidenceLimit]==100 ? 'bg-green-100' : 'bg-gray-200'">
                                          {{me.metrics.recallThreshold[confidenceLimit]}}%
                                        </td><td v-else class="border">-</td>
                                        <td class="border text-center">
                                          <span v-if="model.dataset">
                                            <span v-if="model.dataset.exampleCount && me.evaluatedExampleCount">
                                              {{ this.helper.formatScore(model.dataset.exampleCount-me.evaluatedExampleCount) }}
                                            </span>
                                          </span>
                                        </td>
                                        <td class="border text-center">{{ this.helper.formatScore(me.evaluatedExampleCount) }}</td>
                                      </tr>
                                    </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="col-span-12 lg:col-span-5">   
                              <div v-if="DataMatrix">
                                  <div class="text-base pb-2 font-bold">{{ $t('Confusion matrix') }}</div>
                                  <table class="table table--sm mb-5 w-full lg:w-2/3  mb-5">
                                    <tbody>
                                        <tr class="hover:bg-gray-200">
                                          <td class="w-40"></td>
                                          <td :colspan="DataMatrix.displayName.length"><span class="text-gray-500">{{$t('Predicted label')}}</span></td>
                                        </tr>
                                        <tr class="hover:bg-gray-200">
                                          <td class="w-40"><span class="text-gray-500">{{$t('Trust tag')}}</span></td>
                                          <td class="text-center font-bold datamatrixColHeader" v-for="name in DataMatrix.displayName" v-bind:key="name">{{name}}</td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-for="(name,idx1) in DataMatrix.displayName" v-bind:key="name">
                                          <td class="border text-center w-40">{{name}}</td>
                                          <td v-for="(val,idx2) in DataMatrix.row[idx1].exampleCount" v-bind:key="val" class="border text-center" 
                                            :class="!val ? '' : idx2==idx1 ? 'bg-blue-500 text-white' : 'bg-gray-500'">
                                            <span v-if="val==0">-</span><span v-else>
                                              {{ this.helper.formatScore(val) }}
                                            </span>
                                            <br>
                                            <span class="text-xs" v-if="val">{{ Math.round(((val / DataMatrix.rowsCount[idx1]) * 100).toFixed(2)) }}%</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                  </table>
                                  <div class="text-xs">{{$t('This table shows how often the model classified')}}<br />{{$t('each label correctly')}} ({{$t('on')}} <span class="text-blue-500">{{$t('color blue')}}</span>) {{$t('on')}}<br />{{$t('most often confused with that label')}} ({{$t('on')}} <span class="text-gray-500">{{$t('color gray')}}</span>).</div>
                              </div> 
                              <div id="model-info-box" class="modal modal-slide-over" tabindex="-1" aria-hidden="true">
                                  <div class="modal-dialog modal-sm">
                                      <div class="modal-content">
                                          <div class="modal-header p-5">
                                              <h2 class="font-medium text-base mr-auto">{{$t('Model evaluation')}}</h2>
                                          </div>
                                          <div class="modal-body">
                                              <div class="text-xs"><span class="font-bold">{{$t('Scoring threshold')}}</span><br />{{$t('The score threshold refers to the level of confidence that the model must have to assign a category to a test item')}}.<br><br> {{$t('If the score threshold is low, your model will classify more images, but you run the risk of misclassifying some images in the process')}}.<br><br> {{$t('If the scoring threshold is high, your model will classify fewer images, but have a lower risk of misclassifying images')}}.</div>
                                              <div class="text-xs mt-5"><span class="font-bold">{{$t('Precision')}}</span><br />{{$t('A high-precision model produces fewer false positives')}}.</div>
                                              <div class="text-xs mt-5"><span class="font-bold">{{$t('Recovery')}}</span><br />{{$t('A model with a high degree of recovery produces fewer false negatives')}}.</div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <!-- End Model Evaluation -->     
                      <!-- Model Details -->
                      <div v-if="ModelInfo" class="mb-5">
                        <div class="text-base pb-1 font-bold">{{$t('Model details')}}</div>
                        <table class="table table--sm mt-3">
                            <tbody>
                                <tr class="hover:bg-gray-200" v-if="model.modelID"><td class="border">{{$t('Model ID')}}</td><td class="border font-medium">{{ model.modelID }}</td></tr>
                                <tr class="hover:bg-gray-200" v-if="model.dataset && model.dataset.displayName">
                                  <td class="border">{{$t('Dataset')}}</td>
                                  <td class="border font-medium">
                                    <div class="cursor-pointer" v-on:click="curModel=false; curDataset=model.dataset.id">{{ model.dataset.displayName }}</div>
                                  </td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.name">
                                  <td class="border">
                                    <Tippy tag="a" href="javascript:;"
                                        :content="$t('Format: projects / {project_id} / locations / {locationId} / models / {modelId}')">
                                        {{$t('Model resource name')}}
                                    </Tippy>
                                  </td>
                                  <td class="border font-medium">{{ model.name }}</td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.displayName">
                                  <td class="border">
                                    <Tippy tag="a" href="javascript:;"
                                        :content="$t('The name of the model that is displayed in the interface. The name can be up to 32 characters long and can only consist of ASCII Latin letters A-Z and a-z, underscores (_), and ASCII digits 0-9. It must start with a letter')">
                                        {{$t('Internal name')}}
                                    </Tippy>
                                  </td>
                                  <td class="border font-medium">{{ model.displayName }}</td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.createTime">
                                  <td class="border">
                                    <Tippy tag="a" href="javascript:;"
                                        :content="$t('Timestamp when model training finished and can be used for prediction')">
                                        {{$t('Creation date')}}
                                    </Tippy>
                                  </td>
                                  <td class="border font-medium">{{ this.helper.getUnixDate(model.createTime.seconds) }}</td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.updateTime">
                                  <td class="border">
                                    <Tippy tag="a" href="javascript:;"
                                        :content="$t('Timestamp of the last update of this model')">
                                        {{$t('Update date')}}
                                    </Tippy>
                                  </td>
                                  <td class="border font-medium">{{ model.updateTime.seconds }} {{ this.helper.getUnixDate(model.updateTime.seconds) }}</td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.deploymentState">
                                  <td class="border">
                                    <Tippy tag="a" href="javascript:;"
                                        :content="$t('Can only serve prediction requests after deployment')">
                                        {{$t('Model implementation status')}}
                                    </Tippy>
                                  </td>
                                  <td class="border font-medium">{{ model.deploymentState }}</td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.modelMetadata">
                                  <td class="border">
                                    <Tippy tag="a" href="javascript:;"
                                        :content="$t('It must match the metadata type of the dataset used to train the model')">
                                        {{$t('Model metadata')}}
                                    </Tippy>
                                  </td>
                                  <td class="border font-normal">
                                    <div v-if="model.modelMetadata=='imageClassificationModelMetadata'" class="font-medium">Metadata for image classification models.</div>
                                    {{ model.modelMetadata }}
                                  </td>
                                </tr>
                            </tbody>
                        </table>
                      </div>
                      <!-- End Model Details -->
                      <!-- Model Metadata -->
                      <div v-if="ModelMetadata">
                        <div class="text-base pb-1 font-bold">{{$t('Metadata for image classification')}}</div>
                        <table class="table table--sm mt-3">
                            <tbody>
                                <tr class="hover:bg-gray-200">
                                  <td class="border w-40"><Tippy tag="a" href="javascript:;" content="baseModelId">{{$t('Base model ID')}}</Tippy></td>
                                  <td class="border font-medium"><span v-if="model.imageClassificationModelMetadata.baseModelId">{{ model.imageClassificationModelMetadata.baseModelId }}</span><span v-else>{{$t('None')}}</span></td>
                                  <td class="border">
                                  <span class="text-xs">{{$t('If specified, the new model will be created based on the base model')}}.<br>{{$t('Otherwise the new model will be created from scratch')}}.<br>{{$t('The base model must be in the same project and location as the new model to create, and have the same model type')}}.</span>
                                  </td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.stopReason">
                                  <td class="border"><Tippy tag="a" href="javascript:;" content="stopReason">{{$t('Reason for stop')}}</Tippy></td>
                                  <td class="border font-medium">{{ model.imageClassificationModelMetadata.stopReason }}</td>
                                  <td class="border">
                                  <span class="text-xs">{{$t('The reason this model build operation stopped')}}.</span>
                                  </td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.modelType">
                                  <td class="border"><Tippy tag="a" href="javascript:;" content="modelType">{{$t('Model type')}}</Tippy></td>
                                  <td class="border font-medium">
                                    <span v-if="model.imageClassificationModelMetadata.modelType=='mobile-high-accuracy-1'">
                                      <Tippy tag="a" href="javascript:;" :content="model.imageClassificationModelMetadata.modelType">{{$t('Greater accuracy on mobile device')}}</Tippy>
                                    </span>
                                    <span v-else>{{ model.imageClassificationModelMetadata.modelType }}</span>
                                  </td>
                                  <td class="border">
                                  <span class="text-xs" v-if="model.imageClassificationModelMetadata.modelType=='mobile-high-accuracy-1'">
                                    {{$t('This is a model that, in addition to providing predictions via the AutoML API, can also be exported (see AutoMl.ExportModel) and then used on a mobile or edge device with TensorFlow. It is expected to have higher latency, but it should also have higher prediction quality than other models')}}
                                  </span>
                                  <span class="text-xs" v-if="model.imageClassificationModelMetadata.modelType=='cloud'">
                                    {{$t('Model to be used by prediction calls to the AutoML API. This is the default value')}}. 
                                  </span>
                                  </td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.nodeQps">
                                  <td class="border">NodeQps</td>
                                  <td class="border font-medium">{{ model.imageClassificationModelMetadata.nodeQps }}</td>
                                  <td class="border"><span class="text-xs">{{$t('Approximate number of online prediction QPS this model can support for each node where it is deployed')}}.</span></td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.nodeCount">
                                  <td class="border">nodeCount</td>
                                  <td class="border font-medium">{{ model.imageClassificationModelMetadata.nodeCount }}</td>
                                  <td class="border"><span class="text-xs">{{$t('The number of nodes in which this model is implemented')}}.<br /> {{$t('A node is an abstraction of a machine resource, which can handle the QPS online prediction as indicated in the nodeQps field')}}.</span></td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.trainBudgetMilliNodeHours">
                                  <td class="border"><Tippy tag="a" href="javascript:;" content="trainBudgetMilliNodeHours">{{$t('Budget to')}}<br /> {{$t('creating this model')}}</Tippy></td>
                                  <td class="border font-normal">
                                    <div class="font-medium">{{ Number.parseFloat(model.imageClassificationModelMetadata.trainBudgetMilliNodeHours/1000).toFixed(2) }} {{$t('Node hours')}}</div>
                                    <span class="text-xs">{{$t('Value')}}: {{ model.imageClassificationModelMetadata.trainBudgetMilliNodeHours }}</span>
                                  </td>
                                  <td class="border"><span class="text-xs">{{$t('The train budget for the creation of this model, expressed in milli node hours, that is, the value 1000 in this field means 1 node hour. The actual train cost will be equal to or less than this value')}}.<br />{{$t('If the additional training of the model stops providing improvements, it will be stopped without using the full budget and the reason for the stop will be MODEL_CONVERGED')}}.</span></td>
                                </tr>
                                <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.trainCostMilliNodeHours">
                                  <td class="border"><Tippy tag="a" href="javascript:;" content="trainCostMilliNodeHours">{{$t('Real cost')}}</Tippy></td>
                                  <td class="border font-normal">
                                    <div class="font-medium">{{ Number.parseFloat(model.imageClassificationModelMetadata.trainCostMilliNodeHours/1000).toFixed(2) }} {{$t('Node Processing Hours')}}</div>
                                    <span class="text-xs">{{$t('Value')}}: {{ model.imageClassificationModelMetadata.trainCostMilliNodeHours }}</span>
                                  </td>
                                  <td class="border"><span class="text-xs">{{$t('The actual train cost of creating this model, expressed in milli node hours, that is, the value of 1,000 in this field means 1 node hour. Guaranteed not to exceed the train budget')}}.</span></td>
                                </tr>
                            </tbody>
                        </table>
                      </div> 
                      <!-- End Model Metadata -->
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { model as $model } from "@/model/model";
import { helper as $h } from "@/utils/helper";
import { event as $event } from "@/model/events";
//mport { useStore } from "@/store";

export default defineComponent({
  created () {
    this.helper = $h
  },  
  setup() {

    const models     = ref([])
    const datasets   = ref(false)
    const curModel   = ref()
    const curDataset = ref()
    const DataMatrix = ref(false)
    const createDataset   = ref(false);
    const createModel     = ref(false);
    const confidenceLimit = ref(0.5)
    const ModelInfo       = ref(true)
    const ModelMetadata   = ref(false)
    const ModelEvaluation = ref(false)
    const ImportForm      = ref(false)
    const ImportLog       = ref(false)
    const TrainingForm    = ref(false)
    const TrainingLog     = ref(false)
    const DatasetModels   = ref([])

    const ChangeModel = (m) => {  curModel.value = m; confidenceLimit.value = 0.5; ModelEvaluation.value = false;  ModelEvaluation.value = false; DataMatrix.value = false;};
    const ChangeDataset = async (d) => { 
      curDataset.value = d; ImportForm.value = TrainingForm.value = false; await checkImport(); await checkTraining(); await getDatasetModels(curDataset.value)
    }
    
    const getEvaluation = async (modelID) => { 
      
      ModelEvaluation.value = await $model.getEvaluation(modelID); 
      if(ModelEvaluation.value){
        DataMatrix.value = await $model.getEvaluationDatamatrix(ModelEvaluation.value)
        ModelInfo.value = ModelMetadata.value = false;
      }
    };
    
    const setConfidenceLimit = (e) => { confidenceLimit.value = e.target.value }
    const importCSV = () => { ImportForm.value = true; }
    const TrainingDataset = () => { TrainingForm.value = true; }
    
    const LoadDataset = async () => {
      datasets.value = await $model.getDataset(); if(datasets.value[0])curDataset.value = datasets.value[0].id 
      await getDatasetModels(curDataset.value)
      await checkImport()
      await checkTraining()
    }
    const LoadModels = async () => {
      models.value   = await $model.get(); 
    }
    const getDatasetModels = async (datasetId) => {
      DatasetModels.value = [];
      for (let i = 0; i < models.value.length; i++) {
        if(models.value[i].dataset.id==datasetId)DatasetModels.value.push(models.value[i])
      }
    }
    const checkImport = async () => {
      ImportLog.value = await $event.get({ type: "dataset.import" , dataset: curDataset.value})
    }
    const checkTraining = async () => {
      TrainingLog.value = await $event.get({ type: "dataset.training" , dataset: curDataset.value})
    }
    onMounted( async () => { 
      await LoadDataset()
      await LoadModels();
    });

    return {
      models,
      datasets,
      curModel,
      curDataset,
      createDataset,
      createModel,
      confidenceLimit,
      DataMatrix,
      ImportForm,
      importCSV,
      ImportLog,
      ChangeModel,
      ChangeDataset,
      LoadDataset,
      TrainingForm,
      TrainingDataset,
      TrainingLog,
      ModelEvaluation,
      getEvaluation,
      setConfidenceLimit,
      ModelInfo,
      ModelMetadata,
      DatasetModels
    };
  },
  methods:{
   getApiCsv: async function(){
      this.$refs["apicsvload"].classList.value = ''
      this.$refs["apicsvlink"].classList.value = ['hidden']
      let res = await $model.generateDataset()
      if(this.$refs["datasetcsv"] && res.training)this.$refs["datasetcsv"].value = res.training.dataset 
      this.$refs["apicsvload"].classList.value = ['hidden']
      this.$refs["apicsvlink"].classList.value = ''
    }
  }
});
</script>
<!--
<style>
.datamatrixColHeader{transform: translateX(-10%) translateY(-5%) rotate(-45deg) !important; width:70px;}
</style>
-->