//import { _firebase as $_firebase } from "@/model/firebase";
import { useStore } from "@/store";
import * as rosepetalModel from "rosepetal-model";

import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { fabric } from 'fabric'

//COCO SSD
require('@tensorflow/tfjs-backend-cpu')
require('@tensorflow/tfjs-backend-webgl')
const tf       = require("@tensorflow/tfjs");
const cocoSsd  = require('@tensorflow-models/coco-ssd')

import { helper as $h } from "@/model/db/helper";
import { dataset as $dataset } from "@/model/db/dataset";
import { event as $event } from "@/model/db/event";

var $_firebase = rosepetalModel._firebase;

const images = {
    async get(imageId) {
       let image = {}
       let snapshot = await $_firebase.firestore().collection('image').doc(imageId).get(); 
       image = snapshot.data(); if(image)image.id = snapshot.id
       return image;
    },
    async getStorageUri(imageId){
        let item = await this.get(imageId)
        return { uri: item && item.uri ? item.uri : "NOT FOUND", error: item && item.uri ? false : true };
    },
    async getStorageUrl(uri) {
        let url = { uri: uri, url: false, error: false }
        try { 
            url.url     = await $_firebase.storage().refFromURL(uri).getDownloadURL(); 
            url.meta    = await $_firebase.storage().refFromURL(uri).getMetadata()
        } catch (error) { url.error = error }
        return url
     },
     async delete(imageId) {
        await $_firebase.firestore().collection('image').doc(imageId).delete().then( async() => {
            await $event.saveEvent('image.delete',{ uid: useStore().state.main.User.uid, imageId: imageId }, false);
            return { error: false, status: "success" }
        })
     },
     async getSet(imageId){
        let item = await this.get(imageId)
        return item && item.set ? item.set : "PREDETERMINED";
     },
     async setSet(imageId, set) {
        if(!imageId || !set)return { error: "ImageId and set are required", status: "error" }
        await $_firebase.firestore().collection("image").doc(imageId.toString()).update({ "set": set });
        await $event.saveEvent('image.update',{ uid: useStore().state.main.User.uid, imageId: imageId, set: set }, false);
        return { error: false, status: "success" }
     },
     async getComments(imageId) {
        let item = await this.get(imageId)
        return { imageId: imageId, name: item.name, comments: item && item.comments ? item.comments : "" }
     },
     async setComments(imageId, comments) {
        if(!imageId || !comments)return { error: "ImageId and comments are required", status: "error" }
        await $_firebase.firestore().collection("image").doc(imageId.toString()).update({ "comments": comments });
        await $event.saveEvent('image.update',{ uid: useStore().state.main.User.uid, imageId: imageId, comments: comments }, false);
        return { error: false, status: "success" }
     },
     async getTags(imageId){
        let item = await this.get(imageId)
        let resp = { image: imageId, dataset: null, tags: [] }
        if(item.updatedAt)resp.updatedAt = $h.getTimestampDate(item.updatedAt.toDate(),'full')
        if (item.tags && item.tags.length) {
            let tagsArr = []; let nameArr = []; let bbArr   = []; let colorArr   = []
            for (let obt in item.tags) {
                if (item.tags[obt].tag) {
                    let tagRef  = item.tags[obt].tag.path.toString().split('/')
                    if(tagRef[1])resp.dataset = tagRef[1]
                    let tagName = false
                    if (tagRef[tagRef.length-1])tagName = tagRef[tagRef.length-1]
                    if (tagName) { if (!tagsArr[tagName]) { tagsArr[tagName] = 1 } else { tagsArr[tagName]++; } }
                    let tagData = await $dataset.getTag(tagRef[1], tagName)
                    if(tagData){
                     if(tagData.name && !nameArr[tagName])nameArr[tagName] = tagData.name
                     if(tagData.color && !colorArr[tagName])colorArr[tagName] = tagData.color
                    }
                    let bb = { 
                              h: item.tags[obt].h, 
                              w: item.tags[obt].w, 
                              x: item.tags[obt].x, 
                              y: item.tags[obt].y, 
                              type: item.tags[obt].type 
                             }
                    if (!bbArr[tagName])bbArr[tagName] = []; 
                    bbArr[tagName].push(bb)
                }
            }
            if (Object.keys(tagsArr).length) {
                for (let objectTag in tagsArr) { 
                  resp.tags.push({ tag: objectTag, name: nameArr[objectTag] ? nameArr[objectTag]: objectTag, color: colorArr[objectTag] ? colorArr[objectTag]: "#000", count: tagsArr[objectTag], bounding_box: bbArr[objectTag] ? bbArr[objectTag]: [] }); 
               }
            } else { resp.response = "IMAGE NOT LABELED" } 
            return resp 
        } else {
            if(item.tag){
                let tagName = item.tag.path.toString().split('/')
                let tags    = []
                let tagData = await $dataset.getTag(tagName[1], tagName[3])
                tags.push({ tag: tagName[3] , name: tagData.name, color: tagData.color , count: 1 })
                resp.dataset = tagName[1]
                resp.tags    = tags
                return resp
            } else { resp.response = "IMAGE NOT LABELED"; return resp }
        }
     },
     async setTags(imageId, tags) {
      let resp = { status: "error", error: false}
      if(imageId && tags){
         let item = await this.get(imageId)
         if(item.id){
            resp.image      = imageId
            let dataset     = item.dataset.path.toString().split('/')
            if(dataset[1]){
               if(Array.isArray(tags)){
                  if(tags.length){
                     let datasetHaveTags = []
                     let newImageTags    = []
                     for (var i = 0; i < tags.length; i++) { 
                        let datasetTags  = await $dataset.getTags(dataset[1].toString())
                        let pushTag      = true
                        if(!datasetTags[tags[i].tag]){ 
                           let created = await $dataset.createTag(dataset[1].toString(), { tag: tags[i].tag })
                           if(!created.status || created.status!="success"){ pushTag = false; datasetHaveTags.push(tags[i].tag) }
                        }
                        if(pushTag){
                           tags[i].tag = await $_firebase.firestore().collection("dataset").doc(dataset[1].toString()).collection('tag').doc(tags[i].tag)
                           newImageTags.push(tags[i]) 
                        }
                     }   
                     if(!datasetHaveTags.length){
                        await $_firebase.firestore().collection('image').doc(imageId).update({ "tags": newImageTags, updatedAt: $_firebase.firebase().firestore.FieldValue.serverTimestamp() });
                        resp.status = "success"   
                     }else{ resp.error = "the tags "+JSON.stringify(datasetHaveTags)+" not found in "+dataset[1].toString()+" dataset" }
                  }else{ resp.error = "new tags is empty" }
               }else{
                  let datasetTags  = await $dataset.getTags(dataset[1].toString())
                  let pushTag      = true
                  if(!datasetTags[tags]){
                     let created = await $dataset.createTag(dataset[1].toString(), { tag: tags })
                     if(!created.status || created.status!="success")pushTag = false;  
                  }
                  if(pushTag){
                     let tagRef = await $_firebase.firestore().collection("dataset").doc(dataset[1].toString()).collection('tag').doc(tags)
                     await $_firebase.firestore().collection("image").doc(imageId).update({ tag: tagRef, updatedAt: $_firebase.firebase().firestore.FieldValue.serverTimestamp() })
                        .then(function () { resp.status = "success" })
                        .catch(async (error) => { resp.status = error });
                  }else{ resp.error = "new tag "+tags.toString()+" not found in "+dataset[1].toString()+" dataset"  }
               }
            }else{ resp.error = "image is not associated with any dataset" }
            resp.currentTags   = await this.getTags(imageId)
         } else { resp.error = "image not found" } 
      } else { resp.error = "image id and new tags are required" }
      return resp 
     },
     async removeTags(imageId) {
      let resp   = { status: "error", error: false }
      if(imageId){
         resp.image = imageId
         let item   = await this.get(imageId)
         if(item.dataset){
            let dataset = item.dataset.path.toString().split('/')
            if(dataset[1]){
               let datasetRef = await $_firebase.firestore().collection("dataset").doc(dataset[1].toString()).collection('tag').doc("0") 
               await $_firebase.firestore().collection("image").doc(imageId)
                     .update({ tag: datasetRef, tags: [], tagsContained: [], updatedAt: $_firebase.firebase().firestore.FieldValue.serverTimestamp() });
               resp.status        = "success"  
               resp.currentTags   = await this.getTags(imageId)   
            }else{ resp.error = "image is not associated with any dataset" }      
         }   
      } else { resp.error = "image id is required" }
      return resp
    },
     async detect (imageId) {
        let item   = await this.get(imageId)
        let detect = { status: "", error: false }
        if(item.uri){
         detect.url = await this.getStorageUrl(item.uri)
         if(detect.url){
            await this.detectCoco(detect.url.url).then( async (p) => { 
               detect.predictions = p
               if(detect.predictions && !detect.predictions.error){ detect.status = "success"; 
               } else { detect.status = "error"; detect.error = "no predictions" }
            });
         }   
        }else{ detect.status = "error"; detect.error = "storage path not found" }
        return detect
     },
     async detectCoco(url) {
        return new Promise((resolve, reject) => {
         let img = new Image()
         img.crossOrigin = "anonymous"
         img.setAttribute('crossOrigin', 'anonymous');
         img.src = url
         let predictions = []
         img.onload = async () => {
            if (!(img instanceof tf.Tensor)) img = tf.browser.fromPixels(img);
            const model = await cocoSsd.load().catch(async (error) => { console.log(error) });
            predictions = await model.detect(img).catch(async (error) => { console.log(error) });
            resolve(predictions)
         }
         img.onerror = reject
        })
     },
     async download(imageId) {
         let resp    = { error:  false,  status: "error" }
         let zip     = { name: "", files: [], folders: [], date: new Date() }
         let images  = []
         if(Array.isArray(imageId)){
            images = imageId
            zip.name = "selected_"+zip.date.getTime()+".zip"
         }else{ 
            zip.name = imageId.replace(/\s+/g, '_')+"_"+zip.date.getTime()+".zip"
            images.push(imageId)
         }
         var z = new JSZip()   
         for (var i = 0; i < images.length; i++) {
            let item       = await this.get(images[i])
            let storageUrl = await this.getStorageUrl(item.uri)
            if(item.uri && storageUrl.url){
               zip.files.push({ 
                  name:  item.uri.substr(item.uri.lastIndexOf("/")+1).replace(/\s+/g, '_'),
                  blob:  fetch(storageUrl.url).then(response => response.blob()),
               })
            }
         }
         zip.folders['images'] = z.folder('images')
         for (var zi = 0; zi < zip.files.length; zi++) { zip.folders['images'].file(zip.files[zi].name, zip.files[zi].blob, { base64: true }) }
         await z.generateAsync({type:"blob"}).then(async function (blob) { saveAs(blob,zip.name); });
         resp.status    = "success"
         resp.name      = zip.name
         resp.images    = images
         resp.message   = "The download will start automatically"
         return resp
     },
     async previewB64(imageId) {
      let resp   = { error:  false,  status: "error" }
      if(imageId){
         let image  = await this.get(imageId)
         if(image.imageData){ 
            resp.image = imageId
            resp.b64   = 'data:image/png;base64,'+ image.imageData.toBase64()
            resp.status = "success"
         } else { resp.error = "Image not available in base64 (imageData)" }      
      } else { resp.error = "image id is required" }
      return resp
     },
     async preview(imageId, opt = false) {
       let resp   = { error:  false,  status: "error" }
       if(imageId){
         resp.image = imageId
         let image  = await this.get(imageId)
         if(opt)resp.options = opt
         if(image.imageData){ 

            resp.canvas =  {
                           id:          "canvas_" + imageId,
                           name:        image.name,
                           image:       'data:image/png;base64,'+ image.imageData.toBase64(),
                           options:     { selectionLineWidth: 2, centeredScaling: false, transparentCorners: true },
                           contentType: "image/" + image.name.split('.').pop(),
                           size:        {},
                           objects:     { text: [], bbox: [] },
                           }

            if(opt.storage && image.uri){
               let path = await this.getStorageUrl(image.uri)
               if(path.url)resp.canvas.image = path.url
               if(path.uri)resp.canvas.uri   = path.uri
               if(path.meta)resp.canvas.contentType = path.meta.contentType
            }
            
            if(opt.width || opt.height){ 
               if(opt.width)resp.canvas.size.width   = opt.width
               if(opt.height)resp.canvas.size.height = opt.height
            }else{resp.canvas.size = await this.getDimensions(resp.canvas.image) } 

            if(opt.tags){ 
               resp.currentTags = await this.getTags(imageId)
               if(resp.currentTags.tags.length){
                  for (var i = 0; i < resp.currentTags.tags.length; i++) {
                     if(resp.currentTags.tags[i]){

                        if(resp.currentTags.tags[i].bounding_box){
                           for (var b = 0; b < resp.currentTags.tags[i].bounding_box.length; b++) {
                              if(resp.currentTags.tags[i].bounding_box[b]){
                                 if(resp.currentTags.tags[i].bounding_box[b].type=="rect"){
                                    resp.canvas.objects.bbox.push(
                                       new fabric.Rect({
                                                      left:     resp.currentTags.tags[i].bounding_box[b].x * resp.canvas.size.width,
                                                      top:      resp.currentTags.tags[i].bounding_box[b].y * resp.canvas.size.height,
                                                      originX:  'left',
                                                      originY:  'top',
                                                      width:    resp.currentTags.tags[i].bounding_box[b].w*resp.canvas.size.width,
                                                      height:   resp.currentTags.tags[i].bounding_box[b].h*resp.canvas.size.height,
                                                      angle:    0,
                                                      fill:     'transparent',
                                                      stroke:   resp.currentTags.tags[i].color,
                                                      strokeWidth: 1,
                                                      transparentCorners: false,
                                                      hoverCursor: "default",
                                                      selectable: false
                                                      }
                                       )
                                    )
                                    resp.canvas.objects.text.push(new fabric.Text(resp.currentTags.tags[i].name, { 
                                                      fill:     resp.currentTags.tags[i].color,
                                                      left:     (resp.currentTags.tags[i].bounding_box[b].x * resp.canvas.size.width),
                                                      top:      (resp.currentTags.tags[i].bounding_box[b].y * resp.canvas.size.height)-20,
                                                      fontSize: 18,
                                                      padding:  5,
                                                      hoverCursor: "default",
                                                      selectable: false
                                                      })) 
                                 }
                              }
                           }
                        }else{
                           resp.canvas.objects.text.push(new fabric.Rect({
                                                                           fill: "#000",
                                                                           left: -1,
                                                                           top:  -1,
                                                                           width: resp.canvas.size.width+2,
                                                                           height:25,
                                                                           selectable: false,
                                                                           opacity: 0.6,
                                                                           hoverCursor: "default"
                                                                        })) 
                           resp.canvas.objects.text.push(new fabric.Text(resp.currentTags.tags[i].name, { 
                                                                           fill:     resp.currentTags.tags[i].color,
                                                                           left:     2,
                                                                           top:      2,
                                                                           fontSize: 16,
                                                                           padding:  5,
                                                                           hoverCursor: "default",
                                                                           selectable: false
                                                                           })) 
                        }
                     }
                  }
               }
            }

            resp.status  = "success"   
         } else { resp.error = "Image not available in base64 (imageData)" }        
       } else { resp.error = "image id is required" }
       return resp
     },
     async getDimensions(url){
        return new Promise((resolve, reject) => {
         let img    = new Image()
         img.crossOrigin = "anonymous"
         img.setAttribute('crossOrigin', 'anonymous');
         img.src = url
         img.onload = async (i) => { resolve({ width: i.target.width, height: i.target.height }) }
         img.onerror = reject
        })
     },
     async randomImages(opt = false) {
        let images = [];
        let datasets = await $dataset.list()
        for (var i = 0; i < Object.keys(datasets).length; i++) {
           let datasetImages = await $dataset.getImages({ datasetID: datasets[i].id, limit: 5 });
           if(datasetImages.media)images = images.concat(datasetImages.media);
           if(opt.limit && images.length>=opt.limit)break
        }
        return images
     },
}
const install = app => { app.config.globalProperties.$images = images; };

export { install as default, images as image };