<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-4 xxl:col-span-3">
          <div class="intro-y box mt-5 lg:mt-0">
            <div class="relative flex items-center p-5">
              <div class="w-10 h-10 image-fit"><BookmarkIcon class="w-10 h-10"  /></div>
              <div class="ml-2 mr-auto">
                <div class="font-medium text-base">{{ $t('Tags') }}</div>
                <div class="text-gray-600">{{ tags.length }} {{ $t('elements') }}</div>
              </div>
            </div>
            <div class="p-5 border-t border-gray-200 dark:border-dark-5 pt-0 mh-menu">
              <div v-for="(tag) in tags" :key="tag.id">
                <a class="flex items-center mt-3 cursor-pointer" :class="TagActive(tag.id)" @click="ChangeTag(tag.id)">{{ tag.name }}</a>
              </div>
            </div>
          </div>
      </div>
      <div class="col-span-12 lg:col-span-7 xxl:col-span-8">
         <div v-for="(tag) in tags" :key="tag.id">
            <div class="intro-y box" v-if="tag.id == curTag">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 class="font-medium text-base mr-auto">{{ tag.name }}</h2>
                  <div class="form-inline w-8 h-8" :style="{'background-color':colors[tag.id].color}" v-if="colors[tag.id] && colors[tag.id].color"><span class="text-white pl-3">{{colors[tag.id].tag}}</span></div>
                </div>
                <div id="form-validation" class="p-5">
                <div class="preview pb-5">
                    <form :id="tag.id" class="validate-form" @submit.prevent="updateTag(tag.id,$event)" :ref="'form-'+tag.id">
                    <div class="input-form">
                        <label class="form-label">{{ $t('Name') }}</label>
                        <input type="text" class="form-control" :value="tag.name" id="name" disabled />
                    </div> 
                    <div class="input-form mt-3">
                        <label class="form-label">{{ $t('Confidence') }} ({{ $t('Model') }})</label>
                        <input type="text" class="form-control" id="model_confidence" :value="tag.model_confidence" @keypress="isNumber($event)" />
                    </div> 
                    <div class="input-form mt-3">
                        <label class="form-label">{{ $t('Output') }} ({{ $t('Model') }})</label>
                        <input type="text" class="form-control" id="model_output" :value="tag.model_output" :disabled="!root" />
                    </div> 
                    <div class="form-inline mt-4">  
                      <label for="model_otherwise" class="form-label">{{ $t('Otherwise') }} ({{ $t('Model') }})</label>
                      <input id="model_otherwise" class="form-check-switch" type="checkbox" :checked="tag.model_otherwise" :disabled="!root" />
                     </div>   
                    <div class="input-form pt-5 border-t border-gray-200 dark:border-dark-5 mt-5"><button type="submit" class="btn btn-primary">{{ $t('Save') }}</button></div>
                    </form>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!-- Notifications -->
    <div id="success-update" class="toastify-content hidden flex"><CheckCircleIcon class="text-theme-10" /> <div class="ml-4 mr-4"><div class="font-medium">{{ $t('Updated configuration') }}</div></div></div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, onBeforeMount, computed, watch } from "vue";
import { tag as $tag } from "@/model/tag";
import { useStore } from "@/store";
import { event as $event } from "@/model/events";

export default defineComponent({
  setup() {
    
    const store  = useStore();  
    const User   = computed(() => store.state.main.User);
    const root   = ref(false);
    const tags   = ref([])
    const colors = ref([])
    const curTag = ref()
    const TagActive = (t) => { if(t==curTag.value)return "dark:text-white text-theme-12 font-medium" };
    const ChangeTag = (t) => { curTag.value = t;};

    onBeforeMount( async () => { 
      tags.value   = await $tag.list();
      colors.value = await $tag.getColors();
      if(tags.value[0])curTag.value = tags.value[0].id  
     });

    onMounted( async () => { 
       if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true; 
    });

    watch( () => store.state.main.User.role, () => { if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;  });

    return {
     tags,
     curTag,
     TagActive,
     ChangeTag,
     colors,
     User,
     root
    };
  },
  methods:{
      updateTag: async function(tagID,event){
        if (event) event.preventDefault()
        if(this.$refs['form-'+tagID].length){
            const data = {};
            for (let i = 0; i < this.$refs['form-'+tagID].length; i++) {
              if(this.$refs['form-'+tagID][i].type!='submit'){
                 if(this.$refs['form-'+tagID][i].id){
                    if(this.$refs['form-'+tagID][i].type==='checkbox'){ 
                      let chkBool = Boolean(); if(this.$refs['form-'+tagID][i].checked)chkBool = Boolean(true)
                      data[this.$refs['form-'+tagID][i].id] = chkBool
                    }else{
                      data[this.$refs['form-'+tagID][i].id] = this.$refs['form-'+tagID][i].value;
                    }
                 }
               }
            }  
            await $tag.update(this.$refs['form-'+tagID].id,data,"#success-update").then(async () =>  {
              await $event.saveEvent('app.tag.update', { uid: useStore().state.main.User.uid, updated: data} , false ).then( async () => { 
                this.tags = await $tag.list();
                this.ChangeTag(tagID)
              }) 
            }) 
        }
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) { evt.preventDefault();
        } else {
          return true;
        }
      }
    }
});

</script>
