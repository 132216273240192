<template>
  <div>
      <div class="grid grid-cols-12 gap-6 ">
        <div class="col-span-12 xxl:col-span-6 dashboxItem">
            <!--<div class="col-span-12 mt-5 mb-5 intro-y">
                <div class="alert alert-dismissible show box bg-theme-20 text-white flex items-center mb-6" role="alert">
                    <span>Introducing new dashboard! Download now at <a href="#" class="underline ml-1" target="blank">themeforest.net</a>.</span>
                    <button type="button" class="btn-close text-white" data-bs-dismiss="alert" aria-label="Close"> <XIcon class="w-4 h-4 " /> </button>
                </div>
            </div>-->
            <div class="col-span-12 mt-5">
                <div class="ads-box box p-5 pb-6 relative overflow-hidden intro-y">
                    <div class="ads-box__title w-full sm:w-52 text-theme-17 dark:text-white text-xl mt-1">Dashboard</div>
                    <img class="absolute top-0 right-0 w-1/2 mt-0 company-logo-dash pt-0 mr-3" :alt="firebaseConfig.projectId" :src="Logo">
                </div>
            </div>  
            <div class="col-span-12 grid grid-cols-12 gap-3 mt-5">
                <template v-for="(menu, menuKey) in mainMenu">
                    <div class="col-span-12 sm:col-span-4 lg:col-span-4 xxl:col-span-4 intro-y" 
                        :key="menu + menuKey" :class="menu != 'devider' && menu.pageName != 'side-menu-dashboard' ? '' : 'hidden'" 
                        v-if="User.role && menu.role_id && menu.role_id!=User.role.role_id ? false : true && menu.roles ? User.role ? menu.roles.includes(User.role.role_id) : false ? true : false : true">
                        <div class="box p-3 zoom-in bg-theme-36 hover:bg-theme-2 hover:text-white" @click="linkTo(menu.pageName)">
                            <div class="flex items-center">
                                <div class="w-2/4 flex-none">
                                    <div class="text-sm font-medium truncate">{{menu.title}} </div>
                                </div>
                                <div class="flex-none ml-auto relative"> <component :is="menu.icon" class="w-6 h-6" /></div>
                            </div>
                        </div>
                    </div> 
                 </template>
            </div>
        </div>
        <div class="col-span-12 xxl:col-span-3 dashboxItem">
            <div class="xxl:border-l border-theme-25 -mb-10 pb-10">
                <div class="xxl:pl-6 grid grid-cols-12 gap-6">

                    <div class="col-span-12">
                        <div class="col-span-12 xl:col-span-3 xl:col-start-10 z-30">
                            <div class="h-full flex flex-col">
                                <div class="report-box-4__content xl:min-h-0 intro-x">
                                    <div class="max-h-full xl:overflow-y-auto box mt-5">
                                        <div class="bg-white dark:bg-dark-3 xl:sticky top-0 px-5 pt-5 ">
                                            <div class="flex items-center">
                                                <div class="text-lg font-medium truncate mr-5">{{ $t('My account') }}</div>
                                                <a class="ml-auto flex items-center text-theme-26 dark:text-theme-33 cursor-pointer" @click="linkTo('side-menu-profile')">{{ $t('Edit') }}</a>
                                            </div>
                                        </div>
                                        <div class="tab-content px-5 pb-5">
                                            <div class="tab-pane active grid grid-cols-12 gap-y-3" id="active-users" role="tabpanel" aria-labelledby="active-users-tab">
                                                <div class="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                                                    <div class="text-gray-900 dark:text-gray-500 text-base" v-if="User.displayName">
                                                        {{ User.displayName }}  
                                                        <span class="py-1 px-2 rounded-full text-xs bg-theme-6 w-20 mt-2 ml-3 text-white cursor-pointer font-medium" v-if="User.role">{{ User.role.name }}</span>
                                                    </div>
                                                    <div class="text-gray-700 mt-1 flex items-center">
                                                        <div class="text-xs" v-if="User.email">{{ User.email }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div> 

                    <div class="col-span-12" v-if="User.role && User.role.role_id && User.role.role_id==3">
                        <div class="col-span-12 xl:col-span-3 xl:col-start-10 xl:pb-5 z-30">
                            <div class="h-full flex flex-col">
                                <div class="report-box-4__content xl:min-h-0 intro-x">
                                    <div class="max-h-full xl:overflow-y-auto box ">
                                        <div class="bg-white dark:bg-dark-3 xl:sticky top-0 px-5 pt-5 pb-2">
                                            <div class="flex items-center">
                                                <div class="text-lg font-medium truncate mr-5">{{ $t('Status') }}</div>
                                            </div>
                                        </div>
                                        <div class="tab-content pl-1 pb-2">
                                            <div>
                                                <table class="table table--sm text-xs w-full table-dash-settings">
                                                    <tbody>
                                                        <tr>
                                                            <td class="text-gray-600 dark:text-gray-500 p-0">{{ $t('Controller') }}</td>
                                                            <td class="text-green-600" v-if="controllerStatus"><CloudIcon class="w-4 h-4" /> {{ $t('Online') }}</td>
                                                            <td v-else><CloudOffIcon class="w-4 h-4 text-red-600" /><span class="text-red-600">{{ $t('Offline') }}</span></td>
                                                            <td>
                                                                <button type="button" class="btn btn-sm ml-8 text-gray-600" v-if="controllerStatus" @click="linkTo('side-menu-controller')">{{ $t('Manage') }}</button>
                                                                <button type="button" class="btn btn-sm ml-2 text-gray-600" @click="resetController()">{{ $t('Reset') }}</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-12 xl:col-span-3 xl:col-start-10 xl:pb-5 z-30">
                            <div class="h-full flex flex-col">
                                <div class="report-box-4__content xl:min-h-0 intro-x">
                                    <div class="max-h-full xl:overflow-y-auto box ">
                                        <div class="bg-white dark:bg-dark-3 xl:sticky top-0 px-5 pt-5 pb-2">
                                            <div class="flex items-center">
                                                <div class="text-lg font-medium truncate mr-5">{{ $t('Settings') }}</div>
                                                <a class="ml-auto flex items-center text-theme-26 dark:text-theme-33 cursor-pointer" @click="linkTo('side-menu-settings')">{{ $t('Edit') }}</a>
                                            </div>
                                        </div>
                                        <div class="tab-content px-5 pb-5">
                                            <div>
                                                <table class="table table--sm text-xs w-full table-dash-settings">
                                                    <tbody>
                                                        <tr>
                                                            <td class="text-gray-600 dark:text-gray-500 p-0">{{ $t('ProjectID') }}</td>
                                                            <td>{{firebaseConfig.projectId}}</td>
                                                        </tr>
                                                        <tr v-if="modelBucket">
                                                            <td class="text-gray-600 dark:text-gray-500 p-0">{{ $t('Storage Bucket') }}</td>
                                                            <td>{{modelBucket}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-gray-600 dark:text-gray-500 p-0">{{ $t('NodeRed Auth') }}</td>
                                                            <td>
                                                                <div v-if="cockpitAuth">{{cockpitAuth.user+":"+cockpitAuth.password.substring(0, 2)+'*******'}}</div>
                                                                <div v-else class="text-red-600">{{ $t('Not defined') }}</div>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="nodeConfig">
                                                            <td class="text-gray-600 dark:text-gray-500 p-0">{{ $t('Host') }}</td>
                                                            <td>{{nodeConfig.host}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        
                        <!--
                        <div class="col-span-12 w-1/2  sm:w-full">
                            <div class="intro-y box mt-0 cursor-pointer" @click="linkTo('doc')">
                                <div class="col-span-12 lg:col-span-6">
                                    <div class="ads-box box p-5 relative overflow-hidden intro-y">
                                    <div class="ads-box__title w-full sm:w-52 text-theme-17 dark:text-white text-base -mt-3">{{ $t('User guide and') }} <span class="font-medium">{{ $t('Documentation') }}</span></div>
                                    <div class="w-full sm:w-60 leading-relaxed text-gray-600 mt-2">v.1</div>
                                    <img class="hidden sm:block absolute top-0 right-0 w-40 mt-12 -mr-8 opacity-40" :alt="$t('Documentation')" :src="require(`@/assets/images/logo/rphelp.png`)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        -->
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
</template>
<script>
import { defineComponent, computed, onMounted,ref } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import * as rosepetalModel from "rosepetal-model";
import { helper as $h } from "@/utils/helper";
import config from '../../etc/rosepetal.json';

const axios = require('axios').default;

const firebaseConfig = config.firebaseConfig;
const nodeConfig     = config.nodeConfig;
const modelBucket    = config.modelBucket;

export default defineComponent({
  components: {
  },
  setup() {
    
    const router                = useRouter();
    const store                 = useStore(); 
    const User                  = computed(() => store.state.main.User );
    const mainMenu              = ref();
    const Logo                  = ref(false)
    const cockpitAuth           = ref()
    const controllerStatus      = ref(false)

    const linkTo = (page,params=false,monID=false) => {  
        let p = { type: false, monID: false }; 
        if(params)p.type= params; 
        if(monID)p.monID= monID; 
        router.push({ name: page, params: p });  
    };

    const resetController = async () => {  
        let ajentiCall = '/ajenti/api/services/do/restart/systemd/rosepetal-controller.service'
        if(config.firebaseConfig.projectId=="rosepetal-analysis")ajentiCall = '/ajenti/api/services/do/restart/systemd/rosepetal-controller-analysis.service'
        await axios.post(nodeConfig.host+ajentiCall, {}, { timeout: 10000 })
            .then(async () => {
                $h.NotificationTxt({ text: 'Controller has been reset' });
                setTimeout(async () => { await getStatus() }, 2000);
            })
            .catch(async () => {
                $h.NotificationTxt({ text: 'Failed to restart controller' });
                setTimeout(async () => { await getStatus() }, 2000);
            });          
    };

    const getStatus = async () => {  
        try {
            await rosepetalModel.controller.getStatus().then((_status) => { 
                if(_status && !_status.error)controllerStatus.value = true;
                else controllerStatus.value = false;
            });
        } catch (e) {controllerStatus.value = false; }
    }

    const getLogo = () => {  
        try { Logo.value            = require(`@/assets/logo/`+firebaseConfig.projectId+`.jpg`)
        } catch (e) { Logo.value    = require(`@/assets/logo/ais-vision-ai.jpg`) }
        if(firebaseConfig.projectId && firebaseConfig.projectId=='rosepetal-dep-inserma')Logo.value   = require(`@/assets/logo/brand/inserma_susmagpro.png`) //patch custom logo
    }

    onMounted( async () => { 
        getLogo()
        mainMenu.value      = await rosepetalModel.ui.getMainMenu({ raw: true }) //status: controllerStatus.value, noCheckStatus: true, 
        await getStatus()
        cockpitAuth.value   = await rosepetalModel.project.getSettings('cockpit'); 
    });

    return {
     firebaseConfig,
     modelBucket,
     nodeConfig,
     linkTo,
     User,
     mainMenu,
     Logo,
     cockpitAuth,
     controllerStatus,
     resetController
    };
  },
});
</script>
<style>
body{ padding-right: 1rem !important; padding-left: 1rem !important;}
.rpdashbox{ background-color: #1c3271 !important;}
.node-frame{ width: 100%; height:100vw; display: none;}
.company-logo-dash{ max-width: 180px;}
.table-dash-settings td{ padding-top: 0 !important;}
</style>