//import { _firebase as $_firebase } from "@/model/firebase";
import { monitor as $monitor } from "@/model/monitor";
import { helper as $h } from "@/utils/helper";
import { user as $user } from "@/model/user";
import { useStore } from "@/store";
import moment from 'moment';
import * as rosepetalModel from "rosepetal-model";

var $_firebase = rosepetalModel._firebase;

const sessions = {
    async get(s=false,dates=false){
      if(s.doc){
        let session = $_firebase.firestore().collection('session').doc(s.doc)
        const snapshot = await session.get();
        let item = snapshot.data(); item.id = snapshot.id; 
        if(useStore().state.main.User.uid && item.uid==useStore().state.main.User.uid){
          item.userName = useStore().state.main.User.displayName
        }else{ 
          item.userName = await $user.search(item.uid); 
          //console.log('search user -> '+item.uid) 
        }
        if(item.start)item.start_date = $h.getTimestampDate(item.start.toDate(),'full')
        if(item.stop)item.stop_date = $h.getTimestampDate(item.stop.toDate(),'full')
        item.duration = this.getDuration(item)
        return item;
     }else{
        let sessions = []
        let list     = $_firebase.firestore().collection('session')
        if(dates){
          if(dates[0]){ let init = dates[0].split("/"); var i = new Date(init[2], init[1] - 1, init[0]); list = list.where('start', '>=', i); }
          if(dates[1]){ let end = dates[1].split("/"); var e = new Date(end[2], end[1] - 1, end[0],23,59,59); list = list.where('start', '<=', e); }
        }
        if(s.uid)list = list.where("uid", "==", s.uid)
        if(s.monitor)list = list.where("monitor", "==", s.monitor)
        list = list.orderBy("start", "desc")
        if(s.limit)list = list.limit(s.limit)
        await list.get().then( async (snapShot) => {
          snapShot.forEach(async (doc) => {
            let item = doc.data()
            item.id  = doc.id;
            if(item.start){ item.start_date  = $h.getTimestampDate(item.start.toDate(),'full'); item.start_seconds = moment(item.start_date, 'DD/MM/YYYY H:i:s').valueOf(); }
            if(item.stop){ item.stop_date = $h.getTimestampDate(item.stop.toDate(),'full'); item.stop_seconds = moment(item.stop_date, 'DD/MM/YYYY H:i:s').valueOf(); }
            item.duration = this.getDuration(item)
            sessions.push(item)
          })
        }
        ).catch((error) => console.log(error.message));
        return sessions;
      }
    },
    async start(s){
      let SessionID = null
      let mon = await $monitor.get(s.monitor)
      await $_firebase.firestore().collection('session').add({ 
        monitor: s.monitor, 
        //prediction_model: mon.prediction.model,
        prediction_rest: mon.prediction.rest,
        start: $_firebase.firebase().firestore.FieldValue.serverTimestamp(), 
        uid: useStore().state.main.User.uid,  
      }).then(function(docRef) { SessionID = docRef.id; });
      return SessionID;
    },
    async end(sessionID, data=false){
      let end = { stop: $_firebase.firebase().firestore.FieldValue.serverTimestamp() }
      if(data)end.data = data
      await $_firebase.firestore().collection('session').doc(sessionID).update(end, { merge: true })
    },
    async getLast(filter=false){
      let last   = []
      let params = { limit: 1 }
      if(filter.uid)params.uid = filter.uid
      if(filter.monitor)params.monitor = filter.monitor
      last = await this.get(params)
      if(last[0])return last[0]
      return last;
    },
    async updateData(sessionID, data){
      await $_firebase.firestore().collection('session').doc(sessionID).update(data, { merge: true }) 
    },
    getDuration(session){
      let duration = { days: 0, hours: 0, minutes: 0, seconds: 0}
      let i = new Date(session.start.toDate()); const init = moment(i); 
      if(session.stop){
        let e = new Date(session.stop.toDate());  const end = moment(e); 
        const d = moment.duration(end.diff(init));
        duration.days    = Math.round(d.asDays());
        duration.hours   = Math.round(d.asMinutes() / 60);
        duration.minutes = Math.round(d.asMinutes() % 60);
        duration.seconds = Math.round(d.asSeconds() % 60);
        duration.unix    = moment.duration(duration.hours+":"+duration.minutes+":"+duration.seconds).asSeconds();
      }
      return duration  
    },
    async byDateRange(dateRange){
      return await this.get(false,dateRange);
    }
};

const install = app => {
  app.config.globalProperties.$sessions = sessions;
};

export { install as default, sessions as session };
