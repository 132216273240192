'use strict';

const $_firebase  = require('./firebase');
const helper      = require("./helper");
const event       = require("./event");
const other       = require("./other");
const project     = require("./project");

const model = { 
    create: async function(datasetID, modelName, modelData = false) { 
        let resp = { status: "error", error: false }
        if (datasetID && modelName){
        var nowdate       = new Date()
        resp.modelId      = modelName.toString().toLowerCase().replace(/\s+/g, '-') + "-" + nowdate.getTime() 
        resp.modelCreated = {
            automl:           "",
            aws:              "",
            dataset:          await $_firebase.firestore().collection("dataset").doc(datasetID.toString()),
            description:      "",
            savedModel:       "",
            status:           "undeployed",
            trainBudget:      "",
            annotationSetId:  "",
            createdAt:        $_firebase.firebase.firestore.FieldValue.serverTimestamp(), //$_firebase.firebase().firestore.FieldValue.serverTimestamp(), 
        }
        if(modelData)resp.modelCreated = {...resp.modelCreated, ...modelData}
        await $_firebase.firestore().collection("model").doc(resp.modelId).set(resp.modelCreated)
            .then(function () { resp.status = "success" })
            .catch(function (error) { resp.error = error });
            resp.modelCreated.dataset = datasetID
            resp.status = "success"
        }else{ resp.error = "dataset Id and model name is required" } 
        return resp
    },

    list: async function(opt = false) {
        let models = [];
        let m      = $_firebase.firestore().collection('model').orderBy('createdAt', 'desc')
        if(opt.limit)m = m.limit(opt.limit)
        await m.get().then(async snapshot => {
                snapshot.forEach(async doc => {
                let item = doc.data()
                item.id = doc.id;
                if(item.dataset)item.dataset = item.dataset.path.toString().split('/').pop()
                if(item.createdAt)item.created = helper.getTimestampDate(item.createdAt.toDate(),'full')
                let pushItem = true
                if (opt.export && !item.savedModel)pushItem = false
                if (opt.vertex && !item.automl)pushItem = false
                if (item.deleted)pushItem = false
                if(pushItem)models.push(item)
                });
        });
        return models 
    },

    get: async function(modelId) { 
        const dataset   = require("./dataset");
        let model       = {};
        await $_firebase.firestore().collection('model').doc(modelId).get().then(async snapshot => {
        model    = snapshot.data()
        if(model){
            model.id = snapshot.id;
            if(model.dataset)model.dataset = model.dataset.path.toString().split('/').pop()
            if(model.createdAt)model.created = helper.getTimestampDate(model.createdAt.toDate(),'full')
            await dataset.update(model.dataset,{ trained : Boolean(true) })
        }
        });
        return model
    },

    getVertex: async function(modelId) { 
        let resp = { status: "error", error: false }
        if (modelId){
            await other.httpsCallable('api/model/get/model_id/' + modelId).then(function (vertexModel) {
                if (vertexModel.data){ 
                    resp.data    = vertexModel.data
                    resp.status  = "success" 
                }else{ resp.error = "model "+modelId+" not found" } 
            })
        }else{ resp.error = "modelId is required" } 
        return resp
    },

    update: async function(modelId, data) { 
        let resp = { status: "error", error: false }
        if (modelId){
        data["updatedAt"] = $_firebase.firebase.firestore.FieldValue.serverTimestamp()
        await $_firebase.firestore().collection("model").doc(modelId).update(data)
        await event.saveEvent('model.update', JSON.stringify(data), false)
        resp.status = "success" 
        }else{ resp.error = "modelId is required" } 
        return resp
    },

    getExportUrl: async function(modelId) { 
        let resp = { status: "error", error: false }
        if (modelId){
        resp.model    = modelId
        let item      = await this.get(modelId)
        if (item.automl){
            resp.automlId   = item.automl
            resp.api_url    = 'api/model/export/model_id/' + item.automl
            let config      = project.getConfig()
            if (config.modelBucket){
            resp.bucket   = config.modelBucket
            resp.api_url += '-|-' + config.modelBucket.replace(/\//g, "!!-")
            }
            resp.status   = "success" 
        }else{ resp.error = "model does not have automl Id" }   
        }else{ resp.error = "modelId is required" } 
        return resp
    },

    
    export: async function(modelId, opt = false) { 
        let resp = { status: "error", error: false }
        if (modelId){
        let exportUrl = await this.getExportUrl(modelId)
        if(exportUrl.api_url){
            let req   = await other.httpsCallable(exportUrl.api_url)
            if(!req.error){
                let savedModel     = req.data.metadata.outputInfo.artifactOutputUri + "/saved_model.pb"
                let _rq            = await this.update(modelId, { savedModel: savedModel  } ) 
                resp.exportStorage = { model: exportUrl.automlId,  name: exportUrl.model, bucket: exportUrl.bucket, url : exportUrl.api_url } 
                if(!_rq.error){ 
                resp.savedModel = savedModel
                if(opt.updateModel)await this.update(modelId, { savedModel: savedModel  })
                resp.status     = "success" 
                }else{ resp.error = _rq.error }
            }else{ resp.error = req.error } 
        }else{ resp.error = exportUrl.error } 
        }else{ resp.error = "modelId is required" } 
        return resp
    },
   

    getEvaluations: async function(modelId) { 
        let resp = { status: "error", error: false }
        if (modelId){
        let item      = await this.get(modelId)
        resp.model    = modelId
        if(item && item.automl){
            resp.automl      = item.automl
            let vertexModel  = await other.httpsCallable('api/model/get/model_id/' + item.automl)
            let req          = await other.httpsCallable('api/model/evaluations/model_id/' + item.automl)
            resp.count       = req.data ? req.data.length : 0
            resp.evaluations = []
            if(resp.count){
            for (var i = 0; i < resp.count; i++) {
                let vertexModelSlices = await other.httpsCallable('api/model/evaluationslices/evaluation_id/' + req.data[i].name.toString().replace(/\//g, "_"))
                //evaluation
                let eva = { 
                        evaluationId : req.data[i].name.toString().split('/').pop(), 
                        name         : req.data[i].name.toString(),
                        typeObjects  : req.data[i].metrics.structValue.fields.boundingBoxMetrics ? true : false,
                        created      : helper.getFbDate(req.data[i].createTime),
                        explanations : req.data[i].explanationSpecs.length ? { count: req.data[i].explanationSpecs.length, types: [] } : false,
                        model        : { id: item.automl, tagMap: item.tagMap ? item.tagMap : false }, 
                        slices       : { ALL: await this.parserVertexMetrics(req.data[i].metrics) }, 
                        evaluatedCount: req.data[i].metrics.structValue.fields.evaluatedBoundingBoxCount ? req.data[i].metrics.structValue.fields.evaluatedBoundingBoxCount.numberValue : 0,
                        } 

                //explanations
                for (var ex = 0; ex < req.data[i].explanationSpecs.length; ex++) { eva.explanations.types.push(req.data[i].explanationSpecs[ex].explanationType)} 

                //slices          
                if(vertexModelSlices.data){        
                for (let _s = 0; _s < vertexModelSlices.data.length; _s++) {
                    eva.slices[vertexModelSlices.data[_s].slice.value] = await this.parserVertexMetrics(vertexModelSlices.data[_s].metrics) 
                }
                }

                //model data
                if(vertexModel.data)eva.model = await this.parserVertexModel(vertexModel.data, eva.model) 
                
                //add evaluation
                resp.evaluations.push(eva)
            }
            resp.status     = "success" 
            }else{ resp.error = "could not get the vertex evaluation" } 
        }else{ resp.error = "model does not have automl Id" } 
        }else{ resp.error = "modelId is required" } 
        return resp
    },

    parserVertexModel: async function(model , modelObj = false) { 
        let resp = { status: "error", error: false }
        if (model){
        let m = modelObj ? modelObj : {}
        m.displayName                 = model.displayName
        m.type                        = model.metadata.structValue.fields.modelType.stringValue
        m.versionId                   = model.versionId 
        m.trainingDataItemsCount      = model.metadata.structValue.fields.trainingDataItemsCount.stringValue 
        m.trainingAnnotationsCount    = model.metadata.structValue.fields.trainingAnnotationsCount.stringValue  
        m.validationDataItemsCount    = model.metadata.structValue.fields.validationDataItemsCount.stringValue   
        m.validationAnnotationsCount  = model.metadata.structValue.fields.validationAnnotationsCount.stringValue   
        m.eligibleAsBaseModel         = model.metadata.structValue.fields.eligibleAsBaseModel ? model.metadata.structValue.fields.eligibleAsBaseModel.boolValue : false
        let trainingPipeline          = await other.httpsCallable('api/model/trainingpipeline/status/' + model.trainingPipeline.replace(/\//g, "--")) 
        let training                  = trainingPipeline.data.trainingTaskMetadata.structValue.fields
        if(training)m.training        = { 
                                            budgetMilliNodeHours: trainingPipeline.data.trainingTaskInputs.structValue.fields.budgetMilliNodeHours.stringValue, 
                                            costMilliNodeHours: training.costMilliNodeHours.stringValue, 
                                            stopReason: training.successfulStopReason.stringValue 
                                        }  
        return m
        }else{ resp.error = "model data is required" } 
        return resp
    },

    parserVertexMetrics: async function(metrics) { 
        let resp = { status: "error", error: false }
        if (metrics){
        let m = {}
        //metrics confidence
        m.auPrc    = metrics.structValue.fields.auPrc ? metrics.structValue.fields.auPrc.numberValue : false
        m.logLoss  = metrics.structValue.fields.logLoss ? metrics.structValue.fields.logLoss.numberValue : false
        m.boundingBoxMeanAveragePrecision  = metrics.structValue.fields.boundingBoxMeanAveragePrecision ? metrics.structValue.fields.boundingBoxMeanAveragePrecision.numberValue : 0 
        m.evaluatedBoundingBoxCount        = metrics.structValue.fields.evaluatedBoundingBoxCount ? metrics.structValue.fields.evaluatedBoundingBoxCount.numberValue : 0 
        let meItem = metrics.structValue.fields.confidenceMetrics ? metrics.structValue.fields.confidenceMetrics.listValue.values  : false     
        if(metrics.structValue.fields.boundingBoxMetrics)meItem   = metrics.structValue.fields.boundingBoxMetrics.listValue.values
        if(meItem){
            let _mt   = { count: meItem.length, metrics: [] /*{}*/ }
            for (let _i   = 0; _i < meItem.length; _i++) {
            let bItem   = meItem[_i]
            let m       = { metricId: _i+1 }
            //let index   = 0
            for (const _b of Object.keys(bItem.structValue.fields)){ 
                let _bitem = bItem.structValue.fields[_b]; 
                m[_b]      = _bitem.numberValue 
                if(_b =='recall' || _b =='precision')m[_b+"Percent"] = (_bitem.numberValue * 100).toFixed(1) 

                //if(_b =='confidenceThreshold')index = _bitem.numberValue

                if(_bitem.listValue){
                let _bitemList = bItem.structValue.fields[_b].listValue.values
                m[_b]          = { count: _bitemList.length, confidence: [] }
                for (const _bl of Object.keys(_bitemList)){ 
                    let _bitemListFields = _bitemList[_bl].structValue.fields; 
                    let v                = {}
                    for (const _bf of Object.keys(_bitemListFields)){ 
                    v[_bf] = _bitemListFields[_bf].numberValue 
                    if(_bf =='recall' || _bf =='precision' || _bf =='f1Score')v[_bf+"Percent"] = (_bitemListFields[_bf].numberValue * 100).toFixed(1) 
                    }
                    m[_b].confidence.push(v)
                }
                }
            }
            //_mt.metrics[index] = m
            _mt.metrics.push(m)
            }
            m.confidenceMetrics = _mt
        }
        //metrics confusion matrix
        let confusionMatrix = metrics.structValue.fields.confusionMatrix ? metrics.structValue.fields.confusionMatrix.structValue.fields : false
        if(confusionMatrix){
            let matrix    = {}
            for (const con of Object.keys(confusionMatrix)){  
            matrix[con] = [] 
            let cm      = confusionMatrix[con].listValue.values
            for (let _i  = 0; _i < cm.length; _i++) {
                let m       = false
                if(cm[_i].structValue){
                m       = {}
                let _cm = cm[_i].structValue.fields
                for (const _c of Object.keys(_cm)){ m[_c] = _cm[_c].stringValue }
                }
                if(cm[_i].listValue){
                m       = []
                let _cm =  cm[_i].listValue.values
                for (let _c  = 0; _c < _cm.length; _c++) { m.push(_cm[_c].numberValue) }
                }
                matrix[con].push(m)
            }
            }
            if(matrix.rows){
            matrix.rowsPercent = []
            for (let _cr  = 0; _cr < matrix.rows.length; _cr++) { 
                let countRows  = 0
                let rowVals    = []
                for (let _r = 0; _r < matrix.rows[_cr].length; _r++) { 
                countRows = countRows + matrix.rows[_cr][_r]
                rowVals.push(matrix.rows[_cr][_r])
                }
                let rowAdd = []
                for (let _rv = 0; _rv < rowVals.length; _rv++) { 
                rowAdd.push(Math.round((rowVals[_rv]/countRows) * 100, 2))
                }
                matrix.rowsPercent.push(rowAdd)
            }
            }
            m.confusionMatrix = matrix
        }
        return m
        }else{ resp.error = "metrics are required" }
        return resp
    },

    renderEvaluations: async function(modelId , opt = false) { 
        let resp = { status: "error", error: false, render: false }
        if (modelId){
        let modelEvaluations = await this.getEvaluations(modelId)
        let themeSettings = {
                             dark: {
                                backgroundColor: '#1a202c',
                                textColor:       '#fff',
                                matrixBackColor: '#1a202c',
                                padBox:          '20px'
                             },
                             light: {
                                backgroundColor: '#fff',
                                textColor:       '#1a202c',
                                matrixBackColor: '#ededed',
                                padBox:          '0'
                             },
                             settings: {
                                textSize:       opt && opt.textSize  ? opt.textSize  : '0.75rem', 
                                titleSize:      opt && opt.titleSize ? opt.titleSize : '15px', 
                                titles:         opt && opt.titles    ? opt.titles    : true,
                             }  
                            }
        resp.theme = opt && opt.theme ? opt.theme : 'dark'                  
        if (modelEvaluations.evaluations && Object.keys(modelEvaluations.evaluations).length){

            resp.evaluations = modelEvaluations.evaluations
            resp.render      = "<div id='evaluationBox' style='color: "+ themeSettings[resp.theme].textColor +"; background-color:"+ themeSettings[resp.theme].backgroundColor +"; padding: "+ themeSettings[resp.theme].padBox +"; font-size:"+ themeSettings.settings.textSize +"'>"
            for (let _c = 0; _c < resp.evaluations.length; _c++) {
                
                if(!opt.onlyMetrics){
                    //Evaluation
                    resp.render += "<table style='width: 100%; margin: 0;'>"
                    resp.render += "<tr><td style='padding-bottom: 10px;font-size:"+ themeSettings.settings.titleSize +"; font-weight:500' colspan='2'>Evaluation</td></tr>"
                    if(resp.evaluations[_c].evaluationId)resp.render    += "<tr><td style='padding:5px; width: 250px'>ID</td><td style='padding:5px'>"+resp.evaluations[_c].evaluationId+"</td></tr>"
                    if(resp.evaluations[_c].created)resp.render         += "<tr><td style='padding:5px'>Created</td><td style='padding:5px'>"+resp.evaluations[_c].created+"</td></tr>"
                    if(resp.evaluations[_c].model.id)resp.render        += "<tr><td style='padding:5px'>Model</td><td style='padding:5px'>"+ resp.evaluations[_c].model.displayName +"</td></tr>"
                    if(resp.evaluations[_c].model.versionId)resp.render += "<tr><td style='padding:5px'>Version</td><td style='padding:5px'>"+ resp.evaluations[_c].model.versionId + "</td></tr>"
                    resp.render    += "</table>"

                    //Training
                    resp.render  += "<table style='width: 100%;margin: 0 0 30px 0;'>"
                    if(resp.evaluations[_c].model.training){
                        resp.render  += "<tr><td style='padding: 20px 0 10px 0;font-size:"+ themeSettings.settings.titleSize +"; font-weight:500' colspan='2'>Training</td></tr>"
                        if(resp.evaluations[_c].model.training.budgetMilliNodeHours)  
                        resp.render  += "<tr><td style='padding:5px; width: 250px'>budget</td><td style='padding:5px'>" + resp.evaluations[_c].model.training.budgetMilliNodeHours + " milliNodeHours" + "</td></tr>"
                        if(resp.evaluations[_c].model.training.costMilliNodeHours)  
                        resp.render  += "<tr><td style='padding:5px'>cost</td><td style='padding:5px'>" + resp.evaluations[_c].model.training.costMilliNodeHours + " milliNodeHours" + "</td></tr>"
                        if(resp.evaluations[_c].model.training.stopReason)    
                        resp.render  += "<tr><td style='padding:5px'>stopReason</td><td style='padding:5px'>" + resp.evaluations[_c].model.training.stopReason + "</td></tr>"
                    }
                    if(resp.evaluations[_c].model){
                        let modelItems   = ["trainingDataItemsCount", "trainingAnnotationsCount", "validationDataItemsCount", "validationAnnotationsCount"]
                        for (let _m   = 0; _m < modelItems.length; _m++) {
                            if(resp.evaluations[_c].model[modelItems[_m]]){ resp.render  += "<tr>" + "<td style='padding:5px'>"+modelItems[_m] + "</td>" + "<td style='padding:5px'>"+resp.evaluations[_c].model[modelItems[_m]] + "</td>" + "</tr>"  }
                        }
                    }
                    if(resp.evaluations[_c].metrics){
                        let metrics   = ["auPrc", "logLoss", "boundingBoxMeanAveragePrecision", "evaluatedBoundingBoxCount"]
                        for (let _m   = 0; _m < metrics.length; _m++) {
                            if(resp.evaluations[_c].metrics[metrics[_m]]){ resp.render  += "<tr>" + "<td style='padding:5px'>"+metrics[_m] + "</td>" + "<td style='padding:5px'>" + resp.evaluations[_c].metrics[metrics[_m]] + "</td>" + "</tr>" }
                        }
                    }
                    resp.render  += "</table>"
                }

                if(resp.evaluations[_c] && resp.evaluations[_c].slices && resp.evaluations[_c].slices["ALL"].confusionMatrix){
                    //if(themeSettings.settings.titles)resp.render  += "<div style='padding: 10px 5px; font-size:"+ themeSettings.settings.titleSize +"; font-weight:500'>Confusion matrix</div>"
                    resp.render  += "<table style='margin: 60px 10px 0 10px'>"
                    resp.render  += "<tr style='border-bottom: 1px solid #ccc;'>"
                    resp.render  += "<td style='padding:3px 0 0 0; min-width: 180px; max-width: 180px;'></td>"
                    for (let _cm  = 0; _cm < resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs.length; _cm++) {
                        resp.render += "<td style='padding:3px 5px;transform: translateX(-5%) translateY(-30px) rotate(-40deg) !important; overflow: hidden; overflow-x: visible; white-space: nowrap;min-width: 70px !important;max-width: 70px !important;'>" 
                                    + resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs[_cm].displayName 
                                    + "</td>"
                    }
                    resp.render  += "</tr>"
                    resp.render  += "</table>"
                    resp.render  += "<table style='margin:5px 10px;'>"
                    for (let _cm     = 0; _cm < resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs.length; _cm++) {
                    resp.render   += "<tr style='border-bottom: 1px solid #f7f8f9;line-height: 35px'>"
                    resp.render   += "<td style='padding: 3px 0 0 0; min-width: 160px; max-width: 160px; overflow-x: hidden'>" + resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs[_cm].displayName + "</td>"
                    for (let _r    = 0; _r < resp.evaluations[_c].slices["ALL"].confusionMatrix.rows[_cm].length; _r++) {
                        resp.render += "<td style='padding:3px 5px; max-width: 70px !important; width: 70px !important; min-width: 70px !important; text-align: center;"
                        resp.render += "background-color: " + (resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r]=="100" ? '#104b9e' : resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r]>"50" ? '#b7d3fa' : themeSettings[resp.theme].matrixBackColor) + ";"
                        resp.render += "color: " + (resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r]=="100" ? '#fff' : resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r]>"50" ? '#174EA6' : themeSettings[resp.theme].textColor)
                        resp.render += "'>"
                        //resp.render += "<div style='display: inline-block; text-align: center'>"+resp.evaluations[_c].slices["ALL"].confusionMatrix.rows[_cm][_r] + "</div>"
                        resp.render += "<div style='padding: 0 0 0 5px; display: inline-block; line-height: 18px;'>"
                            if(resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r])resp.render += resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r] + "%"
                            else resp.render += "-"
                        resp.render += "</div>"
                        resp.render += "</td>"
                    }
                    resp.render  += "</tr>"
                    }
                    resp.render  += "</table>"
                }
                
                //tagMap
                if(resp.evaluations[_c].model && resp.evaluations[_c].model.tagMap){
                    var _tagMap  = JSON.parse(resp.evaluations[_c].model.tagMap)
                    console.log(_tagMap)
                    resp.render  += "<table style='margin: 5px 0 0 5px; width:100%; font-size: "+ themeSettings.settings.textSize +";'>"
                    resp.render  += "<tr>"
                    resp.render  += "<td style='padding:5px 0 15px 0;font-size:"+ themeSettings.settings.titleSize +";' colspan='2'>TagMap</td>"
                    resp.render  += "</tr>"
                    for(var _tag in _tagMap.tags){
                        resp.render  += "<tr>"
                            resp.render   += "<td style='width:30%; padding: 5px 5px 5px 10px; border: 1px solid #e2e8f0;background-color: rgba(247, 250, 252, 0.8);'>"+ _tag + "</td>"
                            resp.render   += "<td style='width:10%; padding: 5px 5px 5px 10px; border: 1px solid #e2e8f0; text-align: center'>" + _tagMap.tags[_tag].total + "</td>"
                            resp.render   += "<td style='width:60%; padding: 5px 5px 5px 10px; border: 1px solid #e2e8f0;'>"
                            var _labelTags = Object.keys(_tagMap.tags[_tag].tags).length
                            var _cntTags   = 0;
                            for(var _t in _tagMap.tags[_tag].tags){
                                if(_t!='count'){
                                    _cntTags++;
                                    resp.render  += _t + " (" + _tagMap.tags[_tag].tags[_t].count + ")" 
                                    if(_cntTags < _labelTags - 1)resp.render  += " + "
                                }
                            }
                            resp.render  += "</td>"
                        resp.render  += "</tr>"
                    }
                    resp.render  += "</table>"
                }

                //slices metrics objects
                if(resp.evaluations[_c].slices && resp.evaluations[_c].slices["ALL"]["boundingBoxMeanAveragePrecision"]){
                    resp.render += "<table style='margin: 20px 0 0 5px;'>"
                    resp.render  += "<tr>"
                    resp.render  += "<td style='width:200px; padding:5px 0 15px 0;font-size:"+ themeSettings.settings.titleSize +";'>Tag</td>"
                    if(resp.evaluations[_c].slices["ALL"]["auPrc"])resp.render  += "<td style='width:100px; padding:5px 0 15px 0;font-size:"+ themeSettings.settings.titleSize +"; font-weight:500'>auPrc</td>"
                    if(resp.evaluations[_c].slices["ALL"]["logLoss"])resp.render  += "<td style='width:100px; padding:5px 0 15px 0;text-align: center;font-size:"+ themeSettings.settings.titleSize +"; font-weight:500'>logLoss</td>"
                    if(resp.evaluations[_c].slices["ALL"]["boundingBoxMeanAveragePrecision"])resp.render  += "<td style='width:150px; padding:5px 0 15px 0;font-size:"+ themeSettings.settings.titleSize +"'>Average precision</td>"
                    if(resp.evaluations[_c].slices["ALL"]["evaluatedBoundingBoxCount"])resp.render  += "<td style='width:200px; padding:5px 0 15px 0;font-size:"+ themeSettings.settings.titleSize +"'>Evaluated BoundingBox</td>"
                    //if(!resp.evaluations[_c].typeObjects && resp.evaluations[_c].slices["ALL"]["confidenceMetrics"])resp.render  += "<td style='padding:5px 0 15px 0;text-align: center; font-size:"+ themeSettings.settings.titleSize +"; font-weight:500'>Confidence / Precision / Recovery</td>"
                    resp.render  += "</tr>"
                    for (const _s of Object.keys(resp.evaluations[_c].slices)){  
                    resp.render  += "<tr style='border-bottom: 1px solid #e2e8f0;'>"
                    resp.render  += "<td style='width:200px;padding: 5px 0;'>"+_s+"</td>"
                    if(resp.evaluations[_c].slices[_s]["auPrc"])resp.render    += "<td style='width:100px'><div style='background-color: #f7f8f9; color: #333; text-align: center; padding: 5px 2px;width: 45px;'>"+resp.evaluations[_c].slices[_s]["auPrc"].toFixed(3)+"</div></td>"
                    if(resp.evaluations[_c].slices[_s]["logLoss"])resp.render  += "<td style='width:100px;text-align: center'>"+resp.evaluations[_c].slices[_s]["logLoss"].toFixed(3)+"</td>"
                    if(resp.evaluations[_c].slices[_s]["boundingBoxMeanAveragePrecision"])resp.render += "<td style='width:150px; '><div style='background-color: #e8f0fe; color: #333; text-align: center; padding: 2px 2px;width: 40px;'>"+resp.evaluations[_c].slices[_s]["boundingBoxMeanAveragePrecision"].toFixed(3)+"</div></td>"
                    if(resp.evaluations[_c].slices[_s]["evaluatedBoundingBoxCount"])resp.render  += "<td style='width:200px'>"+resp.evaluations[_c].slices[_s]["evaluatedBoundingBoxCount"]+"</td>"
                    /*if(!resp.evaluations[_c].typeObjects && resp.evaluations[_c].slices[_s].confidenceMetrics){
                        resp.render  += "<td style='padding: 10px 0 5px 37px;'>"
                        resp.render  += "<table style='margin: 2px 0; font-size: 12px; line-height: 12px;'>"
                        let confidenceSections = { "0": true, "0.1": true, "0.3": true, "0.6": true, "0.8": true, "0.9": true, }  
                        resp.render  += "<tr style='padding: 0; margin:0'>"
                        let countConfidence = 0
                        for (let _m   = 0; _m < resp.evaluations[_c].slices[_s].confidenceMetrics.metrics.length; _m++) {
                        let _confidence = resp.evaluations[_c].slices[_s].confidenceMetrics.metrics[_m]
                        if(!_confidence.confidenceThreshold)_confidence.confidenceThreshold = 0
                        if(countConfidence==2 || countConfidence==4 || countConfidence==6) resp.render  += "</tr><tr style='padding: 0; margin:0'>"
                        if(confidenceSections[_confidence.confidenceThreshold]){ countConfidence++;
                            resp.render  += "<td style='padding: 0 5px 10px 10px; width: 30px;'><div style='border: 1px solid #ccc; text-align: center; padding: 1px 2px;width: 30px;'>"+_confidence.confidenceThreshold+"</div></td>"
                            resp.render  += "<td style='padding: 0 5px 10px 0; width:50px; text-align: center;" + (_confidence.precision=="1" ? "color: #3CB371" : "" )+ "'>"+_confidence.precisionPercent+"%</td>"
                            resp.render  += "<td style='padding: 0 10px 10px 0; width: 60px; text-align: center;" + (_confidence.recall=="1" ? "color: #3CB371" : "" )+ "'>"+ (_confidence.recallPercent ? _confidence.recallPercent+"%" : "-") +"</td>"
                        }
                        }
                        resp.render  += "</tr>"
                        resp.render  += "</table>"
                        resp.render  += "</td>"
                    }*/
                    resp.render  += "</tr>"
                    }
                    resp.render    += "</table>"
                }

            }
            resp.status      = "success"
            resp.render     += "</div>"
        }else{ resp.error = "model does not have evaluations" }   
        }else{ resp.error = "modelId is required" } 
        return resp
    },

}

module.exports = model
