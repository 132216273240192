<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12" v-if="dataset"><datasetCmp :dataset="dataset" ref="datasetCmp" /></div>
    </div>   
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref,  } from "vue"; //useContext
import { useRouter } from "vue-router";
import { useStore } from "@/store";

import datasetCmp from "@/components/dataset/Main.vue";


export default defineComponent({
  components: {
    datasetCmp
  },
  setup() { //context

    const store      = useStore();   
    const User       = computed(() => store.state.main.User);
    const router     = useRouter();
    const dataset   = ref(false)
   
    const linkTo = (page) => {  router.push({ name: page  });  };

    onMounted( async () => { 
      dataset.value = router.currentRoute.value.params.id;
    });

    return {
      User,
      dataset,
      linkTo,
    };
  }
});
</script>