<template>
  <div>
    <!-- Add Array item --> 
      <div :id="'add-device'" class="add-device modal" tabindex="-1" aria-hidden="true" >
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-body p-0">
                      <form ref="addMonitor" class="validate-form" @submit.prevent="addMonitor('addMonitor',$event)">
                        <div class="p-5 text-left">
                            <div class="text-2xl mt-2">{{ $t('Add new device') }}</div>
                            <div class="p-5 pb-2 bg-theme-2 mt-2 pb-5">
                                <input type="text" name="id" class="form-control" placeholder="Device ID"  required  />
                            </div>
                        </div>
                        <div class="px-5 pb-8 text-center p">
                            <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                            <button type="submit" class="btn bg-theme-2 text-white w-24">{{ $t('Save') }}</button>
                        </div>
                      </form>
                  </div>
              </div>
          </div>
      </div> 
    <!-- End Add Array item --> 
    <!-- Add device -->
    <div class="grid  grid-cols-12 gap-6 mb-3" v-if="!curMonitor">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
          <div class="dropdown">
              <button class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300" aria-expanded="false">
                  <span class="w-5 h-5 flex items-center justify-center"> <PlusIcon class="w-4 h-4" /> </span>
              </button>
              <div class="dropdown-menu w-40">
                  <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer" @click="AddDevide()"> <MonitorIcon class="w-4 h-4 mr-2" /> Add Device </a>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!-- End Add device -->
    <!-- List -->
    <div class="grid  grid-cols-12 gap-6" v-if="!curMonitor">
      <div class="intro-y col-span-12 lg:col-span-6" v-for="(monitor) in monitors" :key="monitor.id">
        <div class="box">
            <div class="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                <div class="w-12 h-12 lg:w-12 lg:h-12 image-fit lg:mr-1">
                    <div class="w-12 h-12 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 pt-1 text-center hover:bg-theme-17 bg-theme-17" @click="ChangeMonitor(monitor.id)" role="button" aria-expanded="false">
                        <span class="text-white text-2xl">{{  monitor.id.charAt(0) }}</span>
                    </div>
                    <div class="w-3 h-3 absolute right-0 bottom-0 rounded-full border-2 border-white z-10" :class="MonStatus[monitor.id] ? 'bg-theme-10' : 'bg-red-500'"></div>
                </div>
                <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                    <a class="font-medium">{{ $t('ID') }}: {{ monitor.id }}</a> 
                    <div class="text-gray-600 text-xs mt-0.5">{{ $t('Status') }}: <span v-if="MonStatus[monitor.id]">{{ $t('active') }}</span><span v-else class="text-red-500">{{ $t('stopped') }}</span></div>
                </div>
                <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                      <div class="box p-2 zoom-in rpdashbox text-white mr-2">
                        <div class="flex items-center" @click="linkTo('side-menu-node','ui',monitor.id)" title="Monitor">
                            <div class="flex-none ml-auto relative"><EyeIcon class="w-5 h-5" /></div>
                        </div>
                    </div>
                    <div class="box p-2 zoom-in bg-theme-26 text-white mr-2">
                        <div class="flex items-center" @click="linkTo('side-menu-monitor')" title="UI Monitor">
                            <div class="flex-none ml-auto relative"><AirplayIcon class="w-5 h-5" /></div>
                        </div>
                    </div>
                    <div class="box p-2 zoom-in bg-theme-7 ">
                        <div class="flex items-center" @click="ChangeMonitor(monitor.id)" title="Manage">
                            <div class="flex-none ml-auto relative"><SettingsIcon class="w-5 h-5" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!-- End List -->
     <!-- Monitors -->
    <div class="grid chat grid-cols-12 gap-6 mt-0" >
      <div class="col-span-12">
        <div v-for="(monitor) in monitors" :key="monitor.id">
            <div class="intro-y col-span-6 mb-5" v-if="monitor.id == curMonitor">
              <div class="box mt-0">
                  <div class="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                      <div class="w-12 h-12 lg:w-12 lg:h-12 image-fit lg:mr-1">
                          <div class="w-12 h-12 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 pt-1 text-center hover:bg-theme-17 bg-theme-17" @click="ChangeMonitor(monitor.id)" role="button" aria-expanded="false">
                              <span class="text-white text-2xl">{{  monitor.id.charAt(0) }}</span>
                          </div>
                          <div class="w-3 h-3 absolute right-0 bottom-0 rounded-full border-2 border-white z-10" :class="MonStatus[monitor.id] ? 'bg-theme-10' : 'bg-red-500'"></div>
                      </div>
                      <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                          <a class="font-medium">{{ $t('ID') }}: {{ monitor.id }}</a> 
                          <div class="text-gray-600 text-xs mt-0.5">{{ $t('Status') }}: <span v-if="MonStatus[monitor.id]">{{ $t('active') }}</span><span v-else class="text-red-500">{{ $t('stopped') }}</span></div>
                      </div>
                      <div class="flex -ml-2 lg:ml-0 lg:justify-end mt-3 lg:mt-0">
                            <div class="box p-2 zoom-in rpdashbox text-white mr-2">
                              <div class="flex items-center" @click="linkTo('side-menu-node','ui',monitor.id)" title="Monitor">
                                  <div class="flex-none ml-auto relative"><EyeIcon class="w-5 h-5" /></div>
                              </div>
                          </div>
                          <div class="box p-2 zoom-in bg-theme-26 text-white mr-6">
                              <div class="flex items-center" @click="linkTo('side-menu-monitor')" title="UI Monitor">
                                  <div class="flex-none ml-auto relative"><AirplayIcon class="w-5 h-5" /></div>
                              </div>
                          </div>
                          <div class="box p-2 zoom-in bg-theme-7 mr-2">
                              <div class="flex items-center" @click="DeviceInfo()" title="Device details">
                                  <div class="flex-none ml-auto relative"><InfoIcon class="w-5 h-5" /></div>
                              </div>
                          </div>
                          <div class="box p-2 zoom-in bg-theme-27 text-white mr-2">
                              <div class="flex items-center" @click="Restart(monitor.id)" title="Update Module">
                                  <div class="flex-none ml-auto relative"><RefreshCcwIcon class="w-5 h-5" /></div>
                              </div>
                          </div>
                          <div class="box p-2 zoom-in bg-theme-7 mr-2">
                              <div class="flex items-center" @click="Rules(monitor.id)" title="Rules of use">
                                  <div class="flex-none ml-auto relative"><FilterIcon class="w-5 h-5" /></div>
                              </div>
                          </div>
                          <div class="box p-2 zoom-in bg-theme-7 mr-2">
                              <div class="flex items-center" @click="DemoSet(monitor.id)" title="Demo">
                                  <div class="flex-none ml-auto relative"><CameraIcon class="w-5 h-5" /></div>
                              </div>
                          </div>
                          <div class="box p-2 zoom-in bg-theme-7 mr-10">
                              <div class="flex items-center" @click="Tags(monitor.id)" title="Labeling">
                                  <div class="flex-none ml-auto relative"><TagIcon class="w-5 h-5" /></div>
                              </div>
                          </div>
                          <div class="w-full sm:w-auto flex mt-4 sm:mt-0"><button class="btn btn-primary mr-2" @click="curMonitor=false">{{ $t('Back') }}</button></div>
                      </div>
                  </div>
              </div>
            </div>
            <div class="intro-y box w-full " v-if="monitor.id == curMonitor">
              <div class="preview p-5 pt-2">
                 <div v-if="MonRules"><MonitorRules :monitor="MonRules" ref="MonitorRules" /></div>
                 <div v-if="MonDemo"><MonitorDemo :monitor="MonDemo" ref="MonitorDemoSet" /></div>
                 <div v-if="MonTags"><MonitorTags :monitor="MonTags" ref="MonitorTags" /></div>
                 <div v-if="MonDetails">
                    <div class="intro-y block sm:flex items-center h-10">
                      <span class="text-base font-medium truncate mr-5">{{ $t('Device settings') }}</span>
                    </div>
                    <div id="form-validation pt-5">
                      <div class="preview pb-5">
                          <form ref="updDevice" :class="validate-form" @submit.prevent="updateDevice('updDevice',monitor.id,$event)">
                            <div class="input-form mt-2">
                                <label class="form-label">{{ $t('Module URL') }}</label>
                                <input type="text" class="form-control" name="module_url" :value="monitor.module ? monitor.module.url : ''" /> 
                            </div> 
                            <div class="input-form mt-5">
                                <label class="form-label">{{ $t('Prediction Rest') }}</label>
                                <input type="text" class="form-control" name="prediction_rest" :value="monitor.prediction ? monitor.prediction.rest : ''"  />
                            </div> 
                            <div class="input-form pt-5 border-t border-gray-200 dark:border-dark-5 mt-5"><button type="submit" class="btn btn-primary">{{ $t('Save') }}</button></div> 
                          </form>
                      </div>
                    </div>
                 </div>  
                 <!--<div v-else>
                  <div class="text-base pb-2 font-medium" v-if="MonSessions[monitor.id]">{{ $t('Last session') }}</div>
                  <table class="table table--sm" v-if="MonSessions[monitor.id]">
                      <tbody>
                          <tr class="hover:bg-gray-200"><td class="border">ID</td><td class="border font-normal">{{MonSessions[monitor.id].id}}</td></tr>
                          <tr class="hover:bg-gray-200"><td class="border">{{ $t('Date') }}</td><td class="border font-normal">{{MonSessions[monitor.id].start_date}}</td></tr>
                          <tr class="hover:bg-gray-200"><td class="border">{{ $t('Duration') }}</td>
                            <td class="border font-normal" v-if="MonSessions[monitor.id].duration">
                                {{MonSessions[monitor.id].duration.hours}}h {{MonSessions[monitor.id].duration.minutes}}m {{MonSessions[monitor.id].duration.seconds}}s
                            </td>
                          </tr>
                          <tr class="hover:bg-gray-200"><td class="border">{{ $t('Predicition rest') }}</td><td class="border font-normal truncate">{{MonSessions[monitor.id].prediction_rest}}</td></tr>
                          <tr class="hover:bg-gray-200"><td class="border">{{ $t('User') }}</td><td class="border font-normal">{{MonSessions[monitor.id].uid}}</td></tr>
                      </tbody>
                  </table>
                 </div>
                  -->
              </div>
            </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { monitor as $monitor } from "@/model/monitor";
import { session as $session } from "@/model/session";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import cash from "cash-dom";

import MonitorRules from "@/components/monitor-rules/Main.vue";
import MonitorDemo  from "@/components/monitor-demo/Main.vue";
import MonitorTags  from "@/components/monitor-tags/Main.vue";

export default defineComponent({
  components: {
    MonitorRules,
    MonitorDemo,
    MonitorTags
  },
  created () {
    this.helper  = $h
  },  
  setup() {
 
    const router     = useRouter();
    const monitors     = ref([])
    const curMonitor   = ref()
    const MonStatus    = ref({})
    const MonSessions  = ref({})
    const MonRules     = ref(false)
    const MonDemo      = ref(false)
    const MonTags      = ref(false)
    const MonDetails   = ref(false)

    const AddDevide = async () => { cash('#add-device').modal("show"); };
    const DeviceInfo = async () => { MonDetails.value = true; MonRules.value = MonDemo.value = MonTags.value = false; };
    
    const ChangeMonitor = async (m) => { MonDetails.value = MonRules.value = MonDemo.value = MonTags.value = false; curMonitor.value = m; await getStatus();  Tags(m)};

    const getStatus     = async ()  => {
      if(monitors.value){
        for (let i = 0; i < monitors.value.length; i++) {
          if(monitors.value[i].id){
            MonStatus.value[monitors.value[i].id]   = await $monitor.isPlay(monitors.value[i].id);
            MonSessions.value[monitors.value[i].id] = await $session.getLast({ uid : useStore().state.main.User.uid , monitor: monitors.value[i].id });
          }
        }
      }
     };
    
    const Rules    = (m) => { MonRules.value = m; MonDemo.value = MonTags.value = MonDetails.value = false; };
    const Tags     = (m) => { MonTags.value = m; MonRules.value =  MonDemo.value = MonDetails.value = false; };
    const DemoSet  = (m) => { MonDemo.value = m;  MonRules.value = MonTags.value = MonDetails.value = false;};
    const Restart  = async (m) => { await $monitor.restart(m); };
  
     const linkTo = (page,params=false,monID=false) => {  
        let p = { type: false, monID: false }; if(params)p.type= params; if(monID)p.monID= monID; 
        router.push({ name: page, params: p });  
    };

    onMounted( async () => { 
      if(router.currentRoute.value.params && router.currentRoute.value.params.type!="false")ChangeMonitor(router.currentRoute.value.params.type)
      monitors.value = await $monitor.get(); 
      await getStatus()
    });

    return {
      monitors,
      curMonitor,
      MonStatus,
      MonSessions,
      MonRules,
      MonitorTags,
      MonDemo,
      MonTags,
      ChangeMonitor,
      Rules,
      Tags,
      DemoSet,
      Restart,
      linkTo,
      AddDevide,
      DeviceInfo,
      MonDetails
    };
  },
  methods:{
    addMonitor: async function(formID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        let addMon = { data: {} }
        for (let i = 0; i < this.$refs[formID].length; i++) {  
         if(this.$refs[formID][i].name=="id")addMon['id'] = this.$refs[formID][i].value; 
        }  
        let newMonID = await $monitor.addMonitor(addMon)
        cash('.add-device').modal("hide");
        $h.NotificationTxt({text: this.$i18n.t('New monitor created successfully') })
        this.monitors = await $monitor.get()
        if(newMonID)await this.ChangeMonitor(newMonID)
      }
    },
    updateDevice: async function(formID,deviceID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        let updDev = { monitor: deviceID , data : { module: {} , prediction: {} }}
        for (let i = 0; i < this.$refs[formID].length; i++) {  
          if(this.$refs[formID][i].name=="module_url")updDev.data.module['url'] = this.$refs[formID][i].value;
          if(this.$refs[formID][i].name=="prediction_rest")updDev.data.prediction['rest'] = this.$refs[formID][i].value;
        }  
        await $monitor.updateDevice(updDev);
        $h.NotificationTxt({text: this.$i18n.t('Device configuration successfully updated')})
        this.monitors = await $monitor.get()
      }
    },
  }
});
</script>
