import { createRouter, createWebHistory } from "vue-router";
import Dashboard  from "../views/dashboard/Main.vue";
import SideMenu from "../layouts/side-menu/Main.vue";
import Profile  from "../views/profile/Main.vue";
import Sessions from "../views/sessions/Main.vue";
import Log  from "../views/log/Main.vue";
import Settings from "../views/settings/Main.vue";
import ErrorPage from "../views/error-page/Main.vue";
import Documentation from "../views/doc/Main.vue";
import Api from "../views/api/Main.vue";
import Login from "../views/login/Main.vue";
import Node  from "../views/node/Main.vue";
import Flow  from "../views/flow/Main.vue";
import Controller  from "../views/controller/Main.vue";
import Dev  from "../views/dev/Main.vue";
import Data  from "../views/data/Main.vue";
import Model  from "../views/model/Main.vue";
import Datasets  from "../views/datasets/Main.vue";
import Dataset  from "../views/dataset/Main.vue";

const routes = [
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "/",
        name: "side-menu-dashboard",
        component: Dashboard
      },
      {
        path: "settings",
        name: "side-menu-settings",
        component: Settings
      },
      {
        path: "log",
        name: "side-menu-log",
        component: Log
      },
      {
        path: "profile",
        name: "side-menu-profile",
        component: Profile
      },   
      {
        path: "sessions",
        name: "side-menu-sessions",
        component: Sessions
      },
      {
        path: "node",
        name: "side-menu-node",
        component: Node
      },
      {
        path: "flow",
        name: "side-menu-flow",
        component: Flow
      },
      {
        path: "controller",
        name: "side-menu-controller",
        component: Controller
      },
      {
        path: "data",
        name: "side-menu-data",
        component: Data
      },
      {
        path: "datasets/:id?/:i?",
        name: "side-menu-datasets",
        component: Datasets
      },
      {
        path: "dataset/:id",
        name: "side-menu-dataset",
        component: Dataset
      },
      {
        path: "dev",
        name: "side-menu-dev",
        component: Dev
      },
      {
        path: "model",
        name: "side-menu-model",
        component: Model
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage
  },
  {
    path: "/doc",
    name: "doc",
    component: Documentation
  },
  {
    path: "/api",
    name: "api",
    component: Api
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes, scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

export default router;
