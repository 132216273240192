<template>
  <div class="mt-5">
     <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12"><ManageLog :options="LogOptions"  /></div>
    </div>  
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";

import ManageLog from "@/components/manage-log/Main.vue";

export default defineComponent({
  components: {
   ManageLog
  },
  setup() {

    const LogOptions = ref({});

    onMounted( () => { 
      LogOptions.value = { stats: true } 
     });

    return {
      LogOptions
    };

  }
});
</script>
