<template>
  <div class="mt-5">
     <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12"><ManageSession :options="SessionOptions"  /></div>
    </div>  
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";

import ManageSession from "@/components/manage-session/Main.vue";

export default defineComponent({
  components: {
   ManageSession
  },
  setup() {

    const SessionOptions = ref({});

    onMounted( () => { });

    return {
      SessionOptions
    };

  }
});
</script>
