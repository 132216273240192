<template>
  <div id="backIframeNode" class="w-full h-full">
    <div v-if="layout.error" class="flex flex-col items-center text-center pt-32">
      <img :src="require('@/assets/images/rosepetal/icon/errorNet.png')" class="w-32" />
      <div class="mt-5 text-gray-600">{{ $t('Controller is offline') }}</div>
      <button class="btn flex items-center bg-gray-100 border-gray-300 py-1 px-8 mt-3 text-sm font-normal hover:bg-blue-900 hover:text-white" @click="forceReload()">{{ $t('Retry') }}</button>
    </div>
    <div v-else class="flex flex-col items-center text-center"><img :src="require('@/assets/images/rosepetal/icon/train7.gif')" class="w-72" /></div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, computed,  onUnmounted } from "vue";
import * as rosepetalModel from "rosepetal-model";
import cash from "cash-dom";
import { useStore } from "@/store";
import config from '../../etc/rosepetal.json';

const nodeConfig = config.nodeConfig;

export default defineComponent({
  setup() {

    const store            = useStore();  
    const User             = computed(() => store.state.main.User);
    const layout           = ref({ error: true });

    const loadNR = async (url) => {  
      layout.value.error  = false;
      const uiFrame       = document.getElementById("uiFrame");
      uiFrame.src         = "about:blank";  
      uiFrame.onload      = async () => {
        let _status       = await rosepetalModel.controller.getStatus()
        if(_status.error){
          cash(".content").removeClass("hidden");
          uiFrame.style.display = 'none';
          layout.value.error    = true;
          uiFrame.src           = "about:blank";  
        }else{
          cash(".content,#backIframeNode").addClass("hidden");
          uiFrame.style.display = 'block';
        }  
      };
      uiFrame.src = nodeConfig.host + "/nodered" + url + "?uid=" + User.value.uid; 
    }

    onMounted( async () => { 
      cash(".content").addClass("rpContentNodeBackground");
      cash("#topBarUiIframe").css("display", "block");
      cash(".dashboxItem,.side-nav").addClass("hidden");
      cash(".top-bar-node-menu").removeClass("hidden");
      cash(".top-bar-node-menu-side-menu-node").addClass("top-bar-node-menu-current");
      cash("body").css("overflow-y", "hidden");
      if(cash("#uiFrame").attr("src")=="about:blank") await loadNR('/ui/#!/0');
      else{
        layout.value.error = false;
        cash(".content").addClass("hidden");
        document.getElementById("uiFrame").style.display = 'block';
      }
    });

    onUnmounted( () => { 
      cash(".content").removeClass("rpContentNodeBackground");
      cash(".dashboxItem,.side-nav,.content").removeClass("hidden");
      cash(".top-bar-node-menu").addClass("hidden");
      cash("#topBarUiIframe").css("display", "none");
      cash(".top-bar-node-menu-current").removeClass("top-bar-node-menu-current");
      cash("body").css("overflow-y", "scroll");
    });

    const forceReload = async () => { await loadNR('/ui/#!/0'); }

    return{
      layout,
      forceReload
    }

  }
});
</script>
<style>
.rpContentNodeBackground{ background-color: #fff !important; padding: 0 !important; }
</style>