<template>
  <div class="mt-5">
    <div class="grid grid-cols-12 gap-6 mt-2">
      <div class="col-span-12 lg:col-span-12 xxl:col-span-12"><div><component :is="CurTab" ref="ManageDatasets" /></div></div>
    </div>   
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref,  } from "vue"; //useContext
import { useRouter } from "vue-router";
import { useStore } from "@/store";

import ManageDatasets from "@/components/manage-datasets/Main.vue";

import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    ManageDatasets
  },
  setup() { //context

    const store      = useStore();   
    const User       = computed(() => store.state.main.User);
    const router     = useRouter();
    const {t}        = useI18n(); 
    const CurTab     = ref("ManageDatasets")
   
    const linkTo = (page) => {  router.push({ name: page  });  };

    onMounted( async () => { 
    });

    return {
      User,
      CurTab,
      linkTo,
      t
    };
  }
});
</script>