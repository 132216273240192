const _firebase     = require("./firebase");
const packageJson   = require('../package.json');
const other         = require("./other");

const project = { 
    getModelVersion: function(){ return packageJson.version },
    getConfig: function( host = false) { 
        let firebaseConfig  = _firebase.getConfig()
        if(firebaseConfig.projectId){
            let projectName     = firebaseConfig.projectId.toString().split('-').pop() 
            let nodeHost        = host ? host :  "https://" + projectName + ".dep.rosepetal.ai"
            if(!host && projectName=="dev")nodeHost = "https://dev.rosepetal.ai"
            if(projectName=="baxter")nodeHost = "https://flexicap-baxter.dep.rosepetal.ai"
            if(projectName=="analysis")nodeHost = "https://analysis.rosepetal.ai"
            let usApiUrl  = "https://usapi-5oaggxmg3a-uc.a.run.app"
            if(projectName=="montanacolors")usApiUrl = "https://usapi-43jliszvyq-uc.a.run.app"
            return {
                    "firebaseConfig":   firebaseConfig,
                    "nodeConfig":       { "host": nodeHost },
                    "modelBucket":      "gs://" + firebaseConfig.projectId,
                    "firebaseRegion":   firebaseConfig.region ? firebaseConfig.region : "europe-west1",
                    "localhost":        "localhost",
                    "functions":        { "usapi": usApiUrl },
                    /*"awsConfig":      {}*/
            }
        }else return { error: "No firebase config found" }
    }, 
    getModelObject: function(model){ 
        let obj = {}
        for (let m in model) {
            if(!obj[m])obj[m] = []
            for (let f in model[m]) {
                let methodId = "model-" + m + "-" + f
                obj[m].push({
                    "id":        methodId,
                    "model":     m,
                    "name":      f,
                    "arguments": model[m][f].length ? true : false,
                    "description":    other.getApiMethods({ id: methodId }).description
                })
                
            }
        }
        for (let m in obj) {
            obj[m] = obj[m].sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
        }
        return obj
    },
    
    getUsers: async function(opt = false){ 
        let resp = { status: "error", error: false, count: 0, roleId: false, users: {}  }
        let list = await other.httpsCallable('api/user/list')
        if(list.data){
            if(opt.roleId)resp.roleId = opt.roleId
            if(opt.uid)resp.uid = opt.uid
            if(list.data.length){
                for(let i=0 ; i < list.data.length ; i++){
                    let pushItem = true
                    if(opt.roleId && (list.data[i].role && list.data[i].role.role_id!=opt.roleId))pushItem = false
                    if(opt.uid && (list.data[i].uid != opt.uid))pushItem = false
                    if(pushItem)resp.users[list.data[i].uid] = list.data[i]
                }
                resp.count  = Object.keys(resp.users).length
                if(resp.count){
                    resp.status = "success"
                }else{ resp.error = "not found users" } 
            }else{ resp.error = "The project does not have assigned users" }
        }else{ resp.error = "The operation could not be completed" }
        return resp
    },

    getSettings: async function(viewId, param=false){ 
        let viewSettings = false;
        await _firebase.firestore().collection('view').doc(viewId).get().then((doc) => { if(doc.exists){ viewSettings = doc.data(); if(param)viewSettings = viewSettings[param] } 
        }).catch((error) => { console.log("Error getting document:", error); return false;});
        return viewSettings;
      },

}

module.exports = project
