<template>
  <div>
    <!-- MONITOR MODE CONTROLS -->
    <div class="col-span-12 lg:col-span-8 xl:col-span-6 mt-2" v-if="Layout.monitormode">
      <div class="box rounded-md px-5 py-3 bg-theme-21">
        <div class="intro-y block sm:flex items-center">
          <h2 class="text-lg font-medium truncate mr-5 text-white">{{ $t('Monitor Mode') }} <span class="text-xs pl-3">{{ $t('Last dataset image') }}</span></h2> 
          <button class="btn box flex items-center bg-theme-1 text-white py-1 px-5 font-normal sm:ml-auto mt-3 sm:mt-0 sm:w-auto" @click="MonitorMode(true)">{{ $t('Back') }}</button>
        </div>
      </div>
    </div>
     <!-- /MONITOR MODE CONTROLS -->
    <!-- SINGLE MODE CONTROLS -->
    <div class="col-span-12 lg:col-span-8 xl:col-span-6 mt-2" v-if="Layout.singlemode">
      <div class="box rounded-md px-5 py-3 bg-white">
        <div class="intro-y block sm:flex items-center">
          <h2 class="text-lg font-medium truncate mr-5">{{ $t('Data Labeling') }} 
            <span class="text-xs">{{ $t('Single Mode') }}</span>
          </h2>
          <div class="flex flex-col sm:flex-row">
            <div class="form-check ml-10 mr-1 pr-1" v-if="curDataset"> 
                <div class="w-7 h-7 image-fit">
                    <div class="w-7 h-7 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 text-center bg-theme-8" role="button" aria-expanded="false">
                        <span class="text-white text-xl" :title="curDataset.id">{{ curDataset.id.charAt(0) }}</span>
                    </div>
                </div>
            </div>   
            <div class="form-check mr-2"> 
              <span class="inline-block mr-1 text-sm align-top pr-4" v-if="media.length"> 
                  <span class="font-bold text-lg">{{ pagination.currentPage+1 }}</span>
                    <span class="px-1"> {{ $t('of') }} </span> 
                    <span v-if="pagination.pages">{{ pagination.pages }}</span> 
                    {{ $t('media') }} <span v-if="curDataset.tags && curDataset.tags[CurTag]" class="font-bold">{{curDataset.tags[CurTag].name}}</span>
                </span>
            </div>
          </div>  
          <button class="btn box flex items-center bg-theme-2 text-white py-1 px-5 font-normal sm:ml-auto mt-3 sm:mt-0 sm:w-auto" @click="SingleMode(true)">{{ $t('Back') }}</button>
        </div>
      </div>
    </div>
    <!-- /SINGLE MODE CONTROLS -->
    <div class="grid grid-cols-12" v-if="!Layout.singlemode && !Layout.monitormode"><div class="col-span-12"><h2 class="text-2xl font-medium mt-5">{{ $t('Data Labeling') }}</h2></div></div>
    <div class="grid grid-cols-12 mt-4" v-if="!Layout.singlemode && !Layout.monitormode">
       <div class="col-span-12">
        <div class="box rounded-md px-5 py-2 bg-white pt-0 pb-2" v-if="datasets && Object.keys(datasets).length">
          <!-- DATASET -->
          <span class="text-sm pr-5">{{ $t('Choose dataset') }}:</span>
          <select id="filterDatasetSel" name="filterDatasetSel" class="form-select bg-gray-300 cursor-pointer mt-3 w-52 mr-5" @change="Filter($event)" >
              <option v-for="(d) in datasets" :value="d.id" :key="d.id" :selected="curDataset.id === d.id">
                <span v-if="d.name">{{ d.name }}</span><span v-else>{{ d.id }}</span>
              </option>
          </select>
          <!-- LABELING v-if="media.length && (curDataset.type && curDataset.type=='imageObjectDetection' ? false : true)" -->
          <span class="text-sm pr-5" v-if="curDataset && curDataset.tags && Object.keys(curDataset.tags).length && (curDataset.type && curDataset.type=='imageObjectDetection' ? false : true)">{{ $t('Label') }}:</span>
          <select id="filterCatSel" class="form-select bg-gray-200 cursor-pointer mt-3 w-52 mr-5" v-model="CurTag" @change="Filter($event)" 
            v-if="curDataset && curDataset.tags && Object.keys(curDataset.tags).length && (curDataset.type && curDataset.type=='imageObjectDetection' ? false : true)">
              <!-- <option value="all">{{ $t('All') }} ({{curDataset.imageCounter}})</option> -->
              <option v-for="t in curDataset.tags" :value="t.id" :key="t.id" :selected="CurTag === t.id">{{ t.name=="0" ? 'Unclassified' : t.name }} ({{ t.imageCounter ? t.imageCounter : '0' }})</option>
          </select>
          <!-- DATASET ACTIONS
            <a class="text-theme-27 hover:text-theme-28 truncate cursor-pointer" @click="Modal('modal-dataset-overview','dataDivision')  " v-if="curDataset"><InfoIcon class="w-5 h-5 mr-1" /> {{ $t('Overview') }}</a>
           -->
          <a class="text-theme-27 hover:text-theme-28 truncate cursor-pointer" :href="'/datasets/'+curDataset.id" v-if="curDataset"><PackageIcon class="w-5 h-5 mr-1" /> {{ $t('Manage dataset') }}</a>
        </div>      
       </div> 
    </div> 
    <!-- FILTERS -->
    <div class="grid grid-cols-12 mt-1" v-if="media.length && !Layout.singlemode && !Layout.monitormode && !Layout.loading">
       <div class="col-span-12">
        <div class="box rounded-md px-5 py-2 bg-white">
          <div class="flex flex-col sm:flex-row">
             <div class="form-check mr-2 pr-2" v-if="curDataset"> 
                <div class="w-7 h-7 image-fit">
                    <div class="w-7 h-7 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 text-center bg-theme-8" role="button" aria-expanded="false">
                        <span class="text-white text-xl" :title="curDataset.id">{{ curDataset.id.charAt(0) }}</span>
                    </div>
                </div>
             </div>   
             <div class="form-check mr-2 pr-4"> 
              <span class="inline-block mr-1 text-sm align-top pr-1" v-if="media.length"> 
                  <span class="font-bold text-lg" v-if="pagination.total && curDataset.imageCounter!=pagination.total">{{ pagination.total }}</span>
                    <span v-if="pagination && pagination.total && curDataset.imageCounter!=pagination.total" class="px-1"> {{ $t('of') }} </span> 
                    <span v-if="curDataset && curDataset.type && curDataset.objtags && curDataset.type=='imageObjectDetection'">{{curDataset.objtags.total}}</span>
                    <span v-else>
                        <span :class="pagination && pagination.total && curDataset.imageCounter!=pagination.total ? pagination.total ? 'text-sm' : '' : 'font-bold'" 
                        v-if="curDataset && curDataset.imageCounter">{{ curDataset.imageCounter }}</span> 
                        <span class="font-bold" v-else>0</span>
                    </span>
                    {{ $t('media') }}
                </span>
             </div>
              <div class="form-check px-5 border-l">
                  <input id="mediaLabel" type="checkbox" :checked="Layout.mediaLabel" class="form-check-input" @change="Layout.mediaLabel = Layout.mediaLabel ? false : true"  />
                  <label class="form-check-label pt-0.5" for="mediaLabel">{{ $t('Labels') }}</label>
              </div>
              <div class="form-check px-5 border-l">
                  <input id="mediaName" type="checkbox" :checked="Layout.mediaName" class="form-check-input" @change="Layout.mediaName = Layout.mediaName ? false : true"  />
                  <label class="form-check-label pt-0.5" for="mediaName">{{ $t('Media name') }}</label>
              </div>
              <div class="form-check px-5 border-l">
                  <input id="mediaMask" type="checkbox" :checked="Layout.mediaMask" class="form-check-input" @change="Layout.mediaMask = Layout.mediaMask ? false : true; Filter($event)"  />
                  <label class="form-check-label pt-0.5" for="mediaMask">{{ $t('Mask') }}</label>
              </div>
              <div class="form-check px-5 border-l">
                  <input id="mediaSet" type="checkbox" :checked="Layout.mediaSet" class="form-check-input" @change="Layout.mediaSet = Layout.mediaSet ? false : true"  />
                  <label class="form-check-label pt-0.5" for="mediaSet">{{ $t('Set') }}</label>
              </div>
              <div class="form-check mr-2 border-l pr-3" v-if="curDataset && curDataset.type && curDataset.type!='imageObjectDetection'">
                <span class="text-sm pr-3 pl-5" v-if="media.length">{{ $t('Size') }}:</span>
                <span class="inline-block align-middle mt-2" 
                  v-if="media.length"><input type="range" name="quantity" min="2" max="4" :value="Layout.mediaSize" 
                  @change="MediaSize($event)"></span>
              </div>
              <div class="form-check mr-5 border-l">
                <span class="text-sm pr-5 pl-4" v-if="media.length">{{ $t('Per page') }}:</span>
                <select class="w-20 form-select p-1 cursor-pointer" v-model="pagination.perPage" @change="SetPerPage($event)" v-if="media.length">
                    <option v-bind:value="12">12</option>
                    <option v-bind:value="50">50</option>
                    <option v-bind:value="125">125</option>
                    <option v-bind:value="250">250</option>
                  </select>
              </div>  
              <button class="btn box flex items-center bg-theme-2 text-white py-1 px-2 font-normal" @click="SingleMode()" 
                  v-if="media.length && (curDataset.type && curDataset.type=='imageObjectDetection' ? false : true)"
                  title="Single Mode">
                  <MaximizeIcon class="h-4 w-4" />
              </button>
              <button class="btn box flex items-center bg-theme-21 text-white py-1 px-2 ml-1 font-normal" @click="MonitorMode()" 
                v-if="media.length && (curDataset.type && curDataset.type=='imageObjectDetection' ? false : true)"
                title="Monitor Mode">
                <MonitorIcon class="h-4 w-4" />
              </button>
          </div>
        </div>      
       </div> 
    </div>
    <!-- /FILTERS -->

    <!-- SELECTED ACTIONS -->
    <div class="grid grid-cols-12 gap-1">
        <div class="col-span-12" v-if="!Layout.singlemode && !Layout.monitormode && !Layout.loading">
          <div class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-0 pt-1 py-2 mb-0 pb-0">
             <div class="flex box rounded-md pl-3 py-2 bg-white mr-3 mt-0.5">
                <MousePointerIcon class="w-5 h-5 mt-2 mr-2" v-if="curDataset && curDataset.type && curDataset.type=='imageObjectDetection' && curDataset.objtags" />
                <select class="form-select bg-gray-300 cursor-pointer w-52 mr-2 p-2" v-model="Layout.filterObject.type" 
                    v-if="curDataset && curDataset.type && curDataset.type=='imageObjectDetection' && curDataset.objtags" @change="FilterObj()">
                    <option value="labeled">{{ $t('Labeled') }} ({{$h.formatScore(curDataset.objtags.labeled)}})</option>
                    <option value="nolabel">{{ $t('Not labeled') }} ({{$h.formatScore(curDataset.objtags.nolabel)}}) </option>
                </select>
                <GridIcon class="w-5 h-5 mt-2 ml-2 mr-2" v-if="curDataset.type=='imageObjectDetection' && Layout.filterObject.type=='labeled' && curDataset.objtags && curDataset.objtags.tagslabeled" />
                <select class="form-select cursor-pointer w-52 mr-2 p-2" :class="Layout.filterObject.byLabel!='all' ? 'bg-theme-1 text-white' : 'bg-gray-300'"
                      v-model="Layout.filterObject.byLabel" @change="FilterObj()" 
                      v-if="curDataset.type=='imageObjectDetection' && Layout.filterObject.type=='labeled' && curDataset.tags">
                      <option v-for="(tag,indx,c) in curDataset.tags" :value="indx==0 ? 'all' : indx" :key="c"> 
                        {{ indx==0 ?  $t('All label'): curDataset.tags[indx] && curDataset.tags[indx].name ? curDataset.tags[indx].name : indx }} 
                        {{ indx==0 ? "" : curDataset.tags[indx] && curDataset.tags[indx].imageCounter ? " ("+curDataset.tags[indx].imageCounter+" "+$t('annotations')+")" : " ("+$t('None')+")" }}
                      </option>
                      <!--
                      <option v-for="(tag,indx,c) in curDataset.objtags.tagslabeled" :value="indx" :key="c"> 
                        {{ indx}} {{ curDataset.tags[indx] ? "("+curDataset.tags[indx].imageCounter+")" : '' }}
                      </option>
                      -->
                </select>
                <LayersIcon class="w-5 h-5 mt-2 ml-2 mr-2" />
                <select class="form-select bg-gray-300 cursor-pointer w-52 mr-2 p-2" 
                    :class="Layout.filterObject.division!='all' ? 'bg-theme-1 text-white' : 'bg-gray-300'"
                    v-model="Layout.filterObject.division" @change="FilterObj()">
                    <option value="all">{{ $t('All division') }}</option>
                    <option value="predetermined">{{ $t('Predetermined') }}</option>
                    <option value="test">{{ $t('Test') }}</option>
                    <option value="validation">{{ $t('Validation') }}</option>
                    <option value="train">{{ $t('Train') }}</option>
                </select> 
            </div>  
          </div>
        </div> 
        <div class="col-span-12 mt-0 pt-0">
          <div class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-0 pt-0" v-if="!Layout.singlemode && !Layout.monitormode && !Layout.loading">
            <div class="flex box rounded-md px-3 py-2 bg-white mr-3" v-if="media.length">
              <div class="flex items-center mt-1">
                <button class="btn box flex items-center bg-theme-1 text-white py-1 px-5 font-normal" @click="Check()">{{ $t('Select all') }}</button>
                <button class="ml-1 btn box flex items-center text-white bg-theme-28 py-1 font-normal" @click="UnCheck()" v-if="selectedImages.length"><XIcon class="hidden sm:block w-3 h-3 mr-1" />{{ $t('Unselect') }}</button>
              </div> 
              <div class="flex pl-5 mt-1" v-if="CountCheck() && !Layout.loading">
                  <div style="vertical-align: top">
                    <span v-if="!actions.labeling && !actions.delete" class="mr-4">
                      <span class="text-2xl font-bold align-middle pr-2">{{CountCheck()}}</span><span class="align-middle">{{ $t('media selected') }}</span>
                    </span>
                  </div>
                  <div style="vertical-align: top">
                    <a class="btn btn-success bg-theme-10 p-0 py-1 px-3 font-normal ml-2 rpbtn2" v-if="!actions.labeling && !actions.delete && curDataset.type && curDataset.type=='imageObjectDetection' ? false : true"  
                        @click="Modal('modal-labeling')" title="Assign label"><TagIcon class="w-5 h-5 cursor-pointer" /></a>
                    <a class="btn btn-success bg-theme-10 p-0 py-1 px-3 font-normal ml-1 rpbtn2"
                        @click="Modal('modal-change-set')" title="Data division"><LayersIcon class="w-5 h-5 cursor-pointer" /></a>
                    <a class="btn btn-success text-white p-0 py-1 px-3 font-normal ml-1 rpbtn1" @click="Modal('modal-download-selected')"><DownloadCloudIcon class="h-5 w-5" /></a>
                    <a class="btn btn-danger p-0 py-1 px-3 font-normal ml-1" @click="Modal('modal-delete')">
                      <TrashIcon class="w-5 h-5 cursor-pointer" />
                    </a>
                  </div>
              </div>
            </div>
            <!-- PAGINATION -->
            <ul class="pagination ml-auto mr-2" v-if="media.length">
              <li class="w-10" v-if="pagination.prev"><button class="pagination__link " @click="FilterMedia('init')"><ChevronsLeftIcon class="w-6 h-6" /></button></li>
              <li class="w-10" v-if="pagination.prev"><button class="pagination__link" @click="FilterMedia('prev')" ><ChevronLeftIcon class="w-6 h-6" /></button></li>
              <li><span class="pagination__link cursor-default" v-if="pagination.pages>2 && (pagination.currentPage+1)>2">...</span></li>
              <li v-for="(n) in pagination.pages" :key="n" class="mt-1">
                  <a class="pagination__link pl-0 pr-0 cursor-default" :class="isCurPage(n)" v-if="n<((pagination.currentPage+1)+2) && n>((pagination.currentPage+1)-2)">{{ n }}</a>
              </li>
              <li><span class="pagination__link cursor-default" v-if="pagination.pages>2 && (pagination.currentPage+1)<(pagination.pages-1)">...</span></li>
              <li class="w-10" v-if="pagination.next"><button class="pagination__link" @click="FilterMedia('next')"><ChevronRightIcon class="w-6 h-6" /></button></li>
              <li class="w-10" v-if="pagination.next && pagination.toend"><a class="pagination__link" @click="FilterMedia('end')"><ChevronsRightIcon class="w-4 h-4" /></a></li>
            </ul>
            <!-- /PAGINATION -->
          </div>
          <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-20" v-if="Layout.loading"><LoadingIcon icon="three-dots" class="w-12 h-12" /></div>
          <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-10" v-if="!media.length && !Layout.loading"><CameraOffIcon  class="w-12 h-12" /></div>
          <!-- RESULTS ------------------------------------------------------------------>
          <div v-else class="mt-3">
            <div class="intro-y grid grid-cols-12 gap-1" v-if="!Layout.loading && !Layout.singlemode && !Layout.monitormode">
              <!-- IMAGES LIST -->
              <div v-for="(m) in media" v-bind:key="m" class="intro-y col-span-2" :class="'sm:col-span-'+Layout.mediaSize+' md:col-span-'+Layout.mediaSize+' lg:col-span-'+Layout.mediaSize+' xxl:col-span-'+Layout.mediaSize">
                  <div class="file box rounded-md px-2 pb-2 relative border" :class="Object.values(selectedImages).includes(m.id) ? 'border-theme-21' : ''">
                    <div class="intro-y flex items-center h-10 m-0 p-0">
                      <input class="form-check-input border border-gray-900" v-model="selectedImages" :value="m.id" type="checkbox" /> 
                      <div class="w-6 inline-flex ml-auto align-top cursor-pointer" @click="Zoom(m)">
                        <EyeIcon class="w-5 h-5" v-if="curDataset.type && curDataset.type=='imageObjectDetection' ? false : true" title="View" />
                        <MousePointerIcon class="w-5 h-5" v-else />
                      </div>
                      <LayersIcon class="w-5 h-5 cursor-pointer ml-1" @click="changeSet(m)" v-if="Layout.mediaSet" />
                      <div class="float-right bg-gray-300 py-1 px-1.5 ml-3 rounded h-6 ml-1 cursor-pointer" v-if="m?.mask?.imageJson" @click="Zoom(m)" 
                        :style="curDataset.tags && curDataset.tags[m.tagName[3]] ? 'background-color: rgba('+parseInt(curDataset.tags[m.tagName[3]].color.slice(1, 3), 16)+', '+parseInt(curDataset.tags[m.tagName[3]].color.slice(3, 5), 16)+', '+parseInt(curDataset.tags[m.tagName[3]].color.slice(5, 7), 16)+', 0.3);border: 1px solid '+curDataset.tags[m.tagName[3]].color : ''">
                        <img :src="require(`@/assets/images/rosepetal/icon/pencil.png`)" class="w-3" title="groundtruth" />
                      </div>
                    </div>
                    <div class="block left-0 top-0  items-center cursor-pointer" @click="CheckItem(m.id)">
                      <div class="image-tags pb-3 ml-8" v-if="curDataset.type && curDataset.type!='imageObjectDetection'">
                        <span class="py-1 px-2 rounded-md cursor-pointer font-medium text-center w-28 text-xs text-truncate" 
                          :class="m.set == 'PREDETERMINED' ? 'bg-theme-7 text-gray-600' : 'bg-theme-21 text-white'" v-if="Layout.mediaSet && m.set">{{ m.set }}</span>
                        <!--<span class="py-1 px-2 rounded-md bg-theme-7 text-gray-600 cursor-pointer font-medium text-center w-28 text-xs text-truncate" v-if="Layout.mediaSet && !m.set">{{ $t('PREDETERMINED') }}</span>-->
                        <AlignLeftIcon class="w-5 h-5 ml-1 text-theme-21" v-if="m.comments" title="Comments" />
                      </div>
                      <!--
                      <a class="w-full file__icon file__icon--image mx-auto" :style="curDataset.type && curDataset.type=='imageObjectDetection' ? ' display: none !important' : ''">
                        <div class="file__icon--image__preview image-fit" :class="curDataset.type && curDataset.type=='imageObjectDetection' ? 'image-fit-contain' : ''">
                          <img :id="'thumbPreview_'+m.id" v-bind:src="'data:image/png;base64,'+ m.img_base64_val" class="w-full" v-if="m.img_base64_val">
                        </div>
                        <div class="image-tags pb-3 pl-2">
                          <span class="py-1 px-3 rounded-md bg-theme-10 text-white cursor-pointer font-medium text-center text-xs text-truncate" :class="Layout.mediaSet && m.set ? 'ml-1' : ''" 
                            v-if="Layout.mediaLabel && m.tagName[3] && curDataset.type && curDataset.type!='imageObjectDetection' ? true : false"
                            :style=" curDataset.tags && curDataset.tags[m.tagName[3]] ? 'background-color:'+curDataset.tags[m.tagName[3]].color : ''">
                            {{ curDataset.tags && curDataset.tags[m.tagName[3]] ? curDataset.tags[m.tagName[3]].name : ''}}
                          </span>
                        </div>  
                      </a>
                      -->

                      <a class="w-full file__icon file__icon--image mx-auto" :style="'display: none !important'">
                        <div class="file__icon--image__preview image-fit" :class="curDataset.type && curDataset.type=='imageObjectDetection' ? 'image-fit-contain' : ''">
                          <img :id="'thumbPreview_'+m.id" v-bind:src="'data:image/png;base64,'+ m.img_base64_val" class="w-full" v-if="m.img_base64_val">
                        </div>
                      </a>


                      <div v-if="curDataset.type && curDataset.type=='MULTICLASS'">
                          <div :id="'ThumbcanvasBox_'+m.id" class="w-full bg-white ThumbcanvasBox" :style="'width: 100% !important; overflow: hidden !important'"></div><!--   h-full -->
                          <div class="image-tags pb-3 pl-3 mt-10">
                            <span class="py-1 px-3 rounded-md bg-theme-10 text-white cursor-pointer font-medium text-center text-xs text-truncate" :class="Layout.mediaSet && m.set ? 'ml-1' : ''" 
                              v-if="Layout.mediaLabel && m.tagName[3] && curDataset.type && curDataset.type!='imageObjectDetection' ? curDataset.tags && curDataset.tags[m.tagName[3]] && curDataset.tags[m.tagName[3]].name!='0' ? true : false : false"
                              :style=" curDataset.tags && curDataset.tags[m.tagName[3]] ? 'background-color:'+curDataset.tags[m.tagName[3]].color : ''">{{ curDataset.tags[m.tagName[3]].name  }}
                            </span>
                          </div>  
                      </div>

                      <div v-if="curDataset.type && curDataset.type=='imageObjectDetection'">
                          <div :id="'ThumbcanvasBox_'+m.id" class="w-full bg-white h-full ThumbcanvasBox" :style="'width: 100% !important; overflow: hidden !important'"></div>
                          <div class="image-tags pb-3 ml-8">
                            <span class="py-1 px-2 rounded-md bg-theme-21 text-white cursor-pointer font-medium text-center w-28 text-xs text-truncate" v-if="Layout.mediaSet && m.set">{{ m.set }}</span>
                            <!-- <span class="py-1 px-2 rounded-md bg-theme-7 text-gray-600 cursor-pointer font-medium text-center w-28 text-xs text-truncate" v-else>{{ $t('PREDETERMINED') }}</span> -->
                            <AlignLeftIcon class="w-5 h-5 ml-1 text-theme-21" v-if="m.comments" title="Comments" />
                          </div>  
                      </div>
                      <div class="text-gray-600 text-xs text-center mt-1 truncate" v-if="Layout.mediaName">
                        <span v-if="m.name">{{ m.name.toString().split('/').pop() }}</span>
                      </div>
                      <div class="text-gray-600 text-xs text-center mt-2 truncate" :class="Layout.mediaLabel && m.tagName[3] ? '' : 'hidden'"
                        v-if="curDataset.type && curDataset.type=='imageObjectDetection' ? true : false && m.tags">
                        {{ this.image.getObjectTags(m.tags) }}
                      </div>
                    </div>
                  </div>
              </div>
              <!-- /IMAGES LIST -->
            </div>
            <div v-else> 
              <!-- SINGLE MODE -->
              <div v-if="Layout.singlemode">
                <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-20" v-if="Layout.singlemodeLoading"><LoadingIcon icon="three-dots" class="w-12 h-12" /></div>
                <div v-for="(m) in media" v-bind:key="m">
                    <div class="intro-y grid grid-cols-12 gap-1 mt-3" :class="Layout.singlemodeLoading ? 'hidden' : ''">
                      <div class="intro-y col-span-12 lg:col-span-9">
                          <div class="mt-1 mb-3">
                              <div class="grid grid-cols-12">
                              <div class="col-span-1 text-right"><ZoomOutIcon class="w-5 h-5 mr-2" /></div>
                              <div class="col-span-10 mt-2"><Slider v-model="Layout.singleModeZoom" :min="30" :max="100" :step="10" :merge="10" :tooltips="false" :lazy="true" /></div>
                              <div class="col-span-1 text-left"><ZoomInIcon class="w-5 h-5 ml-2" /></div>
                            </div>
                          </div> 
                          <div class="file box rounded-md px-2 py-2 relative border">
                            <img id="singlemode-image" class="w-full h-full" :style="'width: '+Layout.singleModeZoom+'%; margin-left:'+((100-Layout.singleModeZoom)/2)+'%'">
                          </div>
                      </div>
                      <div class="col-span-12 mb-5 lg:col-span-3 lg:ml-3">
                        <div class="ml-1">
                            <div class="flex items-center pb-5">  
                              <button class="btn box flex items-center btn-outline-primary py-2 px-2 mr-3 font-normal pr-4 hidden" @click="FilterMedia('prev')" v-if="pagination.prev"> <ArrowLeftIcon class="w-4 h-4 mr-2" /> {{ $t('Previous') }}</button>
                              <button class="btn box flex items-center btn-outline-primary w-24 py-2 px-5 font-normal" @click="FilterMedia('next')" v-if="pagination.next">{{ $t('Next') }} <ArrowRightIcon class="w-4 h-4 ml-2" /></button>
                            </div>  
                            <div class="text-gray-600 text-xs" v-if="m.fileName && m.fileName[m.fileName.length-1]">{{ $t('Media name') }}:</div>
                            <h2 class="text-lg font-medium " v-if="m.fileName && m.fileName[m.fileName.length-1]">{{ m.fileName[m.fileName.length-1] }}</h2>
                            <div class="mb-3 text-xs text-gray-500" v-if="m.id">{{ m.id }}</div>
                            <div class="text-gray-600 text-xs mb-2" v-if="m.set">{{ $t('Set') }}:</div>
                            <div class="py-1 px-2 rounded-md bg-theme-21 text-white cursor-pointer font-medium text-center w-28 text-xs text-truncate mb-3" v-if="m.set">{{ m.set }}</div>
                            <div class="text-gray-600 text-xs mb-2">{{ $t('Current label') }}:</div>
                            <div class="grid grid-cols-12 gap-3">
                              <div class="col-span-12 box p-2 px-3 font-medium" v-if="Layout.mediaLabel && m.tagName[3]" :style="curDataset.tags && curDataset.tags[m.tagName[3]] ? 'color:'+curDataset.tags[m.tagName[3]].color : ''">
                                  <!-- <div class="h-4 w-4 mb-1 rounded w-12 display-inline" :style="curDataset.tags && curDataset.tags[m.tagName[3]] ? 'background-color:'+curDataset.tags[m.tagName[3]].color : ''"></div>  -->
                                  {{ curDataset.tags && curDataset.tags[m.tagName[3]] ? curDataset.tags[m.tagName[3]].name : ''}}
                              </div>
                            </div>
                            <div class="text-gray-600 text-xs mt-2">{{ $t('Assign label') }}:</div>
                            <div class="pt-3 pb-5" v-if="curDataset">
                                <div class="grid grid-cols-12 gap-3" v-if="Object.keys(curDataset.tags).length<=9">
                                  <div class="col-span-12 sm:col-span-4 box p-2 cursor-pointer zoom-in" 
                                    v-for="(t, key, index) in curDataset.tags" :key="t.id" :class="m.tagName[3] && m.tagName[3]!=key ? '' : 'hidden'" 
                                    :id="m.tagName[3] && m.tagName[3]!=key ? 'key_tag_'+(index+1) : ''" @click="actions.assingNumTag=t.id" :title="t.id+'--//--'+m.id">
                                    <div class="h-4 w-4 mb-2 rounded w-12" :style="t.color ? 'background-color:'+t.color : ''"></div> 
                                    <span class="text-left text-xs">{{ t.name }}</span>
                                    <div class="text-slate-500 text-xs text-gray-500">{{ $t('Press key')}} {{ index+1 }}</div>
                                  </div>
                                </div>
                                <div v-else>
                                  <div v-for="(t) in curDataset.tags" :key="t.id" class="col-span-12 sm:col-span-4 box p-1 px-2 mb-1 cursor-pointer zoom-in text-xs hover:bg-theme-2 hover:text-white"
                                    :class="m.tagName[3] && m.tagName[3]!=key ? '' : 'hidden'" @click="actions.assingNumTag=t.id; assignTagValue(t.id,m.id)">
                                    <span class="text-left">{{ t.name }}</span>
                                  </div>
                                </div>  
                            </div>
                        </div>  
                      </div>  
                    </div>
                </div>
              </div> 
              <!-- /SINGLE MODE -->
              <!-- MONITOR MODE --> 
              <div v-if="Layout.monitormode">
                <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-20" v-if="Layout.monitormodeLoading">
                  <LoadingIcon icon="three-dots" class="w-12 h-12" />
                  {{ $t('Waiting for image') }}
                </div>
                 <div class="intro-y grid grid-cols-12 gap-1 mt-3" :class="Layout.monitormodeLoading ? 'hidden' : ''">
                    <div class="intro-y col-span-12 lg:col-span-9">
                        <div class="mt-1 mb-3">
                            <div class="grid grid-cols-12">
                            <div class="col-span-1 text-right"><ZoomOutIcon class="w-5 h-5 mr-2" /></div>
                            <div class="col-span-10 mt-2"><Slider v-model="Layout.singleModeZoom" :min="30" :max="100" :step="10" :merge="10" :tooltips="false" :lazy="true" /></div>
                            <div class="col-span-1 text-left"><ZoomInIcon class="w-5 h-5 ml-2" /></div>
                          </div>
                        </div> 
                        <div class="file box rounded-md px-2 py-2 relative border">
                          <img id="monitormode-image" class="w-full h-full" :style="'width: '+Layout.singleModeZoom+'%; margin-left:'+((100-Layout.singleModeZoom)/2)+'%'">
                        </div>
                    </div>
                    <div class="col-span-12 mb-5 lg:col-span-3 lg:ml-3">
                       <div class="ml-1">
                          <div class="col-span-12 box p-3">
                            <div class="py-1 px-2 rounded-md bg-theme-21 text-white font-medium text-center w-28 text-xs text-truncate mb-2" v-if="curDataset.name">{{ curDataset.name }}</div>
                            <h2 v-if="Layout.MonitorlastImage.id">{{ Layout.MonitorlastImage.id }}</h2>
                            <div class="text-gray-600 text-xs mb-1" v-if="Layout.MonitorlastImage.date">{{ $h.getTimestampDate(Layout.MonitorlastImage.date) }}</div>  
                            <div class="text-gray-600 text-xs mb-1" v-if="Layout.MonitorlastImage.fileName && Layout.MonitorlastImage.fileName[1]">{{ $t('Name') }}: {{ Layout.MonitorlastImage.fileName[1] }}</div>  
                            <div class="text-gray-600 text-xs mb-2" v-if="Layout.MonitorlastImage.set">{{ $t('Set') }}: {{ Layout.MonitorlastImage.set }}</div>  
                          </div>
                          <div class="py-1 px-2 mt-3 rounded-md bg-theme-21 text-white cursor-pointer font-medium text-center w-28 text-xs text-truncate mb-5" v-if="Layout.MonitorlastImage.set">
                            {{ Layout.MonitorlastImage.set }}
                          </div>
                          <div class="text-gray-600 text-xs mb-2 mt-2" v-if="Layout.MonitorlastImage.tagName">{{ $t('Current label') }}:</div>
                          <div class="grid grid-cols-12 gap-3" v-if="Layout.MonitorlastImage.tagName">
                             <div class="col-span-12 box p-2 px-3 font-medium" v-if="Layout.MonitorlastImage.tagName[3]"
                              :style="curDataset.tags && curDataset.tags[Layout.MonitorlastImage.tagName[3]] ? 'color:'+curDataset.tags[Layout.MonitorlastImage.tagName[3]].color : ''">
                                <!--<div class="h-4 w-4 mb-2 rounded w-12 display-inline" :style="curDataset.tags && curDataset.tags[Layout.MonitorlastImage.tagName[3]] ? 'background-color:'+curDataset.tags[Layout.MonitorlastImage.tagName[3]].color : ''"></div>--> 
                              {{ curDataset.tags && curDataset.tags[Layout.MonitorlastImage.tagName[3]] ? curDataset.tags[Layout.MonitorlastImage.tagName[3]].name : ''}}
                             </div>  
                          </div>
                          <div class="text-gray-600 text-xs mt-3" v-if="Layout.MonitorlastImage.tagName">{{ $t('Assign label') }}:</div>

                          <!--
                            <div class="pt-3 pb-5" v-if="curDataset && Layout.MonitorlastImage.tagName">
                              <div class="grid grid-cols-12 gap-3">
                                <div class="col-span-12 sm:col-span-4 box p-5 cursor-pointer zoom-in" 
                                  v-for="(t, key, index) in curDataset.tags" :key="t.id" :class="Layout.MonitorlastImage.tagName[3] && Layout.MonitorlastImage.tagName[3]!=key ? '' : 'hidden'" 
                                  :id="Layout.MonitorlastImage.tagName[3] && Layout.MonitorlastImage.tagName[3]!=key ? 'key_tag_mon_'+(index+1) : ''" @click="actions.assingNumTag=t.id" :title="t.id+'--//--'+Layout.MonitorlastImage.id">
                                  <div class="h-4 w-4 mb-3 rounded w-12" :style="t.color ? 'background-color:'+t.color : ''"></div> 
                                  <span class="text-left">{{ t.name }}</span>
                                  <div class="text-slate-500 text-xs text-gray-500">{{ $t('Press key')}} {{ index+1 }}</div>
                                </div>
                              </div>
                            </div>
                           -->

                           <div class="pt-3 pb-5"  v-if="curDataset && Layout.MonitorlastImage.tagName">
                                <div class="grid grid-cols-12 gap-3" v-if="Object.keys(curDataset.tags).length<=9">
                                  <div class="col-span-12 sm:col-span-4 box p-2 cursor-pointer zoom-in" 
                                    v-for="(t, key, index) in curDataset.tags" :key="t.id" :class="Layout.MonitorlastImage.tagName[3] && Layout.MonitorlastImage.tagName[3]!=key ? '' : 'hidden'" 
                                    :id="Layout.MonitorlastImage.tagName[3] && Layout.MonitorlastImage.tagName[3]!=key ? 'key_tag_mon_'+(index+1) : ''" @click="actions.assingNumTag=t.id; assignTagValue(t.id,Layout.MonitorlastImage.id)" :title="t.id+'--//--'+Layout.MonitorlastImage.id">
                                    <div class="h-4 w-4 mb-2 rounded w-12" :style="t.color ? 'background-color:'+t.color : ''"></div> 
                                    <span class="text-left text-xs">{{ t.name }}</span>
                                    <div class="text-slate-500 text-xs text-gray-500">{{ $t('Press key')}} {{ index+1 }}</div>
                                  </div>
                                </div>
                                <div v-else>
                                  <div v-for="(t) in curDataset.tags" :key="t.id" class="col-span-12 sm:col-span-4 box p-1 px-2 mb-1 cursor-pointer zoom-in text-xs hover:bg-theme-2 hover:text-white"
                                    :class="Layout.MonitorlastImage.tagName[3] && Layout.MonitorlastImage.tagName[3]!=key ? '' : 'hidden'" @click="actions.assingNumTag=t.id; assignTagValue(t.id,Layout.MonitorlastImage.id)">
                                    <span class="text-left">{{ t.name }}</span>
                                  </div>  
                                </div>  
                            </div>

                      </div>  
                    </div>  
                  </div>
              </div>  
              <!-- /MONITOR MODE -->
            </div>
          </div>
           <!-- /RESULTS ------------------------------------------------------------------>
        </div> 
    </div>
    <!-- /SELECTED ACTIONS -->

    <!-- MODALS ------------------------------------------------------------------>
      <!-- IMAGE ZOOM -->
      <div id="modal-zoom" class="modal" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-sm modal-zoom">
          <div class="modal-content h-full bg-gray-100">
            <!-- <div class="modal-header p-2"> :class="curDataset && curDataset.type && curDataset.type=='imageObjectDetection' && !Layout.canvas.mlLoading  ? 'hidden' : ''"
              <div id="modal-zoom-id" class="hidden"></div>
              <ImageIcon class="h-7 w-7 pr-1" />
              <h2 class="text-sm mr-auto" id="modal-zoom-title" :class="Layout.modalZoom>30 ? '' : 'text-xs'"></h2>
              <div id="modal-zoom-tag" v-if="curDataset && curDataset.type && curDataset.type!='imageObjectDetection'" class="mr-10"></div>
              <button id="modal-canvas-close" type="button" data-dismiss="modal" :style="'border-radius: 0 !important'" 
                      class="btn btn-primary hover:bg-theme-2 hover:text-white dark:border-dark-5 dark:text-gray-300 flex items-center text-sm py-2">{{ $t('Cancel') }}</button>
            </div>
            -->
            <div class="modal-body p-0 bg-gray-200 h-full" :class="curDataset && curDataset.type && curDataset.type=='imageObjectDetection' ? 'pt-0 mt-0' : ''">
              <div id="modal-zoom-id" class="hidden"></div>
              <div id="modal-zoom-tag" class="hidden"></div>
              <!-- OBJECT DETECTION LABELING-->
              <div v-if="curDataset && curDataset.type && curDataset.type=='imageObjectDetection'" class="h-full bg-gray-100">
                <div class="grid grid-cols-12 h-full">
                  <div class="col-span-12 sm:col-span-7 lg:col-span-9 xxl:col-span-10">
                    <!-- CANVAS TOOLS BOX-->
                    <div id="canvasImageTools" class="px-5 py-6 canvasImageTools" 
                      :style="'position: fixed; cursor: move; z-index: 999; background-color: #fff;width: 180px;border-radius: 8px; box-shadow: #999 3px 3px 3px; top: 50px; left: 20px'">
                      <div class="grid grid-cols-12">
                        <div class="col-span-12 text-right"><MoveIcon class="w-5 h-5" /></div>
                        <div class="col-span-12 text-left">
                          <div class="pb-2 text-xs">{{ $t('Drawing mode')}}</div>
                          <CropIcon class="w-5 h-5 mr-3" style="vertical-align: top" />
                          <input class="form-check-switch drawing-mode-switch" type="checkbox" v-model="Layout.canvas.drawing" @change="initCanvas()"  />
                        </div>
                        <div class="col-span-12 text-left">
                          <div class="pb-2 text-xs">{{ $t('Zoom')}}</div>
                          <Slider v-model="Layout.modalZoom" class="mb-3" :min="10" :max="500" :step="5" :merge="5" :tooltips="false" :lazy="true" />
                        </div>
                        <div class="col-span-12 text-left" v-if="Layout.modalZoom>100">
                          <div class="pb-2 text-xs">{{ $t('Position')}}</div>
                          <Slider v-model="Layout.modalPosition.right" class="mb-3" :value="0" :min="-80" :max="80" :step="5" :merge="5" :tooltips="false" :lazy="true" />
                        </div>
                        <div class="col-span-12 text-left" v-if="Layout.modalZoom>100">
                          <div class="pb-2 text-xs">{{ $t('Top')}}</div>
                          <Slider v-model="Layout.modalPosition.top" class="mb-3" :value="0" :min="-80" :max="80" :step="5" :merge="5" :tooltips="false" :lazy="true" />
                        </div>
                        <div class="col-span-12 text-left">
                          <div class="pb-2 text-xs">{{ $t('Brightness')}}</div>
                          <Slider v-model="Layout.modalZoomProps.brightness" class="mb-3" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
                        </div>
                        <div class="col-span-12 text-left">
                          <div class="pb-2 text-xs">{{ $t('Contrast')}}</div>
                          <Slider v-model="Layout.modalZoomProps.contrast" class="mb-3" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
                        </div>
                        <div class="col-span-12 text-left">
                          <div class="pb-2 text-xs">{{ $t('Saturation')}}</div>
                          <Slider v-model="Layout.modalZoomProps.saturation" class="mb-3" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
                        </div>
                        <div class="col-span-12 text-left">
                          <div class="pb-2 text-xs">{{ $t('Vibrance')}}</div>
                          <Slider v-model="Layout.modalZoomProps.vibrance" class="mb-3" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
                        </div>
                        <div class="col-span-8 text-lef mt-1">
                          <div class="pb-2 text-xs">{{ $t('Auto detection')}}</div>
                          <CpuIcon class="w-5 h-5 mr-3 mt-0.5" style="vertical-align: top" />
                          <input class="form-check-switch drawing-mode-switch" type="checkbox" v-model="Layout.canvas.autoDetection"   />
                        </div>
                        <div class="col-span-4 text-left" v-if="Layout.canvas.autoLoading">
                          <span><LoadingIcon icon="three-dots" class="w-12 h-12 ml-2" style="background-color: #ff0000" /></span>
                        </div>
                        <div class="col-span-12 text-left mt-1">
                          <div class="pb-2 text-xs">{{ $t('Environment')}}</div>
                          <input v-model="Layout.modalDetection.api" class="form-check-input" type="radio" value="vision" /> <span style="vertical-align: top">{{ $t('Vision') }}</span>
                          <input v-model="Layout.modalDetection.api" class="form-check-input ml-4" type="radio" value="coco" /> <span style="vertical-align: top">{{ $t('Coco') }}</span> 
                        </div>
                        <div class="col-span-12">
                          <button type="button" class="btn bg-gray-600 text-white mt-3 display-inline" @click="resetPropsCanvas()" title="Reset filters">
                            <RotateCcwIcon class="w-4 h-4" />
                          </button>
                          <button id="object-ml-btn" type="button" class="btn bg-theme-21 text-white mt-3 display-inline" @click="startMachineLearning()" title="Object detection">
                            <CpuIcon class="w-4 h-4" />
                          </button>
                        </div>        
                      </div>
                    </div> 
                    <!-- /CANVAS TOOLS BOX-->
                    <!-- CANVAS -->
                    <div id="canvasBox" class="w-full bg-gray-500 h-full" :style="'overflow: hidden !important'"> 
                      <div class="intro-y col-span-9 lg:col-span-9 flex flex-col justify-center items-center mt-20" :class="Layout.canvas.mlLoading ? '' : 'hidden'"> 
                        <div class="flex items-center justify-center mt-10">  
                          <img :src="require(`@/assets/images/rosepetal/icon/logoLoading.png`)" class="w-28" />
                        </div>
                        <LoadingIcon icon="three-dots" class="w-12 h-12" />
                      </div>
                      <div :class="Layout.canvas.mlLoading ? 'hidden' : ''">
                        <div id="canvasContent" :style="'width: '+Layout.modalZoom+'% !important;'+(Layout.modalZoom>100 ? 'margin-left: '+Layout.modalPosition.right+'% !important; margin-top: '+Layout.modalPosition.top+'% !important;' : 'margin-left: calc((100% - '+Layout.modalZoom+'%)/2) !important;')"></div>
                        <img id="modal-zoom-image" :style="'width: 100%; display: none !important'" @load="initCanvas()">
                      </div>
                    </div>
                    <div class="hidden" id="modal-zoom-gsUri-image"></div>
                    <!-- /CANVAS -->
                  </div>
                  <!-- CANVAS SAVE COLUMN-->
                  <div class="col-span-12 sm:col-span-5 lg:col-span-3 xxl:col-span-2 h-full">
                    <div :style="'border-left: 1px solid #ddd'" :class="Layout.canvas.mlLoading ? 'hidden' : ''">
                      <div class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap justify-center items-center mt-0">
                          <button type="button" class="btn bg-theme-25 w-1/2 hover:bg-theme-6 hover:text-white dark:border-dark-5 dark:text-gray-300 flex items-center text-sm py-4" :style="'border-radius: 0 !important; font-weight: normal'" 
                            @click="saveObjects()">{{ $t('Save') }}</button>
                          <button id="modal-canvas-close" type="button" data-dismiss="modal" :style="'border-radius: 0 !important'" @click="refreshTotals()"
                            class="btn bg-theme-15 w-1/2 hover:bg-theme-2 hover:text-white dark:border-dark-5 dark:text-gray-300 flex items-center text-sm py-4">{{ $t('Cancel') }}</button>
                      </div>
                      <div class="text-left text-lg pl-5 pb-0 mt-5 mb-3">{{ $t('Set') }}</div>
                      <div class="text-left MT-2 pl-5" v-if="Layout.set.current">
                        <select v-model="Layout.set.current.set" class="form-select bg-theme-15 cursor-pointer py-2" :style="'width: 250px; padding: 10px 5px; margin-right: 5px;'">
                          <option value="">{{ $t('PREDETERMINED') }}</option>
                          <option value="TRAIN">{{ $t('TRAIN') }}</option> <!-- -->
                          <option value="TEST">{{ $t('TEST') }}</option>
                          <option value="VALIDATION">{{ $t('VALIDATION') }}</option>
                        </select>
                      </div>
                      <div class="text-left text-lg p-5">{{ $t('Labeling') }}</div>
                      <div class="p-5 pt-0" v-if="Layout.canvas.canvas && Layout.canvas.canvas._objects && Object.keys(Layout.canvas.canvas._objects).length">
                        <div v-for="(t,index) in Layout.canvas.canvas._objects" :key="t.type"
                          class="mb-2">
                          <div class="grid grid-cols-12 cursor-pointer" v-if="t.type=='rect'" @mouseover="highlightObject(index)" @mouseleave="highlightObject(index, true)">
                            <div class="col-span-1">
                              <div class="w-full h-8 mt-1" :style="'background-color: '+t.stroke+'; border:1px solid #ccc'"></div><!-- {{t.stroke}}  -->
                              <CornerDownRightIcon class="w-8 h-8 ml-1 mt-2 text-gray-600" v-if="t.name=='new'"  />
                            </div>
                            <div class="col-span-8"> <!-- v-if="curDataset.objtags && Object.keys(curDataset.objtags.tagslabeled).length" -->
                              <select class="form-select bg-gray-200 cursor-pointer w-full py-2 ml-2"
                                :class="t.name=='new' ? 'bg-theme-28 text-white' : ''"
                                  @change="canvaSetObject($event,index)">
                                  <option v-for="(tag,indx,c) in curDataset.tags" :value="indx==0 ? 'new' : indx" :key="c" :selected="indx==t.name"> 
                                    {{ indx==0 ?  $t('New label'): curDataset.tags[indx] && curDataset.tags[indx].name ? curDataset.tags[indx].name : indx }} 
                                  </option>
                                <!--
                                  <option v-for="(tag,indx,c) in curDataset.objtags.tagslabeled" :selected="indx==t.name" :value="indx" :key="c"> 
                                  {{ indx}} 
                                </option>
                                -->
                              </select>
                              <div v-if="t.name=='new'" class="ml-2">
                                <input type="text" class="w-full form-control py-2 px-2 border-transparent bg-theme-7 mt-2 ml-2" @input="updCanvastObjectTxt($event,index)" value="Label name">
                              </div>
                            </div>  
                            <div class="col-span-3 text-gray-500 hover:text-theme-2"><TrashIcon class="w-8 h-8 ml-3 pt-1" @click="removeObject(t.objid)" /> </div> 
                          </div>   
                        </div> 
                      </div>
                      <div v-else>
                        <div class="text-left text-base p-5 pt-0 text-gray-600">
                          <div class="t-3 text-sm">{{ $t('Add bounding boxes and labels')}}.<br />{{ $t('Use the mouse to draw a new box on the image') }}.</div>
                        </div>
                      </div>
                      <div class="text-left text-lg" style="position: fixed; bottom: 0;width: 100%;" v-if="Layout.set.current">
                        <textarea id="object-image-comments" v-model="Layout.set.current.comments" 
                          :style="'width: 100%;height: 80px;resize: none; padding: 10px; font-size: 14px; line-height: 16px; border: 1px solid #ccc; background-color: #fff; margin:0'" 
                        placeholder="Comments..."></textarea>
                      </div>
                    </div> 
                    <div class="intro-y col-span-9 lg:col-span-9 flex flex-col justify-center items-center mt-20" :class="Layout.canvas.mlLoading ? '' : 'hidden'"> 
                      <LoadingIcon icon="three-dots" class="w-12 h-12" />
                    </div> 
                  </div>  
                  <div id="objects-image-date"></div>
                  <div id="objects-image-name"></div>
                  <div id="objects-image-auto-vision" :class="Layout.canvas.autoLoading ? '' : 'hidden'">{{ $t('Auto detection in progress') }}</div>
                  <!-- /CANVAS SAVE COLUMN-->
                </div> 
              </div> 
              <!-- /OBJECT DETECTION LABELING -->
              <!-- CLASIFICATION -->
              <div class="bg-gray-500 h-full overflow-hidden" v-else>
                <div class="grid grid-cols-12 h-full">
                  <div class="col-span-12 sm:col-span-7 lg:col-span-9 xxl:col-span-10" >
                    <div id="muticlass-image-date"></div>
                    <div id="muticlass-image-name"></div>
                    <!-- 
                    <div :style="'width: '+Layout.modalZoom+'% !important;margin-left: calc((100% - '+Layout.modalZoom+'%)/2) !important;'">
                      <img id="modal-zoom-image" :style="'width: 100%'" >
                    </div> 
                     -->
                    <!-- CANVAS TOOLS BOX-->
                    <div id="canvasImageTools2" class="px-5 py-6 canvasImageTools" :class="Layout.canvas.mlLoading ? 'hidden' : ''"
                      :style="'position: fixed; cursor: move; z-index: 999; background-color: #f7f8f9;width: 180px;border-radius: 8px; box-shadow: #999 3px 3px 3px; top: 50px; left: 20px'">
                      <div class="grid grid-cols-12">
                        <div class="col-span-12 text-right"><MoveIcon class="w-5 h-5" /></div>
                        <div class="col-span-12 text-left">
                          <div class="pb-2 text-xs">{{ $t('Drawing mode')}}</div>
                          <Edit-2Icon class="w-5 h-5 mr-3" style="vertical-align: top" />
                          <input class="form-check-switch drawing-mode-switch" type="checkbox" v-model="Layout.canvas.drawing" @change="initCanvasMulticlass()"  />
                        </div>
                        <div class="col-span-12 text-left my-3" v-if="Layout.canvas.drawing">
                          <div class="grid grid-cols-12">
                            <div @click="Layout.modalBrush.type='line'" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer" title="Pencil" :class="Layout.modalBrush.type=='line' ? 'bg-gray-500 text-white rounded' : ''">
                              <img :src="require(`@/assets/images/rosepetal/icon/pencil.png`)" class="w-6 ml-4" />
                            </div>
                            <div @click="Layout.modalBrush.type='erase'" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer" title="Erase" :class="Layout.modalBrush.type=='erase' ? 'bg-gray-500 text-white rounded' : ''">
                              <img :src="require(`@/assets/images/rosepetal/icon/erase.png`)" class="w-6 ml-4" />
                            </div>
                          </div>
                        </div>
                        <div class="col-span-12 text-left" v-if="Layout.canvas.drawing">
                          <div class="pb-2 text-xs">{{ $t('Size')}}</div>
                          <Slider v-model="Layout.modalBrush.size" class="mb-3" :value="0" :min="5" :max="200" :step="5" :merge="5" :tooltips="false" :lazy="true" />
                        </div>
                        <div class="col-span-12 text-left">
                          <div class="pb-2 text-xs">{{ $t('Zoom')}}</div>
                          <Slider v-model="Layout.modalZoom" class="mb-3" :min="20" :max="100" :step="10" :merge="10" :tooltips="false" :lazy="true" />
                        </div>
                        <div class="col-span-12">
                          <button type="button" class="btn bg-gray-600 text-white mt-3 display-inline" @click="resetPropsCanvas()" title="Reset filters"><RotateCcwIcon class="w-4 h-4" />
                          </button>
                          <button type="button" class="btn bg-red-400 text-white mt-3 display-inline text-xs text-white px-5 zoom-in" @click="removeMask()" v-if="Layout?.canvas?.canvas?._objects?.length">
                            <TrashIcon class="w-4 h-4 text-white mr-1" /> {{ $t('MASK')}}
                          </button>
                          <button type="button" class="btn bg-blue-500 text-white mt-2 display-inline text-xs text-white px-5 w-full zoom-in" @click="Layout.canvas.showMask=false; initCanvasMulticlass()"
                             v-if="Layout?.canvas?.canvas?._objects?.length && Layout.canvas.showMask">
                            <Eye-offIcon class="w-4 h-4 text-white mr-1" /> {{ $t('HIDE MASK')}}
                          </button>
                          <button type="button" class="btn bg-gray-300 text-white mt-2 display-inline text-xs text-white px-3 zoom-in ml-1" @click="Layout.canvas.showMask=true; initCanvasMulticlass()" v-else>
                            <img :src="require(`@/assets/images/rosepetal/icon/pencil.png`)" class="w-4" />
                          </button>
                        </div>   
                      </div>
                    </div> 
                    <!-- /CANVAS TOOLS BOX-->
                    <!-- CANVAS -->
                    <div id="canvasBoxMulticlass" class="w-full bg-gray-500 h-full" :style="'overflow: hidden !important'"> 
                      <div class="intro-y col-span-9 lg:col-span-9 flex flex-col justify-center items-center mt-20" :class="Layout.canvas.mlLoading ? '' : 'hidden'">
                        <div class="flex items-center justify-center mt-10">  
                          <img :src="require(`@/assets/images/rosepetal/icon/logoLoading.png`)" class="w-28" />
                        </div> 
                        <LoadingIcon icon="three-dots" class="w-12 h-12" />
                      </div>
                      <div :class="Layout.canvas.mlLoading ? 'hidden' : ''">
                        <div id="canvasContentMulticlass" :style="'width: '+Layout.modalZoom+'% !important;margin-left: calc((100% - '+Layout.modalZoom+'%)/2) !important;'"></div>
                        <img id="modal-zoom-image" :style="'width: 100%; display: none !important'" @load="initCanvasMulticlass()">
                      </div>
                    </div>
                    <div class="hidden" id="modal-zoom-gsUri-image"></div>
                    <!-- /CANVAS -->
                  </div>  
                  <div class="col-span-12 sm:col-span-5 lg:col-span-3 xxl:col-span-2 overflow-hidden bg-white">
                    <div :style="'border-left: 1px solid #ddd'" :class="Layout.canvas.mlLoading ? 'hidden' : ''">
                      <div class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap justify-center items-center mt-0">
                          <button type="button" @click="saveObjectsMulticlass()" class="btn bg-theme-25 w-1/2 hover:bg-green-500 hover:text-white flex items-center text-sm py-4" :style="'border-radius: 0 !important; font-weight: normal'">{{ $t('Save') }}</button> 
                          <button id="modal-canvas-close" type="button" data-dismiss="modal" :style="'border-radius: 0 !important'" @click="refreshTotals()"
                            class="btn bg-theme-15 w-1/2 hover:bg-theme-2 hover:text-white dark:border-dark-5 dark:text-gray-300 flex items-center text-sm py-4">{{ $t('Cancel') }}</button>
                      </div>
                      <div class="text-left text-lg pl-5 pb-0 mt-5 mb-3">{{ $t('Set') }}</div>
                      <div class="text-left p-5 mr-5 pt-0" v-if="Layout.set.current">
                          <select v-model="Layout.set.current.set" class="form-select bg-theme-15 cursor-pointer py-2">
                            <option value="">{{ $t('PREDETERMINED') }}</option>
                            <option value="TRAIN">{{ $t('TRAIN') }}</option> <!-- -->
                            <option value="TEST">{{ $t('TEST') }}</option>
                            <option value="VALIDATION">{{ $t('VALIDATION') }}</option>
                          </select>
                        </div>
                      <div class="p-5 mr-5 pt-0">
                        <div class="text-left text-lg">{{ $t('Labeling') }} </div>
                        <div class="pt-3 pb-5 text-left" v-if="curDataset">
                            <div v-for="(t) in curDataset.tags" :key="t.id" :class="Object.keys(curDataset.tags).length>5 ? 'text-xs' : ''">
                              <button class="btn p-1 mb-1 hover:bg-blue-500 hover:text-white w-full text-left font-normal"  
                                @click="Layout.set.current.tag='dataset/'+curDataset.id+'/tag/'+t.id; refreshPropsCanvas('brushSize')" 
                                :class="Layout.set.current?.tag && Layout.set.current?.tag.toString().split('/')[3]==t.id ? 'bg-blue-500 text-white' : 'text-gray-600'">
                                <div class="float-left w-12"><div class="h-8 w-8 mr-2 rounded display-inline" :style="t.color ? 'background-color:'+t.color+';border: 2px solid #fff' : ''"></div> </div>
                                <div class="float-right w-80 ml-auto text-left"><span class="text-left">{{ t.name=='0' ? 'Unclassified' : t.name }}</span></div>
                              </button>
                            </div>
                        </div> 
                      </div>
                      <div class="text-left text-lg" style="position: fixed; bottom: 0;width: 100%;" v-if="Layout.set.current">
                        <textarea id="object-image-comments" v-model="Layout.set.current.comments" 
                          :style="'width: 100%;height: 80px;resize: none; padding: 10px; font-size: 14px; line-height: 16px; border: 1px solid #ccc; background-color: #fff; margin:0'" placeholder="Comments..."></textarea>
                      </div>
                    </div>
                    <div class="intro-y col-span-9 lg:col-span-9 flex flex-col justify-center items-center mt-20" :class="Layout.canvas.mlLoading ? '' : 'hidden'"> 
                      <LoadingIcon icon="three-dots" class="w-12 h-12" />
                    </div> 
                  </div>
                </div>
              </div>   
              <!-- /CLASIFICATION --> 
            </div>
          </div>
        </div>
      </div>
      <!-- /IMAGE ZOOM -->
      <!-- IMAGE SET -->
      <div id="modal-set" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
            <div class="modal-body p-5 text-center" v-if="Layout.set.current">
              <h2 class="text-2xl font-medium truncate pt-1 pb-1"> {{ $t('Data division') }}</h2>
              <div class="text-xs truncate mb-3"> {{ Layout.set.current.name }}</div>
              <select v-model="Layout.set.current.set" class="form-select bg-theme-21 cursor-pointer py-2 text-white pl-5" @change="updateImageSet(Layout.set.current.id)"
                :style="'width: 200px; margin-right: 5px; color: #fff'">
                  <option value="">{{ $t('PREDETERMINED') }}</option>
                  <option value="TRAIN">{{ $t('TRAIN') }}</option> <!-- -->
                  <option value="TEST">{{ $t('TEST') }}</option>
                  <option value="VALIDATION">{{ $t('VALIDATION') }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- /IMAGE SET -->
      <!-- MODAL LABEL IMAGES CLASSIFICATION -->
      <div id="modal-labeling" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
            <div class="modal-body p-5 text-center">
              <h2 class="text-2xl font-medium truncate pt-1 pb-1"> {{ $t('Label assignment') }}</h2>
              <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5" v-if="actions.assingLoad">
                <LoadingIcon icon="ball-triangle" class="w-12 h-12" />
              </div>
              <div v-else>
                <div class="text-lg truncate mb-2"> {{ $t('Selected items') }}: {{CountCheck()}}</div>
                <div class="text-xs truncate mb-1"> {{ $t('Select the label you want to assign') }} </div>
                <!-- <div class="pt-3 pb-5 text-left" v-if="curDataset">
                    <div v-for="(t) in curDataset.tags" :key="t.id">  
                      <button class="btn mb-1 hover:bg-theme-15 w-full text-left font-normal"  @click="actions.assingTag=t.id" :class="actions.assingTag===t.id ? ' bg-theme-15' : ''">
                        <div class="h-4 w-4 mr-2 rounded" :style="t.color ? 'background-color:'+t.color : ''"></div> 
                        <span class="text-left">{{ t.name }} 22</span>
                      </button>
                    </div>
                </div> -->
                <div class="pt-3 pb-5 text-left" v-if="curDataset">
                    <div v-for="(t) in curDataset.tags" :key="t.id">  
                      <button class="btn mb-1 hover:bg-blue-500 hover:text-white w-full text-left font-normal" @click="actions.assingTag=t.id"
                        :class="actions.assingTag===t.id ? 'bg-blue-500 text-white' : 'text-gray-600'">
                        <div class="float-left w-12"><div class="h-8 w-8 mr-2 rounded display-inline" :style="t.color ? 'background-color:'+t.color+';border: 2px solid #fff' : ''"></div> </div>
                        <div class="float-right w-80 ml-auto text-left"><span class="text-left">{{ t.name }}</span></div>
                      </button>
                    </div>
                </div> 

                <div class="px-5 pb-3 text-center">
                    <button id="dismiss-modal-labeling" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                    <button type="button" class="btn bg-green-600 text-white w-32" @click="Categorize($t('media updated'))">{{ $t('Confirm') }}</button>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
      <!-- MODAL LABEL IMAGES CLASSIFICATION -->
      <!-- MODAL SELECTED CHANGE SET -->
      <div id="modal-change-set" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
            <div class="modal-body p-5 text-center">
              <h2 class="text-2xl font-medium truncate pt-1 pb-1"> {{ $t('Data division') }}</h2>
              <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5" v-if="actions.assingLoad">
                <LoadingIcon icon="ball-triangle" class="w-12 h-12" />
              </div>
              <div v-else>
                <div class="text-lg truncate mb-2"> {{ $t('Selected items') }}: {{CountCheck()}}</div>
                <div class="text-xs truncate mb-3"> {{ $t('Select the label you want to assign') }} </div>
                <select class="form-select bg-gray-300 cursor-pointer w-52 p-2" v-model="actions.assingSet">
                    <option value="predetermined">{{ $t('Predetermined') }}</option>
                    <option value="test">{{ $t('Test') }}</option>
                    <option value="validation">{{ $t('Validation') }}</option>
                    <option value="train">{{ $t('Train') }}</option>
                </select> 
                <div class="px-5 pb-3 text-center mt-5">
                    <button id="dismiss-modal-labeling" type="button"
                    data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                    <button type="button" class="btn btn-primary w-24" v-if="actions.assingSet"  @click="setDataDivision($t('media set updated'))">{{ $t('Confirm') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /MODAL SELECTED CHANGE SET -->
      <!-- DOWNLOAD SEL IMAGES ZIP -->
      <div id="modal-download-selected" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
            <div class="modal-body px-5 text-center">
              <div v-if="Layout.zip.loading" class="pb-2">
                <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 mb-5"><LoadingIcon icon="ball-triangle" class="w-12 h-12" /></div>
                <div class="text-normal">{{ $t('Creating Zip file') }} ({{Layout.zip.counter}}/{{ selectedImages.length }})</div>
                <div class="text-normal pb-5" v-if="selectedImages.length && Layout.zip.counter==selectedImages.length">
                    {{ $t('Your download will start automatically') }}
                    <div>{{ $t('Please wait...') }}</div>
                </div>
              </div>
              <div v-else>
                <div class="p-5 text-center">
                    <DownloadCloudIcon class="w-16 h-16 text-theme-1 mx-auto" />
                    <div class="text-2xl ">{{ $t('Download') }}</div>
                    <div class="text-normal">{{ selectedImages.length }} {{ $t('selected media') }}</div>
                </div>
                <div class="px-5 pb-8 text-center mt-5">
                    <button id="dismiss-modal-download-selected" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                    <button type="button" @click="downloadMedia()" class="btn btn-primary w-24 rpbtn1">{{ $t('Download') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /DOWNLOAD SEL IMAGES ZIP -->
      <!-- DELETE SEL IMAGES -->
      <div id="modal-delete" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-body p-5 text-center">
              <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
              <div class="p-5 text-center">
                  <div class="text-xl mt-5">{{ $t('Are you sure you want to delete') }}<br> {{ selectedImages.length }} {{ $t('selected media') }}?</div>
                  <div class="text-gray-600 mt-2">{{ $t('This action is irreversible') }}.</div>
              </div>
              <div class="px-5 pb-8 text-center">
                  <button id="dismiss-modal-delete" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                  <button type="button" class="btn btn-danger w-24" @click="Delete()">{{ $t('Continue') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /DELETE SEL IMAGESS -->
      <!-- OVERVIEW DATASET -->
      <div id="modal-dataset-overview" class="modal" tabindex="-1" v-if="curDataset">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header"> <h2 class="font-medium text-base mr-auto">{{$t('Dataset')}}</h2></div>
            <div class="modal-body p-5 pt-3">
              <div class="grid grid-cols-12">
              <div class="col-span-12">
                    <div class="text-base font-normal mb-3">{{curDataset.id}}</div>
                    <table class="table table--sm text-xs" v-if="curDataset.id">
                        <tbody>
                            <tr class="hover:bg-gray-200" v-if="curDataset.automldata && curDataset.automldata.id">
                              <td class="border">{{$t('Set ID')}}</td><td class="border">{{curDataset.automldata.id}}</td>
                            </tr>
                            <tr class="hover:bg-gray-200" v-if="curDataset.automldata && curDataset.type && curDataset.type=='imageObjectDetection'">
                                <td class="border">{{$t('Total media')}} </td>
                                <td class="border" :class="curDataset.objtags && curDataset.objtags.total ? '' : 'bg-red-200'">
                                  <div class="flex flex-col sm:flex-row" v-if="curDataset.objtags">
                                      <div class="font-bold text-sm" :class="curDataset.objtags && curDataset.objtags.total || (ImportLog && ImportLog.length) ? '' : 'pt-1'">
                                        {{this.helper.formatScore(curDataset.objtags.total)}}
                                      </div>
                                  </div>
                                </td>
                            </tr>
                            <tr class="hover:bg-gray-200" v-else>
                                <td class="border">{{$t('Total media')}} </td>
                                <td class="border" :class="curDataset.imageCounter ? '' : 'bg-red-200'">
                                  <div class="flex flex-col sm:flex-row">
                                      <div class="font-bold text-sm" :class="curDataset.imageCounter  ? '' : 'pt-1'">
                                        {{ this.helper.formatScore(curDataset.imageCounter) }}
                                      </div>
                                  </div>
                                </td>
                            </tr>
                            <tr class="hover:bg-gray-200" v-if="curDataset.automldata && curDataset.automldata.imageClassificationDatasetMetadata"><td class="border">{{$t('Classification type')}}</td><td class="border">{{curDataset.automldata.imageClassificationDatasetMetadata.classificationType}}</td></tr>
                            <tr v-else class="hover:bg-gray-200"><td class="border">{{$t('Classification type')}}</td><td class="border">{{curDataset.type}}</td></tr>
                            <tr class="hover:bg-gray-200" v-if="curDataset.uploadRef"><td class="border">{{$t('Upload Ref')}}</td><td class="border">{{curDataset.uploadRef}}</td></tr>
                            <tr class="hover:bg-gray-200" v-if="curDataset.uploadStatus"><td class="border">{{$t('Upload Status')}}</td><td class="border">{{curDataset.uploadStatus}}</td></tr>
                            <tr class="hover:bg-gray-200" v-if="curDataset.automldata && curDataset.automldata.createTime"><td class="border">{{$t('Creation date')}}</td><td class="border">{{curDataset.automldata.createTime.seconds}}</td></tr>
                            <tr class="hover:bg-gray-200" v-else><td class="border" v-if="curDataset.createdAt">{{$t('Creation date')}}</td><td class="border" v-if="curDataset.createdAt">{{ this.helper.getTimestampDate(curDataset.createdAt.toDate(),'date')}}</td></tr>
                        </tbody>
                    </table>
              </div>
              <div class="col-span-12 mt-5" v-if="curDataset.tagstats && curDataset.tagstats.data && Object.keys(curDataset.tagstats.data).length">
                <div class="text-base font-normal mb-3">{{$t('Labeling')}}</div>
                <div class="relative" v-if="curDataset && curDataset.type && curDataset.type=='imageObjectDetection'">
                  <div v-if="curDataset.objtags">
                      <table class="table table--sm w-full text-xs mb-5">
                          <tbody>
                              <tr>
                                <td class="border w-60">{{$t('Labeled')}}</td>
                                <td class="border w-30">{{$h.formatScore(curDataset.objtags.labeled)}}</td>
                              </tr>
                              <tr>
                                <td class="border w-60">{{$t('No label')}}</td>
                                <td class="border w-30" :class="curDataset.objtags.nolabel ? 'text-theme-5' : ''">{{$h.formatScore(curDataset.objtags.nolabel)}}</td>
                              </tr>
                          </tbody>
                      </table> 
                      <Chart type="doughnut"  :options="{ legend: { display: true, position: 'right'}, cutoutPercentage: 80 }" :data='{
                                                                                                              labels: ["Labeled","No label"],
                                                                                                              datasets: [ {
                                                                                                                  data: [curDataset.objtags.labeled, curDataset.objtags.nolabel],
                                                                                                                  backgroundColor: ["#407BBF","#ff0000"],
                                                                                                                  borderWidth: 1,
                                                                                                                  borderColor: "#fff",
                                                                                                                }
                                                                                                              ]
                                                                                                            }' />
                      <table class="table table--sm w-full text-xs mt-5 mb-5" v-if="curDataset.tags">
                          <tbody>
                              <tr v-for="(ct) in curDataset.tags" :key="ct">
                                <td class="border w-60" v-if="!ct.unclassified">{{ct.name}}</td>
                                <td class="border w-30" v-if="!ct.unclassified">{{$h.formatScore(ct.imageCounter)}}</td>
                              </tr>
                          </tbody>
                      </table> 
                      <!--
                      <Chart type="doughnut"  :options="{ legend: { display: true, position: 'right'}, cutoutPercentage: 80 }" :data='{
                                                                                                              labels: curDataset.tagstats.labels,
                                                                                                              datasets: [ {
                                                                                                                  data: curDataset.tagstats.data,
                                                                                                                  backgroundColor: curDataset.tagstats.color,
                                                                                                                  hoverBackgroundColor: curDataset.tagstats.hovercolor,
                                                                                                                  borderWidth: 1,
                                                                                                                  borderColor: "#fff",
                                                                                                                }
                                                                                                              ]
                                                                                                            }' />-->

                  </div> 
                </div>
                <div v-else class="relative">
                  <Chart type="doughnut"  :options="{ legend: { display: true, position: 'right'}, cutoutPercentage: 80 }" :data='{
                                                                                                              labels: curDataset.tagstats.labels,
                                                                                                              datasets: [ {
                                                                                                                  data: curDataset.tagstats.data,
                                                                                                                  backgroundColor: curDataset.tagstats.color,
                                                                                                                  hoverBackgroundColor: curDataset.tagstats.hovercolor,
                                                                                                                  borderWidth: 1,
                                                                                                                  borderColor: "#fff",
                                                                                                                }
                                                                                                              ]
                                                                                                            }' />
                </div>
              </div>
              <div class="col-span-12">
                <h2 class="text-base font-normal mb-3 mt-5"> {{ $t('Data division') }}</h2>
                <div v-if="curDataset.setCounter">
                  <table class="table table--sm w-full text-xs mb-5">
                      <tbody>
                          <tr v-if="curDataset.setCounter.predetermined">
                            <td class="border w-30">{{$t('PREDETERMINED')}}</td>
                            <td class="border w-30">{{curDataset.setCounter.predetermined}}</td>
                            <td class="border w-30"></td>
                          </tr>
                          <tr>
                            <td class="border w-30">{{$t('TRAIN')}}</td>
                            <td class="border w-30">{{curDataset.setCounter.train}}</td>
                            <td class="border w-30">80% **</td>
                          </tr>
                          <tr>
                            <td class="border w-30">{{$t('TEST')}}</td>
                            <td class="border w-30">{{curDataset.setCounter.test}}</td>
                            <td class="border w-30">10% **</td>
                          </tr>
                          <tr>
                            <td class="border w-30">{{$t('VALIDATION')}}</td>
                            <td class="border w-30">{{curDataset.setCounter.validation}}</td>
                            <td class="border w-30">10% **</td>
                          </tr>
                      </tbody>
                  </table> 
                  <span class="text-xs">** {{$t('The ideal percentage')}}</span>
                </div>
                <div v-else>
                  <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-2">
                    <LoadingIcon icon="three-dots" class="w-12 h-12 text-gray-500" />
                  </div> 
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /OVERVIEW DATASET -->
    <!-- /MODALS ------------------------------------------------------------------>

    <!-- <imageEditor :options="{ id: Layout.editor, 'dataset': curDataset.id }" v-if="curDataset" ref="imageEditorComponent" /> -->
    
  </div>
</template>
<script>
import { defineComponent, ref,  onMounted, onUnmounted, watch  } from "vue"; 
//import { _firebase as $_firebase } from "@/model/firebase";
import { dataset as $dataset } from "@/model/dataset";
import { image as $image } from "@/model/image";
import { helper as $h } from "@/utils/helper";
import { useRouter } from "vue-router";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import cash from "cash-dom";
import Slider from '@vueform/slider'
import { fabric } from 'fabric'
import { ml as $ml } from "@/model/ml";
//import rosepetalModel from "rosepetal-model";
const axios = require("axios").default;
import lzstring from 'lz-string';
//import imageEditor  from "@/components/manage-image/Main.vue";

import * as rosepetalModel from "rosepetal-model";

var $_firebase = rosepetalModel._firebase;

export default defineComponent({
  created () {
    this.helper = $h
    this.image  = $image
  },  
  components: {
      Slider, //imageEditor
  },
  setup(context) {
    const canvasbox  = {};
    const router     = useRouter();
    const Layout     = ref({
                      debug:      false,
                      loading:    true,
                      mediaLabel: true,
                      mediaSet:   true,
                      mediaName:  false,
                      mediaMask:  true,
                      mediaSize:  window.innerWidth<900 ? 4 : 2,
                      singlemode: false,
                      singlemodeLoading: true,
                      singleModeZoom: 100,
                      monitormode: false,
                      monitormodeLoading: true,
                      MonitorlastImage: {},
                      zip: { loading: false, counter: 0 },
                      set: { current: false },
                      modalZoom: 100,
                      modalBrush: { size: 30, type: "line" },
                      modalPosition: { top:0, right:0},
                      modalZoomProps: { contrast: 0, brightness: 0, saturation: 0, vibrance: 0 },
                      modalDetection: { api: "vision" },
                      canvas: { canvas: {}, curImage: false, tags: [], mlLoading: false, mlPredictions: [], image64: "", drawing: false, autoDetection: true, autoLoading: false, showMask: true } ,
                      filterObject: { type: 'labeled', division: 'all', byLabel: 'all' },
                      googleUris: [],
                      googleUris64: [],
                      fbListenerTags: null,
                      editor: false
                    })                   
    const media           = ref([]);
    const pagination      = ref()   
    const selectedImages  = ref([]);  
    const selectToDataset = ref([]);  
    const CurTag          = ref(false);   

    const datasets        = ref({})  
    const curDataset      = ref();   
    const actions         = ref({
                               labeling:      false,
                               delete:        false,
                               assingTag:     false,
                               assingLoad:    false,
                               assingSet:     false,
                               assingNumTag:  false
                              })   
    /* PAGINATION */
    const initPagination = () => { 
        pagination.value =  {
          currentPage: 0,
          perPage: 50,
          pages: 1,
          total: 0,
          init:  null,
          first: null,
          last:  null,
          prev:  false,
          next:  true,
          toend: false
        }
     }
    
    const isCurPage = (p) => { if(p==(pagination.value.currentPage+1))return "pagination__link--active" }
    const SetPerPage = (num) => { Layout.value.mediaSize = 2; pagination.value.perPage = num.target.value; FilterMedia() }
    const SetPaginationControls = () => {
      pagination.value.pages = Math.ceil(pagination.value.total/pagination.value.perPage);
      if((pagination.value.currentPage+1)==pagination.value.pages){ pagination.value.next = false; }else{ pagination.value.next = true; }
      if(pagination.value.currentPage==0){ pagination.value.prev = false; }else{ pagination.value.prev = true;}
    }
    /* /PAGINATION */

    /* FILTER */
    const Filter = async (evt) => {
      Layout.value.loading  = true
      if(evt && evt.target.id && evt.target.id == 'filterDatasetSel'){ 
        curDataset.value = datasets.value[evt.target.value]; 
        if(!evt.target.sameCat)CurTag.value = false; 
        pagination.value.currentPage = 0
        Layout.value.filterObject = { type: 'labeled', division: 'all', byLabel: 'all' }
      }
      if(evt && evt.target.value && evt.target.id == 'filterCatSel')CurTag.value = evt.target.value;
      datasets.value   = await $dataset.get(false,true); 

      if(curDataset.value.type && curDataset.value.type=='imageObjectDetection'){
        Layout.value.mediaSize    = 2
        curDataset.value.objtags  = await $dataset.getTagsObjectDetection(curDataset.value.id)
      }
      Layout.value.fbListenerTags = null
      if(curDataset.value){
        Layout.value.fbListenerTags = $_firebase.firestore().collection('dataset').doc(curDataset.value.id).collection("tag").onSnapshot(async () => {
            if(curDataset.value.id)curDataset.value.tags = await $dataset.getTags(curDataset.value.id)
        })
      }
      refreshTotals()
    }

    const FilterObj = async () => {
      Layout.value.loading  = true
      curDataset.value.objtags  = await $dataset.getTagsObjectDetection(curDataset.value.id)
      initPagination()
      FilterMedia()
    }

   const FilterMedia = async (a=false) => {
      if(!a)pagination.value.init = false; 
      if(Layout.value.singlemode)Layout.value.singlemodeLoading  = true
      selectedImages.value  = []
      actions.value.assingTag = actions.value.assingNumTag = false
      if(curDataset.value.tags && Object.keys(curDataset.value.tags).length && !CurTag.value){ for(let index in curDataset.value.tags){ if(curDataset.value.tags[index].imageCounter){ CurTag.value = index;  break;} }}
      let images = await $dataset.getImages({
                                    datasetID : curDataset.value.id,
                                    tagID:      CurTag.value,
                                    pagination: pagination.value,
                                    qry:        { param:     'date', condition: '!=', val:  '', order:  "date", type: "desc" },
                                    action:     a,
                                    curDataset: curDataset.value,
                                    objtagsType: curDataset.value.type=='imageObjectDetection' ? Layout.value.filterObject.type : false,
                                    objByLabel:  Layout.value.filterObject.byLabel,
                                    objDivision: Layout.value.filterObject.division,
                                    objtagsTypeCount: curDataset.value.type=='imageObjectDetection' && curDataset.value.objtags ? 
                                                        Layout.value.filterObject.type=='labeled' ? curDataset.value.objtags.labeled : curDataset.value.objtags.nolabel 
                                                      : false,
                                  }) 
      media.value          =  images.media 
      pagination.value     =  images.pagination    
      Layout.value.loading = false
      if(Layout.value.singlemode){
       let gimage  = media.value[0].uri && media.value[0].uri.startsWith('gs://') ? await getGoogleLink(media.value[0].uri) : media.value[0].img_base64_val;
       cash("#singlemode-image").attr("src",gimage); 
       Layout.value.singlemodeLoading  = false
      }
      SetPaginationControls()
      //thumbnails with bounding box
      setTimeout(async () => { 
        //if(curDataset.value.type=='imageObjectDetection'){ 
          for(let i in media.value){ 
            if(media.value[i])await PreviewObjectBB(media.value[i]) 
          }
        //}
      }, 250);
    }
    
    const PreviewObjectBB = async (image) => {
       if(cash("#ThumbcanvasBox_"+image.id)){
          cash("#ThumbcanvasBox_"+image.id).html('<canvas id="thumbcanvas_'+image.id+'"></canvas>')
          let thumbCanvas = new fabric.Canvas('thumbcanvas_'+image.id, {selection: false});
          //load mask json
          fabric.Image.fromURL('data:image/png;base64,'+image.img_base64_val, function (img) { 
              
            thumbCanvas.setHeight(img.height); 
              thumbCanvas.setWidth(img.width);

              //load BackgroundImage
              thumbCanvas.setBackgroundImage(img, thumbCanvas.renderAll.bind(thumbCanvas), {
                  scaleX: thumbCanvas.width / img.width,
                  scaleY: thumbCanvas.height / img.height
              });
              thumbCanvas.renderAll();

              //load mask json
              if(Layout.value.mediaMask && image?.mask?.imageJson){
                var json = false//JSON.parse(image.mask.imageJson)
                try { json = JSON.parse(image.mask.imageJson)
                } catch (error) { //Compressed with lz-string
                  json =  JSON.parse(lzstring.decompressFromUint8Array(image.mask.imageJson.toUint8Array())) ;
                }
                
                thumbCanvas.loadFromJSON(json, function() {
                  thumbCanvas.setBackgroundImage(img, thumbCanvas.renderAll.bind(thumbCanvas), {
                      scaleX: thumbCanvas.width / img.width,
                      scaleY: thumbCanvas.height / img.height
                  });
                  thumbCanvas.renderAll();
                }, function(o, object) {
                    object.selectable = false;
                    var widthRatio = thumbCanvas.width / image?.mask?.width;
                    var heightRatio = thumbCanvas.height / image?.mask?.height;
                    var scaleFactor = Math.min(widthRatio, heightRatio);
                    object.set({
                      strokeWidth: object.strokeWidth * scaleFactor,
                      scaleX: object.scaleX * scaleFactor,
                      scaleY: object.scaleY * scaleFactor,
                      left: object.left * scaleFactor,
                      top: object.top * scaleFactor,
                      opacity: 0.8,
                      selectable: false,
                      hoverCursor: "default"
                    });
                    thumbCanvas.renderAll();
                });
              }      

              //load boundingBox
              if(image.tags){
                  for(let index in image.tags){ 
                    let savedObject = image.tags[index] 
                      if(savedObject.type=="rect"){
                        let RectOpt = {
                            left: savedObject.x * img.width,
                            top: savedObject.y * img.height,
                            originX: 'left',
                            originY: 'top',
                            width: savedObject.w*img.width,
                            height: savedObject.h*img.height,
                            angle: 0,
                            fill: 'transparent',
                            strokeWidth: 1,
                            transparentCorners: false,
                        }
                        RectOpt.selectable = false;   
                        let newObject = new fabric.Rect(RectOpt);
                        let tagRef  = savedObject.tag.path.toString().split('/')
                        let tagName = false
                        if (tagRef[tagRef.length-1])tagName = tagRef[tagRef.length-1]
                        if (tagName) {
                          let bbColor = curDataset.value.tags[tagName] ? curDataset.value.tags[tagName].color : 'ff0000'
                          let newObjectId    = index+tagName;
                          newObject.toObject = (function(toObject) {
                            return function(propertiesToInclude) {
                              return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                                name: tagName,
                                objid: newObjectId
                              });
                            };
                          })(newObject.toObject); 
                          newObject.set({stroke: bbColor })
                          newObject.set({name: tagName })
                          newObject.set({objid: newObjectId })
                          thumbCanvas.add(newObject);
                          let TextOpt = {
                                  fill: bbColor,
                                  left: savedObject.x * img.width,
                                  top: ((savedObject.y * img.height)-28),
                                  fontSize: 20,
                                  padding: 5,
                                  objParentId: newObjectId,
                                  hoverCursor: "default"
                              }
                          TextOpt.selectable = false;   
                          var tagNameBox = new fabric.Text(tagName, TextOpt);
                          tagNameBox.toObject = (function(toObject) {
                            return function(propertiesToInclude) {
                              return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                                objParentId: newObjectId
                              });
                            };
                          })(newObject.toObject);     
                          tagNameBox.set({objParentId: newObjectId })
                          thumbCanvas.add(tagNameBox);
                        }
                      }
                    }
              }
          });
          thumbCanvas.renderAll();
       }
        
    }

    /* LABELING */
    const Categorize  =  async (msg=false) => {
      actions.value.assingLoad = true
      let cnt = 0;
      for (const s of selectedImages.value) {
          let uTag = { id: s , datasetID: curDataset.value.id  , tagID: actions.value.assingTag }
          await $image.updateTag(uTag); cnt++;  
      }  
      let txt = msg ? msg: ""
      $h.NotificationTxt({ text: cnt +" "+txt });
      cash("#modal-labeling").modal("hide");
      let curDset             = curDataset.value.id;
      Layout.value.loading    = true
      actions.value.assingTag = curDataset.value  = false
      datasets.value          = media.value       = selectedImages.value = []
      setTimeout(async () => { 
        pagination.value.currentPage = 0
        actions.value.assingLoad = false
        await LoadDatasets(); 
        await Filter({ target: { id: 'filterDatasetSel', value: curDset, sameCat: true }}) 
        }, 2500);
    }

    const Delete  = async () => {
      cash("#modal-delete").modal("hide")
      Layout.value.loading = true
      for (const s of selectedImages.value) {
        let gUri = await $image.getUri(s)
        await $_firebase.firestore().collection('image')
                .doc(s)
                .delete()
                .then( async() => {
                  if(gUri)await $image.deleteStorage(gUri)
                })
      }  
      cash(".main").removeClass("overflow-y-hidden")
      let curDset             = curDataset.value.id;
      Layout.value.loading    = true
      actions.value.assingTag = curDataset.value  = false
      datasets.value          = media.value       = selectedImages.value = []
      setTimeout(async () => { 
        pagination.value.currentPage = 0
        await LoadDatasets(); 
        await Filter({ target: { id: 'filterDatasetSel', value: curDset, sameCat: true }})  
      }, 1500);
    }

    /* SELECT CONTROLS */
    const Check   = () => { selectedImages.value = [];  media.value.forEach(function (s) { selectedImages.value.push(s.id); }); }
    const UnCheck = () => { selectedImages.value = [];  }
    const CountCheck = () => { return selectedImages.value.length ? selectedImages.value.length : 0 }

    const Modal = async (m, action = false) => { 
      cash("#"+m).modal("show"); cash(".main").removeClass("overflow-y-hidden")
      if(action && action=="dataDivision"){ curDataset.value.setCounter = false; curDataset.value.setCounter = await $dataset.getSetCounter(curDataset.value.id, false, curDataset.value.type); }
    }

    const MediaSize = async (n) => { Layout.value.mediaSize = n.target.value };
    
    const CheckItem  = async (s) => {
      let exists = false
      selectedImages.value.findIndex(function(m, index) { if(m == s){ exists = true; if (index > -1)selectedImages.value.splice(index, 1); } });
      if(!exists)selectedImages.value.push(s) 
    }
    
    const changeSet = async (i) => { 
      cash("#modal-set").modal("show");  
      if(!i.set)i.set = ""
      Layout.value.set.current = i
    }

     const updateImageSet = async (i) => { 
      cash("#modal-set").modal("hide"); 
      await $image.updateSet(i, Layout.value.set.current.set)
    }

    const setDataDivision  =  async (msg=false) => {
      actions.value.assingLoad = true
      let cnt = 0;
      for (const s of selectedImages.value) {
          await $image.updateSet(s, actions.value.assingSet.toString().toUpperCase())
          cnt++;  
      }  
      let txt = msg ? msg: ""
      $h.NotificationTxt({ text: cnt +" "+txt });
      cash("#modal-change-set").modal("hide");
      let curDset             = curDataset.value.id;
      Layout.value.loading    = true
      actions.value.assingTag = curDataset.value  = false
      datasets.value          = media.value       = selectedImages.value = []
      setTimeout(async () => { 
        pagination.value.currentPage = 0
        actions.value.assingSet = false
        await LoadDatasets(); 
        await Filter({ target: { id: 'filterDatasetSel', value: curDset, sameCat: true }}) 
        actions.value.assingLoad = false
        }, 1000);
    }
    
    const Zoom  = async (i) => { 
      /*
      if(i.id){
        console.log('edit image',i)
        Layout.value.editor = i.id
      }
      */
      Layout.value.canvas.mlLoading = true
      cash("#modal-zoom").modal("show");  
      cash(".main").removeClass("overflow-y-hidden")
      cash("#modal-zoom-id,#modal-zoom-title,#modal-zoom-uri,#objects-image-date").html(""); 
      cash("#modal-zoom-image").attr("src",""); 
      if(cash("#modal-zoom-gsUri-image"))cash("#modal-zoom-gsUri-image").html(i.uri)
      cash("#modal-zoom-id").html(i.id); 
      cash("#objects-image-date,#muticlass-image-date").html($h.getTimestampDate(i.date)); 
      cash("#muticlass-image-name,#objects-image-name").html(i.name); 
      if(cash("#muticlass-image-tag") && i.tagName[3])cash("#muticlass-image-tag").html(i.tagName[3])
      let gimage  = i.uri && i.uri.startsWith('gs://') ? await getGoogleLink(i.uri) : i.img_base64_val;
      cash("#modal-zoom-image").attr("src",gimage);
      i.tagName[3] ? cash("#modal-zoom-tag").html(i.tagName[3]) : false
      Layout.value.set.current = i
      Layout.value.set.current["comments"] = i.comments ? i.comments : ""
      if(cash("#canvasImageTools2"))$h.dragElement(document.getElementById("canvasImageTools2"));
    };

    const urlToBase64  = async (url) => {   
      if(Layout.value.googleUris64[url]){ /*console.log("urlToBase64 ----- cached!"); */return Layout.value.googleUris64[url];}
      try { 
        /*console.log("urlToBase64 ----- axios get binary!")*/
        Layout.value.googleUris64[url] = await axios.get(url, { responseType: 'arraybuffer' }).then(response => "data:image/png;base64,"+Buffer.from(response.data, 'binary').toString('base64'))
        return Layout.value.googleUris64[url]
      } catch (error) { console.log("getGoogleLink error: "+error); }
    }

    const syncCanvasboxObjects  = async () => {   /*canvasbox is not reactive for can edit canva scale and rotation*/
      Layout.value.canvas.canvas._objects = {}
      Layout.value.canvas.canvas._objects = canvasbox.value._objects ? canvasbox.value._objects :  {}
    }

    /* CANVAS MULTICLASS */
    const initCanvasMulticlass = async (imageId=false,gimage=false) => {
      
      Layout.value.canvas.mlLoading = true
      if(!imageId)imageId           = cash("#modal-zoom-id").html()
      if(!gimage)gimage             = cash("#modal-zoom-image").attr("src"); 
      gimage                        = await urlToBase64(gimage)
      let curImage                  = await $image.get(imageId)  
      cash("#canvasContentMulticlass").html('<canvas id="c"></canvas>')
      Layout.value.canvas.curImage  = imageId 
      let canvaOpt = { selectionLineWidth: 2, centeredScaling: false, transparentCorners: true }
      if(Layout.value.canvas.drawing)canvaOpt.isDrawingMode = true 
      canvasbox.value = new fabric.Canvas('c',canvaOpt);

      //load mask json
      if(Layout.value.canvas.showMask && curImage?.mask?.imageJson){
        let json = false //JSON.parse(curImage.mask.imageJson)
        try { json = JSON.parse(curImage.mask.imageJson)
        } catch (error) { //Compressed with lz-string
          json =  JSON.parse(lzstring.decompressFromUint8Array(curImage.mask.imageJson.toUint8Array())) ;
        }
        canvasbox.value.loadFromJSON(json, function() {
          canvasbox.value.renderAll();
        }, function(o, object) {
          object.selectable = false;
        });
      }

      /*canvasbox.value.getObjects().forEach((object) => {
        object.selectable = false
        console.log(object)
      });*/

      fabric.Image.fromURL(gimage, function (img) {   
          canvasbox.value.setHeight(img.height); 
          canvasbox.value.setWidth(img.width);
          canvasbox.value.setBackgroundImage(img, canvasbox.value.renderAll.bind(canvasbox.value), {
              scaleX: canvasbox.value.width / img.width,
              scaleY: canvasbox.value.height / img.height
          });
      });

      /*if (fabric.PatternBrush) {
        var vLinePatternBrush = new fabric.PatternBrush(canvasbox.value);
        vLinePatternBrush.getPatternSrc = function() {
          var patternCanvas = fabric.document.createElement('canvas');
          patternCanvas.width = patternCanvas.height = 10;
          var ctx = patternCanvas.getContext('2d');
          ctx.strokeStyle = this.color;
          ctx.lineWidth = 5;
          ctx.beginPath();
          ctx.moveTo(0, 5);
          ctx.lineTo(10, 5);
          ctx.closePath();
          ctx.stroke();
          return patternCanvas;
        };
        canvasbox.value.freeDrawingBrush = vLinePatternBrush;
        if (canvasbox.value.freeDrawingBrush) {
          var brush = canvasbox.value.freeDrawingBrush;
          brush.color = "#FB48C4";
          if (brush.getPatternSrc)brush.source = brush.getPatternSrc.call(brush);
          brush.width = parseInt(Layout.value.modalBrush.size, 10) || 1;
          brush.shadow = new fabric.Shadow({
            blur: parseInt(Layout.value.modalBrush.size, 10) || 0,
            offsetX: 0,
            offsetY: 0,
            affectStroke: true,
            color: "#FB48C4",
          });
        }
      }*/

      //pencil
      if(Layout.value.modalBrush.type=="line"){
        let _tag = Layout.value.set.current.tag.toString().split("/").pop()
        let _tagData = await rosepetalModel.dataset.getTag(curDataset.value.id, _tag)
        canvasbox.value.freeDrawingBrush        = new fabric.PencilBrush(canvasbox.value);
        canvasbox.value.freeDrawingBrush.color  = _tagData?.color ? _tagData?.color : "#FB48C4";
        canvasbox.value.freeDrawingBrush.width  = Layout.value.modalBrush.size; 
        canvasbox.value.freeDrawingBrush.shadow = new fabric.Shadow({
          blur: 0,
          offsetX: 0,
          offsetY: 0,
          affectStroke: true,
          color: 'rgba(0,0,0,0.5)'
        });
      }
      //end pencil
      
      canvasbox.value.on('mouse:up', async function(){ await syncCanvasboxObjects() });

      refreshPropsCanvas()
      await syncCanvasboxObjects()
      canvasbox.value.renderAll();
      Layout.value.canvas.mlLoading = false
    }

    /* CANVAS OBJECT DETECTION */
    const initCanvas = async (imageId=false,gimage=false) => {

      Layout.value.canvas.mlLoading = true
      if(!imageId)imageId = cash("#modal-zoom-id").html()
      if(!gimage)gimage   = cash("#modal-zoom-image").attr("src"); 
      gimage = await urlToBase64(gimage)
      cash("#canvasContent").html('<canvas id="c"></canvas>')
      Layout.value.canvas.tags      = [] 
      Layout.value.canvas.curImage  = imageId 

      await LoadDatasets()
      for(let index in curDataset.value.tags){ 
         if(!curDataset.value.tags[index].unclassified){
          Layout.value.canvas.tags.push(curDataset.value.tags[index])
         }
      }

      let canvaOpt = { selectionLineWidth: 2, centeredScaling: false, transparentCorners: true }
      canvasbox.value = new fabric.Canvas('c',canvaOpt);

      fabric.Image.fromURL(gimage, function (img) {   
          canvasbox.value.setHeight(img.height); 
          canvasbox.value.setWidth(img.width);
          canvasbox.value.setBackgroundImage(img, canvasbox.value.renderAll.bind(canvasbox.value), {
              scaleX: canvasbox.value.width / img.width,
              scaleY: canvasbox.value.height / img.height
          });
      });
      
      //mouse drawing
      if(Layout.value.canvas.drawing){
        var rect, isDown, origX, origY;
        canvasbox.value.on('mouse:down', async function(o){
            
          console.log('mouse down', o.target)
          if(!o.target){ 
            var pointer = canvasbox.value.getPointer(o.e);
            origX = pointer.x;
            origY = pointer.y;
            console.log("mouse down -> origX:"+origX+" / pointer.x:"+pointer.x)
            isDown = true;
            rect = new fabric.Rect({
                left: origX,
                top: origY,
                originX: 'left',
                originY: 'top',
                width: pointer.x-origX,
                height: pointer.y-origY,
                angle: 0,
                fill: 'transparent',
                strokeWidth: 1,
                stroke: 'red',//Layout.value.canvas.tags[0] && Layout.value.canvas.tags[0].color ? $h.hexToRgb(Layout.value.canvas.tags[0].color) : ,
                transparentCorners: false
            });
            highlightObject(canvasbox.value.getObjects().length)
            let newObjectId = 'MB'+Math.random();
            rect.toObject = (function(toObject) {
              return function(propertiesToInclude) {
                return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                  name: 'new', //Layout.value.canvas.tags && Layout.value.canvas.tags[0].id ? Layout.value.canvas.tags[0].id : ''
                  objid: newObjectId
                });
              };
            })(rect.toObject);
            rect.set({name: 'new' })
            rect.set({objid: newObjectId })
            canvasbox.value.add(rect);
            var tagNameBox = new fabric.Text('Label name', {
                        fill: 'red',
                        left: origX,
                        top: origY-26,
                        fontSize: 23,
                        padding: 5,
                        objParentId: newObjectId,
                        selectable: Layout.value.canvas.drawing ? false : true,
                        hoverCursor: "default"
                    });
            tagNameBox.toObject = (function(toObject) {
              return function(propertiesToInclude) {
                return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                  objParentId: newObjectId
                });
              };
            })(tagNameBox.toObject);     
            tagNameBox.set({objParentId: newObjectId })   
            canvasbox.value.add(tagNameBox);
            await syncCanvasboxObjects()

          }
        });
        canvasbox.value.on('mouse:move', async function(o){
            if (!isDown) return;
            var pointer = canvasbox.value.getPointer(o.e);
            if(origX>pointer.x)rect.set({ left: Math.abs(pointer.x) });
            if(origY>pointer.y)rect.set({ top: Math.abs(pointer.y) });
            rect.set({ width: Math.abs(origX - pointer.x) });
            rect.set({ height: Math.abs(origY - pointer.y) });
            canvasbox.value.renderAll();
            await syncCanvasboxObjects()
        });
        canvasbox.value.on('mouse:up', async function(){ isDown = false;  highlightObject(canvasbox.value.getObjects().length,true); await syncCanvasboxObjects() });
      }
      
      //set limits to move objects
      canvasbox.value.on('object:moving', async function(e){
        var obj = e.target;
        // if object is too big ignore
        if(obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width)return;
        obj.setCoords();
        // top-left  corner
        if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
          obj.top = Math.max(obj.top, obj.top-obj.getBoundingRect().top);
          obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left);
        }
        // bot-right corner
        if(obj.getBoundingRect().top+obj.getBoundingRect().height  > obj.canvas.height || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width){
          obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top);
          obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left);
        }
        //move subobj related (text)
        if(obj.objid && canvasbox.value._objects){
          for(let robj in canvasbox.value._objects){  //remove parent object
            if(canvasbox.value._objects[robj].objParentId && canvasbox.value._objects[robj].objParentId==obj.objid){
              let subObj = canvasbox.value._objects[robj]
              subObj.setCoords();
              subObj.top  = obj.top - 30
              subObj.left = obj.left
            }
          }
          canvasbox.value.renderAll();
        }
        await syncCanvasboxObjects()
      });
      
      canvasbox.value.on('object:scaling', async function(e){
        var obj = e.target;
        if (!obj || obj.type !== 'rect') { return; }
        obj.setCoords();
        if(obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width)return;

        if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
          obj.top  = Math.max(obj.top, obj.top-obj.getBoundingRect().top);
          obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left);
        }

        if(obj.getBoundingRect().top+obj.getBoundingRect().height  > obj.canvas.height || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width){
          obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top);
          obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left);
        }
        var sX = obj.scaleX;
        var sY = obj.scaleY;
        obj.width *= sX;
        obj.height *= sY;
        obj.scaleX = 1;
        obj.scaleY = 1;
        obj.dirty = true;

        //move subobj related (text)
        if(obj.objid && canvasbox.value._objects){
          for(let robj in canvasbox.value._objects){  //remove parent object
            if(canvasbox.value._objects[robj].objParentId && canvasbox.value._objects[robj].objParentId==obj.objid){
              let subObj = canvasbox.value._objects[robj]
              subObj.setCoords();
              subObj.top  = obj.top - 30
              subObj.left = obj.left
            }
          }
          canvasbox.value.renderAll();
        }
        
        await syncCanvasboxObjects()
      });
      
      //render image saved bbox labels
      let curImage       = await $image.get(imageId)  
      const curImageUrl  = await getGoogleLink(curImage.uri);
      fabric.Image.fromURL(curImageUrl, async function (imgsize) {   
        //console.log("Load zoom image ----- ",curImage.id)
        if(curImage.tags){
          for(let index in curImage.tags){ 
          let savedObject = curImage.tags[index] 
            if(savedObject.type=="rect"){
              let RectOpt = {
                  left: savedObject.x * imgsize.width,
                  top: savedObject.y * imgsize.height,
                  originX: 'left',
                  originY: 'top',
                  width: savedObject.w*imgsize.width,
                  height: savedObject.h*imgsize.height,
                  //angle: 0,
                  fill: 'transparent',
                  strokeWidth: 1,
                  lockRotation: true
                  //stroke:  '#'+curDataset.value.objtags.colors[index],
                  //transparentCorners: false,
              }
              
              if(Layout.value.canvas.drawing)RectOpt.selectable = false;
              let newObject = new fabric.Rect(RectOpt);
              let tagRef  = savedObject.tag.path.toString().split('/')
              let tagName = false
              if (tagRef[tagRef.length-1])tagName = tagRef[tagRef.length-1]
              if (tagName) {
                let bbColor = curDataset.value.tags[tagName] ? curDataset.value.tags[tagName].color : 'ff0000'
                let newObjectId    = index+tagName;
                newObject.toObject = (function(toObject) {
                  return function(propertiesToInclude) {
                    return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                      name: tagName,
                      objid: newObjectId
                    });
                  };
                })(newObject.toObject); 
                newObject.set({stroke: bbColor })
                newObject.set({name: tagName })
                newObject.set({objid: newObjectId })
                canvasbox.value.add(newObject);
                let TextOpt = {
                        fill: bbColor,
                        left: savedObject.x * imgsize.width,
                        top: ((savedObject.y * imgsize.height)-28),
                        fontSize: 25,
                        padding: 5,
                        objParentId: newObjectId,
                        hoverCursor: "default"
                    }
                if(Layout.value.canvas.drawing)TextOpt.selectable = false;
                var tagNameBox = new fabric.Text(tagName, TextOpt);
                tagNameBox.toObject = (function(toObject) {
                  return function(propertiesToInclude) {
                    return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                      objParentId: newObjectId
                    });
                  };
                })(newObject.toObject);     
                tagNameBox.set({objParentId: newObjectId })
                canvasbox.value.add(tagNameBox);
              }
            }
          }
        }
        if(!curImage.tags[0]){
         if(Layout.value.canvas.autoDetection && cash("#object-ml-btn").length)document.getElementById("object-ml-btn").click()
        }
        canvasbox.value.renderAll();
        await syncCanvasboxObjects()
      });
      //end render

      refreshPropsCanvas()
      $h.dragElement(document.getElementById("canvasImageTools"));
      canvasbox.value.renderAll();
      Layout.value.canvas.mlLoading = false

      //load next image
      setTimeout(async () => { 
        if(media.value.length){
          for(let i in media.value){ 
            if(media.value[i] && media.value[i].id==imageId){
              let cur = Number(i)
              if(media.value[cur+1]){
                if(media.value[cur+1].uri){
                  //console.log('preload next image no sync '+media.value[cur+1].uri)
                  let preloadUrl = await getGoogleLink(media.value[cur+1].uri)
                  await urlToBase64(preloadUrl)
                }
              }
              break;
            }
          }
        }
      }, 1500);
    }

    const resetPropsCanvas = () => {
       Layout.value.modalZoom      = 100;
       Layout.value.modalPosition  = { top:0, right:0 };
       Layout.value.modalZoomProps = { contrast: 0, brightness: 0, saturation: 0, vibrance: 0 }
       Layout.value.modalBrush     = { size: 30, type: 'line' };
    }

    const updCanvastObjectTxt = async (event,objectItem) => {
      if(canvasbox.value._objects[objectItem]){
        if(canvasbox.value._objects[objectItem].objid){
          for(let icobj in canvasbox.value._objects){ 
            if(canvasbox.value._objects[icobj].objParentId && canvasbox.value._objects[icobj].objParentId==canvasbox.value._objects[objectItem].objid){
              canvasbox.value._objects[icobj].text = event.target.value;
              canvasbox.value.renderAll();
            }
          }
        }
      } 
      await syncCanvasboxObjects()
    }

    const canvaSetObject = async (event,objectItem) => {
        //let tc = curDataset.value.objtags.colors[((event.target.selectedOptions[0].index)-1)]
        let tc = curDataset.value.tags[event.target.value] ? curDataset.value.tags[event.target.value].color : 'red'
        if(event.target.value=='new')tc = "red"
        canvasbox.value._objects[objectItem].set({stroke: tc  })  
        canvasbox.value._objects[objectItem].toObject = (function(toObject) {
                return function(propertiesToInclude) {
                  return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                    name: event.target.value
                  });
                };
              })(canvasbox.value._objects[objectItem].toObject);
         canvasbox.value._objects[objectItem].set({name: event.target.value })   

        if(canvasbox.value._objects[objectItem]){
         if(canvasbox.value._objects[objectItem].objid){
           for(let icobj in canvasbox.value._objects){ 
            if(canvasbox.value._objects[icobj].objParentId && canvasbox.value._objects[icobj].objParentId==canvasbox.value._objects[objectItem].objid){
              canvasbox.value._objects[icobj].text = canvasbox.value._objects[objectItem].name
              canvasbox.value._objects[icobj].set({fill: canvasbox.value._objects[objectItem].stroke }) 
              break
            }
          }
        }
        canvasbox.value.renderAll();
       }
       await syncCanvasboxObjects()
    }

    const highlightObject = async (objectItem,leave = false) => {
      if(canvasbox.value._objects){
        for(let index in canvasbox.value._objects){ 
          if(index!=objectItem)canvasbox.value._objects[index].set({opacity: 0})
          if(leave)canvasbox.value._objects[index].set({opacity: 1})
        }
        if(canvasbox.value._objects[objectItem]){
          if(canvasbox.value._objects[objectItem].objid){
            for(let icobj in canvasbox.value._objects){ 
              if(canvasbox.value._objects[icobj].objParentId && canvasbox.value._objects[icobj].objParentId==canvasbox.value._objects[objectItem].objid){
                canvasbox.value._objects[icobj].set({opacity: 1})
              }
            }
          }
        } 
        canvasbox.value.renderAll();
      }
      await syncCanvasboxObjects()
    }

    const removeObject = async (objectItemId) => {
       if(canvasbox.value._objects){
        for(let robj in canvasbox.value._objects){  //remove parent object
          if(canvasbox.value._objects[robj].objParentId && canvasbox.value._objects[robj].objParentId==objectItemId){
             var TXTobject = canvasbox.value.item(robj);
             canvasbox.value.remove(TXTobject);
          }
        }
        for(let robj in canvasbox.value._objects){  //remove main object
          if(canvasbox.value._objects[robj].objid && canvasbox.value._objects[robj].objid==objectItemId){
             var mainObject = canvasbox.value.item(robj);
             canvasbox.value.remove(mainObject);
          }
        }
        highlightObject(canvasbox.value.getObjects().length,true)
        canvasbox.value.renderAll();
      }
      await syncCanvasboxObjects()
    }

    const removeMask = async () => {
      let currentObjects  = canvasbox.value.getObjects()
      for(let c=0;c<currentObjects.length;c++){
        canvasbox.value.remove(currentObjects[c]);
      }
      await syncCanvasboxObjects()
    }

    const saveObjectsMulticlass = async () => {
      await syncCanvasboxObjects()
      let imageId         = cash("#modal-zoom-id").html()
      let currentObjects  = canvasbox.value.getObjects()
      canvasbox.value.setBackgroundImage(null);
      /*let canvasURL = canvasbox.value.toDataURL({
        format: 'png',
        quality: 1
      });
      console.log(canvasURL)*/
      let _tag     = Layout.value.set.current.tag.toString().split("/").pop()
      let _tagData = await rosepetalModel.dataset.getTag(curDataset.value.id, _tag)
      let canvasJSON = canvasbox.value.toJSON();
      if(Object.keys(currentObjects).length && canvasJSON)await $image.updateMask(imageId, { imageJson: JSON.stringify(canvasJSON), width: canvasbox.value.width, height: canvasbox.value.height, color: _tagData?.color ? _tagData?.color : "#FB48C4" })
      else await $image.updateMask(imageId, {})
      if(Layout.value.set.current){
        if(Layout.value.set.current.set)await $image.updateSet(imageId, Layout.value.set.current.set)
        await rosepetalModel.image.setComments(imageId, Layout.value.set.current.comments)
        if(Layout.value.set.current.tag)await $image.updateTag({ id: imageId , datasetID: curDataset.value.id, tagID: Layout.value.set.current.tag.toString().split("/").pop() }); 
      }
      $h.NotificationTxt({ text: "Successfully image saved ", position: "center" });
    }

    const saveObjects = async () => {
       await syncCanvasboxObjects()
       let imageId = cash("#modal-zoom-id").html()
       let next  = false
       //let prev  = false
       if(media.value.length){
        for(let i in media.value){ 
          if(media.value[i] && media.value[i].id==imageId){
             let cur = Number(i)
            //if(cur && cur>0)prev = cur-1;
            if(media.value[cur+1])next = cur+1;
            break;
          }
        }
      }
      let currentObjects  = canvasbox.value.getObjects()
      let objectTags      = [] 
      //let curImage      = await $image.get(imageId)
      const curImageUrl   = await getGoogleLink(cash("#modal-zoom-gsUri-image").html()); //curImage.uri
      fabric.Image.fromURL(curImageUrl, async function (imgsize) { 
          for(let index in currentObjects){ 
            if(currentObjects[index].type=="rect"){
              let tagNameUpd = currentObjects[index].name.toString()
              if(currentObjects[index].objid){
                for(let sobj in currentObjects){ 
                  if(currentObjects[sobj].objParentId && currentObjects[sobj].objParentId==currentObjects[index].objid){
                    tagNameUpd = currentObjects[sobj].text.toString()
                  }
                }
              }
              let newtagRef = await $_firebase.firestore().collection("dataset").doc(curDataset.value.id.toString()).collection('tag').doc(tagNameUpd)
              let saveBd = {
                tag: newtagRef,
                type: currentObjects[index].type,
                h: currentObjects[index].height / imgsize.height,
                w: currentObjects[index].width / imgsize.width,
                x: currentObjects[index].left / imgsize.width,
                y: currentObjects[index].top / imgsize.height,
              }

              if(curDataset.value && curDataset.value.tags && !curDataset.value.tags[tagNameUpd])
                await $dataset.addTag({ dataset: curDataset.value.id.toString(), id: tagNameUpd, data: { name: tagNameUpd, description: '', imageCounter: Number(0) } })
              
              objectTags.push(saveBd)
            }
          }
          //Layout.value.canvas.mlLoading = true
          //console.log('save object image: '+imageId)
          if(Layout.value.set.current && Layout.value.set.current.set)await $image.updateSet(imageId, Layout.value.set.current.set)
          await $image.setObjectTags(imageId, objectTags, cash("#object-image-comments").val()).then( async () => {  
             $h.NotificationTxt({ text: "Successfully image labels saved", position: "center" });
             if(media.value.length){
                if(next){
                  Zoom(media.value[next])
                }else{
                  let nextpage = pagination.value.pages-(pagination.value.currentPage+1)
                  if(nextpage){
                    await FilterMedia('next')
                    //await DatasetStats()
                    if(media.value[0])Zoom(media.value[0])
                  }else{
                    await refreshTotals()
                    cash("#modal-zoom").modal("hide"); 
                  }
                }
              }
          }).catch( async (error) => {  console.log(error) });
      }) 
    }

    const startMachineLearning = async () => {
        Layout.value.canvas.mlPredictions = []
        const pic0 = new Image();
        pic0.crossOrigin = "anonymous"
        pic0.setAttribute('crossOrigin', 'anonymous');
        pic0.src = cash("#modal-zoom-image").attr("src")
        pic0.onload = async () => {
          Layout.value.canvas.autoLoading = true
          pic0.objApi = Layout.value.modalDetection.api
          pic0.gsUri  = cash("#modal-zoom-gsUri-image").html()  //'gs://rosepetal-dev/upload/manual/untitled_v1.1662724331096/set1.cam34.Hair.Cam4_NOK__00046_1.png'
          await $ml.detect(pic0).then( (predictions) => { 
            //if(predictions.class)console.log(predictions.class)
            Layout.value.canvas.mlPredictions = predictions; 
            predictions.forEach(prediction => {
              let newObjectId = 'MB'+Math.random();
              let newPredictionObject = new fabric.Rect({
                left: prediction.bbox[0],
                top: prediction.bbox[1],
                originX: 'left',
                originY: 'top',
                width: prediction.bbox[2],
                height: prediction.bbox[3],
                angle: 0,
                fill: 'transparent',
                strokeWidth: 1,
                stroke: 'red', //Layout.value.canvas.tags[0] && Layout.value.canvas.tags[0].color ? $h.hexToRgb(Layout.value.canvas.tags[0].color) : 'red',
                transparentCorners: false,
                selectable: Layout.value.canvas.drawing ? false : true,
                centeredScaling: false,
                lockRotation: true
              }) 
              canvasbox.value.add(newPredictionObject);
              canvasbox.value._objects[canvasbox.value.getObjects().length-1].toObject = (function(toObject) {
                                                                                return function(propertiesToInclude) {
                                                                                  return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                                                                                    name:'new',
                                                                                    objid:newObjectId
                                                                                  });
                                                                                };
                                                                              })(canvasbox.value._objects[canvasbox.value.getObjects().length-1].toObject);
              canvasbox.value._objects[canvasbox.value.getObjects().length-1].set({name: 'new' })
              canvasbox.value._objects[canvasbox.value.getObjects().length-1].set({objid: newObjectId })
              var tagNameBox = new fabric.Text('Label name', { //prediction.class
                          fill: 'red',
                          left: prediction.bbox[0],
                          top: prediction.bbox[1]-26,
                          fontSize: 23,
                          padding: 5,
                          objParentId: newObjectId,
                          selectable: Layout.value.canvas.drawing ? false : true,
                          hoverCursor: "default"
                      });
              tagNameBox.toObject = (function(toObject) {
                return function(propertiesToInclude) {
                  return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                    objParentId: newObjectId
                  });
                };
              })(tagNameBox.toObject);     
              tagNameBox.set({objParentId: newObjectId })       
              canvasbox.value.add(tagNameBox);
              canvasbox.value.renderAll();
            })
            Layout.value.canvas.autoLoading = false
            canvasbox.value.renderAll();
          }).catch( async (error) => {  console.log(error) });
        }
        await syncCanvasboxObjects()
    }

    /* /CANVAS OBJECT DETECTION */

    const MonitorSetLast = async (id,image) => { 
      Layout.value.MonitorlastImage                 = image
      Layout.value.MonitorlastImage.id              = id
      if(Layout.value.MonitorlastImage.tag.path){
        Layout.value.MonitorlastImage.tag             = Layout.value.MonitorlastImage.tag.path
        Layout.value.MonitorlastImage.tagName         = Layout.value.MonitorlastImage.tag.toString().split('/')
      }
      Layout.value.MonitorlastImage.fileName        = Layout.value.MonitorlastImage.name.toString().split('/')
      Layout.value.MonitorlastImage.img_base64_val  = Layout.value.MonitorlastImage.imageData && Layout.value.MonitorlastImage.imageData._delegate._byteString.binaryString ? btoa(Layout.value.MonitorlastImage.imageData._delegate._byteString.binaryString) : null
      let gimage = Layout.value.MonitorlastImage.uri && Layout.value.MonitorlastImage.uri.startsWith('gs://') ? await getGoogleLink(Layout.value.MonitorlastImage.uri) : Layout.value.MonitorlastImage.img_base64_val
      cash("#monitormode-image").attr("src",gimage)
    }

    const MonitorMode = async (a=false) => { 
      if(a){ 
        cash(".top-bar-line,.side-nav.side-nav--simple").show(); 
        cash(".wrapper-box .content").removeClass("singlemode_wrapper")
        cash(".main").removeClass("p-0")
        Layout.value.monitormode = false
        Layout.value.monitormodeLoading = true
        Layout.value.MonitorlastImage = {}
        let curDset = curDataset.value.id;
        Layout.value.loading    = true
        actions.value.assingTag = curDataset.value  = false
        datasets.value          = media.value       = selectedImages.value = []
        setTimeout(async () => { await LoadDatasets(); await Filter({ target: { id: 'filterDatasetSel', value: curDset, sameCat: true }})  }, 500);
      }else{
        cash(".top-bar-line,.side-nav.side-nav--simple").hide(); 
        cash(".wrapper-box .content").addClass("singlemode_wrapper")
        cash(".main").addClass("p-0")
        Layout.value.monitormode = true
        let datasetRef  = await $_firebase.firestore().collection("dataset").doc(curDataset.value.id)
        $_firebase.firestore().collection('image').where('dataset','==',datasetRef).orderBy('date', 'desc').limit(1) 
          .onSnapshot(async function(snapshot) {
              snapshot.forEach( async function(doc) {
                  await MonitorSetLast(doc.id, doc.data())
                  Layout.value.monitormodeLoading = false
              });
          });
      } 
    }

    const SingleMode = async (a=false) => { 
      if(a){ 
        Layout.value.mediaSize   = 2;  
        pagination.value.perPage = 50; 
        cash(".top-bar-line,.side-nav.side-nav--simple").show(); 
        cash(".wrapper-box .content").removeClass("singlemode_wrapper")
        cash(".main").removeClass("p-0")
        Layout.value.singlemode  = false;
        pagination.value.currentPage = 0;
        let curDset = curDataset.value.id;
        Layout.value.loading    = true
        actions.value.assingTag = curDataset.value  = false
        datasets.value          = media.value       = selectedImages.value = []
        setTimeout(async () => { await LoadDatasets(); await Filter({ target: { id: 'filterDatasetSel', value: curDset }})  }, 500);
      }else{
        Layout.value.mediaSize   = 2; 
        pagination.value.perPage = 1; 
        cash(".top-bar-line,.side-nav.side-nav--simple").hide(); 
        cash(".wrapper-box .content").addClass("singlemode_wrapper")
        cash(".main").addClass("p-0")
        Layout.value.singlemode  = true 
        pagination.value.currentPage = 0;
        await FilterMedia()
      } 
    }

    const SingleModeControls = async () => { 
      if(curDataset.value.tags && Object.keys(curDataset.value.tags).length<=9){
        window.addEventListener("keydown", async function (event) {
        if (event.defaultPrevented)return; 
        if(Layout.value.singlemode){
          if (event.key !== undefined) {
            if(event.key == "ArrowRight" && pagination.value.next)FilterMedia('next')
            //if(event.key == "ArrowLeft" && pagination.value.prev)FilterMedia('prev')
            if(event.key == "Escape")SingleMode(true)
            let keyTag = document.getElementById("key_tag_"+event.key)
            if(keyTag){
              let assingNumID  = false
              let assingNumTag = false
              if(keyTag.title){
                let kt = keyTag.title.toString().split('--//--')
                //console.log(kt)
                assingNumTag = kt[0]
                assingNumID  = kt[1];
              }
              if(assingNumTag){
                await assignTagValue(assingNumTag,assingNumID)
                /*await $image.updateTag({ id: assingNumID , datasetID: curDataset.value.id  , tagID: assingNumTag });
                $h.NotificationTxt({ text: "Media update to label: "+ curDataset.value.tags[assingNumTag].name });
                if(pagination.value.next){ FilterMedia('next') }else{ SingleMode(true) }*/
              }
            }
          }
        }
      }, true);
      }
    }

    const assignTagValue = async (NumTag,NumID) => { 
      await $image.updateTag({ id: NumID , datasetID: curDataset.value.id  , tagID: NumTag });
      $h.NotificationTxt({ text: "Media update to label: "+ curDataset.value.tags[NumTag].name });
      if(Layout.value.singlemode){
        if(pagination.value.next){ FilterMedia('next') }else{ SingleMode(true) }
      }
    }

    const getGoogleLink = async (uri) => { 
        if(Layout.value.googleUris[uri]){ /*console.log("getGoogleLink ----- cached!");*/ return Layout.value.googleUris[uri];}
        try { 
          /*console.log("getGoogleLink ----- getDownloadURL!")*/
          Layout.value.googleUris[uri]   = await $_firebase.storage().refFromURL(uri).getDownloadURL();
          return Layout.value.googleUris[uri];
        } catch (error) { console.log("getGoogleLink error: "+error); }
        
    }

    const LoadDatasets = async (loadDataset = false) => {
      datasets.value = await $dataset.get(false,true); 
      if(loadDataset){
        curDataset.value = datasets.value[loadDataset]; 
        CurTag.value = false;
        await DatasetStats()
        await Filter()
      }else{
        for(let index in datasets.value){ 
          if(!curDataset.value){ 
            curDataset.value = datasets.value[index]; 
            if(curDataset.value.type && curDataset.value.type=='imageObjectDetection')curDataset.value.objtags  = await $dataset.getTagsObjectDetection(curDataset.value.id)
            await FilterMedia()
          } 
        }
        await DatasetStats()
      }
    };

    const DatasetStats = async () => {
      if(curDataset.value && curDataset.value.type && curDataset.value.type=='imageObjectDetection')
        curDataset.value.objtags  = await $dataset.getTagsObjectDetection(curDataset.value.id)
      let tagstats = { labels: [], data: [], color: [], hovercolor: []}
      if(curDataset.value && Object.keys(curDataset.value.tags).length){ 
         for(let index in curDataset.value.tags){ 
           let pushTag = true
           if(curDataset.value.type && curDataset.value.type=='imageObjectDetection' && index=="0")pushTag = false
           if(pushTag){
            tagstats.labels.push(curDataset.value.tags[index].name)
            tagstats.data.push(curDataset.value.tags[index].imageCounter)
            tagstats.color.push(curDataset.value.tags[index].color)
            tagstats.hovercolor.push(curDataset.value.tags[index].color)
           }
         }
       }
      if(curDataset.value)curDataset.value.tagstats = tagstats;
    };
    
    const getSelectedImages = async () => {
      selectToDataset.value = []
      await media.value.reduce(async (a, s) => {
        await a;
        for (const si of selectedImages.value) {
          if(si==s.id){
            Layout.value.zip.counter++
            var filename    = s.name.substr(s.name.lastIndexOf("/")+1);
            const imageUrl  = await getGoogleLink(s.uri);
            const imageBlob = fetch(imageUrl).then(response => response.blob());
            selectToDataset.value.push({name: filename, tag: s.tag, file: imageBlob});
          } 
        }  
      }, Promise.resolve());
    }

    const refreshPropsCanvas = async (type=false) => {
      if(canvasbox.value.backgroundImage){
          //console.log("modalZoomProps",Layout.value.modalZoomProps)
          canvasbox.value.backgroundImage._originalElement.crossOrigin= "anonymous"
          for(let index in canvasbox.value.backgroundImage.filters){
            if(type=="saturation" && canvasbox.value.backgroundImage.filters[index].saturation)
              canvasbox.value.backgroundImage.filters.splice(index, 1); 
            if(type=="brightness" && canvasbox.value.backgroundImage.filters[index].brightness)
              canvasbox.value.backgroundImage.filters.splice(index, 1);
            if(type=="contrast" && canvasbox.value.backgroundImage.filters[index].contrast)
              canvasbox.value.backgroundImage.filters.splice(index, 1);  
            if(type=="vibrance" && canvasbox.value.backgroundImage.filters[index].vibrance)
              canvasbox.value.backgroundImage.filters.splice(index, 1); 
            if(!type)   
              canvasbox.value.backgroundImage.filters.splice(index, 1); 
          }
          if(!type || type=="saturation")
            canvasbox.value.backgroundImage.filters.push(new fabric.Image.filters.Saturation({ saturation: parseFloat(Layout.value.modalZoomProps.saturation) }));
          if(!type || type=="brightness")
            canvasbox.value.backgroundImage.filters.push(new fabric.Image.filters.Brightness({ brightness: parseFloat(Layout.value.modalZoomProps.brightness) })); 
          if(!type || type=="contrast")
            canvasbox.value.backgroundImage.filters.push(new fabric.Image.filters.Contrast({ contrast: parseFloat(Layout.value.modalZoomProps.contrast) })); 
          if(!type || type=="vibrance")
            canvasbox.value.backgroundImage.filters.push(new fabric.Image.filters.Vibrance({ vibrance: parseFloat(Layout.value.modalZoomProps.vibrance) })); 
          try { 
            canvasbox.value.backgroundImage.applyFilters();
          } catch(e) { console.log('error: '+e) }

          if(!type || type=="brushSize"){
            let _tag = Layout.value.set.current.tag.toString().split("/").pop()
            let _tagData = await rosepetalModel.dataset.getTag(curDataset.value.id, _tag)
            //pencil
            if(Layout.value.modalBrush.type=="line"){
              canvasbox.value.freeDrawingBrush = new fabric.PencilBrush(canvasbox.value);
              canvasbox.value.freeDrawingBrush.color = _tagData?.color ? _tagData?.color : "#FB48C4";
              canvasbox.value.freeDrawingBrush.width = Layout.value.modalBrush.size; 
              canvasbox.value.freeDrawingBrush.shadow = new fabric.Shadow({
                blur: 0,
                offsetX: 0,
                offsetY: 0,
                affectStroke: true,
                color: 'rgba(0,0,0,0.5)'
              });
            }
            //end pencil
            //erase
            if(Layout.value.modalBrush.type=="erase"){
              var ErasedGroup = fabric.util.createClass(fabric.Group, {
                original: null,
                erasedPath: null,
                initialize: function (original, erasedPath, options, isAlreadyGrouped) {
                  this.original = original;
                  this.erasedPath = erasedPath;
                  this.callSuper('initialize', [this.original, this.erasedPath], options, isAlreadyGrouped);
                },

                _calcBounds: function (onlyWidthHeight) {
                  const aX = [],
                    aY = [],
                    props = ['tr', 'br', 'bl', 'tl'],
                    jLen = props.length,
                    ignoreZoom = true;

                  let o = this.original;
                  o.setCoords(ignoreZoom);
                  for (let j = 0; j < jLen; j++) {
                    let prop = props[j];
                    aX.push(o.oCoords[prop].x);
                    aY.push(o.oCoords[prop].y);
                  }

                  this._getBounds(aX, aY, onlyWidthHeight);
                },
              });
              var EraserBrush = fabric.util.createClass(fabric.PencilBrush, {
                  /**
                   * On mouseup after drawing the path on contextTop canvas
                   * we use the points captured to create an new fabric path object
                   * and add it to the fabric canvas.
                   */
                  _finalizeAndAddPath: function () {
                    var ctx = this.canvas.contextTop;
                    ctx.closePath();
                    if (this.decimate) {
                      this._points = this.decimatePoints(this._points, this.decimate);
                    }
                    var pathData = this.convertPointsToSVGPath(this._points).join('');
                    if (pathData === 'M 0 0 Q 0 0 0 0 L 0 0') {
                      // do not create 0 width/height paths, as they are
                      // rendered inconsistently across browsers
                      // Firefox 4, for example, renders a dot,
                      // whereas Chrome 10 renders nothing
                      this.canvas.requestRenderAll();
                      return;
                    }

                    // use globalCompositeOperation to 'fake' eraser
                    var path = this.createPath(pathData);
                    path.globalCompositeOperation = 'destination-out';
                    path.selectable = false;
                    path.evented = false;
                    path.absolutePositioned = true;

                    // grab all the objects that intersects with the path
                    const objects = this.canvas.getObjects().filter((obj) => {
                      // if (obj instanceof fabric.Textbox) return false;
                      // if (obj instanceof fabric.IText) return false;
                      if (!obj.intersectsWithObject(path)) return false;
                      return true;
                    });

                    if (objects.length > 0) {

                      // merge those objects into a group
                      const mergedGroup = new fabric.Group(objects);

                      // This will perform the actual 'erasing' 
                      // NOTE: you can do this for each object, instead of doing it with a merged group
                      // however, there will be a visible lag when there's many objects affected by this
                      const newPath = new ErasedGroup(mergedGroup, path);

                      const left = newPath.left;
                      const top = newPath.top;

                      // convert it into a dataURL, then back to a fabric image
                      const newData = newPath.toDataURL({
                        withoutTransform: true
                      });
                      fabric.Image.fromURL(newData, (fabricImage) => {
                        fabricImage.set({
                          left: left,
                          top: top,
                        });

                        // remove the old objects then add the new image
                        this.canvas.remove(...objects);
                        this.canvas.add(fabricImage);
                      });
                    }

                    this.canvas.clearContext(this.canvas.contextTop);
                    this.canvas.renderAll();
                    this._resetShadow();
                  },
              });
              canvasbox.value.freeDrawingBrush = new EraserBrush(canvasbox.value);
              canvasbox.value.freeDrawingBrush.inverted = true;
            }
            //end erase
            //change exist objects color
            let currentObjects  = canvasbox.value.getObjects()
            for(let index in currentObjects){ 
              currentObjects[index].set({stroke: _tagData?.color })
            }
            //end change exist objects color
            canvasbox.value.freeDrawingBrush.width = Layout.value.modalBrush.size;
          }
          canvasbox.value.renderAll();
      }
    }

    const refreshTotals = async () => {
      await FilterMedia(); await DatasetStats();
    }

    onUnmounted( async () => {
      console.log('onUnmounted data labeling....')
    });


    onMounted( async () => {
      initPagination()  
      await LoadDatasets(router.currentRoute.value.params["datasetID"] ? router.currentRoute.value.params["datasetID"] : false)
      if (process.client && window)window.history.scrollRestoration = 'auto';
      SingleModeControls()
    });
    
    watch(() => Layout.value.modalZoomProps.saturation, async () => { 
      await refreshPropsCanvas('saturation')
    });

    watch(() => Layout.value.modalZoomProps.brightness, async () => { 
      await refreshPropsCanvas('brightness')
    });

    watch(() => Layout.value.modalZoomProps.contrast, async () => { 
      await refreshPropsCanvas('contrast')
    });

    watch(() => Layout.value.modalZoomProps.vibrance, async () => { 
      await refreshPropsCanvas('vibrance')
    });

    watch(() => Layout.value.modalBrush.size, async () => { 
      await refreshPropsCanvas('brushSize')
    });

    watch(() => Layout.value.modalBrush.type, async () => { 
      await refreshPropsCanvas('brushSize')
    });

    const linkTo = (page,params=false) => {  let p = {}; if(params)p=params; router.push({ name: page, params: p });  };
    
    return {
      router,
      context,
      Layout,
      media,
      pagination,
      SetPerPage,
      selectedImages,
      CurTag,
      Filter,
      Check,
      UnCheck,
      CountCheck,
      CheckItem,
      datasets,
      curDataset,
      isCurPage,
      FilterMedia,
      FilterObj,
      MediaSize,
      linkTo,
      Zoom,
      Modal,
      changeSet,
      actions,
      Categorize,
      setDataDivision,
      SingleMode,
      MonitorMode,
      selectToDataset,
      getSelectedImages,
      assignTagValue,
      canvaSetObject,
      updCanvastObjectTxt,
      highlightObject,
      removeObject,
      saveObjects,
      saveObjectsMulticlass,
      startMachineLearning,
      initCanvas,
      initCanvasMulticlass,
      resetPropsCanvas,
      refreshPropsCanvas,
      updateImageSet,
      refreshTotals,
      removeMask,
      canvasbox,
      Delete,
    };
  },
  methods:{
    downloadMedia: async function(){ 
      this.Layout.zip.loading = true;
      this.Layout.zip.counter = 0;
      await this.getSelectedImages()
      var zip           = new JSZip()  
      var nowdate       = new Date()
      var filename      = this.curDataset.id+"_("+this.selectToDataset.length+")_"+nowdate.getTime()
      var zipFilename   = filename+".zip";
      
      //create default tags
      let DatasetTags  = await $dataset.getTags(this.curDataset.id)
      let folders      = []
      if(Object.keys(DatasetTags).length){
        for (const k of Object.keys(DatasetTags)){ 
          folders[DatasetTags[k].id] = zip.folder(DatasetTags[k].id);
        }
      }
      //insert folder images
      for (let i = 0; i < this.selectToDataset.length; i++) {
        let imgTagID =  this.selectToDataset[i].tag.substring(this.selectToDataset[i].tag.lastIndexOf('/') + 1)
        folders[imgTagID].file(this.selectToDataset[i].name, this.selectToDataset[i].file, { base64: true }) 
      }
      //download zip
      await zip.generateAsync({type:"blob"}).then(async function (blob) {
          saveAs(blob,zipFilename);
          cash("#modal-download-selected").modal("hide");
      });
      //hide modal
      this.Layout.zip.loading = false;
     },
  },
});
</script>
<style>
.modal-zoom{ width: 100% !important;min-width: 600px; margin: 0 !important; height: 100% !important;}
.modal-zoom .modal-content{ height: 100% !important;}
.modal.show{ padding-left: 0 !important}
#modal-zoom-tag{ padding: 1px 20px;border: 1px solid #ccc;}
.cursor-default { cursor: default !important; }
.image-tags{ position: absolute; top:0; left: 0; padding-top: 10px; }
.image-tags span{ font-size: 11px !important;}

.image-number{ position: absolute; top:0; right: 0; padding-top: 10px; }
.image-number span{ font-size: 11px !important;}

.pagination__link{ padding: 2px !important}
.pagination__link--active{ background-color: #e9e9e9 !important; padding: 2px !important}
.singlemode_wrapper{min-height: 100vh !important}
.gimage{ max-height: 820px}
.gimage img{ max-height: 800px}
.rpbtn1{ background-color: #539179 !important;border-color: #15704d !important;}
.rpbtn2{ background-color: #306090 !important;border-color: #306090 !important; font-weight: normal !important}
#canvasContent, #canvasContentMulticlass{display: flex;justify-content: center; width: 100%;}
#canvasContent .canvas-container, #canvasContentMulticlass .canvas-container,.ThumbcanvasBox .canvas-container{ width: 100% !important;}
.lower-canvas,.upper-canvas{ width: 100% !important; height: auto !important;}
.btnGreen{ background-color: #32bea6 !important; border-color: #32bea6 !important;}
.image-fit-contain > img{object-fit: contain !important;}
.canvasImageTools .slider-connect{background-color: #306090 !important;}
.drawing-mode-switch:checked{ background-color: #61bd4f !important; border-color: #61bd4f !important ;}
#objects-image-name{display: block;position: fixed;top: 2px; left: 130px;font-size: 12px;background-color: #fff;padding: 1px 5px;opacity: 0.7;}
#objects-image-date{display: block;position: fixed;top: 2px; left: 2px;font-size: 12px;background-color: #fff;padding: 1px 5px;opacity: 0.7;}
#objects-image-auto-vision{position: fixed;top: 50px; left: 38%;font-size: 12px;background-color: #ff0000; color: #ffff;padding: 5px 10px;opacity: 0.7;}
#muticlass-image-name{display: block;position: fixed;top: 2px; left: 150px;font-size: 12px;background-color: #fff;padding: 1px 20px;opacity: 0.7;z-index: 999;}
#muticlass-image-date{display: block;position: fixed;top: 2px; left: 5px;font-size: 12px;background-color: #fff;padding: 1px 10px;opacity: 0.7;z-index: 999;}

@media only screen and (min-width: 1024px) {
  .gimage img{ min-height: 740px}
}
@media only screen and (min-width: 1750px) {
  .gimage{ max-height: 870px}
  .gimage img{ min-height: 780px}
}
</style>