<template>
  <div class="container grid grid-cols-12 -my-3 min-h-screen relative z-10 w-full">
    <!-- Logo & Tabs -->
    <div class="col-span-3 bg-gray-800 py-10">
        <a href="" class="intro-x flex items-center pl-5 mb-8">
            <img alt="Seeds" class="w-40" src="@/assets/images/logo/logorosepetal.png" />
            <span class="text-white text-lg ml-3"> <span class="font-medium">docs</span> </span>
        </a>
        <a v-for="(tab) in Tabs" :key="tab.id" 
           class="intro-x flex items-center px-5 py-3 rounded-l hover:bg-gray-900 transition duration-300 ease-in-out cursor-pointer" 
           :class="!tab.root ? isTabSel(CurTab,tab.id) : root ? isTabSel(CurTab,tab.id): 'hidden'" @click="viewTab(tab.id)">{{ tab.title }}</a> 
        <a class="intro-x flex items-center px-5 py-3 rounded-l hover:bg-gray-900 transition duration-300 ease-in-out cursor-pointer" @click="linkTo('home')"><ChevronLeftIcon class="w-4 h-4 mr-2" />{{ $t('Back') }}</a> 
    </div>
    <!-- Proyecto -->
    <div v-if="CurTab=='project'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
        <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />{{ $t('Back') }}</a></div>
        <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800">{{ $t('Project') }}</h1>
        <h2 class="intro-y text-xl font-medium pb-5 mb-5 border-b border-gray-800">{{ $t('Description') }}</h2>
        <div class="intro-y leading-relaxed">
            <p class="mb-3">{{ $t('Implementation of a machine vision classifier using supervised learning systems with convolutional neural networks (deep learning)') }}.</p>
        </div>
        <h2 class="intro-y text-xl font-medium pb-5 mb-5 border-b border-gray-800 mt-10">
            {{ $t('Browser Support') }}
        </h2>
        <div class="w-full grid grid-cols-12 gap-5">
            <div class="intro-y col-span-6 sm:col-span-3 cursor-pointer" v-for="(browser) in browsers" :key="browser">
                <div class="zoom-in flex flex-col items-center p-5 rounded-md bg-gray-800">
                    <img class="w-10" :alt="browser" :src="require(`../../assets/images/browsers/${browser}.png`)">
                    <div class="font-medium mt-3">{{ $h.capitalizeFirstLetter(browser) }}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- Instalación -->
    <div v-if="CurTab=='install'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
        <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />{{ $t('Back') }}</a></div>
        <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800">{{ $t('Installation') }}</h1>
        <div class="intro-y leading-relaxed mt-1 mb-8" v-if="gitHub">
          <div class="intro-y p-8 rounded-md bg-gray-800 mt-1">
              <h2 class="intro-y text-xl font-medium mb-5 border-b border-gray-800"><component :is="gitHub.icon" class="w-6 h-6" /> GitHub</h2>
              <table class="intro-y w-full">
                  <tr v-if="gitHub.repository_url">
                      <td class="border border-gray-700 px-4 py-2 font-medium">Url</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium">
                        <a class="hover:text-white" target="blank" :href="gitHub.repository_url">{{gitHub.repository_url}}</a>
                      </td>
                  </tr>
                  <tr v-if="gitHub.repository_git">
                      <td class="border border-gray-700 px-4 py-2 font-medium">Git</td><td class="border border-gray-700 px-4 py-2 font-medium">{{gitHub.repository_git}}</td>
                  </tr>
              </table>
          </div>
        </div>
        <div class="intro-y leading-relaxed mt-1 mb-10" v-if="fireBas">
          <div class="intro-y p-8 rounded-md bg-gray-800 mt-1">
              <h2 class="intro-y text-xl font-medium mb-5 border-b border-gray-800"><component :is="fireBas.icon" class="w-6 h-6" /> Firebase</h2>
              <table class="intro-y w-full">
                  <tr v-if="fireBas.project_name"><td class="border border-gray-700 px-4 py-2 font-medium">{{ $t('Project name') }}</td><td class="border border-gray-700 px-4 py-2 font-medium">{{fireBas.project_name}}</td></tr>
                  <tr v-if="fireBas.project_id"><td class="border border-gray-700 px-4 py-2 font-medium">{{ $t('Project id') }}</td><td class="border border-gray-700 px-4 py-2 font-medium">{{fireBas.project_id}}</td></tr>
                  <tr v-if="fireBas.project_num"><td class="border border-gray-700 px-4 py-2 font-medium">{{ $t('Project number') }}</td><td class="border border-gray-700 px-4 py-2 font-medium">{{fireBas.project_num}}</td></tr>
              </table>
          </div>
        </div>
        <h2 class="intro-y text-xl font-medium pb-5 mb-5 border-b border-gray-800">{{ $t('Frontend assets and dependencies') }}</h2>
        <div class="intro-y leading-relaxed">
            <p class="mb-3">{{ $t('The following process is used to install the application using the package manager') }}. </p>
            <ul class="list-decimal ml-6">
                <li class="mt-4">{{ $t('Install Node.js: Install the latest version of Node.js from') }} https://nodejs.org</li>
                <li class="mt-4">{{ $t('Install Yarn from') }} https://yarnpkg.com</li>
                <li class="mt-4">{{ $t('Extract the downloaded project files to your proper directory or folder') }}.</li>
                <li class="mt-4">{{ $t('Access console: Open the node.js console') }}.</li>
                <li class="mt-4">{{ $t('On the console, navigate to the location where the parent folder is located') }}. </li>
                <li class="mt-4">{{ $t('Navigate to directory') }} <span class="text-yellow-500 font-medium">rosepetal-ui</span>.</li>
                <li class="mt-4">{{ $t('Run the command') }} <span class="text-yellow-500 font-medium">yarn install</span> {{ $t('to install package dependencies') }}.</li>
                <li class="mt-4">{{ $t('Run the command') }} <span class="text-yellow-500 font-medium">yarn serve</span> {{ $t('to start compile or') }} <span class="text-yellow-500 font-medium">yarn build</span> {{ $t('for production use') }}.</li>
            </ul>
        </div>
    </div>
    <!-- Guía de uso -->
    <div id="docLayout" v-if="CurTab=='guide'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
        <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />{{$t('Back')}}</a></div>
        <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800">{{$t('Quick guide to use')}}</h1>
          <div id="faq-accordion-1" class="accordion">
              <div class="accordion-item">
                  <div id="faq-accordion-content-1" class="accordion-header">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-accordion-collapse-1" aria-expanded="true" aria-controls="faq-accordion-collapse-1">
                        {{$t('Monitor controls')}}
                      </button>
                  </div>
                  <div id="faq-accordion-collapse-1" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
                      <div class="accordion-body text-white-700 dark:text-white-600 leading-relaxed">
                          <div class="intro-y leading-relaxed mt-6">
                              <div class="intro-y leading-relaxed mt-1 mb-8">
                                <div align="center" class="bg-white p-10"><img alt="Controles de monitor" src="@/assets/images/doc/monitor.png" /></div>
                                <div class="intro-y p-8 rounded-md bg-gray-800 mt-1">
                                    <table class="intro-y w-full">
                                        <tr>
                                            <td class="border border-gray-700 px-4 py-2 font-medium camselector w-40">
                                               <img alt="Selector de monitor" src="@/assets/images/doc/cam_selector.png" />
                                            </td>
                                            <td class="border border-gray-700 px-4 py-2">
                                              {{ $t('At the top you will find a monitor selector that will give you access to the rest of the monitors assigned to it.') }}.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border border-gray-700 px-4 py-0 font-medium camselector w-40 text-gray-800"><PlayCircleIcon class="w-6 h-6 mr-1" /> {{ $t('Play') }}</td>
                                            <td class="border border-gray-700 px-4 py-0">
                                              <div class="pt-2">{{ $t('Start the monitor capture process') }}.</div><br>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border border-gray-700 px-4 py-0 font-medium camselector w-40 text-gray-800"><StopCircleIcon class="w-6 h-6 mr-1" /> {{ $t('Stop') }}</td>
                                            <td class="border border-gray-700 px-4 py-0 font-medium">
                                              <div class="pt-2">{{ $t('Stops the monitor capture process and training mode if activated') }}.</div><br>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border border-gray-700 px-4 py-0 font-medium camselector w-40 text-gray-800"><EyeIcon class="w-6 h-6 mr-1" /> {{ $t('training') }}</td>
                                            <td class="border border-gray-700 px-4 py-0 ">
                                              <div class="pt-2 pb-2">{{ $t('If selected, the training mode in charge of storing the images captured by the monitor for later model training') }}.</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <br />
                                <div class="intro-y p-8 rounded-md bg-gray-800">
                                    <table class="intro-y w-full">
                                        <tr>
                                            <td class="border border-gray-700 px-4 py-0 font-medium camselector w-14 text-gray-800"><DatabaseIcon class="w-6 h-6" /></td>
                                            <td class="border border-gray-700 px-4 py-0 font-normal">
                                              <div class="pt-2 pb-2">{{ $t('Database connection') }}.</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border border-gray-700 px-4 py-0 font-medium camselector w-14 text-gray-800"><CameraIcon class="w-6 h-6" /></td>
                                            <td class="border border-gray-700 px-4 py-0 font-medium">
                                              <div class="pt-2 pb-2">{{ $t('Reader in operation') }}.</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border border-gray-700 px-4 py-0 font-medium camselector w-14 text-gray-800"><GitCommitIcon class="w-6 h-6" /></td>
                                            <td class="border border-gray-700 px-4 py-0 font-normal">
                                              <div class="pt-2 pb-2">{{ $t('Conveyor belt in operation') }}.</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                              </div>
                            </div>
                      </div>
                  </div>
              </div>
              <div class="accordion-item">
                  <div id="faq-accordion-content-2" class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-accordion-collapse-2" aria-expanded="true" aria-controls="faq-accordion-collapse-2">
                          {{ $t('Model training with definitive data and model retraining') }}
                      </button>
                  </div>
                  <div id="faq-accordion-collapse-2" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-2" data-bs-parent="#faq-accordion-1">
                      <div class="accordion-body text-white-700 dark:text-white-600 leading-relaxed">
                          <div class="w-full gap-5 mt-5">
                              <div class="intro-y p-5 rounded-md bg-gray-800"> 
                                 <p>
                                  - {{ $t('The model is trained with already labeled test data') }}.<br>
                                  - {{ $t('The model is trained with already labeled test data') }}.<br>
                                  - {{ $t('The more data provided, the better the predictions') }}.<br>
                                 </p>
                              </div>
                              <p class="pt-5">
                                {{$t('Once the final training with supervised plant data has been carried out, the deep learning model that we have implemented allows us to re-evaluate the model with new labeled data, so that it is possible to improve effectiveness as the sample expands')}}.
                              </p>
                          </div>
                          <p class="mt-5">
                            {{$t('Once you have stored images from the monitor training mode, you can proceed with the model retraining')}}:<br><br>
                          </p>
                          <div aling="center"><h3 class="font-bold docUrl">{{ $t('Main menu') }} > {{ $t('Model') }} > {{ $t('training') }}</h3></div><br>
                          <p>
                            1. {{$t('Select the category you want to retrain')}}.<br><br>
                            2. {{$t('On a list of results you can select multiple images to mark them using the option')}}: <span class="font-medium docUrl">{{$t('Assign Category')}}</span><br>
                            <br>
                          </p>
                          <div aling="center"><img src="@/assets/images/doc/trainingdoc1.png" /></div><br />
                          <p>
                            3. {{$t('Select the category you want to assign to the selected images and press confirm')}}<br>
                          </p>
                          <div align="center" class="p-10"><img src="@/assets/images/doc/trainingdoc2.png" /></div><br />
                      </div>
                  </div>
              </div>
          </div>

        
        
    </div> 
    <!-- Javascript -->
    <div v-if="CurTab=='components'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
        <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />{{$t('Back')}}</a></div>
        <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800">
          <span @click="viewTab(CurTab)" class="cursor-pointer">Javascript</span>
        </h1>
        <div v-for="(obj,type) in LoadObjects" :key="obj">
          <div v-if="Object.keys(obj).length">
            <div v-if="tactive ? tactive==type ? true : false : true">
              <h2 class="intro-y text-xl font-medium pb-3 mb-4 border-b border-gray-800 w-full text-gray-600">{{ $h.capitalizeFirstLetter(type) }}</h2>
              <div class="w-full grid grid-cols-12 gap-5 mb-5">
                  <div class="intro-y col-span-6 sm:col-span-2 cursor-pointer" v-for="(o,idx) in obj" :key="idx">
                      <div class="zoom-in flex flex-col items-center p-5 pt-2 rounded-md bg-gray-800" @click="viewComponent(o[0].id,type)" v-if="o[0].id">
                          <CodesandboxIcon class="w-8 h-8 mt-2" :class="cactive && isSelected(cactive,o[0].id)" />
                          <div class="font-medium mt-2" :class="isSelected(cactive,o[0].id)">{{ o[0].id }}</div>
                      </div>
                  </div>
              </div>
            </div>
          </div>  
        </div>
        <div v-for="(obj,type) in LoadObjects" :key="obj">
          <div v-for="(o,idx) in obj" :key="idx">
            <div class="intro-y p-8 rounded-md bg-gray-800 mt-5 overflow-hidden" v-if="cactive && cactive.toLowerCase()==o[0].id.toLowerCase()">
               <h2 class="intro-y text-xl font-medium mb-1 border-b border-gray-800 text-yellow-500">{{ o[0].id }}</h2>
               <div class="mb-3"><span class="text-gray-600">{{ type }}</span></div>  
               <div class="mb-2" v-if="type=='views'"><span class="text-green-600 cursor-pointer" @click="linkTo(o[0].link)">Visitar vista</span></div>  
               <div class="mb-5" v-if="o[0].path"><span class="text-gray-600">Path:</span> {{ o[0].path }}</div>
               <div class="mb-5" v-if="o[0].props.length">
                    <table class="intro-y w-full">
                        <tr>
                          <td class="border border-gray-700 px-4 py-2 font-medium w-40">{{$t('Props')}}</td>
                          <td class="border border-gray-700 px-4 py-2 font-medium method_code">
                              <div v-for="p in o[0].props" :key="p">
                              <div v-for="(pv,key) in p" :key="pv">
                                  {{ key }} <span v-if="pv.default">({{$t('Default')}}: {{ pv.default }})</span>
                              </div>
                              </div>
                          </td> 
                        </tr>
                    </table> 
               </div>
               <div class="mb-5" v-if="o[0].methods.length">
                  <table class="intro-y w-full">
                      <tr>
                      <td class="border border-gray-700 px-4 py-2 font-medium w-40">{{$t('Methods')}}</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium method_code">
                          <div v-for="p in o[0].methods" :key="p">
                          <div v-for="(pv,key) in p" :key="pv">
                              {{ key }} <span v-if="pv.default">({{$t('Default')}}: {{ pv.default }})</span>
                          </div>
                          </div>
                      </td> 
                      </tr>
                  </table> 
               </div>
               <div class="mb-5" v-if="o[0].functions.length">
                  <div class="text-gray-600 mt-5 mb-3">{{$t('Functions')}}</div>
                  <table class="intro-y w-full" v-for="p in o[0].functions" :key="p">
                      <tr>
                      <td class="border border-gray-700 px-4 py-2 font-medium w-40 cursor-pointer" :class="isSelected(mactive,p)" @click="viewMethod(p)">{{ p }}</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium method_code">
                          <div v-if="mactive==p"><pre class="overflow-hidden rounded-md"><code class="scss text-xs p-0 rounded-md html pt-4 -mb-10 -mt-10 "> {{ o[0].component[p] }}</code></pre></div>
                      </td> 
                      </tr>
                  </table> 
               </div>
               <div class="mb-5" v-if="o[0].actions.length">
                  <table class="intro-y w-full">
                    <tr>
                      <td class="border border-gray-700 px-4 py-2 font-medium w-40">{{$t('Actions')}}</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium method_code">
                          <div v-for="p in o[0].actions" :key="p">
                            <div v-for="(pv,key) in p" :key="pv">
                                - {{ key }} 
                            </div>
                          </div>
                      </td> 
                    </tr>
                  </table> 
               </div>
               <div class="mb-5" v-if="o[0].components.length">
                  <table class="intro-y w-full">
                      <tr>
                      <td class="border border-gray-700 px-4 py-2 font-medium w-40">{{$t('Components')}}</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium method_code">
                          <div v-for="p in o[0].components" :key="p">
                          <div v-for="(pv,key) in p" :key="pv">
                              <div>
                                <div @click="viewComponent(key,getObjType(key))" class="hover:text-yellow-500 cursor-pointer">{{ key }}</div> 
                                <div v-if="pv.__file" class="text-gray-700">({{ pv.__file }})</div>
                              </div> 
                          </div>
                          </div>
                      </td> 
                      </tr>
                  </table> 
                </div>
            </div>
          </div>
        </div>
    </div>
    <!-- Changelog -->
    <div v-if="CurTab=='changelog'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
        <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />{{$t('Back')}}</a></div>
        <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800">Changelog</h1>
        <div class="intro-y p-8 rounded-md bg-gray-800">
          <div><span class="text-yellow-500">{{$t('Current version')}}</span> </div>
          <div class="mt-3">
            <ul class="list-disc ml-6 mt-3">
              <li>{{ General.version }} </li>
            </ul>
          </div>
        </div>
    </div>  
    <!-- Soporte -->
    <div v-if="CurTab=='support'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
      <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />{{$t('Back')}}</a></div>
      <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800"> {{$t('Support')}}</h1>
      <div class="intro-y p-8 rounded-md bg-gray-800"> 
        {{$t('For support and questions contact us at')}}: <a class="hover:text-white font-medium" :href="'mailto:'+General.support_mail">{{ General.support_mail }}</a> 
      </div>
    </div>  
    <!-- Desarrollo -->
    <div v-if="CurTab=='development'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
      <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />{{$t('Back')}}</a></div>
      <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800"> {{$t('Development')}}</h1>
      <div class="intro-y p-4 rounded-md bg-gray-800 cursor-pointer" @click="viewTab('components')"> 
        <CodesandboxIcon class="w-6 h-6 mr-1" /> Javascript
      </div>
      <div class="intro-y p-4 rounded-md bg-gray-800 mt-5 cursor-pointer" @click="viewTab('changelog')"> 
        <BookmarkIcon class="w-6 h-6 mr-1" /> Changelog
      </div>
    </div> 
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeMount, computed, watch} from "vue";
import cash from "cash-dom";

//components
import { useRouter } from "vue-router";
import { useStore } from "@/store";

import { helper as $h } from "@/utils/helper";
import _ from "lodash";
import { view as $v } from "@/model/view";
import { event as $event } from "@/model/events";
import { monitor as $monitor } from "@/model/monitor";
import { tag as $tag } from "@/model/tag";
import { layout as $layout } from "@/model/layout";
import { user as $user } from "@/model/user";
import { _firebase as $_firebase } from "@/model/firebase";
import { model as $model } from "@/model/model";
import { settings as $set } from "@/model/settings";
import { table as $table } from "@/model/table";
import sideMenu from "@/store/side-menu";

import ManageApp    from "@/components/manage-app/Main.vue";
import ManageTags   from "@/components/manage-tags/Main.vue";
import ManageEvents from "@/components/manage-events/Main.vue";
import ManageModels from "@/components/manage-models/Main.vue";
import ManageUsers  from "@/components/manage-users/Main.vue";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
import ProfileMenu  from "@/components/profile-menu/Main.vue";
import GlobalCounter from "@/components/global-counter/Main.vue";
import MonitorControls from "@/components/monitor-controls/Main.vue";
import MonitorPreview from "@/components/monitor-preview/Main.vue";
import ManageSession from "@/components/manage-session/Main.vue";

import Login  from "@/views/login/Main.vue";
import Profile  from "@/views/profile/Main.vue";
//import Monitor  from "@/views/monitor/Main.vue";
import Settings from "@/views/settings/Main.vue";
//import Training from "@/views/training/Main.vue";
import Log from "@/views/log/Main.vue";
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {

    const router      = useRouter();
    const store       = useStore();
    const User        = computed(() => store.state.main.User);
    const root        = ref(false);
    const {t}         = useI18n(); 

    //Navigation
    const Tabs           = ref([ { id: 'project',    title: t('Project'),     root: true }, 
                                 { id: 'install',    title: t('Installation'),  root: true },
                                 { id: 'guide',      title: t('User guide'),  root: false },
                                 { id: 'support',    title: t('Support'),      root: false },
                                 { id: 'development',title: t('Development'),   root: true }
                               ])
    const CurTab         = ref('guide') //Tabs.value[0].id)
    const isTabSel       = (item, value) => { if(item==value)return 'bg-gray-900' };
    const viewTab        = (t) => { CurTab.value = t; cactive.value = tactive.value = mactive.value = false;};

    //Components & Methods
    const components     = ref([
                            { id: 'helper',           type: 'util',          component: $h },
                            { id: 'cash',             type: 'util',          component: cash },
                            { id: 'store',            type: 'util',          component: useStore },
                            { id: 'lodash',           type: 'util',          component: _ },
                            { id: 'view',             type: 'model',         component: $v },
                            { id: 'event',            type: 'model',         component: $event },
                            { id: '_monitor',         type: 'model',         component: $monitor },
                            { id: 'tag',              type: 'model',         component: $tag },
                            { id: 'layout',           type: 'model',         component: $layout },
                            { id: 'user',             type: 'model',         component: $user },
                            { id: 'firebase',         type: 'model',         component: $_firebase },
                            { id: 'model',            type: 'model',         component: $model },
                            { id: 'setting',          type: 'model',         component: $set },
                            { id: 'table',            type: 'model',         component: $table },
                            { id: 'router',           type: 'nav',           component: router },
                            { id: 'sidemenu',         type: 'nav',           component: sideMenu },
                            { id: 'topBar',           type: 'nav',           component: TopBar },
                            { id: 'mobileMenu',       type: 'nav',           component: MobileMenu },
                            { id: 'managesession',    type: 'components',    component: ManageSession },
                            { id: 'manageapp',        type: 'components',    component: ManageApp },
                            { id: 'managetags',       type: 'components',    component: ManageTags },
                            { id: 'manageevents',     type: 'components',    component: ManageEvents },
                            { id: 'managemodels',     type: 'components',    component: ManageModels },
                            { id: 'manageusers',      type: 'components',    component: ManageUsers },
                            { id: 'profileMenu',      type: 'components',    component: ProfileMenu },
                            { id: 'globalCounter',    type: 'components',    component: GlobalCounter },
                            { id: 'monitorControls',  type: 'components',    component: MonitorControls },
                            { id: 'monitorPreview',   type: 'components',    component: MonitorPreview },
                            
                            { id: 'login',            type: 'view',    component: Login,       link: 'login'},
                            { id: 'profile',          type: 'view',    component: Profile,     link: 'side-menu-profile'},
                            //{ id: 'monitor',          type: 'view',    component: Monitor,     link: 'side-menu-monitor'},
                            { id: 'settings',         type: 'view',    component: Settings,    link: 'side-menu-settings'},
                            //{ id: 'training',         type: 'view',    component: Training,    link: 'side-menu-training'},
                            { id: 'log',              type: 'view',    component: Log,         link: 'side-menu-log'},
                            
                           ])
    const LoadObjects    = ref({
                                utils: {},
                                navigation: {},
                                models: {},
                                components: {},
                                views: {},
                               })
    const mactive        = ref()
    const tactive        = ref()
    const cactive        = ref()
    const isSelected     = (item, value) => { if(item && item.toLowerCase()==value.toLowerCase())return 'text-yellow-500' } ;
    const viewComponent  = (c,t) => { cactive.value = c; tactive.value = t;};
    const viewMethod     = (m) => { mactive.value = m;};
    const getObjType     = (o) => { let type = tactive.value; for (const [key,obj] of Object.entries(components.value)) { if(key!=null && o.toLowerCase()==obj.id.toLowerCase())type = obj.type.toLowerCase() } return type};
    

    //Other
    const browsers  = ref(false)
    const gitHub    = ref(false);
    const fireBas   = ref(false);
    const General   = ref(false);

    onBeforeMount( () => {  cash("body").removeClass('main').addClass("DocLayout");   });
    onMounted(async () => {
      LoadObjects.value.utils      = $h.getObjectProperty(components.value, 'util')
      LoadObjects.value.components = $h.getObjectProperty(components.value, 'components')
      LoadObjects.value.models     = $h.getObjectProperty(components.value, 'model')
      LoadObjects.value.views      = $h.getObjectProperty(components.value, 'view')
      LoadObjects.value.navigation = $h.getObjectProperty(components.value, 'nav')
      gitHub.value                 = await $v.getSettings('github');
      fireBas.value                = await $v.getSettings('firebase');
      General.value                = await $v.getSettings('settings');
      browsers.value               = await $h.getBrowsers();

     if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true; 
    });

    watch( () => store.state.main.User.role, () => { if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;  });

    const linkTo = (page) => { console.log(page); cash("body").removeClass("DocLayout").addClass("main");   if(page=='home')router.replace('/'); router.push({ name: page }); };

    return {
      Tabs,
      CurTab,
      viewTab,
      isTabSel,
      router,
      linkTo,
      LoadObjects,
      tactive,
      mactive,
      cactive,
      isSelected,
      components,
      viewComponent,
      getObjType,
      viewMethod,
      browsers,
      gitHub,
      fireBas,
      General,
      User,
      root
    };
  }
});
</script>
<style>
.DocLayout{ color: #cbd5e0; background-color: rgba(45, 55, 72);  }
.method_code{max-width: 600px}
.accordion-button{font-size: 20px !important}
#docLayout .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed),.accordion .accordion-collapse{ color:#ffffff !important}
.camselector{background-color:#eef3f7 !important;}
.docUrl{font-size: 16px !important}
</style>