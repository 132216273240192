//import firebase from 'firebase/app'
import { useStore } from "@/store";
//import { _firebase as $_firebase } from "@/model/firebase";
import * as rosepetalModel from "rosepetal-model";

var $_firebase = rosepetalModel._firebase;

const events = {
  async get(e){
    let listEvents = []
    let events = $_firebase.firestore().collection('events')
    if(e.uid)events = events.where("payload.uid", "==", useStore().state.main.User.uid)
    if(e.api)events = events.where("source", "==", 'api')
    if(e.ui)events = events.where("source", "==", 'ui')
    if(e.type && e.type!='all')events = events.where("name", "==", e.type)
    if(e.dataset)events = events.where("payload.dataset", "==", e.dataset)
    if(e.isCritical)events = events.where("isCritical", "==", true)
    events = events.orderBy("createdAt", "desc")
    if(e.limit)events = events.limit(e.limit)
    await events.get()
            .then(snapshot => {
                  snapshot.forEach(async doc => {
                    let item = doc.data()
                    item.id  = doc.id;
                    listEvents.push(item)
                  });
                });
    if(e.last && listEvents.length)return listEvents[0]
    return listEvents
  },
  async saveEvent (e, payload, error=false){
    await $_firebase.firestore().collection('events').add({ name: e, source: 'ui', payload: payload, isCritical: error, createdAt: $_firebase.firebase().firestore.FieldValue.serverTimestamp() });
  },
  async deleteEvent (eId){ await $_firebase.firestore().collection('events').doc(eId).delete() },
  async updateEvent(e, data) {
    let upd = { "updatedAt": $_firebase.firebase().firestore.FieldValue.serverTimestamp() }
    if (data.status) upd["status"] = data.status
    if (data.discard) upd["discard"] = Boolean(data.discard)
    await $_firebase.firestore().collection('events').doc(e.toString()).update(upd);
  },
  getEventTypes(type=false){
    let types = [
                  { id: 'all' },
                  { id: 'user.login',  name: 'Inicio de sesión' },
                  { id: 'user.logout', name: 'Ha cerrado sesión' },
                  { id: 'user.saveprofile', name: 'Actualización perfil de usuario' },
                  { id: 'user.sendverifyemail', name: 'Envío verificación de perfil' },
                  { id: 'user.updatepass', name: 'Actualización de contraseña' },
                  { id: 'monitor.start', name: 'Monitor Start' },
                  { id: 'monitor.stop', name: 'Monitor Stop' },
                  { id: 'monitor.training.start', name: 'Training Start' },
                  { id: 'monitor.training.stop', name: 'Training Stop' },
                  { id: 'monitor.controller.output.start', name: 'Activación de salida' },
                  { id: 'monitor.controller.output.stop', name: 'Desactivación de salida' },
                  { id: 'monitor.controller.command.sendoutput', name: 'Forzar envío a salida' },
                  { id: 'monitor.controller.restart', name: 'Actualización módulo' },
                  { id: 'monitor.demo.start', name: 'Activación modo demo' },
                  { id: 'monitor.demo.stop', name: 'Desactivación modo demo' },
                  { id: 'app.view.add', name: 'Nueva configuración de vista' },
                  { id: 'app.view.delete', name: 'Eliminación de vista' },
                  { id: 'app.view.update', name: 'Actualización de vista' },
                  { id: 'app.view.add.attribute', name: 'Nuevo atributo de vista' },
                  { id: 'app.view.delete.attribute', name: 'Eliminación de atributo de vista' },
                ]
    if(type)return this.getEventName(types,type)         
    return types
  },
  getEventName(e,t){
    let typeName = t;
    e.forEach( type => { if(type.id==t)typeName = type.name; });
    return typeName;   
  }
  
};


const install = app => {
  app.config.globalProperties.$event = events;
};

export { install as default, events as event };
