//import { _firebase as $_firebase } from "@/model/firebase";
import { helper as $h } from "@/utils/helper";
import { settings as $settings } from "@/model/settings";
import * as rosepetalModel from "rosepetal-model";

//var $_firebase = rosepetalModel._firebase;
//const functions = $_firebase.firebase().app().functions(); //local
//const functions = $_firebase.firebase().app().functions('europe-west1'); //online
//if(rosepetalModel._firebase)console.log('INIT FIREBASE ROSEPETAL MODEL', rosepetalModel._firebase.firebase.app().name);
//const functions = null //rosepetalModel._firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;

//if(rosepetalModel._firebase.firebase)console.log('INIT USER', rosepetalModel._firebase.firebase);

const users = {
    async getapiUrl(){
        let Settings = await $settings.get('api');
        let ApiUrl   = Settings.url_local;
        let apipath  = 'user/';
        return ApiUrl+apipath;
    },
    async list(){
       let users   = []; 
       let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
       if(!functions)return users;
       const action = functions.httpsCallable('api/user/list');
       await action({}).then( response => { if(response.data)users = response.data;  }).catch( async (error) => { console.log(error) });
       return users;
    },
    async getRole(email) { 
        let role   = { role_id: 1, name: 'user' };
        let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
        if(!functions)return role;
        const action = functions.httpsCallable('api/user/role/email/'+email);
        await action().then( response => {  if(response.data)role = response.data; }).catch( async (error) => { if(error)console.log(error) });
        return role;
    },
    async delete(uid, msg=false){
        let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
        const action = functions.httpsCallable('api/user/delete');
        await action({ uid: uid }).then( () => { if(msg)$h.NotificationTxt({ text: 'Usuario eliminado correctamente'});  })
                                  .catch( (error) => { if(msg)$h.NotificationTxt({ text: 'No se ha podido eliminar el usuario. '+error}); });
    },
    async update(user, msg=false){
        let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
        const action = functions.httpsCallable('api/user/update');
        await action(user).then( () => {  if(msg)$h.NotificationTxt({ text: 'Usuario actualizado correctamente'});   })
                          .catch( (error) => { if(msg)$h.NotificationTxt({ text: 'No se ha podido actualizar el usuario. '+error}); });
    },
    async create(user, msg=false){
        let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
        const action = functions.httpsCallable('api/user/create');
        await action(user).then( () => { if(msg)$h.NotificationTxt({ text: 'Usuario creado correctamente'});  })
                          .catch( (error) => { if(msg)$h.NotificationTxt({ text: 'No se ha podido crear el usuario. '+error}); });
    },
    async search(uid){
        let user   = [];
        let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
        if(!functions)return user;
        const action = functions.httpsCallable('api/user/search/uid/'+uid);
        await action({}).then( response => { if(response.data)user = response.data;  }).catch( async (error) => { console.log(error) });
        return user;
     },
};

const install = app => {
  app.config.globalProperties.$users = users;
};

export { install as default, users as user };
