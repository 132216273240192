'use strict';
const $_firebase = require("./firebase");
const controller = require("./controller");

const ui = { 
    getMainMenu: async function(opt= {}) { 
        const snapshot          = await $_firebase.firestore().collection('view').doc('layout').get();
        let item                = snapshot.data(); item.id = snapshot.id
        if(!item.main_menu)return []
        let mainMenu            = JSON.parse(item.main_menu)
        /*
        let ctrStatus           = opt.status 
        if(!opt.noCheckStatus){
            let _status   = await controller.getStatus()
            ctrStatus  = !_status.error ? true : false
        }
        
        if(!ctrStatus || ctrStatus.error){
            let mainMenuByStatus   = []
            let disableMenus       = ['side-menu-controller','side-menu-flow','side-menu-node'] //,'side-menu-node'
            mainMenu.forEach( m => {
                if(m.pageName && !disableMenus.includes(m.pageName))mainMenuByStatus.push(m)
                if(m=="devider")mainMenuByStatus.push(m)
            });
           mainMenu = mainMenuByStatus
        }*/
        /*if(opt.top){
            for(let i=0;i<mainMenu.length;i++){
                if(mainMenu[i].pageName=="side-menu-settings" || mainMenu[i].pageName=="side-menu-datasets")mainMenu.splice(i,1)
            }
        }*/


        if(opt.raw)return JSON.parse(JSON.stringify(mainMenu));
        else return mainMenu;
    },
    getConfig: async function(type) {
        const snapshot          = await $_firebase.firestore().collection('view').doc(type).get();
        let item                = snapshot.data();
        if(item){
            item.id  = snapshot.id
            return item;
        }else return {}
    }, 
}

module.exports = ui