
import Tabulator from "tabulator-tables";
import feather from "feather-icons";
import moment from 'moment';
const table = {
    async init(tableRef, Columns, Options=false){
        let table = new Tabulator(tableRef, {
            data: [],
            printHeader: Options.printHeader ? Options.printHeader : '', 
            pagination: "local",
            paginationSize: 5,
            paginationSizeSelector: [5, 10, 20, 30, 40],
            layout: "fitColumns",
            responsiveLayout: "collapse",
            placeholder: "No se encontraron registros coincidentes",
            columns: Columns,
            responsiveLayoutCollapseStartOpen:false,
            locale:true,
            langs:{
                "es-es":{
                    "columns":      { "name":"Nombre",},
                    "ajax":         { "loading":"Cargando", "error":"Error",},
                    "groups":       { "item":"item", "items":"items",},
                    "pagination":   { "page_size":"", "first":"First", "first_title":"Primera", "last":"Last", "last_title":"Última", "prev":"Prev", "prev_title":"Anterior", "next":"Next", "next_title":"Siguiente", },
                    "headerFilters":{ "default":"Filtrar columna...", "columns":{ "name":"Filtrar nombre...", }}
                }
            },
          renderComplete() { feather.replace({ "stroke-width": 1.5 }); }
          });
        this.InitOnResizeWindow(table);  
        return table;
    },
    async InitOnResizeWindow(t){  
        window.addEventListener("resize", () => { t.redraw(); /*console.log(t);*/ feather.replace({ "stroke-width": 1.5 }); }); 
    },
    filterOperators(){ 
        return [{ id: 'like', name: 'Contains'}, { id: '=' }, { id: '<', name: '<' }, { id: '<=', name: '<=' }, { id: '>' }, { id: '>=' }, { id: '!=' }]; 
    },
    renderFilter(filter,filterOpt){
        let form = '<form id="tabulator-html-filter-form" disabled>'
        form    += '<select id="tabulator-html-filter-field" class="form-select w-full cursor-pointer">'  
            for (const o of Object.values(filterOpt)){ form  += '<option value="'+o.id+'" key="'+o.id+'">'+(o.name ? o.name : o.id)+'</option>' }   
        form    += '</select>'  
        form    += '<select id="tabulator-html-filter-type" class="form-select w-full cursor-pointer mt-2">'
            for (const o of Object.values(this.filterOperators())){ form  += '<option value="'+o.id+'" key="'+o.id+'">'+(o.name ? o.name : o.id)+'</option>' }   
        form    += '</select>'
        form    += '<input id="tabulator-html-filter-value" type="text" class="form-control mt-2" placeholder="..." />' 
        form    += '</form>'
        return form
    },
    renderSearchBtn(txt){
        let html = '<button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full sm:w-1/2">'+txt+'</button>';
        return html;
    },
    renderResetBtn(txt){
        let html = '<button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full sm:w-1/2">'+txt+'</button>';
        return html;
    },
    renderPrintBtn(txt){
        let html  = '<button id="tabulator-print" class="btn btn-outline-secondary w-50 sm:w-auto mr-2">'+txt+'</button>';
        return html;
    },
    renderExportBtn(txt){
        let html = '<button id="tabulator-export-csv" class="btn btn-outline-secondary w-50 sm:w-auto">'+txt+'</button>';
        return html;
    },
    async setData(t,data){ 
        await t.setData(data);
    },
    getData(t){ 
        t.getData();
    },
    filter(t,filter){  
       t.setFilter(filter.field, filter.type, filter.value); 
       return t;
    },
    resetFilter(t, filter){ 
        for (const [k,f] of Object.entries(filter)){ document.getElementById("tabulator-html-filter-"+k).value = f }
        this.filter(t, filter); 
        return t;
    },
    print(t){ 
        t.print(); 
    },
    exportCsv(t,filename=false){ 
        if(!filename)filename = 'data.csv'; t.download("csv", filename); 
    },
    async getStats(t,Stats,Opt=false){ 
        Stats.count       = t.getDataCount('active')
        Stats.data        = t.getData("active").reverse();
        console.log(Stats.count)
        console.log('crash2!')
        Stats.percentage  = Math.round(((Stats.count / Stats.total) * 100).toFixed(2));
        let graphData     = [Stats.total, Stats.count]
        if(Stats.count==Stats.total)graphData = [Stats.total]
        Stats.filterGraph = { labels: [], datasets: [{ data: graphData,backgroundColor: ["#306090", "#2BB6D0"],hoverBackgroundColor: ["#2BB6D0", "#E63b1f"],borderWidth: 5,borderColor: "#fff"}]}
        let labels        = []
        let datasets      = []
        Stats.totalGraphLimit  = 15
        if(Opt.limit)Stats.totalGraphLimit = Opt.limit
        if(Stats.count<Stats.totalGraphLimit)Stats.totalGraphLimit = Stats.count
        if(Opt.duration)Stats.duration = { total: 0, average: {}, duration: 0 }
        Stats.analyze           = 0
        let group               = []
        //let filterTags          = []
        //let TagsNames           = {}
        //let TagsColors          = {}
        let TDuration           = { hours: 0, minutes: 0, seconds: 0 }
        for(let i=0;i<Stats.totalGraphLimit;i++){
            if(Opt.dataSubKey){ 
                //if(Stats.data[i] && Stats.data[i][Opt.dataKey] && Stats.data[i][Opt.dataKey][Opt.dataSubKey])labels.push(Stats.data[i][Opt.labelKey])
                console.log(Stats.data[i] && Stats.data[i][Opt.dataKey]+" --- "+Stats.data[i][Opt.dataKey][Opt.dataSubKey])
                if(Stats.data[i] && Stats.data[i][Opt.dataKey] && Stats.data[i][Opt.dataKey][Opt.dataSubKey])datasets.push(Stats.data[i][Opt.dataKey][Opt.dataSubKey])
            }else{ 
                if(Stats.data[i] && Stats.data[i][Opt.dataKey]){
                    if(Opt.group){  
                        let idx = Stats.data[i][Opt.labelKey]
                        if(Opt.dataKey=='date'){ idx = idx.toString().substring(0, 10);  } //
                        if(!group[idx]){ group[idx] = 1 }else{ group[idx]++; }
                    }else{
                        //labels.push(Stats.data[i][Opt.labelKey])
                        datasets.push(Stats.data[i][Opt.dataKey])
                    }
                }
            }
            if(Opt.duration){ //Only for sessions
                if(Stats.data[i] && Stats.data[i]["duration"] && Stats.data[i]["duration"][Opt.duration]){
                    let duration = moment.utc(Stats.data[i]["duration"][Opt.duration]*1000)
                    Stats.duration.total++;
                    TDuration.hours   = parseInt(TDuration.hours)+parseInt(duration.format('HH'))
                    TDuration.minutes = parseInt(TDuration.minutes)+parseInt(duration.format('mm'))
                    TDuration.seconds = parseInt(TDuration.seconds)+parseInt(duration.format('ss'))
                }
            }
            if(Stats.data[i] && Stats.data[i]["data"] && Stats.data[i]["data"]["total"])Stats.analyze += Stats.data[i]["data"]["total"]

            /*if(Stats.data[i].tag){
                if(!filterTags[Stats.data[i].tag]){ 
                    filterTags[Stats.data[i].tag] = 1;
                    if(Opt.tags.names[Stats.data[i].tag])TagsNames[Stats.data[i].tag] = Opt.tags.names[Stats.data[i].tag]
                    if(Opt.tags.colors[Stats.data[i].tag])TagsColors[Stats.data[i].tag] = Opt.tags.colors[Stats.data[i].tag].color
                }else{ filterTags[Stats.data[i].tag]++;}
                Stats.filterTags      = { labels: TagsNames, datasets: [{ data: filterTags,backgroundColor: TagsColors,hoverBackgroundColor: TagsColors,borderWidth: 2,borderColor: "#fff"}]}
            }*/
        }
        
        /*if(Stats.filterTags){
            Stats.filterTagsDonut = {}
            Stats.filterTagsDonut.labels = []
            Stats.filterTagsDonut.datasets = [{data : [] , backgroundColor: [] , hoverBackgroundColor: []}]
            for (const [k,f] of Object.entries(Stats.filterTags.labels)){  if(k)Stats.filterTagsDonut.labels.push(f) }
            for (const [k,f] of Object.entries(Stats.filterTags.datasets[0].data)){ if(k)Stats.filterTagsDonut.datasets[0].data.push(f) }
            for (const [k,f] of Object.entries(TagsColors)){  
                if(k)Stats.filterTagsDonut.datasets[0].backgroundColor.push(f)
                if(k)Stats.filterTagsDonut.datasets[0].hoverBackgroundColor.push(f)
            }
            Stats.filterTagsDonut.datasets[0].borderWidth = 2
            Stats.filterTagsDonut.datasets[0].borderColor = "#fff"
        }*/

         if(Opt.duration){ 
            let totalDuration        = moment.duration({ seconds: TDuration.seconds, minutes: TDuration.minutes, hours: TDuration.hours, });
            Stats.duration.duration  = moment.utc(totalDuration.asMilliseconds()).format("HH:mm:ss")
            if(totalDuration.asMilliseconds()){
                Stats.duration.average.hours    = moment.utc((totalDuration.asMilliseconds()/Stats.duration.total)).format("HH")
                Stats.duration.average.minutes  = moment.utc((totalDuration.asMilliseconds()/Stats.duration.total)).format("mm")
                Stats.duration.average.seconds  = moment.utc((totalDuration.asMilliseconds()/Stats.duration.total)).format("ss")
            }
        }   
        if(Opt.group){
            Object.keys(group).forEach(function(key) { labels.push(key); datasets.push(group[key]) }); 
        }
        
        /*Stats.filterTagsDonut  = Stats.filterTags;
        console.log("filterTagsDonut"); 
        
        Object.entries(Stats.filterTags.labels).forEach(function(key) {  
            if(key.length){
                Object.entries(key).forEach(function(val) {
                    console.log(val)  
                }) 
            } 
        }); 
        console.log(Stats.filterTagsDonut)
        */
        console.log(datasets) 
        Stats.totalGraph = { labels: labels,  datasets: [{ label: "Registros", data: datasets, borderWidth: 2,borderColor: "transparent",backgroundColor: "#142e71", borderDash: [5, 5],}]}
       
        console.log('endStats!') 
        return Stats
    },
};

const install = app => {
  app.config.globalProperties.$table = table;
};

export { install as default, table as table };
