<template>
  <div class="container grid grid-cols-12 -my-3 min-h-screen relative z-10 w-full">

    <!-- Logo & Tabs -->
    <div class="col-span-3 bg-gray-800 py-10">
        <a href="" class="intro-x flex items-center pl-5 mb-8">
            <img class="w-40" src="@/assets/images/logo/logorosepetal.png" />
            <span class="text-white text-lg ml-3"> <span class="font-medium text-xs">Models</span> </span>
        </a>
        <a v-for="(tab) in layout.tabs" :key="tab.id" 
           class="intro-x flex items-center px-5 py-3 rounded-l hover:bg-gray-900 transition duration-300 ease-in-out cursor-pointer" 
           :class="!tab.root ? isTabSel(layout.curTab,tab.id) : root ? isTabSel(layout.curTab,tab.id): 'hidden'" @click="viewTab(tab.id)">
           {{ tab.title }}
        </a> 
        <a class="intro-x flex items-center px-5 py-3 rounded-l hover:bg-gray-900 transition duration-300 ease-in-out cursor-pointer" @click="linkTo('home')">
          <ChevronLeftIcon class="w-4 h-4 mr-2" />{{ $t('Back') }}
        </a> 
    </div>

    <!-- Quickstart -->
    <div v-if="layout.curTab=='project'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
        <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />{{ $t('Back') }}</a></div>
        <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800">{{ $t('Quickstart') }}</h1>
        <div class="intro-y leading-relaxed mb-10">
            <p class="mb-3">{{ $t('Rosepetal AI is an integrated suite of machine learning tools and services for building and using ML models with AutoML or custom code') }}.</p>
        </div>
        <div class="intro-y leading-relaxed mt-1 mb-8" v-if="api.functions">
          <div class="intro-y p-8 rounded-md bg-gray-800 mt-1">
              <table class="intro-y w-full">
                  <tr v-if="api.functions.app_.options.projectId">
                      <td class="border border-gray-700 px-4 py-2 font-medium">projectId</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium">{{api.functions.app_.options.projectId}}</td>
                  </tr>
                  <tr v-if="api.url">
                      <td class="border border-gray-700 px-4 py-2 font-medium">Host</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium">
                        <a class="hover:text-white" target="blank" :href="api.url">{{api.url}}</a>
                      </td>
                  </tr>
                  <tr v-if="api.functions.region">
                      <td class="border border-gray-700 px-4 py-2 font-medium">region</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium">{{api.functions.region}}</td>
                  </tr>
              </table>
          </div>
        </div>
    </div>

    <!-- Resources -->
    <div v-if="layout.curTab=='functions'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
      <div align="right"><a @click="viewTab('project')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />{{$t('Back')}}</a></div>
      <h1 class="intro-y text-2xl font-medium pb-5 mb-8 border-b border-gray-800"><PackageIcon class="w-8 h-8" /> {{$t('Resources')}}</h1>
      <div class="flex flex-col sm:flex-row mb-3">
          <div class="form-check mr-5">
            <select class="w-52 form-select p-1 cursor-pointer text-gray-600" v-model="layout.resource.model">
              <option :value="false">{{$t('Select model')}}</option>
              <option :value="indx" v-for="(c,indx) in api.console.modelsFunctions" :key="indx">
                {{indx.charAt(0).toUpperCase() + indx.slice(1).toLowerCase()}} ({{c}})
              </option>
            </select>
          </div>
          <div class="form-check mr-5" v-if="layout.resource.model">
            <select class="w-32 form-select p-1 cursor-pointer text-gray-600" v-model="layout.resource.source">
                <option :value="'all'">{{$t('All')}}</option>
                <option :value="'model'">{{$t('Model')}}</option>
                <option :value="'API'">{{$t('Api')}}</option>
            </select>
          </div>
          <div class="form-check mr-5"  v-if="layout.resource.model">
            <select class="w-52 form-select p-1 cursor-pointer" v-model="layout.resource.method" :class="layout.resource.method ? 'bg-green-600 text-white' : 'text-gray-600'">
              <option :value="false">{{$t('Select method')}}</option>
              <option :value="f.id" v-for="(f,indx) in api.methods" :key="indx">{{f.name}}</option>
            </select>
          </div>
      </div>  
      <div class="intro-y p-5 rounded-md bg-gray-800 mt-2 overflow-hidden mt-5" v-if="layout.resource.method">
        <div v-for="(f,indx) in api.methods" :key="indx">
          <div v-if="layout.resource.method==f.id">
              <h2 class="intro-y text-lg font-medium mb-1 border-b border-gray-800 text-green-500">
                {{f.name}} <span class="text-gray-600 text-xs">{{f.source=='model' ? "" : f.source }}</span>
              </h2>
              <div><span class="text-gray-600">{{f.description}}</span></div>  
              <div class="mt-5">
                <span v-if="f.run" class="p-2 w-24 text-center bg-theme-6 cursor-pointer" @click="initExampleData();httpsCallable(f)">
                  <PlayIcon class="w-5 h-5" /> <span class="text-xs">{{$t('Run')}}</span>
                </span>  
              </div>
              <div v-if="api.url && f.path" class="mt-5">
                <span class="text-gray-600">{{$t('Path')}}: </span> 
                <span class="text-gray-500 text-xs">{{api.url+f.path}}</span>
              </div>
              <div class="mt-5">
                <div v-if="f.important" class="mt-2"><span class="text-red-400 text-xs">{{f.important}}</span></div>  
                <div v-if="f.optionals"  class="mt-2"><span class="text-gray-600 text-xs">{{f.optionals}}</span></div>  
                <div class="mb-2" v-if="f.default"><span class="text-gray-600 text-xs">{{f.default}}</span></div>  
                <div class="mb-2" v-if="f.response"><span class="text-gray-600 text-xs">{{f.response}}</span></div>  
              </div>
              <div v-if="f.props" class="mt-3">
                <div class="text-gray-600 mt-3 mb-1">{{$t('Props')}}</div>
                <div class="text-gray-600 mb-3 text-xs" v-if="f.props.description">{{f.props.description}}.</div>
                <div v-if="f.props.params">
                  <table class="intro-y w-full text-xs">
                    <tr v-for="(p,i) in f.props.params" :key="i">
                      <td class="border border-gray-700 px-4 py-2 font-medium w-40">{{i}}</td>
                      <td class="border border-gray-700 px-4 py-2 font-normal method_code text-gray-600">{{p}}</td> 
                      <td class="w-32 border border-gray-700 px-4 py-2 font-normal">
                        <div v-if="f.props.required">
                          <div v-for="(pr,indx) in f.props.required" :key="indx"><span v-if="i==pr" class="text-xs pt-3 text-yellow-600">{{$t('required')}}</span></div>
                        </div>
                      </td>
                    </tr>
                  </table> 
                </div>
              </div> 
              <div v-if="f.code" class="mt-5">
                <div class="text-gray-600 mt-3 mb-3">{{$t('Implementation')}}</div>
                <div v-for="(c,i) in f.code" :key="i" class="text-xs">
                    <div v-if="c.title" class="font-normal mt-3 mb-3 text-gray-500">{{c.title}}</div>
                    <div v-if="c.code" class="px-5 py-2 bg-gray-700"><code class="text-xs">{{c.code}}</code></div>
                </div>
              </div>  
          </div>
        </div>
      </div>
      <div class="intro-y p-5 rounded-md bg-gray-800 mt-2 overflow-hidden mt-5" v-if="layout.resource.model && !layout.resource.method">
        <div class="pb-5" v-if="layout.resource.model">
          <span v-if="layout.resource.source=='all'">{{$t('All')}}</span>
          <span v-else>{{ layout.resource.source }}</span>
          {{ layout.resource.model }} {{$t('methods')}}
        </div>
        <table class="intro-y w-full text-xs" v-if="layout.resource.model && api.methods.length">
          <tr v-for="(f,indx) in api.methods" :key="indx">
            <td class="border border-gray-700 px-4 py-2 font-normal w-40 text-green-500 cursor-pointer text-xs" @click="layout.resource.method=f.id">{{f.name}}</td>
            <td class="w-20 border border-gray-700 px-4 py-2 font-normal method_code text-gray-600">{{f.source }}</td> 
            <td class="border border-gray-700 px-4 py-2 font-normal method_code">{{f.description}}</td> 
          </tr>
        </table> 
        <div class="pb-5 text-xs" v-else>{{$t('No methods')}}</div>
      </div>
    </div>

    <!-- Log -->
    <div v-if="layout.curTab=='log'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
      <div align="right"><a @click="viewTab('project')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />{{$t('Back')}}</a></div>
      <h1 class="intro-y text-2xl font-medium pb-8 mb-5 border-b border-gray-800"> {{$t('Log')}}</h1>
      <div class="flex flex-col sm:flex-row mb-5">
          <div class="form-check mr-5">
            <select class="w-20 form-select p-1 cursor-pointer text-gray-600" v-model="api.logType" @change="getLog()">
                <option :value="'all'">All</option>
                <option :value="'model'">Model</option>
                <option :value="'api'">API</option>
            </select>
          </div>
          <div class="form-check mr-5" v-if="Object.keys(api.logEvent.types).length">
            <select class="form-select p-1 cursor-pointer text-gray-600 pr-20" v-model="api.logEvent.selectedType" @change="getLog()">
                <option :value="'all'">All</option>
                <option :value="name" v-for="(t,name) in api.logEvent.types" :key="name">{{name}} ({{t}})</option>
            </select>
          </div>    
          <div class="form-check mr-5" v-if="Object.keys(api.logEvent.types).length">
            <select class="w-20 form-select p-1 cursor-pointer text-gray-600" v-model="api.logEvent.isCritical" @change="getLog()">
                <option :value="false">All</option>
                <option :value="true">Errors</option>
            </select>
          </div>  
      </div>  
      <div class="mb-5">
        <div class="my-5">
          <span  v-if="api.logEvent.selected.length">{{api.logEvent.selected.length}} {{$t('items selected')}}</span>
          <a class="pl-5 text-theme-6 hover:text-theme-8 cursor-pointer pr-5" @click="api.logEvent.selected=[]"  v-if="api.logEvent.selected.length">{{ $t('Unselect') }}</a>
          <a class="text-theme-6 hover:text-theme-8 cursor-pointer pr-5" @click="checkLogItem()" v-if="api.log.length">{{ $t('Select all') }}</a>
          <a class="text-theme-6 hover:text-theme-5 cursor-pointer" @click="modal('modal-log-delete')"  v-if="api.logEvent.selected.length">{{ $t('Delete') }}</a>
        </div>
      </div>
      <div class="intro-y p-3 rounded-md bg-gray-800" style="overflow: hidden; max-width: 100%;" v-if="api.logType">
          <table class="intro-y w-full" v-if="api.log.length">
              <tr v-for="(e) in api.log" :key="e.id" :class="isCritical(e.isCritical)" class="text-xs hover:bg-theme-1 hover:text-white">
                  <td class="border border-gray-700 px-2 py-1 font-medium w-8" @click="checkLogItem(e.id)">
                    <div class="form-check"><input  type="checkbox" class="form-check-input border-gray-700" v-model="api.logEvent.selected" :value="e.id" /></div>
                  </td>
                  <td class="border border-gray-700 px-2 py-1 font-medium">{{ e.id }}</td>
                  <td class="border border-gray-700 px-2 py-1 font-medium">{{ this.helper.getTimestampDate(e.createdAt.toDate(),'date') }}</td>
                  <td class="border border-gray-700 px-2 py-1 font-medium">{{ this.helper.getTimestampDate(e.createdAt.toDate(),'time') }}</td>
                  <td class="border border-gray-700 px-2 py-1 font-medium">{{ e.name }}</td>
                  <td class="border border-gray-700 px-2 py-1 font-medium w-12" v-if="e.payload">
                    <span class="px-2 py-1 text-center cursor-pointer" @click="api.logEvent.current=e"><AlignLeftIcon class="w-5 h-5" /></span>  
                  </td>
                  <td class="border border-gray-700 px-2 py-1 font-medium w-12">
                    <span class="px-2 py-1 text-center cursor-pointer" style="float: right;" @click="modal('modal-log-delete', e)"><XCircleIcon class="w-5 h-5" /> </span>  
                  </td>
              </tr>
          </table>
          <div v-else>
            <div class="flex justify-center items-center" v-if="api.logEvent.loading">
              <div class="my-5">{{$t('Updating log, please wait...')}}</div>
            </div>
            <div class="flex justify-center items-center" v-else>
              <div class="my-5">{{$t('no events for selected filters')}}</div>
            </div>
          </div>
      </div>
      <!-- delete log -->
      <div id="modal-log-delete" class="modal" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-body p-10 text-center text-gray-800">
              <div class="p-5 text-center">
                  <TrashIcon class="w-16 h-16 text-theme-24 mx-auto" />
                  <div class="text-2xl mt-5">{{ $t('Are you sure') }}?</div>
                  <div class="text-base mt-1" v-if="api.logEvent.selected.length">{{ api.logEvent.selected.length }} {{ $t('log events will be deleted') }}</div>
                  <div class="text-gray-600 mt-2">{{ $t('This action is irreversible') }}.</div>
              </div>
              <div class="px-2 text-center">
                  <button id="dismiss-modal-delete" type="button" @click="layout.modalObj=false" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                  <button type="button" class="btn btn-danger w-24" @click="deleteLog()">{{ $t('Yes') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- log console -->
      <div class="log_console mt-5 col-span-12" v-if="api.logEvent.current">
        <h2 class="intro-y text-xl font-medium mb-1 border-b border-gray-800 text-yellow-500 px-5 mt-3 pb-5">
         <span class="text-white hover:text-yellow-50 cursor-pointer" @click="viewTab('functions')">{{$t('Log')}}</span> <span class="text-white"> &RightArrow; </span> 
         <span v-if="api.logEvent.current.id">{{api.logEvent.current.id}}</span>
         <a @click="api.logEvent.current=FLIPPED_ALIAS_KEYS" class="cursor-pointer ml-5 pt-1" style="float: right;font-size: 14px;"><XIcon class="w-4 h-4 mr-1" />{{$t('Close')}}</a>
         <span class="px-2 py-1 text-center bg-theme-6 cursor-pointer" @click="consoleFullScreen()" style="float: right;font-size: 14px;"><MaximizeIcon class="w-5 h-5" /></span> 
        </h2>
        <div class="pt-2 pb-3 pl-5 text-xs" style="background-color: #222222">
         <span>{{$t('Date')}}: </span> 
         <span>{{ this.helper.getTimestampDate(api.logEvent.current.createdAt.toDate(),'date') }} {{ this.helper.getTimestampDate(api.logEvent.current.createdAt.toDate(),'time') }}</span> 
         <span class="pl-5" v-if="api.logEvent.current.name">{{$t('Event')}}: </span> 
         <span v-if="api.logEvent.current.name">{{api.logEvent.current.name}}</span> 
        </div>
        <div class="p-5 mb-20" style="overflow-y: scroll; height: 100%;">
          <pre style="padding-bottom: 400px;">{{api.logEvent.current.payload}}</pre>
        </div>
      </div>
    </div> 

    <!-- console -->
    <div class="response_console mt-5 col-span-12" v-if="api.resp.function">
      <div id="dragbar" @mousedown="resizeConsole()"></div>
      <h2 class="intro-y text-xl font-medium mb-1 border-b border-gray-800 text-green-500 px-5 mt-5 pb-5">
        <span class="text-white hover:text-yellow-50 cursor-pointer" @click="layout.resource={ model: false }">
          {{$t('Resources')}}</span> <span class="text-white"> &RightArrow; 
        </span> 
        <span class="text-white hover:text-yellow-50 cursor-pointer" @click="layout.resource.model=api.resp.function.model">
          {{api.resp.function.model}}</span> <span class="text-white"> &RightArrow;
        </span> 
        <span>{{api.resp.function.name}}</span>
        <!-- params -->
        <span class="ml-5 text-sm" v-if="api.resp.function.props && api.resp.function.props.params.datasetId && api.examples.datasetId && !api.respLoading">
          <select class="w-1/5 form-select p-1 cursor-pointer text-gray-600" v-model="api.examples.datasetId" @change="httpsCallable(api.resp.function)">
            <option :value="t.id" v-for="(t,name) in exampleData.datasets" :key="name">{{t.id}} ({{t.type}})</option>
          </select>
          <span v-if="api.resp.function.id=='delete'" class="text-theme-5 mt-3 pl-5"><AlertTriangleIcon class="w-6 h-6" /> {{$t('The selected dataset will be deleted')}}</span>
        </span>
        <span class="ml-5 text-sm" v-if="api.resp.function.props && api.resp.function.props.params.type && !api.respLoading && api.resp.function.id=='get'">
          <select class="w-52 form-select p-1 cursor-pointer text-gray-600" v-model="api.examples.datasetType" @change="httpsCallable(api.resp.function)">
            <option value="all">{{$t('All dataset type')}}</option>
            <option value="MULTICLASS">{{$t('Multiclass')}} ({{$t('Image')}})</option>
            <option value="imageObjectDetection">{{$t('Object detection')}} {{$t('Image')}})</option>
          </select>
        </span>
        <span class="ml-5 text-sm" v-if="api.resp.function.props && api.resp.function.props.params.trained && !api.respLoading">
          <select class="w-52 form-select p-1 cursor-pointer text-gray-600" v-model="api.examples.datasetTrained" @change="httpsCallable(api.resp.function)">
            <option value="all">{{$t('All')}}</option>
            <option value="true">{{$t('Trained')}}</option>
            <option value="false">{{$t('Without training')}}</option>
          </select>
        </span>
        <span v-if="api.resp.function.id=='get-images' && api.examples.datasetId" class="mb-2 text-sm ml-2">
          <select class="w-32 form-select p-1 cursor-pointer text-gray-600 mr-2" v-if="!api.examples.datasetImagesOpt.pagination || exampleData.dataset.type!='MULTICLASS'"
            v-model="api.examples.datasetImagesOpt.objDivision" @change="httpsCallable(api.resp.function)">
            <option value="all">{{ $t('All division') }}</option>
            <option value="test">{{ $t('Test') }}</option>
            <option value="validation">{{ $t('Validation') }}</option>
            <option value="train">{{ $t('Train') }}</option>
          </select>
          <select class="w-32 form-select p-1 cursor-pointer text-gray-600 mr-2" v-model="api.examples.datasetImagesOpt.objtagsType" 
            @change="api.examples.datasetImagesOpt.objByTag='all'; httpsCallable(api.resp.function)">
            <option value="all">{{ $t('All') }}</option>
            <option value="labeled">{{ $t('Labeled') }}</option>
            <option value="nolabel">{{ $t('No label') }}</option>
          </select>
          <select class="w-32 form-select p-1 cursor-pointer text-gray-600" v-if="api.examples.datasetImagesOpt.objtagsType!='nolabel'"
            v-model="api.examples.datasetImagesOpt.objByTag" @change="api.examples.datasetImagesOpt.objtagsType='labeled'; httpsCallable(api.resp.function)">
            <option value="all">{{ $t('All tags') }}</option>
            <option :value="t.id" v-for="(t,name) in exampleData.tags" :key="name">{{t.name}}</option>
          </select>
          <input type="checkbox" class="form-check-input border-gray-700 ml-3" style="vertical-align: middle;" v-if="exampleData.dataset && exampleData.dataset.type=='MULTICLASS'"
            @change="httpsCallable(api.resp.function)" 
            v-model="api.examples.datasetImagesOpt.pagination" /> 
          <span class="text-white font-normal text-xs pl-2" v-if="exampleData.dataset && exampleData.dataset.type=='MULTICLASS'">{{ $t('Pagination') }}</span>
          <select class="w-16 form-select p-1 cursor-pointer text-gray-600 ml-2" v-if="api.examples.datasetImagesOpt.pagination && exampleData.dataset && exampleData.dataset.type=='MULTICLASS'" 
            v-model="api.examples.datasetImagesOpt.perPage" @change="httpsCallable(api.resp.function)">
            <option value="12">12</option>
            <option value="50">50</option>
          </select>
        </span>
        <span v-if="(api.resp.function.id=='datadivision' || api.resp.function.id=='set-random-datadivision') && api.examples.datasetId" class="mb-2 text-sm ml-2">
          <input type="checkbox" class="form-check-input border-gray-700 ml-3" style="vertical-align: middle;" @change="httpsCallable(api.resp.function)" v-model="api.examples.divisionOnlyLabeled"  /> 
          <span class="text-white font-normal text-xs pl-2">{{ $t('Only labeled') }}</span>
        </span>
        <span class="text-sm" v-if="api.resp.function.props && api.resp.function.props.params.imageId && api.examples.imageId && !api.respLoading">
          <select class="w-1/3 form-select p-1 cursor-pointer text-gray-600 ml-3" v-model="api.examples.imageId" @change="httpsCallable(api.resp.function)">
            <option value="">{{$t('Select image')}}</option>
            <option :value="t" v-for="(t,name) in exampleData.images" :key="name">{{t.name.toString()}}</option>
          </select>
        </span>
        <span class="text-sm" v-if="api.resp.function.id=='preview-image' &&  api.resp.function.props && api.resp.function.props.params.imageId && api.examples.imageId && !api.respLoading">
          <select class="w-32 form-select p-1 cursor-pointer text-gray-600 ml-3" v-model="api.examples.previewOpt.size" @change="httpsCallable(api.resp.function)">
            <option value="auto">{{$t('Original size')}}</option>
            <option value="custom">{{$t('Custom size')}}</option>
          </select>
          <input type="checkbox" class="form-check-input border-gray-700 ml-3" style="vertical-align: middle;" @change="httpsCallable(api.resp.function)" v-model="api.examples.previewOpt.storage"  /> 
          <span class="text-white font-normal text-xs pl-2 mr-3">{{ $t('Storage') }}</span>
          <input type="checkbox" class="form-check-input border-gray-700 ml-3" style="vertical-align: middle;" @change="httpsCallable(api.resp.function)" v-model="api.examples.previewOpt.tags"  /> 
          <span class="text-white font-normal text-xs pl-2 mr-8">{{ $t('Tags') }}</span>
          <div class="inline-block text-left" v-if="api.examples.previewOpt.size=='custom'">
            <div class="w-12 inline-block align-middle"><span class="mb-3 font-normal text-xs">{{ $t('Width') }}</span></div>
            <div class="w-32 inline-block align-middle mr-5">
              <Slider class="w-full" v-model="api.examples.previewOpt.width" :min="100" :max="1900" :step="1" :merge="1" :tooltips="false" :lazy="true" @change="httpsCallable(api.resp.function)" />
            </div>
            <div class="w-12 inline-block align-middle"><span class="mb-3 font-normal text-xs">{{ $t('Height') }}</span></div>
            <div class="w-32 inline-block align-middle">
              <Slider class="w-full" v-model="api.examples.previewOpt.height" :min="100" :max="1900" :step="1" :merge="1" :tooltips="false" :lazy="true" @change="httpsCallable(api.resp.function)" />
            </div>
          </div>
        </span> 
        <span class="text-sm" v-if="api.resp.function.props && api.resp.function.props.params.modelId && api.examples.modelId && !api.respLoading">
          <select class="w-1/3 form-select p-1 cursor-pointer text-gray-600 ml-3" v-model="api.examples.modelId" @change="httpsCallable(api.resp.function)">
            <option value="">{{$t('Select model')}}</option>
            <option :value="t.id" v-for="(t,name) in exampleData.models" :key="name">{{t.id.toString()}}</option>
          </select>
        </span>
        <span class="text-sm" v-if="api.resp.function.props && api.resp.function.props.params.url && api.examples.controllerNav && !api.respLoading && api.resp.function.id=='load-page-controller'">
          <select class="w-1/3 form-select p-1 cursor-pointer text-gray-600 ml-3" v-model="api.examples.controllerNav" @change="httpsCallable(api.resp.function)">
            <option value="">{{$t('Select page')}}</option>
            <option :value="t.url" v-for="(t,name) in exampleData.controllerNavMenu.menu" :key="name">{{t.name}}</option>
          </select>
        </span>
        <span class="text-sm" v-if="api.resp.function.props && api.resp.function.props.params.projectName && api.examples.aws.projectName && !api.respLoading">
          <select class="w-1/3 form-select p-1 cursor-pointer text-gray-600 ml-3" v-model="api.examples.aws.projectName" @change="httpsCallable(api.resp.function)">
            <option value="">{{$t('Select project')}}</option>
            <option :value="t.ProjectName" v-for="(t,name) in exampleData.aws.projects.response.Projects" :key="name">{{t.ProjectName}}</option>
          </select>
        </span>   
        <!-- /params --> 
        <a @click="api.resp={}" class="cursor-pointer ml-5 pt-1" style="float: right;font-size: 14px;"><XIcon class="w-4 h-4 mr-1" />{{$t('Close')}}</a>
        <span class="px-2 py-1 text-center bg-theme-6 cursor-pointer" @click="consoleFullScreen()" style="float: right;font-size: 14px;"><MaximizeIcon class="w-5 h-5" /></span>  
        <span v-if="api.resp.function.run" class="px-2 py-1 text-center bg-theme-6 cursor-pointer mr-2" @click="initExampleData();httpsCallable(api.resp.function)" style="float: right;font-size: 14px;">
          <PlayIcon class="w-5 h-5 " />
        </span>  
      </h2>
      <div class="pt-2 pb-3 pl-5 text-xs" style="background-color: #222222">
        <span>{{$t('Request')}}: </span> 
        <span v-if="api.resp.function.source && api.resp.function.source=='model'">{{"Model"}} {{api.resp.function.model}}</span> 
        <span v-else><span v-if="api.console.lastPath">{{api.console.lastPath}}</span></span> 
        <span class="pl-1" v-if="api.console.lastTime && api.console.lastTime>1"> | </span>
        <span class="pl-1" v-if="api.console.lastTime && api.console.lastTime>1">{{$t('Time')}}: <span class="px-2 py-1 bg-theme-1 text-white">{{api.console.lastTime}} ms</span></span>
      </div>
      <!-- canvas -->
      <div id="apiCanvasBox" class="p-5" :class="!api.resp.canvas ? 'hidden': ''"></div>
      <!-- /canvas -->
      <div class="pt-2 pb-3 pl-5 text-xs" style="background-color: #222222" v-if="api.resp.response && api.resp.function.id=='get-tags'">
        {{$t('Items')}}: {{Object.keys(api.resp.response).length}}
      </div>
      <div class="pt-2 pb-3 pl-5 text-xs" style="background-color: #222222" v-if="api.resp.response && Array.isArray(api.resp.response) && api.resp.response.length">
        {{$t('Items')}}: {{api.resp.response.length}}
      </div>
      <div class="pt-2 pb-3 pl-5 text-xs" style="background-color: #222222" v-if="api.resp.response && api.resp.response.media && Array.isArray(api.resp.response.media) && api.resp.response.media.length">
        {{$t('Items')}}: {{api.resp.response.media.length}}
      </div>
      <div class="p-5 mb-20" style="overflow-y: scroll; height: 100%;">
        <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center" v-if="!api.resp.response">
          <!-- params -->
          <LoadingIcon icon="three-dots" v-if="api.respLoading" class="w-12 h-12" />
          <div v-else>
            <div v-if="api.resp.function.id=='csv'" class="mb-2">
              <select class="w-full form-select p-2 cursor-pointer text-gray-600" v-model="api.examples.datasetCsv.test" >
                <option :value="c" v-for="c in 100" :key="c">Test {{c}}%</option>
              </select>
              <select class="w-full form-select p-2 cursor-pointer text-gray-600 mt-2" v-model="api.examples.datasetCsv.validation" >
                <option :value="c" v-for="c in 100" :key="c">Validation {{c}}%</option>
              </select>
            </div>
            <div class="text-sm mb-5" v-if="(api.resp.function.id=='upload-dataset-zip')">
              <DropzoneComponent ref="DropzoneComponent" />
            </div>
            <div class="text-sm" v-if="api.resp.function.props && api.resp.function.props.params.datasetId && !api.examples.datasetId">
              <select class="w-full form-select p-1 cursor-pointer text-gray-600" v-model="api.examples.datasetId" @change="httpsCallable(api.resp.function)">
                <option value="">{{$t('Select dataset')}}</option>
                <option :value="t.id" v-for="(t,name) in exampleData.datasets" :key="name">{{t.id}}</option>
              </select>
              <div v-if="api.resp.function.id=='delete'" class="text-theme-5 mt-3"><AlertTriangleIcon class="w-8 h-8" /> {{$t('The selected dataset will be deleted')}}</div>
            </div>
            <div class="ml-5 text-sm" v-if="api.resp.function.id=='create-dataset'">
              <input type="text" class="w-52 form-control text-gray-800 hover:text-gray-800" placeholder="Dataset Name" v-model="api.examples.datasetNewName"  />
              <select class="w-52 form-select p-2 cursor-pointer text-gray-600 mt-2" v-model="api.examples.datasetNewType" >
                <option value="MULTICLASS">{{$t('Multiclass')}} ({{$t('Image')}})</option>
                <option value="imageObjectDetection">{{$t('Object detection')}} {{$t('Image')}})</option>
              </select><br />
              <div class="mt-2 text-xs">{{$t('Describe the objective')}}</div>
              <textarea v-model="api.examples.datasetDescription" class="form-control text-gray-800 w-52 h-20 mt-2 hover:text-gray-800"></textarea>
              <button type="button" class="btn bg-yellow-500 border-yellow-500 text-gray-800 w-52 mt-3" @click="httpsCallable(api.resp.function)">{{ $t('Create') }}</button>
            </div>
            <div class="text-sm" v-if="api.resp.function.props && api.resp.function.props.params.imageId && !api.examples.imageId">
              <select class="w-52 form-select p-1 cursor-pointer text-gray-600" v-model="api.examples.imageId" @change="httpsCallable(api.resp.function)">
                <option value="">{{$t('Select image')}}</option>
                <option :value="t" v-for="(t,name) in exampleData.images" :key="name">{{t.name.toString()}}</option>
              </select>
            </div>
            <div class="text-sm" v-if="api.resp.function.props && api.resp.function.props.params.url && api.resp.function.id=='get-image-coco-detect'">
              <input type="text" class="form-control text-gray-800 hover:text-gray-800" placeholder="https://" v-model="api.examples.imageUrl"  />
              <button type="button" class="btn bg-yellow-500 border-yellow-500 text-gray-800 w-52 mt-3" @click="httpsCallable(api.resp.function)">{{ $t('Get prediction') }}</button>
            </div>
            <div class="text-sm" v-if="api.resp.function.props && api.resp.function.props.params.modelId && !api.examples.modelId">
              <select class="w-full form-select p-1 cursor-pointer text-gray-600" v-model="api.examples.modelId" @change="httpsCallable(api.resp.function)">
                <option value="">{{$t('Select model')}}</option>
                <option :value="t.id" v-for="(t,name) in exampleData.models" :key="name">{{t.id.toString()}}</option>
              </select>
            </div>
            <div class="text-sm" v-if="api.resp.function.props && api.resp.function.props.params.url && api.resp.function.id=='load-page-controller' && !api.examples.controllerNav">
              <select class="w-full form-select p-1 cursor-pointer text-gray-600" v-model="api.examples.controllerNav" @change="httpsCallable(api.resp.function)">
                <option value="">{{$t('Select page')}}</option>
                <option :value="t.url" v-for="(t,name) in exampleData.controllerNavMenu.menu" :key="name">{{t.name}}</option>
              </select>
            </div>
            <div class="text-sm" v-if="api.resp.function.props && api.resp.function.props.params.projectName && !api.examples.aws.projectName">
              <select class="w-full form-select p-1 cursor-pointer text-gray-600" v-model="api.examples.aws.projectName" @change="httpsCallable(api.resp.function)">
                <option value="">{{$t('Select project')}}</option>
                <option :value="t.ProjectName" v-for="(t,name) in exampleData.aws.projects.response.Projects" :key="name">{{t.ProjectName}}</option>
              </select>
            </div>  
          </div>
          <!-- /params -->
        </div>
        <div v-else>
          <!-- pagination -->
          <div v-if="api.resp.response && api.resp.response.pagination" class="pb-5">
            <ul class="pagination ml-auto">
              <li class="w-10" v-if="api.resp.response.pagination.prev">
                <button class="pagination__link" @click="api.examples.datasetImagesOpt.pagAction='init'; httpsCallable(api.resp.function)">
                  <ChevronsLeftIcon class="w-6 h-6 text-theme-10" />
                </button>
              </li>
              <li class="w-10" v-if="api.resp.response.pagination.prev">
                <button class="pagination__link" @click="api.examples.datasetImagesOpt.pagAction='prev'; httpsCallable(api.resp.function)">
                  <ChevronLeftIcon class="w-6 h-6 text-theme-10" />
                </button>
              </li>
              <li>
                  <span class="pagination__link cursor-default" v-if="api.resp.response.pagination.pages>2 && (api.resp.response.pagination.currentPage+1)>2">
                    <span class=" text-theme-10">...</span>
                  </span>
              </li>
              <li v-for="(n) in api.resp.response.pagination.pages" :key="n" class="mt-1">
                  <a class="pagination__link pl-0 pr-0 cursor-default" :class="n==(api.resp.response.pagination.currentPage+1) ? 'bg-theme-10' : ''"
                  v-if="n<((api.resp.response.pagination.currentPage+1)+2) && n>((api.resp.response.pagination.currentPage+1)-2)"><span class="text-white">{{ n }}</span></a>
              </li>
              <li>
                <span class="pagination__link cursor-default text-theme-10" v-if="api.resp.response.pagination.pages>2 && (api.resp.response.pagination.currentPage+1)<(api.resp.response.pagination.pages-1)">
                  <span class=" text-theme-10">...</span>
                </span>
              </li>
              <li class="w-10" v-if="api.resp.response.pagination.next">
                <button class="pagination__link" @click="api.examples.datasetImagesOpt.pagAction='next'; httpsCallable(api.resp.function)"><ChevronRightIcon class="w-6 h-6 text-theme-10" /></button>
              </li>
              <li class="w-10" v-if="api.resp.response.pagination.next && api.resp.response.pagination.toend">
                <a class="pagination__link" @click="api.examples.datasetImagesOpt.pagAction='end'; httpsCallable(api.resp.function)"><ChevronsRightIcon class="w-4 h-4 text-theme-10" /></a>
              </li>
            </ul>
          </div>
          <!-- render -->
          <div v-if="api.resp.response && api.resp.response.render" class="pb-5">
            <div v-html="api.resp.response.render"></div>
          </div>
          <!-- response  -->
          <pre class="preConsole" style="padding-bottom: 400px;">{{api.resp.response}}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeMount, computed, watch} from "vue"; //provide
import cash from "cash-dom";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
//import { _firebase as $_firebase } from "@/model/firebase";
import * as rosepetalModel2 from "rosepetal-model";
import { useI18n } from 'vue-i18n'
import { fabric } from 'fabric'

var $_firebase = rosepetalModel2._firebase;

const rosepetalModel = require("rosepetal-model");

/*import { dataset as $dataset } from "@/model/db/dataset";
import { model as $model } from "@/model/db/model";
import { other as $other } from "@/model/db/other";
import { helper as $h } from "@/model/db/helper";
import { event as $event } from "@/model/db/event";
import { image as $image } from "@/model/db/image";
import { aws as $aws } from "@/model/db/aws";
import { project as $project } from "@/model/db/project";
import { controller as $controller } from "@/model/db/controller";
import { vertex as $vertex } from "@/model/db/vertex";*/

import DropzoneComponent  from "@/components/dropzone/Main.vue";

const functions = $_firebase.functions();

import Slider from '@vueform/slider'

export default defineComponent({
  created () {
    this.helper     = rosepetalModel.helper
  }, 
  components: {
      Slider,
      DropzoneComponent
  },
  setup() {

    const router      = useRouter();
    const store       = useStore();
    const User        = computed(() => store.state.main.User);
    const root        = ref(false);
    const {t}         = useI18n(); 
    const exampleData = ref({})

    const layout      = ref({
                             tabs: [
                                     { id: 'project',    title: t('Quickstart'),  root: true }, 
                                     { id: 'functions',  title: t('Resources'),   root: true },
                                     { id: 'log',        title: t('Log'),         root: true }
                                   ],
                              curTab: 'project',
                              resource: { model: false, source: 'all', method: false },
                              modalObj: false,
                            })

    const api         = ref({
                            examples:    {},
                            functions:   functions,
                            logType:     'all',
                            logEvent:    {
                                          loading:    false,
                                          isCritical: false,
                                          current:    false,
                                          types:      {},
                                          selectedType: 'all',
                                          selected:   []
                                        },
                            log:         [],
                            console:     { dragging: false, modelsFunctions: rosepetalModel.other.getApiMethods({ models: true }), lastTime: 0, lastPath: false },  
                            respLoading: false,  
                            resp:        {},
                            canvas:      {},
                            methods:     rosepetalModel.other.getApiMethods()
                          })    
    const viewTab           = async (t) => { layout.value.curTab = t; };
    const isSelected        = (item, value) => { if(item && item.toLowerCase()==value.toLowerCase())return 'text-yellow-500' } ;
    const isTabSel          = (item, value) => { if(item==value)return 'bg-gray-900' };
    const functionDetails   = async (f) => { for (var i = 0; i < Object.keys(api.value.methods).length; i++) { if(f.id==api.value.methods[i].id)api.value.methods[i].visible = true } }
    const isCritical        = (t) => {  if(t)return "bg-theme-24 text-white hover:bg-red-900"  };
    const consoleFullScreen = async () => { cash('.response_console,.log_console').css("top","0").css("margin-top","0");  }

    const initExampleData   = async () => { 
        api.value.examples  = { 
                                datasetType:        "all", 
                                datasetTrained:     "all", 
                                datasetId:          "", 
                                datasetNewType:     "MULTICLASS", 
                                datasetDescription: "", 
                                datasetCsv:         { validation: 0, test: 0 },
                                datasetImagesOpt:   { objDivision: 'all', objtagsType: 'all', objByTag: 'all', pagination: true, perPage: 12 },
                                imageId:            "", 
                                imageUrl:           "", 
                                previewOpt:         { storage: false, size: "auto", height: 200, width: 200, tags: true },
                                modelId:            "", 
                                controllerNav:      "",
                                importZip:          {},
                                aws:                { projectName: "" }
                              } 
    }

    const getExampleData    = async () => { 
      exampleData.value.controllerNavMenu = rosepetalModel.controller.getNavMenu({ pageList : true})
      exampleData.value.datasets = await rosepetalModel.dataset.list() 
      exampleData.value.dataset  = api.value.examples.datasetId ? await rosepetalModel.dataset.get(api.value.examples.datasetId) : {}
      exampleData.value.tags     = api.value.examples.datasetId ? await rosepetalModel.dataset.getTags(api.value.examples.datasetId,false) : {}
      exampleData.value.images   = await rosepetalModel.image.randomImages({ limit: 50 })
      exampleData.value.models   = await rosepetalModel.model.list()
      exampleData.value.aws      = {
                                    projects: await rosepetalModel.aws.listProjects()
                                   }
    }

    const httpsCallable = async (func) => {
     if(!api.value.examples.datasetImagesOpt.pagAction)api.value.resp = {}
     api.value.resp.function    = func
     api.value.console.lastTime = 0
     var start                  = Date.now();
     if(cash('.response_console').css("top")!='0px')cash('.response_console').css("top","50%"); 
     let opt = {}
     if(api.value.examples.datasetId)opt.datasetId = api.value.examples.datasetId
     if(api.value.examples.datasetType && api.value.examples.datasetType!='all')opt.type = api.value.examples.datasetType 
     if(api.value.examples.datasetTrained && api.value.examples.datasetTrained!='all')opt.trained  = api.value.examples.datasetTrained
     if(func.source && func.source=='model'){ //local qry
       api.value.respLoading   = true
       if(func.model=="dataset"){
        if(func.id=="list")api.value.resp.response = await rosepetalModel.dataset.list(opt)
        if(func.id=="get" && opt.datasetId)api.value.resp.response         = await rosepetalModel.dataset.get(opt.datasetId)
        if(func.id=="get-models" && opt.datasetId)api.value.resp.response  = await rosepetalModel.dataset.getModels(opt.datasetId)
        if(func.id=="get-images" && opt.datasetId){
          let imgOpt = { datasetID: opt.datasetId }
          if(api.value.examples.datasetImagesOpt.objDivision!='all')imgOpt.objDivision = api.value.examples.datasetImagesOpt.objDivision
          if(api.value.examples.datasetImagesOpt.objtagsType!='all')imgOpt.objtagsType = api.value.examples.datasetImagesOpt.objtagsType
          if(api.value.examples.datasetImagesOpt.objByTag!='all')imgOpt.objByTag = api.value.examples.datasetImagesOpt.objByTag
          if(api.value.examples.datasetImagesOpt.pagination)imgOpt.pagination = api.value.examples.datasetImagesOpt.pagination
          imgOpt.perPage = api.value.examples.datasetImagesOpt.perPage
          if(api.value.resp.response && api.value.resp.response.pagination)imgOpt.paginationQry = api.value.resp.response.pagination
          if(api.value.examples.datasetImagesOpt.pagAction)imgOpt.action = api.value.examples.datasetImagesOpt.pagAction
          if (imgOpt.objDivision && imgOpt.objDivision != 'all' && imgOpt.pagination)imgOpt.objDivision = api.value.examples.datasetImagesOpt.objDivision = 'all' //pagination only whithout set
          api.value.resp.response  = await rosepetalModel.dataset.getImages(imgOpt) 
        }
        if(func.id=="get-tags" && opt.datasetId)api.value.resp.response         = await rosepetalModel.dataset.getTags(opt.datasetId)
        if(func.id=="get-tags-counter" && opt.datasetId)api.value.resp.response = await rosepetalModel.dataset.getTagsCounter(opt.datasetId)
        if(func.id=="delete" && opt.datasetId)api.value.resp.response           = await rosepetalModel.dataset.delete(opt.datasetId)
        if(func.id=="create-dataset" && api.value.examples.datasetNewName && api.value.examples.datasetNewType){
          api.value.resp.response = await rosepetalModel.dataset.create({ name: api.value.examples.datasetNewName, type: api.value.examples.datasetNewType, description: api.value.examples.datasetDescription })
        }
        if(func.id=="create-dataset-vertex" && opt.datasetId)api.value.resp.response  = await rosepetalModel.dataset.createVertex(opt.datasetId)
        if(func.id=="create-dataset-aws" && opt.datasetId)api.value.resp.response     = await rosepetalModel.dataset.createAws(opt.datasetId)
        if(func.id=="datadivision" && opt.datasetId)api.value.resp.response           = await rosepetalModel.dataset.getDataDivision(opt.datasetId, api.value.examples.divisionOnlyLabeled ? true : false)
        if(func.id=="set-random-datadivision" && opt.datasetId)api.value.resp.response = await rosepetalModel.dataset.setRandDataDivision(opt.datasetId, api.value.examples.divisionOnlyLabeled ? true : false)
        if(func.id=="validate" && opt.datasetId)api.value.resp.response               = await rosepetalModel.dataset.validateToTrain(opt.datasetId)
        if(func.id=="get-status" && opt.datasetId)api.value.resp.response             = await rosepetalModel.dataset.getStatus(opt.datasetId)
        if(func.id=="dataset-download" && opt.datasetId)api.value.resp.response       = await rosepetalModel.dataset.downloadZip(opt.datasetId)
        if(func.id=="dataset-import-vertex" && opt.datasetId)api.value.resp.response  = await rosepetalModel.dataset.uploadStorage(opt.datasetId)
        if(func.id=="dataset-upload-s3" && opt.datasetId)api.value.resp.response      = await rosepetalModel.dataset.uploadS3(opt.datasetId)
        if(func.id=="upload-dataset-zip"){if(opt.datasetId)api.value.resp.response     = await rosepetalModel.dataset.uploadZip(opt.datasetId)
        }
        if(func.id=="dataset-normalAnomaly" && opt.datasetId)api.value.resp.response  = await rosepetalModel.dataset.normalAnomaly(opt.datasetId)
        if(func.id=="dataset-getvertex" && opt.datasetId)api.value.resp.response      = await rosepetalModel.dataset.getVertex(opt.datasetId)
        if(func.id=="dataset-getaws" && opt.datasetId)api.value.resp.response         = await rosepetalModel.dataset.getAws(opt.datasetId)
        if(func.id=="get-dataset-last-log" && opt.datasetId)api.value.resp.response   = await rosepetalModel.dataset.getLastLog(opt.datasetId)
        if(func.id=="get-dataset-preview" && opt.datasetId)api.value.resp.response    = await rosepetalModel.dataset.getPreview(opt.datasetId)
       }

       if(func.model=="model"){
        if(func.id=="model-list")api.value.resp.response                                      = await rosepetalModel.model.list()
        if(func.id=="model-get" && api.value.examples.modelId)api.value.resp.response         = await rosepetalModel.model.get(api.value.examples.modelId)
        if(func.id=="model-export-url" && api.value.examples.modelId)api.value.resp.response  = await rosepetalModel.model.getExportUrl(api.value.examples.modelId)
        if(func.id=="model-export" && api.value.examples.modelId)api.value.resp.response      = await rosepetalModel.model.export(api.value.examples.modelId)
        if(func.id=="get-evaluation" && api.value.examples.modelId)api.value.resp.response    = await rosepetalModel.model.getEvaluations(api.value.examples.modelId)
        if(func.id=="render-evaluation" && api.value.examples.modelId)api.value.resp.response = await rosepetalModel.model.renderEvaluations(api.value.examples.modelId, { class: "table table--sm w-full" })
        if(func.id=="model-create" && opt.datasetId)api.value.resp.response                   = await rosepetalModel.model.create(opt.datasetId, "Model 1")
       }

       if(func.model=="event"){
        if(func.id=="get-event")api.value.resp.response         = await rosepetalModel.event.get({ limit: 100 })
        if(func.id=="get-event-types")api.value.resp.response   = await rosepetalModel.event.getEventTypes()
       }

       if(func.model=="image"){
        if(func.id=="get-image" && api.value.examples.imageId)api.value.resp.response               = await rosepetalModel.image.get(api.value.examples.imageId.id)
        if(func.id=="get-storage-uri" && api.value.examples.imageId)api.value.resp.response         = await rosepetalModel.image.getStorageUri(api.value.examples.imageId.id)
        if(func.id=="get-storage-url" && api.value.examples.imageId)api.value.resp.response         = await rosepetalModel.image.getStorageUrl(api.value.examples.imageId.uri)
        if(func.id=="get-image-set" && api.value.examples.imageId)api.value.resp.response           = await rosepetalModel.image.getSet(api.value.examples.imageId.id)
        if(func.id=="get-image-tags" && api.value.examples.imageId)api.value.resp.response          = await rosepetalModel.image.getTags(api.value.examples.imageId.id)
        if(func.id=="set-image-set" && api.value.examples.imageId)api.value.resp.response           = await rosepetalModel.image.setSet(api.value.examples.imageId.id, 'TRAIN')
        if(func.id=="get-image-comments" && api.value.examples.imageId)api.value.resp.response      = await rosepetalModel.image.getComments(api.value.examples.imageId.id)
        if(func.id=="get-image-detect" && api.value.examples.imageId)api.value.resp.response        = await rosepetalModel.image.detect(api.value.examples.imageId.id)
        if(func.id=="get-image-coco-detect" && api.value.examples.imageUrl)api.value.resp.response  = await rosepetalModel.image.detectCoco(api.value.examples.imageUrl)
        if(func.id=="download-image" && api.value.examples.imageId)api.value.resp.response          = await rosepetalModel.image.download(api.value.examples.imageId.id)
        if(func.id=="remove-image-tags" && api.value.examples.imageId)api.value.resp.response       = await rosepetalModel.image.removeTags(api.value.examples.imageId.id)
        if(func.id=="preview-image" && api.value.examples.imageId){
          if(api.value.examples.previewOpt.size=="auto"){ api.value.examples.previewOpt.width = api.value.examples.previewOpt.height = "" }
          api.value.resp.response = await rosepetalModel.image.preview(api.value.examples.imageId.id, api.value.examples.previewOpt)
          if(api.value.resp.response.canvas)api.value.resp.canvas = api.value.resp.response.canvas
        }
       }
       
       if(func.model=="controller"){
        if(func.id=="get-menu-controller")api.value.resp.response        = rosepetalModel.controller.getNavMenu()
        if(func.id=="get-node-config-controller")api.value.resp.response = rosepetalModel.controller.getNodeConfig()
        if(func.id=="load-page-controller" && api.value.examples.controllerNav)api.value.resp.response = await rosepetalModel.controller.loadPage(api.value.examples.controllerNav)
        if(func.id=="get-status-controller")api.value.resp.response      = await rosepetalModel.controller.getStatus() 
       }

       if(func.model=="aws"){
        if(func.id=="aws-get-config")api.value.resp.response            = rosepetalModel.aws.getConfig()
        if(func.id=="aws-get-bucket-cors")api.value.resp.response       = await rosepetalModel.aws.getBucketCors()
        if(func.id=="aws-get-bucket-acl")api.value.resp.response        = await rosepetalModel.aws.getBucketAcl()
        if(func.id=="aws-bucket-list-objects")api.value.resp.response   = await rosepetalModel.aws.getBucketObjects()
        if(func.id=="aws-uploads3")api.value.resp.response              = await rosepetalModel.aws.uploadS3() 
        if(func.id=="aws-list-projects")api.value.resp.response         = await rosepetalModel.aws.listProjects()
        if(func.id=="aws-create-dataset")api.value.resp.response        = await rosepetalModel.aws.createDataset()
        if(func.id=="aws-describe-dataset" && api.value.examples.aws.projectName)api.value.resp.response      = await rosepetalModel.aws.getDataset(api.value.examples.aws.projectName)
        if(func.id=="aws-describe-project" && api.value.examples.aws.projectName)api.value.resp.response      = await rosepetalModel.aws.getProject(api.value.examples.aws.projectName)
        if(func.id=="aws-list-models" && api.value.examples.aws.projectName)api.value.resp.response           = await rosepetalModel.aws.listModels(api.value.examples.aws.projectName)
        if(func.id=="aws-get-object")api.value.resp.response            = await rosepetalModel.aws.getObject("projects/rosepetal-dev/datasets/rosepetal-dev-untitled_v2mc-1669758592434/anomaly/ROTA_20.png", true)
        if(func.id=="aws-get-object-attributes")api.value.resp.response = await rosepetalModel.aws.getObjectAttributes("projects/rosepetal-dev/datasets/rosepetal-dev-untitled_v2mc-1669758592434/anomaly/ROTA_20.png")
        if(func.id=="aws-get-model" && api.value.examples.aws.projectName)api.value.resp.response               = await rosepetalModel.aws.getModel(api.value.examples.aws.projectName)
        if(func.id=="aws-list-dataset-entries" && api.value.examples.aws.projectName)api.value.resp.response    = await rosepetalModel.aws.getDatasetEntries(api.value.examples.aws.projectName)
        if(func.id=="aws-get-model-pakaging-jobs" && api.value.examples.aws.projectName)api.value.resp.response = await rosepetalModel.aws.getModelPackagingJobs(api.value.examples.aws.projectName)
        if(func.id=="aws-get-model-pakaging-job" && api.value.examples.aws.projectName){
          let proJobs = await rosepetalModel.aws.getModelPackagingJobs(api.value.examples.aws.projectName)
          if(proJobs.response.ModelPackagingJobs && proJobs.response.ModelPackagingJobs[0])api.value.resp.response  = await rosepetalModel.aws.getModelPackagingJob(api.value.examples.aws.projectName, proJobs.response.ModelPackagingJobs[0].JobName)
        }
        if(func.id=="aws-create-model" && api.value.examples.aws.projectName)api.value.resp.response            = await rosepetalModel.aws.createModel(api.value.examples.aws.projectName)
        if(func.id=="aws-get-model-evaluation" && api.value.examples.aws.projectName)api.value.resp.response    = await rosepetalModel.aws.getEvaluation(api.value.examples.aws.projectName)
        if(func.id=="aws-list-deployments" && api.value.examples.aws.projectName)api.value.resp.response        = await rosepetalModel.aws.listDeployments(api.value.examples.aws.projectName)
       }

       if(func.model=="project"){
        if(func.id=="get-config")api.value.resp.response          = await rosepetalModel.project.getConfig()
        //if(func.id=="get-project-users")api.value.resp.response   = await rosepetalModel.project.getUsers()
       }

       if(func.model=="vertex"){
        if(func.id=="get-vertex-config")api.value.resp.response = rosepetalModel.vertex.getConfig()
       }

       if(func.model=="other"){
        if(func.id=="get-icons")api.value.resp.response = rosepetalModel.other.getIcons()
       }
       
       api.value.examples.datasetImagesOpt.pagAction = false
       api.value.respLoading   = false
     }else{ //Api qry
      let apiCall = false
      if(func.id=="csv"){
        if(opt.datasetId){
          apiCall = func.path.replace(/DATASETID/g, opt.datasetId)
          if(api.value.examples.datasetCsv.test || api.value.examples.datasetCsv.validation)apiCall += "?"
          if(api.value.examples.datasetCsv.test)apiCall += "test="+ api.value.examples.datasetCsv.test
          if(api.value.examples.datasetCsv.validation){
            apiCall += api.value.examples.datasetCsv.test ? "&" : ""
            apiCall += "validation="+ api.value.examples.datasetCsv.validation
          }
        }
      }else if(func.id=="get-operation"){
        let lastOperation = await rosepetalModel.event.getLastOperation()
        apiCall = func.path.replace(/OPERATION_NAME/g, lastOperation.replace(/\//g, "--") )
      }else if(func.id=="get-pipeline"){
        let lastPipeline = await rosepetalModel.event.getLastPipeline()
        apiCall = func.path.replace(/PIPELINE_NAME/g, lastPipeline.replace(/\//g, "--") )
        //apiCall = func.path.replace(/PIPELINE_NAME/g, "projects--785421993051--locations--us-central1--trainingPipelines--7255420083411877888" )
      }else if(func.id=="get-imageb64" || func.id=="get-image-vision-detect"){ 
        if(exampleData.value.images && exampleData.value.images.length)apiCall = func.path.replace(/GS_URI/g, exampleData.value.images[Math.floor(Math.random() * ((exampleData.value.images.length-1) - 0 + 1) + 0)].uri.replace(/\//g, "--"))
      }else if(func.id=="get-vertex-dataset" || func.id=="get-vertex-dataitems" || func.id=="get-vertex-dataset-annotation-set-list"){ 
        let vd  = await rosepetalModel.dataset.list({ vertex: true })
        if(vd[0]){ apiCall = func.path.replace(/AUTOMLID/g, vd[Math.floor(Math.random() * ((vd.length-1) - 0 + 1) + 0)].automl)
        }else{ api.value.resp.response = "not found vertex dataset" }
      }else if(func.id=="get-vertex-model"){ 
        let vm = await rosepetalModel.model.list({ vertex: true })
        if(vm[0])apiCall = func.path.replace(/MODELID/g, vm[0].automl)
      }else if(func.id=="get-vertex-dataitem-annotation"){ 
        let vd    = await rosepetalModel.dataset.list({ vertex: true })
        if(vd[0]){
          let _r  = await rosepetalModel.other.httpsCallable('api/model/dataitems/dataset_id/' + vd[Math.floor(Math.random() * ((vd.length-1) - 0 + 1) + 0)].automl)
          if(!_r.error && _r.data){
            if(Object.keys(_r.data).length) apiCall = func.path.replace(/DATAITEM_ID/g, _r.data[0].name.replace(/\//g, "_"))
            else api.value.resp.response = "not dataitem, retry run"
          }else{ api.value.resp.response = _r.error }  
        }else{ api.value.resp.response = "not found vertex dataset" }
      }else if(func.id=="model-export-api"){
        let vm = await rosepetalModel.model.list({ vertex: true })
        if(vm[0]){ let apiUrl = await rosepetalModel.model.getExportUrl(vm[0].id); apiCall = apiUrl['api_url']  }
      }else if(func.id=="get-model-evaluations"){
        let vd = await rosepetalModel.model.list({ vertex: true })
        if(vd[0]){ apiCall = func.path.replace(/AUTOMLID/g, vd[Math.floor(Math.random() * ((vd.length-1) - 0 + 1) + 0)].automl)
        }else{ api.value.resp.response = "not found vertex model" }
      }else if(func.id=="get-model-evaluation" || func.id=="get-model-evaluation-slices"){
        let vd = await rosepetalModel.model.list({ vertex: true })
        if(vd[0]){ 
          let eva = await rosepetalModel.model.getEvaluations(vd[Math.floor(Math.random() * ((vd.length-1) - 0 + 1) + 0)].id)
          if(eva.evaluations.length)apiCall = func.path.replace(/EVALUATION_NAME/g, eva.evaluations[0].name.replace(/\//g, "_")  )
        }else{ api.value.resp.response = "not found evaluation" }
      }else{
        apiCall = func.path
      }
      if(apiCall){
        api.value.console.lastPath = apiCall
        api.value.respLoading      = true
        let req                    = await rosepetalModel.other.httpsCallable(apiCall)
        api.value.resp.response    = req.error ? req.error : req.data 
        api.value.respLoading      = false
      }
     }
     var end  = Date.now();
     api.value.console.lastTime = end - start
     getExampleData()
    }

    const resizeConsole = async () => {
      api.value.console.dragging = true;
      let main      = cash('.response_console');
      let dragbar   = cash("#dragbar");
      let ghostbar  = '<div id="ghostbar" '
        ghostbar     += 'style="height: '+dragbar.outerHeight()+'px;'
        ghostbar     += 'width: '+dragbar.outerWidth()+'px;'
        ghostbar     += 'top: '+main.offset().top+'px;'
        ghostbar     += 'bottom: '+main.offset().bottom+'px;'
        ghostbar     += '">'
      cash(ghostbar).appendTo('body');
    }
    
    const onMouseMove = async (e) => { if(api.value.console.dragging)cash('#ghostbar').css("top",e.pageY+2); } 

    const onMouseUp = async (e) => {
      if(api.value.console.dragging){
        cash('.response_console').css("top",e.pageY+2);
        cash('#ghostbar').remove();
        api.value.console.dragging = false;
      }
    } 

    const modal       = async (m, obj = false) => { cash("#"+m).modal("show"); cash(".main").removeClass("overflow-y-hidden"); if(obj)layout.value.modalObj = obj }  

    const deleteLog   = async () => { 
      if(layout.value.modalObj.id){
        rosepetalModel.event.deleteEvent(layout.value.modalObj.id); cash("#modal-log-delete").modal("hide"); await getLog(); 
        layout.value.modalObj = false
      }else{
        if(api.value.logEvent.selected.length){
          for (const slog of api.value.logEvent.selected) {
            rosepetalModel.event.deleteEvent(slog);
          }  
          cash("#modal-log-delete").modal("hide"); 
          await getLog(); 
        }
      }
    }   

    const getLog = async () => {
      api.value.logEvent.loading = true
      api.value.logEvent.selected = []; 
      api.value.log = []
      let logParams = {}
      switch(api.value.logType){
        case 'all': logParams.type = "all"
                    break;
        case 'api': logParams.api = true
                    break;
        case 'ui':  logParams.ui  = true
                    break;
        default: break;
      }
      if(api.value.logEvent.selectedType!='all')logParams.type  = api.value.logEvent.selectedType
      if(api.value.logEvent.isCritical)logParams.isCritical = true
      api.value.log = await rosepetalModel.event.get(logParams)
      api.value.logEvent.types = {}; 
      for (var i = 0; i < Object.keys(api.value.log).length; i++) {
        if(!api.value.logEvent.types[api.value.log[i].name]){ api.value.logEvent.types[api.value.log[i].name]= 1
        }else{ api.value.logEvent.types[api.value.log[i].name]++; }
      }
      if(Object.keys(api.value.logEvent.types).length)api.value.logEvent.types = rosepetalModel.helper.orderParams(api.value.logEvent.types)
      api.value.logEvent.loading = false
    }

    const checkLogItem  = async (s = false) => {
      if(!s){
        api.value.logEvent.selected = [];  
        api.value.log.forEach(function (l) {  api.value.logEvent.selected.push(l.id); });
      }else{
        let exists = false
        api.value.logEvent.selected.findIndex(function(m, index) { if(m == s){ exists = true; if (index > -1)api.value.logEvent.selected.splice(index, 1); } });
        if(!exists)api.value.logEvent.selected.push(s)
      }
    }

    onBeforeMount( () => {  cash("body").removeClass('main').addClass("DocLayout"); });

    onMounted(async () => {
      let host = rosepetalModel._firebase.getApiHost(); api.value.url = host.host 
      await initExampleData();
      await getLog();
      /*console resize*/
      window.addEventListener("mousemove", async function (event) {if (event.defaultPrevented)return; onMouseMove(event) }, true);
      window.addEventListener("mouseup", async function (event) {  if (event.defaultPrevented)return; onMouseUp(event) }, true);
      if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true; 
    });

    watch( () => api.value.resp.canvas , (value) => {  
      if(value){
        cash("#apiCanvasBox").html('<canvas id="fjsip"></canvas>')
        let preview = new fabric.Canvas("fjsip", api.value.resp.canvas.options); preview.clear()
        fabric.Image.fromURL(api.value.resp.canvas.image, function (img) { 
          preview.setWidth(api.value.resp.canvas.size.width);
          preview.setHeight(api.value.resp.canvas.size.height); 
          preview.setBackgroundImage(img, preview.renderAll.bind(preview), { scaleX: preview.width / img.width, scaleY: preview.height / img.height });
          for (var t = 0; t < api.value.resp.canvas.objects.bbox.length; t++) { if(api.value.resp.canvas.objects.bbox[t])preview.add(api.value.resp.canvas.objects.bbox[t]); }
          for (var b = 0; b < api.value.resp.canvas.objects.text.length; b++) { if(api.value.resp.canvas.objects.text[b])preview.add(api.value.resp.canvas.objects.text[b]); }
          preview.renderAll()  
        });
        if(api.value.resp.canvas.size){
          api.value.examples.previewOpt.width  =  api.value.resp.canvas.size.width
          api.value.examples.previewOpt.height  =  api.value.resp.canvas.size.height
        }   
      }
    });

    watch( () => layout.value.resource.model  , () => {  api.value.methods = rosepetalModel.other.getApiMethods(layout.value.resource); layout.value.resource.source = 'all'; layout.value.resource.method = false; });
    watch( () => layout.value.resource.source , () => {  api.value.methods = rosepetalModel.other.getApiMethods(layout.value.resource); layout.value.resource.method = false; });
    watch( () => layout.value.resource.method , () => {  api.value.methods = rosepetalModel.other.getApiMethods(layout.value.resource) });
    watch( () => store.state.main.User.role, () => { if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;  });
    const linkTo = (page) => { console.log(page); cash("body").removeClass("DocLayout").addClass("main"); if(page=='home')router.replace('/'); router.push({ name: page }); };

    return {
      layout,
      viewTab,
      isTabSel,
      linkTo,
      isSelected,
      modal,
      User,
      api,
      httpsCallable,
      resizeConsole,
      consoleFullScreen,
      functionDetails,
      isCritical,
      getLog,
      exampleData,
      initExampleData,
      deleteLog,
      checkLogItem,
      root
    };

  }
});
</script>
<style>
.response_console{font-size: 13px; line-height: 16px;background-color: #1a202c;color: #fff;padding: 0x;position: fixed;top: 50%;left: 0;bottom: 0;z-index: 99999;width: 100%;border: 2px solid black;height: 100%;}
.log_console{font-size: 13px; line-height: 16px;background-color: #1a202c;color: #fff;padding: 0x;position: fixed;top: 50%;left: 0;bottom: 0;z-index: 99999;width: 100%;border: 2px solid black;height: 100%;}
#dragbar{background-color:black;height:5px;float: left;width: 100%;cursor: row-resize;}
#ghostbar{width:3px;background-color: #000;opacity:0.5;position:absolute;cursor: row-resize;z-index:99999999;padding: 0;margin: 0;}
.DocLayout{ color: #cbd5e0; background-color: rgba(45, 55, 72);  }
.method_code{max-width: 600px; font-style: italic !important;}
#apiCanvasBox{max-height: 280px;overflow: scroll;} /*pointer-events:none !important;*/
.preConsole{white-space: pre-wrap;}
</style>