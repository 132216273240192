<template>
    <div class="grid grid-cols-12 gap-6">
      reresdafds
    </div>
  </template>
  <script>


  import { defineComponent, onMounted } from "vue";

   //mport * as inc from "inc";

 import * as rosepetalModel from "rosepetal-model";
  //const rosepetalModel = require("rosepetal-model");

  export default defineComponent({
    setup() {
      

      onMounted(async () => {
        console.log('dins model')
        console.log(rosepetalModel)
        rosepetalModel.other.hi()
        console.log(await rosepetalModel.model.list())
        rosepetalModel.other.consoleLog(rosepetalModel.helper.StringtoHex('test'))
        rosepetalModel.other.consoleLog(await rosepetalModel.event.getLastOperation())
        console.log(await rosepetalModel.dataset.list())
        console.log(await rosepetalModel.controller.getStatus())

        //console.log(rosepetalModel.test)
        /*rosepetalModel.other.hi()
        rosepetalModel._firebase.existInParentProject()
        rosepetalModel.other.consoleLog(rosepetalModel._firebase.getConfig())
        rosepetalModel.other.consoleLog("api host "+ rosepetalModel.helper.capitalize('test'))*/
      });
  
      return {
        
      };
    }
  });
  </script>
  <style>
  </style>