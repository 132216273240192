<template>
  <div class="grid grid-cols-12 gap-3 mt-2">

    <!-- Top nav -->
    <div class="intro-y col-span-12 mb-3">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <div class="inline-block text-2xl font-medium mr-4">{{$t('Dataset')}}</div>
        <Tippy tag="a" href="/dev" class="tooltip btn px-2 box text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Back" :options="{ theme: 'light'}">
          <span class="w-5 h-5 flex items-center justify-center"><CornerUpLeftIcon class="w-4 h-4" /></span>
        </Tippy>
        <Tippy v-if="layout.dataset.id" 
          tag="a" href="javascript:;" class="tooltip btn px-2 ml-1 box text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Refresh" :options="{ theme: 'light'}" @click="reload()">
          <span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span>
        </Tippy>
        <div class="mx-auto text-gray-600">
          <div class="col-span-12 intro-y" v-if="layout.reloadMsg">
            <div class="alert alert-dismissible show box bg-theme-6 text-white flex items-center w-100" role="alert">
                <LoadingIcon icon="oval" color="white" class="w-6 h-6 mr-2" /> {{$t('Changes have been detected, updating...')}}
            </div>
          </div>
          <div class="col-span-12 intro-y" v-if="layout.dataset.status?.inProgress && !layout.reloadMsg">
            <div class="alert alert-dismissible show box bg-theme-2 text-white flex items-center w-80" role="alert">
                <LoadingIcon icon="oval" color="white" class="w-6 h-6 mr-2" />{{ this.helper.capitalize(layout.dataset.status?.action) }}, {{$t('please wait...')}}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <XIcon class="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Top nav -->

    <!-- Dataset profile -->
    <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-3 xxl:col-span-3" v-if="layout.dataset.id">
      <div class="box">
        <div class="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200">
          <div class="w-28 h-28 image-fit lg:mr-1 mt-2" v-if="layout.dataset?.preview" @click="layout.action=false"><img :src="layout.dataset?.preview" class="w-full h-full rounded-full zoom-in" /></div>
          <div class="w-28 h-28 image-fit lg:mr-1 mt-2" @click="layout.action=false" v-else><img class="rounded-full border border-gray-300 zoom-in" :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" /></div>
          <div class="lg:ml-3 lg:mr-auto text-center lg:text-left mt-3 mt-5">
            <a class="text-lg font-medium">{{layout.dataset.name.slice(0, 35)}} <span v-if="(layout.dataset.name.length>35)">...</span></a>
            <div class="py-1 border border-gray-400 rounded font-normal mt-1 text-center w-28 text-xs">{{this.helper.capitalize(layout.dataset.typeName.toLowerCase())}} </div>
            <div v-if="layout.dataset?.trained" title="Trained" class="mt-3 cursor-pointer" @click="action('models')">
              <CodesandboxIcon class="w-8 h-8 text-green-600" />
              <span v-if="layout.dataset?.trained && layout.dataset?.models?.count" class="pl-1">x {{layout.dataset.models.count}}</span>
            </div> 
          </div>
          <div class="absolute right-0 top-0 mr-5 mt-3 dropdown">
            <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"><MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300"/></a>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box p-2">
                <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md cursor-pointer" @click="action('log')" data-dismiss="dropdown">
                  <ActivityIcon class="w-4 h-4 mr-2" /> {{ $t('Activity') }}
                </a>
                <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md cursor-pointer" @click="action('copy')" data-dismiss="dropdown">
                  <CopyIcon class="w-4 h-4 mr-2" /> {{ $t('Copy') }}
                </a>
                <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-red-200 rounded-md cursor-pointer" @click="action('delete')" data-dismiss="dropdown">
                  <TrashIcon class="w-4 h-4 mr-2" /> {{ $t('Delete') }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="text-xs text-gray-700 px-5 mt-5">
          <span v-if="layout.dataset.type=='imageObjectDetection'">{{$t('This data set is based on the classification of image by object detection')}}. </span> 
          <span v-else>{{$t('This dataset is based on the Image classification with a single label')}}. </span>
          <span v-if="layout.dataset.tagsCounter?.tags && Object.keys(layout.dataset.tagsCounter?.tags).length>1"> {{$t('Contains')}} {{(Object.keys(layout.dataset.tagsCounter?.tags).length-1)}} {{$t('classification tags and')}} {{layout.dataset.tagsCounter?.count}} {{$t('images')}}, 
            {{$t('of which')}} {{layout.dataset.tagsCounter?.labeled}} {{$t('are labeled')}}<span v-if="layout.dataset.tagsCounter?.nolabel" class="pl-1"> {{$t('and')}} {{layout.dataset.tagsCounter?.nolabel}} {{$t('are unclassified')}}<span>. {{$t('Remember that unclassified images will not be used for training')}}</span></span>.
          </span>
          <span v-if="layout.dataset.tagsCounter?.tagslabeled && Object.keys(layout.dataset.tagsCounter?.tagslabeled).length"> {{$t('Contains')}} {{(Object.keys(layout.dataset.tagsCounter?.tagslabeled).length)}} {{$t('classification tags and')}} {{layout.dataset.tagsCounter?.count}} {{$t('images')}}, 
            {{$t('of which')}} {{layout.dataset.tagsCounter?.labeled}} {{$t('are labeled')}}<span v-if="layout.dataset.tagsCounter?.nolabel" class="pl-1"> {{$t('and')}} {{layout.dataset.tagsCounter?.nolabel}} {{$t('are unclassified')}}<span>. {{$t('Remember that unclassified images will not be used for training')}}</span></span>.
          </span>
          <div v-if="layout.dataset?.trained && layout.dataset?.models?.count" class="mt-2">
            {{$t('The set is trained')}} {{$t('and')}} {{layout.dataset?.models?.count}} {{$t('prediction models have been generated')}}.
          </div>
        </div>
        <div class="text-xs text-gray-700 px-5 mt-5 font-medium">{{$t('A brief description of the goal')}}</div>
        <div v-if="layout.dataset.description" class="text-xs text-gray-600 px-5 mt-2">{{layout.dataset.description}}</div> 
        <div v-else class="text-xs text-gray-600 px-5 mt-2">{{$t('Not defined')}}.</div>   
        <div class="input-form ml-5">
          <div class="flex justify-left text-xs mt-3">
            <div class="px-5 py-2 zoom-in border bg-gray-300 text-gray-700 hover:bg-theme-6 hover:text-white cursor-pointer mr-2 display-inline" @click="action('description')">
              <Edit3Icon class="w-3 h-3" /> {{ $t('Edit') }}
            </div>
          </div>
        </div>
        <div v-if="layout.dataset.createdDate" class="text-gray-600 mt-5 p-5 font-italic text-xs border-t border-gray-200">
          {{$t('Created on')}} {{layout.dataset.createdDate}} 
          <span class="text-gray-600 hover:text-blue-500 cursor-pointer pl-1" @click="action('log')" v-if="layout.action!='log'">
            <ActivityIcon class="w-5 h-5 mr-1" />{{ $t('last activity') }}
          </span>
        </div>
      </div>
    </div>
    <div v-else class="intro-y col-span-12 sm:col-span-12">
      <div class="flex items-center justify-center mt-20" v-if="layout.loading">  
        <img :src="require(`@/assets/images/rosepetal/icon/loading2.gif`)" class="w-20" />
      </div>
      <div v-else class="intro-y col-span-12 sm:col-span-12">
        {{ $t('Could not find dataset') }} {{ $route.params.id }}.
      </div>
    </div>
    <!-- /Dataset profile -->

    <div class="col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-9 xxl:col-span-9" style="max-width:1350px;"  v-if="layout.dataset.id">
      <div v-if="layout.action">
         <!-- Dataset action box -->
        <datasetActionsComponent 
          :dataset="layout.dataset.id" 
          :action="layout.action" 
          :options="layout.options" 
          :timestamp="layout.timestamp" 
          ref="datasetActionsComponent" 
          class="mb-5 w-full" />
          <!-- /Dataset action box -->
      </div>
      <div v-else>
        <div class="grid grid-cols-12 gap-3">

          <!-- Validate to train -->
          <div class="col-span-12" v-if="layout.dataset && !layout.action && layout.dataset?.validation && layout.dataset?.tagsCounter && layout.dataset?.tagsCounter?.count">
            <div class="intro-y text-sm">
              <!-- :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/net2.png`)}); background-position: center; background-size: contain; background-repeat: no-repeat; height: 100%;`" -->
              <div class="box p-5 pt-3">
                <div class="flex items-center mt-3">
                    <div class="font-medium text-xl align-top">
                      <span v-if="!layout.dataset?.trained">{{$t('Validate to train')}}2</span>
                      <span v-else>{{$t('Dataset is trained')}} <span v-if="layout.dataset?.trained && layout.dataset?.models?.count">{{$t('and has')}} {{layout.dataset.models.count}} {{$t('associated models')}}</span></span>
                    </div>
                    <div class="text-xs border border-green-600 text-green-600 px-5 py-3 rounded-md ml-auto mr-2" v-if="layout.dataset?.validation?.validated"><ThumbsUpIcon class="w-4 h-4 mr-2"/><span class="align-middle">{{$t('VALIDATED')}}</span></div>
                    <div class="text-xs bg-gray-400 text-gray-600 px-5 py-3 rounded-md ml-auto mr-2" v-else><ThumbsDownIcon class="w-4 h-4 mr-2"/><span class="align-middle">{{$t('NOT VALIDATED')}}</span></div>
                    <div v-if="layout.dataset?.trained" class="text-xs bg-blue-500 px-5 py-3 rounded-md text-white cursor-pointer zoom-in shover:bg-blue-500 mr-2" @click="action('models')">
                      <CodesandboxIcon class="w-4 h-4 mr-2"/><span class="align-middle">{{$t('MODELS')}}</span>
                    </div>
                    <div v-if="!layout.dataset.validation?.validated" class="text-xs bg-blue-500 zoom-in px-5 py-3 rounded-md text-white cursor-pointer hover:bg-green-600 mr-3" @click="action('train')"><ListIcon class="w-4 h-4 mr-2"/><span class="align-middle">{{$t('FULL REPORT')}}</span></div>
                    <div v-else class="text-xs bg-blue-900 px-5 py-3 rounded-md text-white zoom-in cursor-pointer mr-3" @click="action('train')"><PlayIcon class="w-4 h-4 mr-2"/><span class="align-middle">{{$t('TRAIN A NEW MODEL')}}</span></div>
                </div>
                <div v-if="layout.dataset?.validation?.validated">
                  {{$t('Now the dataset is ready to be trained')}}<span v-if="layout.dataset.validation?.validated" class="pl-0.5"> {{$t('with')}} {{layout.dataset.validation?.trainingImages?.count}} {{$t('labeled images for training')}}</span>.
                </div> 
                <div v-else>{{$t('Now the dataset cannot be training, check requirements and warnings for train')}}.</div> 
                <div class="tab-content text-gray-700 mt-3">
                  <div v-if="!layout.dataset.validation?.validated">
                    <span class="pr-3 text-red-400" v-if="layout.dataset.validation?.errors.length">
                      <AlertCircleIcon class="w-4 h-4" /> {{layout.dataset.validation?.errors.length}} {{$t('Requirements')}}
                    </span>
                    <span v-if="!layout.dataset.validation?.warnings.length">
                      <AlertTriangleIcon class="w-4 h-4" /> {{layout.dataset.validation?.warnings.length}} {{$t('Warnings')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Validate to train -->

          <!-- Graph Tags -->  
          <div class="col-span-4" v-if="layout.dataset && !layout.action">
            <div class="intro-y">
              <div class="box p-5 h-96">
                <div class="tab-content">
                  <div class="tab-pane active" role="tabpanel">
                    <div class="text-left cursor-pointer" @click="action('tags')">
                      <div class="text-lg font-medium" v-if="layout.dataset.tagsCounter">
                        {{$t('Tags')}}
                        <span v-if="layout.dataset.tagsCounter.tags && Object.keys(layout.dataset.tagsCounter.tags).length" class="pl-1">({{Object.keys(layout.dataset.tagsCounter.tags).length}})</span>
                        <span v-if="layout.dataset.tagsCounter.tagslabeled && Object.keys(layout.dataset.tagsCounter.tagslabeled).length" class="pl-1">({{Object.keys(layout.dataset.tagsCounter.tagslabeled).length}})</span>
                      </div>
                    </div>
                    <div class="absolute right-0 top-0 mr-5 mt-3 dropdown">
                      <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"><MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300"/></a>
                      <div class="dropdown-menu w-40">
                        <div class="dropdown-menu__content box p-2">
                          <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md cursor-pointer" @click="action('tags')" data-dismiss="dropdown">
                            <TagIcon class="w-4 h-4 mr-2" /> {{ $t('Manage') }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div v-if="layout.dataset.tagsCounter?.tags">
                      <div class="h-64 mb-5 text-xs">
                        <div class="mt-2" v-if="layout.dataset.type=='MULTICLASS' && layout.dataset.tagsCounter?.tags && Object.keys(layout.dataset.tagsCounter.tags).length>1">
                          <div class="flex font-normal mb-3"><span>{{$t('Name')}}</span><span class="ml-auto">{{$t('Images')}}</span></div>
                          <div class="flex items-center" v-for="(t,i,c) in layout.dataset.tagsCounter.tags" :key="t" :class="c<8 ? 'my-2 border-b pb-2' : ''">
                            <div class="w-4 h-4 rounded-full mr-3" :style="'background-color: '+this.helper.StringtoHex(layout.dataset.tagsCounter.names[i]+i)" v-if="c<8"></div>
                            <span class="truncate" v-if="c<8">{{layout.dataset.tagsCounter.names[i]}}</span>
                            <span class="ml-auto" v-if="c<8">{{t}}</span>
                          </div>
                        </div>
                        <div class="mt-2" v-else-if="layout.dataset.type=='imageObjectDetection' && layout.dataset.tagsCounter?.tagslabeled && Object.keys(layout.dataset.tagsCounter.tagslabeled).length">
                          <div class="flex font-normal mb-3"><span>{{$t('Name')}}</span><span class="ml-auto">{{$t('Annotations')}}</span></div>
                          <div class="flex items-center" v-for="(t,i,c) in layout.dataset.tagsCounter.tagslabeled" :key="t" :class="c<8 ? 'my-2 border-b pb-2' : ''">
                            <div class="w-4 h-4 rounded-full mr-3" :style="'background-color: '+this.helper.StringtoHex(layout.dataset.tagsCounter.names[i]+i)" v-if="c<8"></div>
                            <span class="truncate" v-if="c<8">{{layout.dataset.tagsCounter.names[i]}}</span>
                            <span class="ml-auto" v-if="c<8">{{t}}</span>
                          </div>
                        </div>
                        <div class="text-xs mb-5 mt-3 text-gray-700 mb-3" v-else>
                          <div class="mt-2">{{$t('In the training of an artificial intelligence model, these tags are used to teach the model to recognize and classify different objects and features in images')}}.</div>
                          <div class="flex items-center justify-center">  
                            <img :src="require(`@/assets/images/rosepetal/icon/dataset2.png`)" class="w-32" />
                          </div>
                          <div class="mt-2">{{$t('These tags are used to guide the model in its learning process and improve its accuracy in classifying images')}}.</div>
                        </div>
                      </div> 
                    </div>
                    <div v-else>
                      <div class="intro-y col-span-12 lg:col-span-12 flex flex-col items-center mt-20">
                        <div class="flex items-center justify-center"><img :src="require(`@/assets/images/rosepetal/icon/loading2.gif`)" class="w-16" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Graph Tags -->  

          <!-- Graph Images -->  
          <div class="col-span-4" v-if="layout.dataset && !layout.action">
            <div class="intro-y">
              <div class="box p-5 h-96">
                <div class="tab-content">
                  <div class="tab-pane active" role="tabpanel">
                      <div class="text-left"><div class="text-lg font-medium">{{$t('Images')}}</div></div>
                      <div class="absolute right-0 top-0 mr-5 mt-3 dropdown">
                        <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"><MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300"/></a>
                        <div class="dropdown-menu w-40">
                          <div class="dropdown-menu__content box p-2">
                            <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md cursor-pointer" @click="action('labeling')" data-dismiss="dropdown">
                              <ImageIcon class="w-4 h-4 mr-2" /> {{ $t('Labeling') }}
                            </a>
                            <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md cursor-pointer" @click="action('upload')" data-dismiss="dropdown">
                              <UploadCloudIcon class="w-4 h-4 mr-2" /> {{ $t('Upload') }}
                            </a>
                            <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md cursor-pointer" @click="action('download')" data-dismiss="dropdown">
                              <DownloadCloudIcon class="w-4 h-4 mr-2" /> {{ $t('Download') }}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="h-64 mb-5">
                        <div v-if="layout.dataset?.tagsCounter">
                          <div class="relative mt-2" v-if="layout.dataset.tagsCounter?.chart && layout.dataset.tagsCounter.count">
                            <ReportPieChart :height="140" :data="layout.dataset.tagsCounter.chart" /> 
                          </div>
                          <div class="text-xs mb-5 mt-3 text-gray-700 mb-7" v-else>
                            {{$t('A machine learning model will only be as good as the data you provide')}}. <br>
                            <div class="flex items-center justify-center">  
                              <img :src="require(`@/assets/images/rosepetal/icon/photo.png`)" class="w-32" />
                            </div>
                            <div class="font-medium mb-1">{{$t('Required')}}:</div>
                            - {{$t('At least 10 images per tag')}}<br>
                            <div class="font-medium mt-2 mb-1">{{$t('Recommended')}}:</div>
                            - {{$t('At least 100 images per tag')}}<br>
                          </div>
                          <div v-if="layout.dataset.tagsCounter && layout.dataset.tagsCounter.count" class="mt-2">
                            <div class="flex items-center">
                              <div class="w-2 h-2 rounded-full mr-3" :style="'background-color: '+this.helper.StringtoHex('TotalBlack')"></div>
                              <span class="truncate">{{$t('Total')}}</span>
                              <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                              <span class="font-medium xl:ml-auto">
                                <span v-if="layout.dataset.tagsCounter?.count">{{this.helper.formatScore(layout.dataset.tagsCounter.count)}}</span><span v-else>0</span>
                              </span>
                            </div>
                            <div class="flex items-center mt-2">
                              <div class="w-2 h-2 rounded-full mr-3" :style="'background-color: '+this.helper.StringtoHex('LabeledGreen')"></div>
                              <span class="truncate">{{$t('Labeled')}}</span>
                              <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                              <span class="font-medium xl:ml-auto">
                                <span v-if="layout.dataset.tagsCounter?.labeled">{{this.helper.formatScore(layout.dataset.tagsCounter.labeled)}}</span><span v-else>0</span>
                              </span>
                            </div>
                            <div class="flex items-center mt-2">
                              <div class="w-2 h-2 rounded-full mr-3" :style="'background-color: '+this.helper.StringtoHex('UnclassifiedRed')"></div>
                              <span class="truncate">{{$t('Unclassified')}}</span>
                              <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                              <span class="font-medium xl:ml-auto">
                                <span v-if="layout.dataset.tagsCounter.nolabel">{{this.helper.formatScore(layout.dataset.tagsCounter.nolabel)}}</span><span v-else>0</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="intro-y col-span-12 lg:col-span-12 flex flex-col items-center mt-20">
                            <div class="flex items-center justify-center"><img :src="require(`@/assets/images/rosepetal/icon/loading3.gif`)" class="w-16" /></div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Graph Images -->

          <!-- Graph Data Division -->
          <div class="col-span-4" 
            v-if="layout.dataset && !layout.action">
            <div class="intro-y">
              <div class="box p-5 h-96">
                <div class="tab-content">
                  <div class="tab-pane active" role="tabpanel">
                    <div class="h-64 mb-5">
                      <div class="text-lg font-medium">
                        {{$t('Data division')}} 
                        <Tippy tag="a" href="javascript:;" class="tooltip" 
                          content="The validation set to evaluate its performance and tune hyperparameters, and the test set to evaluate the final performance of the model once once you are fully trained and adjusted.This way, we can get an accurate idea of how our model will perform on real data." 
                          :options="{ theme: 'light'}" @click="action('random-data-division')">
                          <HelpCircleIcon class="w-5 h-5 pl-1 cursor-pointer" />
                        </Tippy>
                      </div>
                      <div class="absolute right-0 top-0 mr-5 mt-3 dropdown">
                        <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"><MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300"/></a>
                        <div class="dropdown-menu w-40">
                          <div class="dropdown-menu__content box p-2">
                            <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md cursor-pointer" @click="action('random-data-division')" data-dismiss="dropdown">
                              <PieChartIcon class="w-4 h-4 mr-2" /> {{ $t('Manage') }}
                            </a>
                            <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md cursor-pointer" @click="action('random-data-division')" data-dismiss="dropdown">
                              <ZapIcon class="w-4 h-4 mr-2" /> {{ $t('Random assigns') }}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div v-if="layout.dataset?.validation">
                        <div class="mt-2" v-if="layout.dataset.tagsCounter?.chart && (layout.dataset.dataDivision.total!=layout.dataset.dataDivision.predetermined)">
                          <ReportPieChart :height="140" :data="layout.dataset.validation?.division?.chart" /> 
                        </div>
                        <div class="text-xs mb-5 mt-3 text-gray-700 text-center" v-else>
                          <div class="mt-5 text-sm">{{$t('A data division has not yet been defined')}}</div>
                        </div>
                        <div class="mt-2">
                          <div class="flex items-center mt-2" v-for="(d,i) in layout.dataset.validation?.division?.percentages" :key="d">
                            <div class="w-2 h-2 rounded-full mr-3" :style="'background-color: '+this.helper.StringtoHex(i)"></div>
                            <span class="truncate">{{i}} <span v-if="layout.dataset?.dataDivision[i]" class="text-xs"> ({{layout.dataset.dataDivision[i]}})</span></span>
                            <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                            <span class="font-medium xl:ml-auto">
                              <span>{{d}}%</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="intro-y col-span-12 lg:col-span-12 flex flex-col items-center mt-20">
                          <div class="flex items-center justify-center"><img :src="require(`@/assets/images/rosepetal/icon/loading5.gif`)" class="w-16" /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Graph Data Division -->

        </div>  
      </div>
    </div>

    <!-- Mosaic last images -->
    <div class="col-span-12 fixedMosaicBox" v-if="layout.dataset && layout.dataset.id && layout.dataset?.tagsCounter  && layout.mosaic">
      <div class="w-full mr-5 p-5">
        <label class="form-label">{{$t('Last images')}}</label>
        <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="layout.mosaic=false"><XIcon class="w-4 h-4" /> {{$t('close')}}</label>
        <div class="border-2 border-dashed rounded-md">
          <div class="flex flex-wrap px-4 pt-4" v-if="layout.dataset?.images?.media.length">
            <div v-for="(i) in layout.dataset?.images?.media" :key="i" class="w-24 h-24 relative image-fit mb-3 mr-5 cursor-pointer zoom-in" @click="action('labeling')">
              <img class="rounded-md" alt="" v-bind:src="'data:image/png;base64,'+i.img_base64_val" />
              <Tippy tag="div" content="Remove?" class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-24 right-0 top-0 -mr-2 -mt-2">
                <xIcon class="w-4 h-4" />
              </Tippy>
            </div>
          </div>
          <div class="py-5 pl-5 text-xs" v-else>
            <div v-if="!layout.dataset?.images?.media">
              <div class="intro-y w-12 py-4">
                <div class="flex items-center justify-center">  
                  <img :src="require(`@/assets/images/rosepetal/icon/loading2.gif`)" class="w-12" />
                </div>
              </div>
            </div>
            <div v-else>
              {{$t('No images found')}}
              <span class="btn box px-5 py-2 zoom-in border bg-gray-200 text-gray-700 hover:bg-theme-6 hover:text-white cursor-pointer ml-2" @click="action('upload')">
                <UploadCloudIcon class="w-4 h-4 pr-1" /> {{ $t('Upload') }}
              </span>
            </div> 
          </div>
        </div>
      </div>
    </div>
    <!-- /Mosaic last images -->

</div>
</template>
<script>

import { defineComponent, onMounted, onUnmounted, ref, watch } from "vue"; 
import * as rosepetalModel from "rosepetal-model";
import datasetActionsComponent  from "@/components/dataset-actions/Main.vue";
import ReportPieChart from "@/components/report-pie-chart/Main.vue";

export default defineComponent({
  props: {
      dataset: {
          type: String,
          required: true
      }
  },
  components: {
    datasetActionsComponent,
    ReportPieChart
  },
  created () {
    this.helper = rosepetalModel.helper
  },
  setup(props) {

      const layout = ref({
                           dataset:     false,
                           loading:     true,
                           action:      false,
                           options:     false,
                           mosaic:      true,
                          });

      const load = async (datasetId) => { 
          //var start1            = Date.now();
          layout.value.dataset    = await rosepetalModel.dataset.get(datasetId, { preview: true, ref: true })
          layout.value.loading    = layout.value.action =  false
          if(layout.value.dataset.id){
            layout.value.mosaic               = true
            //console.log('Time dataset: ' + (Date.now() - start1) + "ms")
            //var start2                         = Date.now();
            layout.value.dataset.dataDivision = await rosepetalModel.dataset.getDataDivision(datasetId, true)
            //console.log('Time division: ' + (Date.now() - start2) + "ms")
            //var start3                         = Date.now();
            layout.value.dataset.tagsCounter   = await rosepetalModel.dataset.getTagsCounter(datasetId, true, { dataset: layout.value.dataset })
            //console.log('Time tagsCounter: ' + (Date.now() - start3) + "ms")
            //var start4                         = Date.now();
            layout.value.dataset.status        = await rosepetalModel.dataset.getStatus(datasetId) 
            //console.log('Time status: ' + (Date.now() - start4) + "ms")
            //var start5                         = Date.now();
            layout.value.dataset.validation    = await rosepetalModel.dataset.validateToTrain(datasetId) 
            //console.log('Time validation: ' + (Date.now() - start5) + "ms")
            //var start6                         = Date.now();
            layout.value.dataset.models        = await rosepetalModel.dataset.getModels(datasetId) 
            //console.log('Time models: ' + (Date.now() - start6) + "ms")
            //var start7                         = Date.now();
            layout.value.dataset.images        = await rosepetalModel.dataset.getImages({ datasetID: datasetId, limit: 5 })
            //console.log('Time images: ' + (Date.now() - start7) + "ms")
            //var start8                         = Date.now();
            layout.value.dataset.models        = await rosepetalModel.dataset.getModels(datasetId)
            //console.log('Time models: ' + (Date.now() - start8) + "ms")
            layout.value.reloadMsg = false
            //console.log('Time total: ' + (Date.now() - start1) + "ms")
            //console.log("------------------------------------------------------------")
          }
      }   

      const action = async (action, opt = {}) => { 
        var nowdate             = new Date()
        if(action=='reload')layout.value.action = false
        else layout.value.action = action
        layout.value.options    = opt
        layout.value.timestamp  = nowdate.getTime() 
      }

      const reload = async () => { 
        if(props.dataset)await load(props.dataset)
      }

      onMounted( async () => {
        layout.value.listener = rosepetalModel._firebase.firestore().collection('dataset').onSnapshot((snapshot) => {
                                  const changes = snapshot.docChanges();
                                  changes.forEach(async (change) => {
                                    if (!change?._delegate?.doc?.metadata?.hasPendingWrites && change.doc.id == props.dataset && change.type === 'modified'){
                                      layout.value.reloadMsg = true
                                      await load(props.dataset)
                                    }
                                  });
                                });
         await load(props.dataset)                       
      });

      onUnmounted( async () => {
        layout.value.listener()
      });

      watch(() => props.dataset, async () => { layout.value.action = false; await load(props.dataset) })

      return {
          layout,
          action,
          reload
      };
  },
  methods: { }
});
</script>
<style>

</style>