'use strict';

const moment = require("moment");
const stc = require('string-to-color');

const helper = { 
    StringtoHex: function(str) { return stc(str) },

    getTimestampDate: function(d,type=false) { 
        let date = new Date(d); 
        if(type){
        if(type=='full')return moment(date).format("DD/MM/YYYY HH:mm:ss"); 
        else if(type=='date'){
        if(moment().format("DD/MM/YYYY")==moment(date).format("DD/MM/YYYY"))return 'Today'
        return moment(date).format("DD/MM/YYYY")
        }
        else if(type=='time')return moment(date).format("HH:mm:ss")
        else return moment(date).format("DD/MM/YYYY HH:mm:ss");
        }else{ return moment(date).format("DD/MM/YYYY HH:mm:ss"); } 
    },

    getUnixDate: function(d) { return moment.unix(d/1000).format("DD MMM YYYY hh:mm a")},

    getFbDate: function(d) { return this.getTimestampDate((d.seconds * 1000 + d.nanos / 1000000),'date') },

    orderParams: function(o) { return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {}); },

    formatBytes: function(bytes, decimals = 2) { 
        if (!+bytes) return '0 Bytes'
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    capitalize: function(s) { return s.charAt(0).toUpperCase()+s.toLowerCase().slice(1) },

    formatScore: function(value) {  let val = (value/1).toFixed(0).replace('.', ','); return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")  },

    hexToRgb: function(hex) { 
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
        }
        throw new Error('Bad Hex');
    },

    getRgbValues: function(rgbaString) { 
        var rgbValues = rgbaString.substring(5, rgbaString.length-1).split(",");
        return rgbValues[0] + "," + rgbValues[1] + "," + rgbValues[2];
    },

    rgbObjectToString: function(rgb) { return `${rgb.r},${rgb.g},${rgb.b}`; },

    arrayBufferToBase64: function(buffer) { 
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    },

    stringToUint8Array: function(str) { 
        const uint8Array = new Uint8Array(str.length);
        for (let i = 0; i < str.length; i++) {
            uint8Array[i] = str.charCodeAt(i);
        }
        return uint8Array;
    },

    uint8ArrayToString: function(uint8Array) { 
        let str = '';
        for (let i = 0; i < uint8Array.length; i++) {
            str += String.fromCharCode(uint8Array[i]);
        }
        return str;
    },

    dateStringToMillis: function(dateString) { 
        const [day, month, year, hour, minute, second] = dateString.match(/\d+/g).map(Number);
        const dateObj = new Date(year, month - 1, day, hour, minute, second);
        return dateObj.getTime();
    },
}  

module.exports = helper