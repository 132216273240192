<template>
  <div class="w-full">
    <div class="intro-y col-span-12" :class="isLoading()"> 
      <div class="load-monitor-box">
        <div class="px-5 pt-5 mt-3">
        <div class="pt-10"><CameraIcon class="w-20 h-20" /></div>
        <span class="text-sm font-medium pt-6">{{ $t('Waiting for captures') }}...</span>
        </div>
      </div>  
    </div>  
    <div class="intro-y col-span-12" :class="isStoping()"> 
      <div class="load-monitor-box">
        <div class="px-5 pt-5 mt-3">
        <div class="pt-8 pb-2"><CameraOffIcon class="w-20 h-20" /></div>
        <span class="text-sm font-medium pt-6">{{ $t('Stopping the monitor and logging out') }}...</span>
        </div>
      </div>  
    </div>  
    <div class="intro-y col-span-12" :class="isOutAct()"> 
      <div class="load-monitor-box">
        <div class="px-5 pt-5 mt-3">
        <div class="pt-8 pb-2"><CornerRightUpIcon class="w-20 h-20" /></div>
        <span class="text-sm font-medium pt-6" v-if="outactive=='start'">{{ $t('Activating the output') }}</span>
        <span class="text-sm font-medium pt-6" v-if="outactive=='stop'">{{ $t('Deactivating the output') }}</span>
        </div>
      </div>  
    </div> 
    <div class="intro-y box px-5 pt-5 mt-3">
        <div class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5">
          <div class="flex px-5 items-center justify-center lg:justify-start" >
            <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-20 image-fit relative text-center"><MonitorIcon class="w-20 h-20" /></div>
            <div class="ml-0">
              <div class="py-1 px-4 rounded-full text-xs bg-theme-12 text-white cursor-pointer font-medium mb-2 w-20 text-center">{{ MonitorId }}</div>
              <div class="text-xs mb-0">{{ $t('Status') }}:</div>
              <div class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-2xl" :class="isActive() ? 'text-green-900' : ''">{{ getStatus() }}</div>
            </div>
          </div>
          <div class="mt-6 lg:mt-0 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0 lg:pr-10">
            <div class="font-medium text-center lg:text-left">{{ $t('Monitor controls') }}</div>
            <div class="flex flex-col justify-center items-center lg:items-start mt-4 ">
              <div class="flex w-full items-center mt-0">
                <a class="flex items-center justify-center w-12 h-12 rounded-full bg-gray-900 dark:bg-dark-1 bg-opacity-10 hover:bg-opacity-40 text-dark hover:bg-green-900 hover:text-white mr-3" 
                  href="#" :title="$t('Start')" :class="isActive()" @click="Play()"><PlayCircleIcon class="w-8 h-8" /></a>
                <a :title="$t('Stop')"
                  class="flex items-center justify-center w-12 h-12 rounded-full bg-gray-900 dark:bg-dark-1 bg-opacity-10 text-dark hover:bg-opacity-40 hover:bg-red-500  mr-3"
                  href="#" :class="isStopped()" @click="Stop()"><StopCircleIcon class="w-8 h-8" /></a>
                <a class="flex items-center justify-center w-12 h-12 rounded-full bg-gray-900 dark:bg-dark-1 bg-opacity-10 hover:bg-opacity-40 text-dark hover:bg-green-900 hover:text-white"
                  href="#" :title="$t('Training mode')" :class="isTraining()" @click="Training()"><EyeIcon class="w-8 h-8" /></a>
              </div>
              <div class="text-gray-500 text-xs mt-5 " v-if="Monitor && root">
                <span class="pr-3 align-middle">{{ $t('Demo') }}:</span> 
                <span class="pt-4 align-middle"><input type="checkbox" class="form-check-switch" @click="Demo()" :checked="isDemo()" /></span>
              </div>
            </div>
          </div>
          <div class="mt-6 lg:mt-0 flex-1 px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0" v-if="root"> 
            <div class="font-medium text-center lg:text-left">{{ $t('Controller') }}</div>
            <div class="items-center justify-center lg:justify-start mt-2">
              <div class="text-gray-500 text-xs mt-1" v-if="controller[MonitorId]">
                <span class="pr-5">UID: {{ controller[MonitorId].uid }}</span> 
              </div>
              <div class="grid chat grid-cols-12 gap-6 mt-2" v-if="controller[MonitorId]">
                <div class="col-span-6 lg:col-span-12 xxl:col-span-3">
                  <div class="items-center justify-center lg:justify-start">
                    <div class="font-medium text-gray-600"><CornerRightDownIcon class="w-4 h-4" /> {{ $t('Input') }}</div>  
                    <div class="text-gray-500 text-xs mt-1">{{ $t('Device') }}: <span class="font-medium text-gray-700">{{ controller[MonitorId].input.device }}</span> </div>
                    <div class="text-gray-500 text-xs mt-1">{{ $t('Driver') }}: <span class="font-medium text-gray-700">{{ controller[MonitorId].input.driver }}</span> </div>
                    <div class="text-gray-500 text-xs mt-1" v-if="controller[MonitorId].input.message"><span class="font-medium text-gray-700">{{ controller[MonitorId].input.message }}</span> </div>
                  </div>
                </div>
                <div class="col-span-6 lg:col-span-12 xxl:col-span-3">
                  <div class="items-center justify-center lg:justify-start">
                    <div class="font-medium " :class="controller[MonitorId].output.status ? 'text-green-900' : 'text-red-900'">
                      <CornerRightUpIcon class="w-4 h-4" /> {{ $t('Output') }} 
                      <span class="py-0.5 px-2 rounded-full text-xs bg-red-700 text-white cursor-pointer text-btn-min ml-2" v-if="controller[MonitorId].output.status" @click="Output('stop')">{{ $t('Deactivate') }}</span>
                      <span v-else class="py-0.5 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer text-btn-min ml-2" @click="Output('start')">{{ $t('Activate') }}</span>
                    </div>  
                    <div class="text-gray-500 text-xs mt-1">{{ $t('Device') }}: <span class="font-medium text-gray-700">{{ controller[MonitorId].output.device }}</span> </div>
                    <div class="text-gray-500 text-xs mt-1">{{ $t('Driver') }}: <span class="font-medium text-gray-700">{{ controller[MonitorId].output.driver }}</span> </div>
                    <div class="text-gray-500 text-xs mt-1" v-if="controller[MonitorId].output.message"><span class="font-medium text-gray-700">{{ controller[MonitorId].output.message }}</span> </div>
                    <div class="text-gray-500 text-xs mt-1">
                       <div id="inline-form">
                        <div class="preview pt-1">
                          <form ref="frmSendOutput" class="validate-form" @submit.prevent="SendOutput('frmSendOutput',$event)">
                          <div class="grid grid-cols-12 gap-2">
                              <input type="text" name="soutput" class="form-control col-span-3 p-0.5 text-center"  />
                              <button type="submit" class="btn btn-sm btn-secondary col-span-5">{{ $t('Force output') }}</button> 
                          </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div class="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0  pt-5 lg:pt-0 hidden" >
            <div class="font-medium text-center lg:text-left">{{ $t('Prediction') }} </div>
              <div class="text-gray-500 text-xs mt-1" v-if="Monitor && Monitor.prediction">
                <span class="pr-7">{{ $t('API Prediction') }}:</span> 
                <select class="form-select bg-transparent border-black border-opacity-10 dark:bg-transparent dark:border-opacity-100 dark:border-dark-5 mx-auto sm:mx-0 py-1.5 px-1 w-60 pr-10 cursor-pointer">
                  <option>{{ $t('Online') }} - {{Monitor.prediction.rest}}</option>
                </select>
              </div>
          </div>
        </div>
        <div class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start" role="tablist">
          <div class="p-3 w-full">
            <div v-for="m in Monitors" v-bind:key="m.id">
              <div class="ml-auto flex items-center" v-if="Monitor && m.id==Monitor.id">
                <!-- Leds -->
                <span :title="$t('Database connection')" class="flex items-center justify-center w-9 h-9 rounded-full bg-gray-900 dark:bg-dark-1 bg-opacity-10 text-dark mr-3" :class="getLed(m.id,'database')"><DatabaseIcon class="w-5 h-5" /></span>
                <span :title="$t('Reader')" class="flex items-center justify-center w-9 h-9 rounded-full bg-gray-900 dark:bg-dark-1 bg-opacity-10 text-dark mr-3" :class="getLed(m.id,'image')"><CameraIcon class="w-5 h-5" /></span>
                <span :title="$t('Conveyer belt')" class="flex items-center justify-center w-9 h-9 rounded-full bg-gray-600 dark:bg-dark-1 bg-opacity-10 text-dark" :class="getLed(m.id,'output')"> <GitCommitIcon class="w-5 h-5" /></span>
                <!-- Session -->
                <span class="text-gray-500 text-xs pl-10">
                  <span class="text-gray-600 font-medium" v-if="lastSession">
                    <span v-if="lastSession.start && !lastSession.stop">
                      <ActivityIcon class="w-4 h-4" style="vertical-align: top" /> {{ $t('Session started') }}: <span class="text-green-700 pl-2">{{ this.helper.getTimestampDate(lastSession.start.toDate(),'date') }} {{ this.helper.getTimestampDate(lastSession.start.toDate(),'time') }}</span>
                    </span>
                    <span v-else>
                      <span v-if="lastSession.start">
                      <ActivityIcon class="w-4 h-4" style="vertical-align: top" /> {{ $t('Last session') }}: 
                      <span class="text-green-900 pl-2">
                        {{ this.helper.getTimestampDate(lastSession.start.toDate(),'date') }} {{ this.helper.getTimestampDate(lastSession.start.toDate(),'time') }} - 
                        <span v-if="this.helper.getTimestampDate(lastSession.start.toDate(),'date')!=this.helper.getTimestampDate(lastSession.stop.toDate(),'date')">{{ this.helper.getTimestampDate(lastSession.stop.toDate(),'date') }}</span> 
                        {{ this.helper.getTimestampDate(lastSession.stop.toDate(),'time') }}
                        </span> 
                        <span class="pl-2 cursor-pointer text-green-900"><InfoIcon class="w-4 h-4" style="vertical-align: top" @click="SessionDetails(lastSession.id)" /></span>
                      </span>
                    </span>
                    <div :id="'modal-session-'+lastSession.id" class="modal modal-session" tabindex="-1" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body p-0">
                                    <div class="intro-y col-span-9 lg:col-span-9 flex flex-col justify-center items-center mt-20 mb-20" v-if="LoadSession"> 
                                        <LoadingIcon icon="three-dots" class="w-20 h-20 pt-4" />
                                        <span class="text-xs pb-8">{{ $t('Loading session data, please wait') }}...</span>
                                    </div>
                                    <div :class="LoadSession ? 'hidden' : ''">
                                      <sessionlog :session_id="lastSession.id" v-if="!LoadSession" />
                                      <div class="px-5 pb-8 text-center pt-5">
                                        <button type="button" data-dismiss="modal" class="btn btn-primary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"><XIcon class="w-4 h-4 mr-1" /> {{ $t('Close') }}</button>
                                        <button type="button" @click="linkTo('side-menu-sessions')" class="btn btn-outline-secondary w-32"><ActivityIcon class="w-4 h-4 mr-1" /> {{ $t('Sessions') }}</button>
                                      </div>
                                    </div> 
                              </div>
                            </div>
                        </div>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Notifications -->
        <div id="start-notification-monitor" class="toastify-content hidden flex">
            <PlayCircleIcon class="text-theme-10 w-12 h-12" />
            <div class="ml-4 mr-4">
                <div class="font-medium">{{ $t('Monitor') }} {{ MonitorId }} {{ $t('active') }}</div>
                <div class="text-gray-600 mt-1">
                  <span class="font-bold">{{ $t('The session has been started') }}</span>
                </div>
            </div>
        </div>
        <div id="stop-notification-monitor" class="toastify-content hidden flex">
            <StopCircleIcon class="text-red-600 w-12 h-12" />
            <div class="ml-4 mr-4">
                <div class="font-medium">{{ $t('Monitor') }} {{ MonitorId }} {{ $t('stopped') }}</div>
                <div class="text-gray-600 mt-1">
                  <span class="font-bold">{{ $t('The session has ended') }}</span>
                </div>
            </div>
        </div>
        <div id="start-notification-training" class="toastify-content hidden flex">
            <EyeIcon class="text-theme-10 w-12 h-12" />
            <div class="ml-4 mr-4">
                <div class="font-medium">{{ $t('Active training mode') }}</div>
                <div class="text-gray-600 mt-1">
                    {{ $t('Monitor') }}: <span class="font-bold">{{ MonitorId }}</span>
                </div>
            </div>
        </div>
        <div id="stop-notification-training" class="toastify-content hidden flex">
            <EyeIcon class="text-red-600 w-12 h-12" />
            <div class="ml-4 mr-4">
                <div class="font-medium">{{ $t('Training mode inactive') }}</div>
                <div class="text-gray-600 mt-1">
                  {{ $t('Monitor') }}: <span class="font-bold">{{ MonitorId }}</span>
                </div>
            </div>
        </div>
        <div id="needstart-notification-training" class="toastify-content hidden flex">
            <InfoIcon class="text-red-600 w-12 h-12" />
            <div class="ml-4 mr-4">
                <div class="font-medium">{{ $t('To start training mode') }}<br> {{ $t('you must activate the monitor first') }}.</div>
                <div class="text-gray-600 mt-1">
                  <span class="font-bold">{{ MonitorId }}</span>
                </div>
            </div>
        </div>
        <div id="status-notification-monitor" class="toastify-content hidden flex">
            <InfoIcon class="text-theme-10 w-12 h-12" />
            <div class="ml-4 mr-4">
                <div class="font-medium">{{ $t('Monitor is') }} {{ getStatus() }}</div>
                <div class="text-gray-600 mt-1">
                  <span class="font-bold">{{ MonitorId }}</span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted , watch, computed } from "vue";
//import { _firebase as $_firebase } from "@/model/firebase";
import { monitor as $monitor } from "@/model/monitor";
import { session as $session } from "@/model/session";
import { useRouter } from "vue-router";
import { tag as $tag } from "@/model/tag";
import { helper as $h } from "@/utils/helper";
import { useStore } from "@/store";
import cash from "cash-dom";
import * as rosepetalModel from "rosepetal-model";

import sessionlog from "@/components/session-log/Main.vue";

export default defineComponent({
  props: {
    monitor: {
      type: String,
      default: ''
    }
  }, 
  components: {
    sessionlog,
  },
  created () {
    this.helper       = $h
  },  
  setup(props) {

    var $_firebase      = rosepetalModel._firebase

    const router        = useRouter();
    const store         = useStore();  
    const ApiMonitor    = ref();
    const User          = computed(() => store.state.main.User);
    const root          = ref(false);
    const training      = ref({});
    const demoMode      = ref({});
    const status        = ref({});
    const Leds          = ref({});
    const Monitor       = ref();
    const Monitors      = ref();
    const tags          = ref([])
    const lastSession   = ref(false);
    const LoadSession   = ref(false)
    const controller    = ref({});
    const loading       = ref(false);
    const stop          = ref(false);
    const outactive     = ref(false);
   
    const getMonitorLeds = (m) => {
      $_firebase.database().ref(m+'/monitor/led').on("value",function(snapshot) {  Leds.value[m] = snapshot.val() }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
      $_firebase.database().ref(m+'/monitor/training').on("value",function(snapshot) { training.value[m] = snapshot.val() }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
      $_firebase.database().ref(m+'/monitor/status').on("value",function(snapshot) { status.value[m]= snapshot.val()}, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
      $_firebase.database().ref(m+'/monitor/demo/status').on("value",function(snapshot) { demoMode.value[m]= snapshot.val()}, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
    }
    const getLed = (m,led) => { if(Leds.value[m] && Leds.value[m][led])return "text-white bg-green-900 bg-opacity-80"; }
    
    const getInitImage = (m) => {
      $_firebase.database().ref(m+'/monitor/tag_counter').on("value", function(snapshot) { if(snapshot.val()){ console.log(snapshot.val()); if(loading.value)loading.value = false;} }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
    }

    const MonitorId = computed( () => { getMonitorLeds(props.monitor); return props.monitor; });
    
    const getMonitorController = (m) => {
     $_firebase.database().ref(m+'/monitor/controller').on("value",function(snapshot) {
        controller.value[m]    = snapshot.val()
     }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
    }

    const getLastSession = async (m) =>{ lastSession.value = await $session.getLast({ uid : useStore().state.main.User.uid , monitor: m }); }
    
    const SessionDetails = (s) => {  LoadSession.value = true;  cash("#modal-session-"+s).modal("show"); setTimeout(() => LoadSession.value = false, 1000);  };

    watch( () => status.value, () => {  if(this.isActive()){ cash(".side-nav").addClass("hidden")  }else{ cash(".side-nav").removeClass("hidden") }  });
    watch( () => props.monitor, async () => {  
      Monitor.value    = await $monitor.get(props.monitor); 
      ApiMonitor.value = await $monitor.getApiUrl(props.monitor);
      getMonitorController(props.monitor)
      getMonitorLeds(props.monitor) 
      getLastSession(props.monitor)
    });

    onMounted( async () => { 
      Monitors.value   = await $monitor.get();
      Monitor.value    = await $monitor.get(props.monitor);
      ApiMonitor.value = await $monitor.getApiUrl(props.monitor);
      tags.value       = await $tag.init();
      getMonitorController(props.monitor)
      getLastSession(props.monitor)
      if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;
    });
    
    const linkTo = (page,params=false) => {  
      cash('.modal-session').modal("hide"); cash('.modal-session').removeClass('show');
      let p = { dates: {} }; if(params)p.dates=params; router.push({ name: page, params: p });  
    };

    watch( () => store.state.main.User.role,  () => { if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;  });

    return {
      ApiMonitor,
      training,
      demoMode,
      Leds,
      getLed,
      status,
      Monitors,
      Monitor,
      MonitorId,
      User,
      root,
      lastSession,
      LoadSession,
      getLastSession,
      SessionDetails,
      getInitImage,
      tags,
      controller,
      loading,
      stop,
      outactive,
      linkTo,
    };
  },
  methods: {
    async Play() {
      if(!this.isActive()){ 
        this.loading = true;
        await $monitor.play(this.monitor,"#start-notification-monitor")
              .then( () => { 
                 this.getLastSession(this.monitor);  
                 this.loading = false;
                 //this.getInitImage(this.monitor); 
              })
              .catch( () => {  this.loading = false; });
      }else{ $h.Notification('#status-notification-monitor') }
    },
    async Demo() {
      await $monitor.demo(this.monitor).then( () => {  })
    },
    async Stop(){
      if(this.isActive()){  
        this.stop = true;
        await $monitor.stop(this.monitor,"#stop-notification-monitor").then( () => {
          this.training[this.monitor] = false; 
          this.getLastSession(this.monitor)
          this.stop = false;
        }).catch( () => {  this.stop = false; });
      }else{ $h.Notification('#status-notification-monitor') }
    },
    async Training (){
      if(this.isActive()){  
        await $monitor.trainingToggle(this.monitor,true).then(async () => {  this.training[this.monitor] = await $monitor.isTraining(this.monitor); })
      }else{ $h.Notification('#needstart-notification-training')}
    },
    async Output(action) {
      this.outactive = action;
      await $monitor.OutputChange(this.monitor,action).then(() => {  setTimeout(() => this.outactive = false, 3000);  })
    },
    async SendOutput(formID,event) { 
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        const soutput = {};
        for (let i = 0; i < this.$refs[formID].length; i++) {  if(this.$refs[formID][i].name=="soutput")soutput.value = this.$refs[formID][i].value.replace(/ /g, "").toLowerCase(); }  
        if(soutput.value)await $monitor.SendOutput(this.monitor,soutput.value).then( () => {  })
      }
    },
    getStatus(){  
        if(this.training[this.monitor]){ return this.$i18n.t('training').charAt(0).toUpperCase()+this.$i18n.t('training').slice(1)
        }else if(this.status[this.monitor]){  return this.$i18n.t('active').charAt(0).toUpperCase()+this.$i18n.t('active').slice(1)
        }else{ return this.$i18n.t('stopped').charAt(0).toUpperCase()+this.$i18n.t('stopped').slice(1) } 
    },
    isActive(){   if(this.status[this.monitor])return "text-white bg-green-900 bg-opacity-50 hover:bg-opacity-50"; },
    isLoading(){  if(!this.loading)return "hidden"; },
    isStoping(){  if(!this.stop)return "hidden"; },
    isOutAct(){   if(!this.outactive)return "hidden"; },
    isDemo(){     if(this.demoMode[this.monitor]){return true;}else{ return false;}},
    isStopped(){  if(!this.status[this.monitor])return "text-white bg-red-900 bg-opacity-60 hover:bg-opacity-50";  },
    isTraining(){ if(this.training[this.monitor])return "text-white bg-green-900 bg-opacity-50 hover:bg-opacity-50"; },
  }
});

</script>

<style>
.text-btn-min{font-size: 10px !important; vertical-align: top;}
.load-monitor-box{
  position: fixed !important;
  z-index: 9999 !important;
  top: 0 !important;
  background-color: #f8f8f8 !important;
  opacity: 0.8;
  width: 100% ;
  height: 215px;
  text-align: center;
}
@media only screen and (max-width: 1905px) { .load-monitor-box{ height: 230px;}}
@media only screen and (max-width: 1600px) { .load-monitor-box{ height: 300px;}}
@media only screen and (max-width: 1074px) { .load-monitor-box{ height: 315px;}}
@media only screen and (max-width: 1024px) { .load-monitor-box{ height: 534px;}}
@media only screen and (max-width: 410px) { .load-monitor-box{ height: 585px;}}
</style>