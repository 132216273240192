<template>
  <div class="dropzoneBoxContent w-full">
    <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-0 uploadFileFull" v-if="layout.loading">
      <img class="w-1/2" :src="require(`@/assets/images/rosepetal/icon/loading.gif`)" />
    </div>
    <div class="w-full" v-else>
      <div class="text-sm mb-5" v-if="(!layout.dataset && !layout.file && !props.dataset)">
          <select class="w-full form-select p-1 cursor-pointer text-gray-600" v-model="layout.dataset">
              <option value="">{{$t('Select dataset')}}</option>
              <option :value="t.id" v-for="(t,name) in layout.datasets" :key="name">{{t.name}} ({{t.type.toLowerCase()}})</option>
          </select>
      </div>
      <div class="text-xs border-b m-5 mb-0" v-if="(layout.dataset && !props.dataset)">
          <div class="text-xl font-bold mt-1 text-green-500" v-if="layout.datasetData.name">{{layout.datasetData.name.toLowerCase()}}</div>
          <div class="mb-3 __uploaddzmin text-gray-200 font-italic" v-if="layout.datasetData.type">
            <span v-if="layout.datasetData.type=='MULTICLASS' || layout.datasetData.type=='MULTILABEL'">{{$t('Classification')}}</span>
            <span v-if="layout.datasetData.type=='imageObjectDetection'">{{$t('Object detection')}}</span>
          </div>
          <div class="px-5 py-1 bg-gray-300 text-gray-900 rounded cursor-pointer font-normal mb-4 w-1/3 text-center" v-if="!layout.file" @click="resetDropzone()">{{$t('Change dataset')}}</div>  
      </div>
      <div class="text-xs py-5 pt-0 pb-3" v-if="(layout.acceptedFormat && layout.dataset && !layout.file)">
        <div class="form-check form-check-inline text-sm">
          <span class="pr-4" v-for="(f,k) in layout.acceptedFormat" :key="k">
            <!-- :disabled="(k=='zip')?false:true" -->
            <input v-model="layout.type" class="btn-check cursor-pointer" type="radio" :value="k" /> <span class="pl-1">{{layout.acceptedFormat[k]}}</span>
          </span>
        </div>
      </div>
      <Dropzone ref-key="dropzoneSingleRef" :options="dropzoneOptions" class="dropzone text-xs cursor-pointer rounded" :class="layout.dataset && !layout.file ? '' : 'hidden'"> 
          <h2 class="text-lg font-medium truncate mr-5">{{$t('Upload images')}}</h2>
          <div class="text-xs font-medium text-gray-800">{{$t('Drop file here or click to upload')}}.</div>
          <UploadCloudIcon class="w-14 h-14 mt-3 text-gray-400" />
      </Dropzone>
      <div class="grid grid-cols-12 mr-20">
        <div class="col-span-12">
          <div class="text-xs mb-1 w-full flex py-3 border text-red-400 rounded font-normal mt-3 pl-5" v-if="layout.error">
              <span class="w-full text-truncate font-italic pt-2">{{layout.error}}.</span>
              <span class="py-2 px-6 rounded-full text-xs font-normal align-top text-center cursor-pointer float-right ml-auto bg-gray-700 text-white mr-2" v-if="(layout.file)" @click="resetDropzone()">{{ $t('Back') }}</span>
          </div>
          <div class="pt-3 pl-3 text-gray-600" v-if="!layout.file && layout.type=='zip'">
            <div class="grid grid-cols-12">
              <div class="col-span-2"><img class="w-20 h-auto mt-3 ml-5" :src="require(`@/assets/images/rosepetal/icon/zip.png`)" /></div>
              <div class="col-span-10">  
                <div v-if="layout.datasetData.type=='MULTICLASS' || layout.datasetData.type=='MULTILABEL'" class="text-xs py-3 w-full">
                    <div class="font-medium text-sm mb-3 text-gray-700">{{$t('How to add multiple images to dataset using a Zip File')}}</div>
                    <div>
                        1. {{$t('Create a directory for each class tag Identifier')}}. 
                        <div class="pl-3 mt-1">{{$t("Use directory name '0' for images you don't want to classify")}}.</div>   
                    </div> 
                    <div class="mt-2">2. {{$t('Insert the images in each directory according to their class')}}.</div> 
                    <div v-if="layout.datasetTags" class="ml-3 mt-1">
                      <span >{{$t('Examples')}}: </span>
                      <span v-if="layout.datasetTags && Object.keys(layout.datasetTags).length>1"><span class="pr-1 font-medium" v-for="(v,k,i) in layout.datasetTags" :key="i"><span v-if="(i<4)">/{{v.id}}/image{{(i+1)}}.jpg,</span></span>...</span>
                      <span v-else class="pr-1 font-medium">OK/image1.jpg, NOK/image1.jpg</span>
                    </div> 
                    <div class="mt-2">3. {{$t('Compress in Zip format and drag the file to the top uploading area')}}.</div>
                </div>
                <div v-if="layout.datasetData.type=='imageObjectDetection'" class="text-xs py-3 w-full">
                    <div class="font-medium text-sm mb-3">{{$t('How to add multiple images to object detection dataset using a Zip File')}}</div>
                    <div>1. {{$t('Create a directory and insert all the images in the root')}}.</div> 
                    <div>2. {{$t('Compress in Zip format')}}.</div>
                    <div>3. {{$t('Drag the file to the top uploading area')}}.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-sm mt-5" v-if="layout.file">
            <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-0 pt-0 uploadingFull" v-if="layout.uploading">
              <div class="w-full text-center pt-5" style="background-color: #f1f2f1">
                <div class="text-lg" style="font-size: 26px !important">{{layout.debug ? layout.debug : $t('Starting the upload, please wait') + "..."}}</div>
                <div class="text-xs mb-3 mt-3 text-center w-full" v-if="layout.datasetImageCount">
                  <div class="text-3xl mb-1">{{layout.datasetImageCount}}</div>
                  <div>{{$t('Total dataset images')}}</div>
                </div>
                <span class="text-base text-red-600" style="line-height: 60px; font-size: 20px">{{$t("Please do not close your browser")}}</span>
              </div>
              <div class="w-1/2 text-center" :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/loading.gif`)}); background-position: top center; background-size: contain; background-repeat: no-repeat; height: 60%`"></div>
            </div>
            <div v-else>
              <div v-if="layout.success" class="text-center">
                <div class="text-center  w-full">
                  <img class="w-20 h-auto d-block mx-auto rounded" :src="require(`@/assets/images/rosepetal/icon/ok.png`)" />
                </div>
                <div class="items-center justify-center"></div>
                <div class="text-base mt-3 font-medium">{{$t('Upload Successful')}}</div>
                <div class="mt-2">{{$t('The images have been correctly incorporated into the dataset')}}</div>
                <div class="tex-xs mb-3 text-center w-full mt-2" v-if="layout.datasetImageCount">
                  <div class="text-3xl mb-1">{{layout.datasetImageCount}}</div>
                  <div>{{$t('Total dataset images')}}</div>
                </div>
                <div class="mb-10 mt-1 text-normal text-xs">{{$t('In a few moments you will have them available in data labeling')}}</div>
                <span class="px-5 py-3 bg-blue-500 cursor-pointer w-full font-normal text-white hover:bg-theme-10 mr-2" @click="resetDropzone()">{{$t('New upload')}}</span> 
                <div id="refreshMosaicBtn" @click="refreshMosaic()"></div>
              </div>
              <div v-else>
                <div class="grid grid-cols-12 gap-3">
                  <div class="intro-y col-span-12">
                    <div class="intro-y col-span-12 box shadow-none">
                      <div class="flex items-center border-b border-gray-200 px-5 pb-4">
                        <div class="w-14 h-14 flex-none image-fit rounded-full border text-center pt-4">{{ layout.file.name.toString().toUpperCase().split('.').pop() }}</div>
                        <div class="ml-3 mr-auto">
                          <span  class="font-medium">
                            {{layout.file.name.toString().toLowerCase().split('.')[0].slice(0, 40)}} <span v-if="(layout.file.name.toString().toLowerCase().split('.')[0].length>40)">...</span>
                          </span>
                          <div class="flex text-gray-600 truncate text-xs mt-0.5">
                            <span v-if="layout.file.type"><span>{{$t('Type')}}: </span><span class="text-green-500">{{layout.file.type}}</span></span>
                            <span class="mx-1">•</span>  <span v-if="layout.file.size"><span>{{$t('Size')}}: </span><span class="text-green-500">{{layout.file.size}}</span></span>
                          </div>
                        </div>
                      </div>
                      <div v-if="(Object.keys(layout.file?.files).length && !layout.success && !layout.uploading)" class="p-5 text-sm">
                        <span><ImageIcon class="w-4 h-4" style="vertical-align: top;" /> {{$t('Valid files')}}: </span>
                        <div class="mt-1 ml-4">
                          <span v-for="(f,k) in layout.file?.files" :key="k" class="pr-3">
                            <span class="text-green-500">.{{(k)}} ({{layout.file.files[k].length}})</span> 
                          </span>
                        </div>
                      </div>
                      <div v-if="(Object.keys(layout.file?.dir).length && !layout.success && !layout.uploading)" class="p-5 pt-0 text-sm">
                        <span><FolderIcon class="w-4 h-4" style="vertical-align: top;" /> {{$t('Tags directories')}}: </span>
                        <div class="mt-2 ml-4">
                          <span v-for="(d,k) in layout.file?.dir" :key="k" class="pr-2">
                            <span class="text-green-500">{{(k)}} ({{(d.length)}}) <span v-if="k=='0'" class="text-green-500">*</span></span> 
                          </span>
                          <div v-if="Object.keys(layout.file?.dir).includes('0')" class="mt-2 __uploaddzmin font-italic font-normal ">* {{$t("Use directory name '0' for unclassified images")}}</div>
                        </div>
                      </div>
                      <div v-if="(Object.keys(layout.file?.invalidFiles).length && !layout.uploading)" class="p-5 pt-0 text-sm text-red-400">
                        <span><AlertTriangleIcon class="w-4 h-4" style="vertical-align: top;" /> {{$t('Invalid files')}}</span>
                        <div class="mt-1 ml-4">
                          <span v-for="(d,k) in layout.file?.invalidFiles" :key="k" class="pr-3">
                            <span class="__uploaddzmin">.{{(k)}} ({{(d.length)}})</span> 
                          </span>
                        </div>
                      </div>
                      <div v-if="(Object.keys(layout.file?.invalidDir).length && !layout.uploading)" class="p-5 pt-0 text-xs text-red-400">
                        <span><FolderIcon class="w-4 h-4" style="vertical-align: top;" /> {{$t('Invalid directories')}}</span>
                        <div class="mt-1 ml-4">
                          <span v-for="(k) in layout.file?.invalidDir" :key="k" class="pr-3">
                            <span class="__uploaddzmin">/{{(k.replace(/\//g, ""))}}</span> 
                          </span>
                        </div>
                      </div>
                      <div class="p-5" v-if="layout.type=='image' && layout.uploadThumb">
                        <div class="h-40 xxl:h-56 image-fit">
                          <img :src="layout.uploadThumb.image" class="d-block mx-auto w-80 h-auto rounded-md" />
                        </div>
                        <div v-if="layout.uploadThumb?.size" class="mt-3">
                          <Maximize2Icon class="w-6 h-6 pr-2" />
                          <span v-if="layout.uploadThumb.size?.width">{{$t('Width')}}: <span class="font-medium">{{layout.uploadThumb.size?.width}}px</span></span> /
                          <span v-if="layout.uploadThumb.size?.height">{{$t('Height')}}: <span class="font-medium">{{layout.uploadThumb.size?.height}}px</span></span>
                        </div>
                      </div>
                      <div class="px-5 pt-3 pb-5 border-t border-gray-200" v-if="layout.type=='image' && !layout.error">
                        <div class="w-full flex items-center mt-3">
                          <div class="flex-1 relative text-gray-700">
                            <input type="text" class="form-control form-control-rounded border-transparent bg-gray-200 pr-10 placeholder-theme-8" v-model="layout.comments" placeholder="Add an comment..."/>
                            <SmileIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"/>
                          </div>
                        </div>
                      </div>
                      <div v-if="(layout.warnings && layout.warnings.length)" class="text-xs pt-0 p-5">
                        <div class="text-xs mb-1 w-full px-1 py-2 text-center border text-gray-900 rounded font-normal">
                          <div class="w-full text-truncate font-italic" v-for="(k) in layout.warnings" :key="k">{{k}}</div>  
                        </div>
                      </div>
                      <div class="flex items-center px-5 py-3 border-t border-gray-200 " v-if="!layout.error">
                        <Tippy tag="a" class="intro-x w-20 h-8 flex items-center justify-center rounded-full bg-theme-31 text-gray-900 ml-auto cursor-pointer py-5" v-if="(layout.file)" @click="resetDropzone()"
                          content="Cancel upload and back">{{$t('Cancel')}}</Tippy>
                        <Tippy tag="a" class="intro-x w-32 h-8 flex items-center justify-center rounded-full bg-theme-17 text-white ml-2 cursor-pointer py-5" v-if="(layout.file && !layout.error)" @click="upload()"
                          content="Confirm and upload image">{{$t('Upload')}}</Tippy>
                      </div>
                    </div>
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch, provide} from "vue"; 
import { helper as $h } from "@/model/db/helper";
import { image as $image } from "@/model/db/image";
//import { dataset as $dataset } from "@/model/db/dataset";
import * as rosepetalModel from "rosepetal-model";
import { useStore } from "@/store";
//import { _firebase as $_firebase } from "@/model/firebase";
import { event as $event } from "@/model/db/event";
import { vertex as $vertex } from "@/model/db/vertex";
import JSZip from "jszip";
import config from '@/etc/rosepetal.json';

export default defineComponent({
  props: {
      dataset: {
      type: String,
      required: true
      }
  },
  setup(props) {


    const layout            = ref({dataset: false});
    const dropzoneSingleRef = ref(); provide("bind[dropzoneSingleRef]", el => { dropzoneSingleRef.value = el;  });
    const dropzoneOptions   = ref({ url: '/', thumbnailWidth: 100, maxFilesize: 10240, uploadMultiple: false, maxFiles: 1, headers: {}, autoQueue: false, }); //, acceptedFiles: '.zip,application/zip'
    
    const dropzoneInit = async () => { 
      layout.value = { 
                      dataset:        "", 
                      datasets:       await rosepetalModel.dataset.list(), 
                      datasetData:    false, 
                      datasetTags:    false,
                      loading:        false,
                      uploading:      false, 
                      success:        false, 
                      type:           "zip", 
                      file:           false, 
                      error:          false,
                      warnings:       [],
                      zip:            { bytesTransferred: 0, totalBytes: 0, uploadStatus: false, percentage: 0 },
                      acceptedFormat: { zip: "Zip", image: "Single image"},
                      comments:       "",
                      acceptedFiles:  ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'webp', 'raw', 'ico', 'tiff']
                    }
      if(!layout.value.dataset && props.dataset){ layout.value.dataset = props.dataset; await load() }     
      if(dropzoneSingleRef?.value?.dropzone)
        dropzoneSingleRef.value.dropzone.on("addedfile", async (file) =>  {
          layout.value.loading  = layout.value.uploaded  = true
          if(layout.value.type == "zip"){
            let validZipFormats = ['application/zip', 'application/x-zip-compressed']
            if(validZipFormats.indexOf(file.type) == -1)layout.value.error  = "The uploaded file is a " + file.type + " and must be a zip file"
          }
          layout.value.file     = { 
                                    name:         file.name, 
                                    type:         file.type, 
                                    size:         $h.formatBytes(file.upload.total), 
                                    dir:          {},
                                    invalidDir:   [],
                                    files:        {},
                                    invalidFiles: {} 
                                  }
          console.log('file',layout.value.file)
          if(!layout.value.error){
            if(layout.value.type=="zip"){
              let z           = new Promise((resolve, reject) => { JSZip.loadAsync(file).then(function(content) { resolve(content) }).catch(() => { reject });  }) 
              let zipFile     = await z
              let empty       = false
              var dirName     = false
              if(zipFile.files){
                Object.keys(zipFile.files).forEach(function(filename) {
                  
                  let fileInZip = zipFile.files[filename]
                  let filenameInZip = filename
                  let dirCount = filename.toString().split("/").filter(element => element)
                  if(dirCount.length==3 && !dirCount[0].includes("__MACOSX")){
                    console.log('in dirCount == 3',dirCount)
                    if(!layout.value.file.dir[dirCount[1]])layout.value.file.dir[dirCount[1]] = []; 
                    filename = filename.replace(dirCount[0]+"/", "")
                    dirName  = dirCount[1]
                  }
                  
                  var f           = { name: filename, extension: filename.toString().split('.').pop(), file: fileInZip }
                  var isDir       = (/\/$/.test(filename)) ? true : false
                  if(isDir)console.log('filename',filename)

                  let excludeFile = false
                  let zipname     = file.name.replace(/.zip/g, "")
                  if(zipname+"/"!=filename && dirCount.length>2)filename = filename.replace(new RegExp(zipname+"/", "g"), "") 

                  if(filename.includes("__MACOSX") || filename.includes(".DS_Store") || filename.includes(".db"))excludeFile = true
                  if(!excludeFile){
                    var path    = { obj: filename.toString().split("/").filter(element => element) }
                    if(isDir){
                      dirName   = path.obj.length ? path.obj[0] : false // dirName = path.obj.length ? path.obj[path.obj.length - 1] : false; 
                      if(filename && dirName){
                        if(!layout.value.file.dir[dirName])layout.value.file.dir[dirName] = []; 
                      }else{ if(filename && !layout.value.file.invalidDir.includes(filename))layout.value.file.invalidDir.push(filename) }
                      console.log('DIR', filename, path, dirName, filenameInZip)
                    }else{ 
                      let tag = "0" 
                      if(dirName && path.obj.length==2)tag = dirName
                      if(!layout.value.file.dir[tag])layout.value.file.dir[tag] = []
                      if(layout.value.file.dir[tag])layout.value.file.dir[tag].push(f)
                      if(!layout.value.file.files[f.extension])layout.value.file.files[f.extension] = []; 
                      layout.value.file.files[f.extension].push(f)
                      console.log('FILE', filename, path, dirName, filenameInZip)
                    }
                  }
                  if(excludeFile){
                    if(isDir){ 
                      if(filename && !layout.value.file.invalidDir.includes(filename))layout.value.file.invalidDir.push(filename)
                    }else{ 
                      //console.log('excludeFile',f)
                      if(!layout.value.file.invalidFiles[f.extension])layout.value.file.invalidFiles[f.extension] = []; 
                      layout.value.file.invalidFiles[f.extension].push(f) 
                    }
                  }
                });
                if(!Object.keys(layout.value.file.files).length)empty = true
              }else{ empty = true }  
              
              if(Object.keys(layout.value.file.invalidFiles).length || Object.keys(layout.value.file.invalidDir).length)
                layout.value.warnings.push("Invalid files or directories will not be included in the dataset")

              if(layout.value.datasetData.type=='imageObjectDetection' && Object.keys(layout.value.file.dir).length)
                layout.value.warnings.push("Object detection dataset do not require tag directories")

              if((layout.value.datasetData.type=='MULTICLASS' || layout.value.datasetData.type=='MULTILABEL') && !Object.keys(layout.value.file.dir).length)
                layout.value.error = "Classification dataset require tags directories"
              
              if(!layout.value.error && empty)
                layout.value.error = "The uploaded zip file is empty or does not have valid images"

            }else if(layout.value.type=="image"){
              const reader = new FileReader();
              reader.onload = async () => { 
                layout.value.uploadThumb = { image: reader.result, size: await $image.getDimensions(reader.result) }
              }
              reader.readAsDataURL(file);
              layout.value.file.blob = new Blob([file], {type: file.type });
            }
          }
         layout.value.loading  = false
        });
    }

    
    const resetDropzone = async () => {
      if(dropzoneSingleRef.value)dropzoneSingleRef.value.dropzone.removeAllFiles(true); 
      layout.value.loading  = false
      layout.value.loading  = false
      layout.value.success  = false
      layout.value.type     = layout.value.type ? layout.value.type : "zip"
      layout.value.file     = false
      layout.value.error    = false
      layout.value.comments = ""
      layout.value.warnings = []
      layout.value.zip      = { bytesTransferred: 0, totalBytes: 0, uploadStatus: false, percentage: 0 }
    }

    const load = async () => {
      if(layout.value.dataset){
        layout.value.datasetData = await rosepetalModel.dataset.get(layout.value.dataset) 
        layout.value.datasetTags = await rosepetalModel.dataset.getTags(layout.value.dataset) 
      }
    }

    const upload = async () => { 
      //let $_firebase = rosepetalModel._firebase
      layout.value.uploading = true
      layout.value.debug     = ""
      var countFiles         = 0
      var nowDate            = new Date()
      var upZip              = new JSZip() 
      let storageConfig      = $vertex.getConfig()
      var storageRef         = rosepetalModel._firebase.firebase.app().storage("gs://" + storageConfig.projectId).ref();  //$_firebase.firebase().app().storage("gs://" + storageConfig.projectId).ref(); 
      if(layout.value.type=="zip"){
        if(Object.keys(layout.value.file.dir).length){
          console.log(layout.value.file.dir)
          
          for(let zf in layout.value.file.dir){
            console.log("drs",layout.value.file.dir)

            if(layout.value.datasetData.type=='MULTICLASS' || layout.value.datasetData.type=='MULTILABEL')upZip.folder(zf);

            for(let zi=0; zi < layout.value.file.dir[zf].length; zi++){
              let ifile = layout.value.file.dir[zf][zi].file
              if(ifile){
                if(layout.value.datasetData.type=='MULTICLASS' || layout.value.datasetData.type=='MULTILABEL') upZip.folder(zf).file(ifile.name.toString().split('/').pop(), ifile._data, {binary: true})
                else upZip.file(ifile.name.toString().split('/').pop(), ifile._data, {binary: true})
                countFiles++
              }
            }
            
          } 
          console.log("countFiles",countFiles)
          let zipName     = layout.value.dataset + "." + nowDate.getTime() + ".zip"
          let uploadRef   = await storageRef.child('upload/manual/zip/' + zipName); //
          await upZip.generateAsync({type:"blob"}).then(async function (blob) { 
            await new Promise((resolve, reject) => {
              const task = uploadRef.put(blob)
              task.on(
                "state_changed",
                (snapshot) => {
                  // Se lanza durante el progreso de subida
                  if(snapshot._delegate.bytesTransferred)layout.value.zip.bytesTransferred = snapshot._delegate.bytesTransferred
                  if(snapshot._delegate.totalBytes)layout.value.zip.totalBytes = snapshot._delegate.totalBytes
                  if(snapshot._delegate.state)layout.value.zip.uploadStatus = snapshot._delegate.state
                  if(snapshot._delegate.bytesTransferred && snapshot._delegate.totalBytes)layout.value.zip.percentage = (snapshot._delegate.bytesTransferred / snapshot._delegate.totalBytes) * 100;
                  if(layout.value.zip.percentage)layout.value.debug = layout.value.zip.percentage.toString().split(".")[0] + "%"  
                },
                (error) => {
                  // Si ha ocurrido un error aquí lo tratamos
                  layout.value.error  =  error
                  reject(error);
                },
                async () => {
                  // Una vez se haya subido el archivo,
                  await rosepetalModel.dataset.fileOnFinalize({ datasetID: layout.value.dataset, filename: zipName }) 
                  await rosepetalModel.dataset.uploadZipReintent(layout.value.dataset, config.functions.usapi); 
                  console.log('UPLOADED FILE: '+ zipName)
                  let dsTags      = await rosepetalModel.dataset.getTagsCounter(layout.value.dataset) 
                  let initCount   = dsTags.count
                  console.log('INIT TAGS COUNTER '+ dsTags.count)
                  console.log('UPLOAD FILES '+ countFiles)
                  let intervCnt   = 0
                  let intervClr   = false
                  let intervId    = setInterval(async () => {
                                    layout.value.debug = "Adding images to the dataset, Please wait..."
                                    let dsData         = await rosepetalModel.dataset.get(layout.value.dataset) 
                                    let dsStatus       = await rosepetalModel.dataset.getStatus(layout.value.dataset) 
                                    dsTags             = await rosepetalModel.dataset.getTagsCounter(layout.value.dataset) 
                                    layout.value.datasetImageCount = dsTags.count ? dsTags.count : 0
                                    if(zipName==dsData.uploadRef){
                                      console.log('- DATASET FILE: '+ dsData.uploadRef + " - " + 'STATUS: ' + dsData.uploadStatus)
                                      console.log('  COUNT: ' + dsTags.count)
                                      console.log('  STATUS:' + ( dsStatus.inProgress ? dsStatus.action : 'DETENIDO') )
                                      if(dsData.uploadStatus){
                                        //layout.value.debug += " (" + dsData.uploadStatus + ")"
                                        switch(dsData.uploadStatus){
                                          case "reintent":    console.log('-- LOAD REINTENT'); /*await rosepetalModel.dataset.uploadZipReintent(layout.value.dataset, config.functions.usapi);*/ break;
                                          case "error":       console.log('-- LOAD ERROR'); /*await $dataset.uploadZipReintent(layout.value.dataset);*/ break;
                                          case "success":     intervClr = true; break;
                                          case "processing":  break;
                                          default: break;
                                        }
                                      }
                                    }
                                    console.log('datasetImageCount: '+ layout.value.datasetImageCount + ' - initCount: '+ initCount + ' - countFiles: '+ countFiles+ "="+((parseInt(layout.value.datasetImageCount)-parseInt(initCount))-parseInt(countFiles)))
                                    if(intervCnt==240 || !((parseInt(layout.value.datasetImageCount)-parseInt(initCount))-parseInt(countFiles)))intervClr = true
                                    else intervCnt++;
                                    if(intervClr){
                                        console.log('--- CLEAR INTERVAL: '+ intervClr, dsData)
                                        layout.value.uploading      = false
                                        if(!dsData.uploadStatus || dsData.uploadStatus=="error"){ layout.value.error = dsData.uploadStatusMsg
                                        }else{ layout.value.success   = true }
                                        if(!((parseInt(layout.value.datasetImageCount)-parseInt(initCount))-parseInt(countFiles)))layout.value.success = true
                                        if(layout.value.success)setTimeout(async () => { if (document.getElementById("refreshMosaicBtn"))document.getElementById("refreshMosaicBtn").click() }, 300);
                                        await rosepetalModel.dataset.update(layout.value.dataset, { uploadStatus: layout.value.error ? "error" : "success" })
                                        await rosepetalModel.event.saveEvent('dataset.upload.zip',{ 
                                              uid: useStore().state.main.User.uid, 
                                              status: layout.value.error ? "error" : "success", 
                                              dataset: layout.value.dataset, 
                                              zip: zipName }, layout.value.error ? true : false);
                                        clearInterval(intervId)
                                        resolve();
                                    }
                                  }, 25000);
                }
              );
            });
            //saveAs(blob, zipName); 
          });
        }
      }else if(layout.value.type=='image'){
        let updateResult              = await rosepetalModel.dataset.uploadImage({ 
                                                                    datasetId: layout.value.dataset, 
                                                                    image:     layout.value.file.blob, 
                                                                    name:      nowDate.getTime() + "." + layout.value.file.name.toString().split('.').pop(),
                                                                    comments:  layout.value.comments 
                                                                  })
        layout.value.uploading        = false                                              
        if(updateResult.status == "success"){
          layout.value.success         = true
          layout.value.uploadedPreview = updateResult.base64
          setTimeout(async () => { if (document.getElementById("refreshMosaicBtn"))document.getElementById("refreshMosaicBtn").click() }, 300);
        }else layout.value.error       = updateResult.error    
      }  
    }

   
    

    onMounted( async () => { 
      await dropzoneInit()
      layout.value.dataset = props.dataset
    });
    
    watch(() => props.dataset, async () => {  layout.value.dataset = props.dataset });
    watch(() => layout.value.dataset, async () => { await load() });

    return {
      dropzoneOptions,
      dropzoneInit,
      resetDropzone,
      upload,
      props,
      layout,
    };
  },
  methods: {
    goAction: async function (a) { if(this.$parent?.goAction)this.$parent.goAction(a) },
    refreshMosaic: async function () { if(this.$parent?.refreshMosaic)this.$parent.refreshMosaic() },
  }
});
</script>
<style>
.dropzone{ max-width: calc(100% - 8%) !important};
.dropzoneBoxContent{ max-width: 100%; min-width: 450px;}
.font-italic{ font-style: italic !important; }
.__uploaddzmin{ font-size: 11px}
.uploadFileFull{ position: fixed !important; left: 0 !important; top: 0 !important; z-index: 999 !important; width: 100% !important; height: 100vh !important; background-color: #f1f2f1; }
.uploadingFull{ position: fixed !important; left: 0 !important; top: 0 !important; z-index: 999 !important; width: 100% !important; height: 100vh !important; background-color: #f1f2f1; }
</style>