
import moment from 'moment';
const stc = require('string-to-color');

const modelHelper = {
    StringtoHex(str) { return stc(str); },
    getTimestampDate(d,type=false){
      let date = new Date(d); 
       if(type){
        if(type=='full')return moment(date).format("DD/MM/YYYY HH:mm:ss"); 
        else if(type=='date'){
          if(moment().format("DD/MM/YYYY")==moment(date).format("DD/MM/YYYY"))return 'Today'
          return moment(date).format("DD/MM/YYYY")
        }
        else if(type=='time')return moment(date).format("HH:mm:ss")
        else return moment(date).format("DD/MM/YYYY HH:mm:ss");
       }else{ return moment(date).format("DD/MM/YYYY HH:mm:ss"); } 
     },
     getUnixDate(d){ return moment.unix(d/1000).format("DD MMM YYYY hh:mm a")},
     getFbDate(d){ return this.getTimestampDate((d.seconds * 1000 + d.nanos / 1000000),'date') },
     orderParams(o){ return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {}); },
     formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
     },
     capitalize(s){ return s.charAt(0).toUpperCase()+s.toLowerCase().slice(1) },
     formatScore(value) { let val = (value/1).toFixed(0).replace('.', ','); return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") },
  };

  const install = app => {
    app.config.globalProperties.$mh = modelHelper;
  };
  
  export { install as default, modelHelper as helper };
  