<template>
  <div>
    <div id="backIframeController" class="w-full h-full">
      <div v-if="layout.error" class="flex flex-col items-center text-center pt-32">
        <img :src="require('@/assets/images/rosepetal/icon/errorNet.png')" class="w-32" />
        <div class="mt-5 text-gray-600">{{ $t('Controller is offline') }}</div>
        <button class="btn flex items-center bg-gray-100 border-gray-300 py-1 px-8 mt-3 text-sm font-normal hover:bg-blue-900 hover:text-white" @click="forceReload()">{{ $t('Retry') }}</button>
      </div>
      <div v-else class="flex flex-col items-center text-center"><img :src="require('@/assets/images/rosepetal/icon/train7.gif')" class="w-72" /></div>
    </div>
    <nav id="menuController" class="top-nav pt-0 mt-0 pl-0 hidden">
      <ul>
        <li>
          <a class="top-menu pt-0 mt-0">
            <div class="top-menu__title">Information <ChevronDownIcon class="top-menu__sub-icon" /></div>
          </a>
          <ul>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadAJ('/view/dashboard')">Dashboard</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/metrics/index.html')">Metrics</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/system/logs.html')">Logs</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/system/hwinfo.html')">Hardware</div></a></li>
          </ul>
         </li>
         <li>
          <a class="top-menu pt-0 mt-0">
            <div class="top-menu__title">Tools <ChevronDownIcon class="top-menu__sub-icon" /></div>
          </a>
          <ul>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="load(':4040')">IDE</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/navigator/index.html')">File Manager</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/system/terminal.html')">Terminal</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadAJ('/view/power')">Power</div></a></li>
          </ul>
        </li>
        <li>
          <a class="top-menu pt-0 mt-0">
            <div class="top-menu__title">Services <ChevronDownIcon class="top-menu__sub-icon" /></div>
          </a>
          <ul>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/system/services.html')">Services</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/podman/index.html')">Containers</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/machines/index.html')">Virtual Machines</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/updates/index.html')">Updates</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadAJ('/view/packages/apt')">APT Packages</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadAJ('/view/packages/pip')">PIP Packages</div></a></li>
          </ul>
        </li>
        <li>
          <a class="top-menu pt-0 mt-0">
            <div class="top-menu__title">Settings <ChevronDownIcon class="top-menu__sub-icon" /></div>
          </a>
          <ul>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/system/index.html')">System</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/storage/index.html')">Storage</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/network/index.html')">Network</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadDH('/')">DHCP status</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadDH('/dhcp_leases')">DHCP leases</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadDH('/dhcp_config')">DHCP config</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadDH('/dhcp_start_stop_restart')">DHCP start/stop</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/users/index.html')">Users</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadAJ('/view/datetime')">Datetime</div></a></li>
            
          </ul>
        </li>
      </ul>
    </nav>  
  </div>
</template>
<script>
import { defineComponent,onMounted, computed, ref, onUnmounted} from "vue"; 
import * as rosepetalModel from "rosepetal-model";
import cash from "cash-dom";
import { useStore } from "@/store";
import config from '../../etc/rosepetal.json';
import { view as $v } from "@/model/view";

const nodeConfig = config.nodeConfig;

export default defineComponent({

  setup() {

    const store            = useStore();   
    const User             = computed(() => store.state.main.User);
    const ctrlMenu         = ref([])
    const cockpitAuth      = ref()
    const layout           = ref({ error: true });

    /*const loadAJ = (url) => {  
      document.getElementById("controllerFrame").style.display = 'block';
      //console.log(nodeConfig.host+"/"+url+"?uid="+User.value.uid)
      //document.getElementById("controllerFrame").src=nodeConfig.host+"/"+url; 
      load("/ajenti"+url+"?uid="+User.value.uid); 
    };*/

    const loadAJ = async (url) => {  
      layout.value.error  = false;
      const uiFrame       = document.getElementById("controllerFrame");
      uiFrame.src         = "about:blank";  
      uiFrame.onload      = async () => {
        let _status       = await rosepetalModel.controller.getStatus()
        if(_status.error){
          cash("#menuController").addClass("hidden");
          cash(".content").removeClass("hidden");
          uiFrame.style.display = 'none';
          layout.value.error    = true;
          uiFrame.src           = "about:blank";  
        }else{
          cash("#topBarControllerIframe").css("display", "block");
          cash(".content,#backIframeController").addClass("hidden");
          uiFrame.style.display = 'block';
          cash("#menuController").removeClass("hidden");
        }  
      };
      uiFrame.src = nodeConfig.host + "/ajenti"+url+"?uid="+User.value.uid
    }

    function load(url) { 
      document.getElementById("controllerFrame").src=nodeConfig.host+url; 
    }

    function loadCP(url) {
      /*
      var auth   = cockpitAuth.value.user+":"+cockpitAuth.value.password 
      var xhr    = new XMLHttpRequest();
      xhr.open("GET", nodeConfig.host+"/cockpitp/cockpit/login", false);
      xhr.setRequestHeader("Authorization", "Basic " + btoa(auth));  
      xhr.send();
      */
      load ("/cockpitp/cockpit/@localhost" + url);
      /*
      const uiFrame       = document.getElementById("controllerFrame");
      uiFrame.onload      =  () => {
        cash("#topBarControllerIframe").css("display", "block");
        cash(".content,#backIframeController").addClass("hidden");
        cash("#menuController").removeClass("hidden");
      };
      uiFrame.src = nodeConfig.host + "/cockpitp/cockpit/@localhost" + url */
    }

    function loadDH(url) { 
      load(":4041"+url); 
    }

    onMounted( async () => { 
      cash(".dashboxItem,.side-nav").addClass("hidden");
      cash("body").css("overflow-y", "hidden");
      cash(".content").addClass("rpContentNodeBackground");
      cash(".content").before(cash("#menuController"))
      cash(".top-bar-node-menu").removeClass("hidden");
      cash(".top-bar-node-menu-side-menu-controller").addClass("top-bar-node-menu-current");
      if(cash("#controllerFrame").attr("src")=="about:blank"){
        cockpitAuth.value = await $v.getSettings('cockpit');
        await loadAJ('/view/dashboard')
      }else{
        layout.value.error = false;
        cash("#topBarControllerIframe").css("display", "block");
        cash(".content,#backIframeController").addClass("hidden");
        document.getElementById("controllerFrame").style.display = 'block';
        cash("#menuController").removeClass("hidden");
      }
    });

    onUnmounted( async () => { 
     cash("body").css("overflow-y", "scroll");
     cash(".content").removeClass("rpContentNodeBackground");
     cash(".dashboxItem,.side-nav,.content").removeClass("hidden");
     cash("#topBarControllerIframe").css("display", "none");
     cash("#menuController").addClass("hidden");
     cash(".top-bar-node-menu").addClass("hidden");
     cash(".top-bar-node-menu-current").removeClass("top-bar-node-menu-current");
    });

    const forceReload = async () => { await loadAJ('/view/dashboard') }
    
    return {
      layout,
      ctrlMenu,
      loadAJ,
      loadCP,
      loadDH,
      load,
      forceReload
    }
  }

});
</script>

<style scoped>
  #menuController{ width: 40%; margin: 0 30%}

  #menuController li ul{ padding: 20px 0;}
  #menuController .top-menu{ height:45px !important; }
  #menuController .top-submenu{ height:23px !important;  }
  #menuController .top-menu__title{ font-weight: normal !important;  font-size: 12px; cursor: pointer }
  #menuController .top-menu__title:hover{ 
    --tw-text-opacity: 1;
    color: rgba(145, 199, 20, var(--tw-text-opacity));
   }
  #menuController .top-nav > ul > li:hover{ background: none !important;}
</style>