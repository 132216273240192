<template>
   <div>
      <div class="grid chat grid-cols-12 gap-6">
        <div class="col-span-12">
          <!-- demo set config -->
          <div >
            <div class="intro-y block sm:flex items-center h-10">
              <span class="text-base font-medium truncate mr-5">{{ $t('Demo settings') }}</span>
            </div>
            <div id="form-validation pt-5">
              <div class="preview pb-5">
                  <form ref="updMonDemo" :class="validate-form" @submit.prevent="updateDemoSet('updMonDemo',$event)">
                    <div class="input-form">
                        <label class="form-label">{{ $t('Bucket') }}</label>
                        <input type="text" class="form-control" name="bucket" :value="MonDemoSet.bucket"  />
                    </div> 
                    <div class="input-form pt-2">
                        <label class="form-label">{{ $t('File with test image list') }}</label>
                        <input type="text" class="form-control" name="file" :value="MonDemoSet.file"  />
                    </div>  
                    <div class="input-form pt-2">
                        <label class="form-label">{{ $t('Rest') }}</label>
                        <input type="text" class="form-control" name="rest" :value="MonDemoSet.rest"  />
                    </div>  
                    <div class="input-form pt-2">
                        <label class="form-label">{{ $t('Frequency') }}</label>
                        <input type="text" class="form-control" name="timeout" :value="MonDemoSet.timeout"  />
                    </div>
                    <div class="input-form pt-5 border-t border-gray-200 dark:border-dark-5 mt-5"><button type="submit" class="btn btn-primary">{{ $t('Save') }}</button></div> 
                  </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted , onBeforeMount } from "vue";
import { monitor as $monitor } from "@/model/monitor";
import { helper as $h } from "@/utils/helper";
//import { tag as $tag } from "@/model/tag";
//import cash from "cash-dom";
//import "smart-webcomponents/source/styles/smart.default.css";
//import "smart-webcomponents/source/modules/smart.querybuilder.js";

export default defineComponent({
  props: {
    monitor: {
      type: String,
      required: true
    }
  },
  created () {
    this.helper     = $h
  },  
  setup(props) {
    
    
    const MonDemoSet = ref({})

    onBeforeMount( async () => {
      
    });

    onMounted( async () => {
      let monDemo      = await $monitor.get(props.monitor)
      MonDemoSet.value = monDemo.demo
    });


    return {
      props,
      MonDemoSet
    };
  },
  methods:{
    updateDemoSet: async function(formID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        let updDemo = { monitor: this.props.monitor, data: { demo: {} }}
        for (let i = 0; i < this.$refs[formID].length; i++) {  
         if(this.$refs[formID][i].name=="bucket")updDemo.data.demo['bucket'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="file")updDemo.data.demo['file'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="rest")updDemo.data.demo['rest'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="timeout")updDemo.data.demo['timeout'] = this.$refs[formID][i].value;
        }  
        await $monitor.updateDemo(updDemo);
        $h.NotificationTxt({text: this.$i18n.t('Demo configuration successfully updated')})
      }
    },
  }
});
</script>