'use strict';

const project       = require("./project");
const axios         = require('axios').default;

const nodeRed = {
    config: { host: project.getConfig()?.nodeConfig?.host },
    setConfig ({config}) {
        this.config = config
    },
    getFlow({flowId}) {
        return new Promise((resolve, reject) => {
            axios.get(this.config.host + '/nodered/flow/'+flowId,  {})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
    },
    setFlow({flowId,node}) {
        return new Promise((resolve, reject) => {
            axios.put(
                this.config.host + '/nodered/flow/'+flowId, 
                JSON.stringify(node), 
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
    },
    importToFlow({flowId,node}) {
        return new Promise((resolve, reject) => {
            this.getFlow({flowId: flowId}).then(flow => {
                flow.nodes = flow.nodes.concat(node)
                this.setFlow({flowId: flowId, node: flow})
                .then(response => resolve(response))
                .catch(error => reject(error))
            }).catch(error => reject(error))
        })
    },
    async getToken(){
        const config        = project.getConfig()
        let resp        = { status: "error", error: false, token: {} }
        await axios.get(config.nodeConfig.host + '/nodered/auth/token',  {
                "id": "admin",
                "password": "oneiron333REs"
            })
            .then(response => {
                console.log('Response getToken from Node-RED API:', response.data)
                resp.settings = response.data
                resp.status   = "success"
            })
            .catch(error => {
                console.log('Error getToken in Node-RED API:', error)
                resp.error = error
            })
        return resp
    },
    async getSettings(){
        const config        = project.getConfig()
        let resp        = { status: "error", error: false, settings: {} }
        const configReq = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Basic ' }, timeout: 10000,};
        await axios.get(config.nodeConfig.host + '/nodered/settings', configReq)
            .then(response => {
                console.log('Response getSettings from Node-RED API:', response.data)
                resp.settings = response.data
                resp.status   = "success"
            })
            .catch(error => {
                console.log('Error getSettings in Node-RED API:', error)
                resp.error = error
            })
        return resp
    },
    async getFlows(){
        let resp        = { status: "error", error: false, tabs: [], uiTabs: [], flowsTypes: {} }
        const config    = project.getConfig()
        //const config    = project.getConfig()
        //config.nodeConfig.username = "info@rosepetal.ai"
        //config.nodeConfig.password = "123456"
        //const credentials = Buffer.from(config.nodeConfig.username + ':' + config.nodeConfig.password).toString('base64');
        const configReq = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Basic ' }, timeout: 10000,}; //+ credentials,
        await axios.get(config.nodeConfig.host+'/nodered/flows/',configReq).then(response => { 
            console.log('response node-Red getFlows', response); 
            if(response.data.length){
                for(let i=0; i<response.data.length; i++){
                    if(!resp.flowsTypes[response.data[i].type])resp.flowsTypes[response.data[i].type] = 1; resp.flowsTypes[response.data[i].type]++
                    if(!response.data[i].disabled){
                        let responseData = response.data[i]
                        if(response.data[i].type=="tab" && response.data[i].label)resp.tabs.push(responseData)
                        if(response.data[i].type=="ui_tab" && response.data[i].name && !response.data[i].hidden)resp.uiTabs.push(responseData)
                    }
                    
                }
            }
            resp.status = "success"
        }).catch(error => { resp.error = error });
        return resp
    },
    /*
    async changeUiTab(flowId){
        console.log(flowId)
        let resp        = { status: "error", error: false}
        const configReq = { headers: { 'Content-Type': 'application/json', 'Authorization': 'Basic ' }, timeout: 10000,}; 
        await axios.post(config.nodeConfig.host+'/nodered/ui/#!/2', configReq).then(response => { 
            console.log('response node-Red change UI TAB', response); 
            resp.status = "success"
        }).catch(error => { resp.error = error });
        return resp
    },*/
    async changeUiTab(panelId){
        const config        = project.getConfig()
        let resp        = { status: "error", error: false }
        const configReq = { headers: { 'Content-Type': 'application/json' } }
        const body      = { command: 'change', argument: panelId }
        console.log('changeUiTab Call', config.nodeConfig.host + '/nodered/ui/'); 
        await axios.put(config.nodeConfig.host + '/nodered/ui_cont/', body, configReq)
          .then(response => {
            console.log('Response from Node-RED API:', response.data)
            resp.status = "success"
          })
          .catch(error => {
            console.log('Error in Node-RED API:', error)
            resp.error = error
          })
        return resp
      }
}  

module.exports = nodeRed