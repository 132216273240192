<template>
    <div class="grid grid-cols-12 gap-6">
      <!-- Search -->
      <div class="col-span-12 lg:col-span-3 xl:col-span-3 xxl:col-span-2" v-if="LayoutOptions.search">
        <div class="intro-y box p-5">
          <div class="relative flex items-center mb-3"><div class="font-medium text-xl">{{ $t('Search') }}</div></div>
          <div class="text-xs mb-3">{{ $t('Date or date range') }}</div>
          <div class="intro-y block sm:flex items-center mb-4 border border-gray-300">
            <CalendarIcon class="w-4 h-4 z-10 absolute my-auto inset-y-0 ml-1.5 left-0 cursor-pointer" />
            <Litepicker
              ref="Litepicker"
              v-model="DateRange"
              :options="{
                    autoApply: true,
                    singleMode: false,
                    numberOfColumns: 2,
                    numberOfMonths: 2,
                    showWeekNumbers: true,
                    format: 'DD/MM/YYYY',
                    lang: $i18n.locale ? $i18n.locale : 'en-EN',
                    buttonText: { apply: $t('Search'), cancel: $t('Cancel') },
                    maxDays: 60,
                    maxDate: new Date(),
                    dropdowns: {
                      minYear: new Date().getFullYear(),
                      maxYear: null,
                      months: true,
                      years: false
                    }
                  }"
                 :disabled="Loading"
              class="form-control w-full box pl-6 cursor-pointer" />
          </div>
          <div class="text-xs mb-3">{{ $t('Filter results') }}</div>
          <div v-html="this.tableModel.renderFilter(filter,filterOpt)"></div>
          <div class="mt-5" :class="Loading ? 'hidden' : ''">
            <span v-html="this.tableModel.renderSearchBtn($t('Search'))" ref="SearchBtn"></span>
            <span v-html="this.tableModel.renderResetBtn($t('Reset'))"  ref="resetBtn"></span>
          </div>
        </div>
      </div>  
      <!-- /Search -->
      <div class="col-span-12 lg:col-span-9 xl:col-span-9 xxl:col-span-10">
        <div>
          <!-- stats -->
          <div class="col-span-12 grid grid-cols-12 gap-6 mb-5" :class="LayoutOptions.stats && !Loading ? '' : 'hidden'">
            <!-- Results -->
            <div class="col-span-12 sm:col-span-6 intro-y" :class="Stats.count ? 'xxl:col-span-3' : 'xxl:col-span-12'">
              <div class="box p-5 pt-0 pr-3 StatsBox" :class="Stats.count ? 'zoom-in' : ''">
                <div class="flex pt-5">
                  <div class="text-lg font-medium truncate mr-3">{{ $t('Results') }}</div>
                  <div class="py-1 px-2 rounded-full text-xs bg-gray-200 dark:bg-dark-5 text-gray-600 dark:text-gray-300 cursor-pointer ml-auto truncate">
                    <CalendarIcon class="w-4 h-4 z-10 mr-1" />{{ this.helper.dateRangeLabel(DateRange) }}
                  </div>
                </div>
                <div class="text-3xl font-medium truncate pt-6" v-if="Stats.count">
                  <div class="inline-block align-top"><LayersIcon class="w-16 h-16 z-10 mr-1 text-gray-200" /></div>
                  <div class="inline-block align-top pt-1 pl-5">
                    <div class="text-center">{{this.helper.formatScore(Stats.count)}}<span v-if="tableData && tableData.length!=Stats.count" class="text-xs">/ {{ this.helper.formatScore(tableData.length) }}</span></div>
                    <div class="text-gray-600 text-base" v-if="Stats.count">{{ itemName }}</div> 
                  </div>
                </div>
                <div class="text-basetext-gray-600 pt-2" v-else>
                  <div class="mb-5">{{ $t('They have not registered') }} {{ itemName.Case() }} {{ $t('for the dates and filters selected') }}.</div>
                  <div class="border w-1/2 text-theme-17 p-5">
                    <div class="text-2xl font-bold text-gray-600">{{ this.helper.dateRangeLabel(DateRange) }}</div>
                    <div v-if="filter.value">
                      <span v-for="f in filterOpt" v-bind:key="f.id"><span class="text-base font-bold " v-if="f.id==filter.field">{{ f.name }}</span></span>
                      <span v-for="o in filterOperator" v-bind:key="o.id"><span class="text-base font-bold text-gray-600" v-if="o.id==filter.type"> - <span v-if="o.name">{{ o.name.toLowerCase() }}</span><span v-else>{{ o.id }}</span>:</span></span>
                      <span v-if="filter.value"><span class="text-base font-bold pl-2"> {{ filter.value }}</span></span>
                      <div class="mt-5"><button  type="button" class="btn btn-secondary w-full sm:w-1/2" @click="ResetFilters()">{{ $t('Reset filters') }}</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Average Donut -->
            <div class="col-span-12 sm:col-span-6 xxl:col-span-4 intro-y" v-if="Stats.filterTags && Stats.filterTags.labels.length" :class="!Loading && Stats.count ? '' : 'hidden'">
              <div class="box p-2 zoom-in pt-0 StatsBox">
                <div class="inline-block align-top w-1/3">
                  <div class="flex-none pl-2 mt-8 pr-4"><ReportDonutChart1 :width="115" :height="115" :data='Stats.filterTagsDonut' /></div>
                </div>
                <div class="inline-block align-top w-2/3">
                  <div class="text-gray-600 dark:text-gray-600 text-xs border-b border-gray-200 flex mb-2 pb-2 mt-4">
                    <div class="w-2/3">{{ $t('Category') }}</div><div class="ml-auto">{{ $t('Total') }}</div>
                  </div>
                  <div class="flex text-xs" v-for="(t,k) in Stats.filterTags.labels" v-bind:key="t">
                    <div v-if="t" class="w-2/3">{{t}}</div>
                    <div class="ml-auto w-15 text-right" v-if="t && Stats.filterTags.datasets[0]">
                      {{Stats.filterTags.datasets[0]["data"][k]}} <span class="text-gray-700">/ {{ (( Number.parseFloat(Stats.filterTags.datasets[0]["data"][k]/ Stats.count) * 100)).toFixed(2) }}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Activity -->
            <div class="col-span-12 sm:col-span-12 xl:col-span-6 xxl:col-span-5 intro-y">
              <div class="box p-5 pb-0 zoom-in StatsBox" v-if="Stats.totalGraph && Stats.count">
                <div class="flex">
                  <div class="text-lg font-medium truncate mr-3">{{ $t('Activity') }}</div>
                </div>
                <div v-if="Stats.totalGraph.labels.length>1"><div class="mt-3"><SimpleLineChart1 :height="60" class="-ml-1" :data="Stats.totalGraph"  /></div></div>
                <div v-else>
                   <div class="text-3xl font-medium truncate pt-2" v-if="Stats.totalGraph.datasets[0]">{{ this.helper.formatScore(Stats.totalGraph.datasets[0].data[0]) }}</div>
                   <div class="text-gray-600 mt-1" v-if="Stats.totalGraph.datasets[0]">{{ itemName }}</div>
                   <div class="text-gray-600 mt-0" v-if=" Stats.totalGraph.labels[0]">{{ Stats.totalGraph.labels[0] }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- /stats -->

          <!-- Table -->
          <div class="intro-y box">
            <div class="relative flex items-center p-5 pb-0 pt-3" :class="!Loading && Stats.count ? '' : 'hidden'">
              <div class="font-medium text-xl">
                <span v-if="Stats.total!=Stats.count">{{ $t('Search results') }}</span><span v-else>{{ $t('Register of') }} {{ itemName }}</span>
                <!-- Filter active -->
                <span v-if="filter.value">
                  <span class="text-gray-500 pl-10 text-base"><FilterIcon class="w-4 h-4 z-10" /> {{ $t('Applied filter') }}:</span>
                  <span v-for="f in filterOpt" v-bind:key="f.id"><span class="font-bold text-theme-17 text-base pl-3" v-if="f.id==filter.field">{{ f.name }}</span></span>
                  <span v-for="o in filterOperator" v-bind:key="o.id"><span class="text-gray-500 text-base pl-2" v-if="o.id==filter.type"><span v-if="o.name">{{ o.name.toLowerCase() }}</span><span v-else>{{ o.id }}</span></span></span>
                  <span v-if="filter.value"><span class="font-bold text-theme-17 truncate text-base pl-2">"{{ filter.value }}"</span></span>
                </span>
              </div>
              <div class="ml-auto flex">
                  <div class="dropdown w-1/2 sm:w-auto"><button id="tabulator-print" class="btn outline-secondary w-50 sm:w-auto mr-2" ref="PrintBtn"><PrinterIcon class="w-4 h-4 z-10 mr-1" /> {{ $t('Print') }}</button></div>
                  <div class="dropdown w-1/2 sm:w-auto"><button class="btn btn-outline-secondary w-50 sm:w-auto" ref="ExportBtn"><FileTextIcon class="w-4 h-4 z-10 mr-1" /> {{ $t('Export') }}</button></div> 
              </div>
            </div>
            <div class="p-5 pt-0 pb-5 block" :class="Loading || !Stats.count ? 'hidden' : ''">
              <div class="overflow-x-auto scrollbar-hidden">
                <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
              </div>
            </div>
          </div>
          <!-- /Table -->

          <!-- Modal item Details -->
          <div id="modal-item-details" class="modal" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog">
                  <div class="modal-content">
                      <div class="modal-body p-0">
                        <div class="p-5 text-center" v-if="itemDetails">
                          <div class="text-xl mt-2 font-medium">{{ itemDetails.tagname}}</div>
                          <div class="text-gray-600">{{ itemDetails.name }}</div>
                          <div class="text-gray-600 pt-2 text-xs">{{ itemDetails.date }}</div>
                          <div class="flex text-center justify-center items-center mt-3">
                            <span class="py-1 px-4 rounded-full text-xs bg-gray-700 text-white cursor-pointer font-medium mb-5 mr-3" v-if="itemDetails.monitor">{{ itemDetails.monitor }}</span>
                            <span class="py-1 px-4 rounded-full text-xs bg-theme-12 text-white cursor-pointer font-medium mb-5" v-if="itemDetails.dataset">{{ itemDetails.dataset }}</span>
                          </div>
                          <div class="flex lg:justify-center"><img :src="'data:image/png;base64,'+itemDetails.image_data" class="w-full lg:w-2/3"></div>
                          <div v-if="itemDetails.predictionTime" class="mt-5 text-center">
                            <div class="text-md font-medium mb-4">{{ $t('Prediction Time') }}</div>
                            <div class="text-gray-600 mt-5 text-2xl ">{{ itemDetails.predictionTime / 60000 }}</div>
                          </div>
                          <div v-if="itemDetails.prediction" class="mt-5">
                            <div class="text-md font-medium mb-4">{{ $t('Prediction results') }}</div>
                            <table class="table table--sm mb-5">
                                <thead>
                                    <tr>
                                        <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">{{ $t('Label') }}</th>
                                        <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">{{ $t('Score') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(label,key) in itemDetails.prediction.labels" v-bind:key="key" 
                                        :class="label==itemDetails.tag ? 'text-white' : ''">
                                        <td class="border">{{label}}</td>
                                        <td class="border">
                                          <span class="pr-2" :class="label==itemDetails.tag ? 'text-white' : 'text-gray-600'" 
                                                v-if="itemDetails.prediction.scores[key]">{{ itemDetails.prediction.scores[key] }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>
                          <div class="mt-3 px-5 pb-1 text-center">
                            <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1 hover:bg-theme-12 hover:text-white">{{ $t('Close') }}</button>
                          </div>
                        </div>
                      </div> 
                  </div>
              </div>
          </div>
          <!-- /Modal item Details -->

          <!-- Modal item Details -->
          <div id="modal-item-zoom" class="modal" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog modal-xl modal-zoom">
                  <div class="modal-content">
                      <div class="modal-body p-0">
                        <div class="p-5 text-center" v-if="itemDetails">
                          <div id="itemDetailsGimage" v-if="itemDetails.gimage">
                              <img :src="itemDetails.gimage" class="w-full h-full">
                          </div>
                          <div class="flex lg:justify-center" v-else><img :src="'data:image/png;base64,'+itemDetails.image_data" class="w-full lg:w-2/3"></div>
                          <div class="mt-3 px-5 pb-1 text-center">
                            <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Close') }}</button>
                          </div>
                        </div>
                      </div> 
                  </div>
              </div>
          </div>
          <!-- /Modal item Details -->

        </div>
        <div v-if="Loading">
          <div class="intro-y col-span-9 lg:col-span-9 flex flex-col justify-center items-center mt-20 mb-20"> 
              <LoadingIcon icon="three-dots" class="w-12 h-12" />
              <span class="text-md">{{ $t('Looking for records, please wait') }}...</span>
          </div>
        </div>
      </div> 
    </div> 
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
//import { _firebase as $_firebase } from "@/model/firebase";
import { useRouter } from "vue-router";
import { image as $image } from "@/model/image";
import { table as $table } from "@/model/table";
import { helper as $h } from "@/utils/helper";
import { view as $view } from "@/model/view";
//import { tag as $tag } from "@/model/tag";
import dayjs from "dayjs";
import cash from "cash-dom";
import { useI18n } from 'vue-i18n'
import * as rosepetalModel from "rosepetal-model";

import ReportDonutChart1 from "@/components/report-donut-chart-1/Main.vue";
import SimpleLineChart1 from "@/components/simple-line-chart-1/Main.vue";

export default defineComponent({
  components: {
    SimpleLineChart1,
    ReportDonutChart1
  },
  props: {
    options: {
      type: Object,
    }
  },
  created () {
    this.tableModel   = $table,
    this.helper       = $h,
    this.viewModel    = $view
  },  
  setup(props) {

    var $_firebase         = rosepetalModel._firebase

    const router           = useRouter();
    const LayoutOptions    = ref({ search: true, stats: true })
    const Loading          = ref(false)
    const Stats            = ref({})
    const tableData        = ref([])
    const tableRef         = ref()
    const tabulator        = ref()
    const filterOperator   = ref([])
    const PrintBtn         = ref(null)
    const ExportBtn        = ref(null)
    const SearchBtn        = ref(null)
    const resetBtn         = ref(null)
    const {t}              = useI18n(); 
    const itemName         = ref(t('Images'))
    const filterOpt        = ref([
                                  { id: 'name',       name: t('Name')},
                                  { id: 'monitor',    name: t('Monitor')},
                                  { id: 'tagname',    name: t('Category')}
                                ])
    const filter           = reactive({ field: filterOpt.value[0].id, type: "like",  value: "" });
    const itemDetails      = ref()
    const tags             = ref({ names: [] , colors: []})
    const DateRange        = ref(dayjs().subtract(1, "week").format('DD/MM/YYYY')+" - "+dayjs().format('DD/MM/YYYY'));

    const tableComuns   = () => { 
      return [ 
        { formatter: "responsiveCollapse", width: 40, minWidth: 30, hozAlign: "left", resizable: false, headerSort: false },
        // HTML 
        { title: t('Date'), maxWidth: 180, responsive: 1, field: "seconds", vertAlign: "middle", hozAlign: "left", headerSort: true, sorter:"number", print: false, download: false, 
          formatter(cell) { return `<div class="text-gray-600 text-xs whitespace-nowrap">${cell.getData().date}</div>`} 
        },
        { title: t('Monitor'), maxWidth: 130, responsive: 1, field: "monitor", vertAlign: "middle", hozAlign: "left", print: false, download: false, 
          formatter(cell) { return `<div class="py-1 px-2 rounded-full text-xs bg-gray-700 text-white cursor-pointer font-medium">${cell.getData().monitor}</div>`;} },
        { title: t('Dataset'), maxWidth: 130, responsive: 1, field: "dataset", vertAlign: "middle", hozAlign: "left", print: false, download: false, 
          formatter(cell) { return `<div class="py-1 px-2 rounded-full text-xs bg-theme-12 text-white cursor-pointer font-medium">${cell.getData().dataset}</div>`;} },
        { title: t('Image'), maxWidth: 120,responsive: 1,field: "image_data",hozAlign: "center",vertAlign: "middle",headerSort: false, print: false, download: false,
          formatter(cell) { 
            const a = cash(`<div class="flex lg:justify-center"><div class="intro-x w-8 h-8 image-fit"><img src="data:image/png;base64,${cell.getData().image_data}" class="rounded-full"></div></div>`);
            cash(a).on("click", function() {  ImageZoom(cell.getData()) });
            return a[0];
          }
        },
        { title: t('Identifier'), minWidth: 210, responsive: 1, field: "id", vertAlign: "middle", hozAlign: "left", print: false, download: false, headerSort: false, formatter(cell) { return `<div class="text-gray-600 font-normal text-xs whitespace-nowrap">${cell.getData().id}</div>`;} },
        { title: t('Name'), minWidth: 180, responsive: 1, field: "name", vertAlign: "middle", hozAlign: "left", print: false, download: false, headerSort: false, formatter(cell) { return `<div class="text-gray-600 font-normal text-xs whitespace-nowrap">${cell.getData().name}</div>`;} },
        { title: t('Label'), minWidth: 200, responsive: 1,field: "tag",vertAlign: "middle",print: false, download: false, headerSort: true, hozAlign: "left", 
          formatter(cell) { 
            if(cell.getData().tagname)return `<div class="py-2 px-4 rounded-full text-xs bg-white border text-gray-600 cursor-pointer font-medium" ${cell.getData().tagColor ? 'style="border-color: '+cell.getData().tagColor+'; color: '+cell.getData().tagColor+' !important;"': ''}>${cell.getData().tagname}</div>`; 
          } 
        },
        { title: "", responsive: 1, maxWidth: 250, field: "image_data",hozAlign: "center",vertAlign: "middle",headerSort: false, print: false, download: false,
          formatter(cell) { 
            const a = cash(`<div class="flex lg:justify-center"><button class="btn btn-sm btn-primary-soft w-24 text-gray-600">`+t('View report')+`</button></div>`);
            cash(a).on("click", function() {  ItemDetails(cell.getData()) });
            return a[0];
          }
        },
        
        // print 
        { title: "ID",        field: "id", minWidth: 250, visible: false, print: true, download: true },
        { title: t('Monitor'),    field: "monitor", minWidth: 350, visible: false, print: true, download: true },
        { title: t('Name'),    field: "name", minWidth: 350, visible: false, print: true, download: true },
        { title: t('Dataset'),   field: "dataset", minWidth: 150, visible: false, hozAlign: "center", print: true, download: true },
        //{ title: t('Date'),     field: "date", visible: false, print: true, download: true },
        //{ title: t('Category'), field: "tagname", visible: false, print: true, download: true },
      ]
    };

    const ItemDetails  = async (i) => { 
      itemDetails.value = i;
      cash("#modal-item-details").modal("show");
    };

    const ImageZoom  = async (i) => { 
      itemDetails.value = i;
      if(i.uri && i.uri.length > 0 && i.uri.startsWith('gs://'))itemDetails.value.gimage = await getGoogleLink(i.uri)
      cash("#modal-item-zoom").modal("show");
    };
    
    const getStats = async () => { 
      await $table.getStats(tabulator.value, Stats.value, { limit: 5000, labelKey: 'date',dataKey: 'date', group: true, tags: tags.value });  
      Loading.value = false;
    };

    const ResetFilters  = async () => { 
       filter.field    = filterOpt.value[0].id; 
       filter.type     = filterOperator[0] ? filterOperator[0].id : "like"; 
       filter.value    = ""; 
       tabulator.value = $table.resetFilter(tabulator.value, filter)
       await getStats()
    };

    const getGoogleLink = async (uri) => { 
        try { const url = await $_firebase.storage().refFromURL(uri).getDownloadURL(); return url;
        } catch (error) { console.log("getGoogleLink error: "+error); }
    }

    watch( () => props.options, async () => { Object.assign(LayoutOptions.value,props.options); });
    watch( () => DateRange.value, async () => { 
       Loading.value = true;
       tableData.value = await $image.byDateRange(DateRange.value.toString().split(' - ')).then(async function() {
                                await $table.setData(tabulator.value, tableData.value) //set table data
                                await ResetFilters()
                            });
    });

    onMounted( async () => { 
      
      if(router.currentRoute.value.params.dates)DateRange.value = router.currentRoute.value.params.dates

      Loading.value        = true;
      LayoutOptions.value  = Object.assign(props.options, LayoutOptions.value);

      tabulator.value      = await $table.init(tableRef.value, tableComuns(), { printHeader: "<h1>"+itemName.value+"</h1>"})
      tableData.value      = await $image.byDateRange(DateRange.value.toString().split(' - '));
      await $table.setData(tabulator.value, tableData.value) //set table data
      Stats.value.total    = tabulator.value.getData().length
      await getStats()

      filterOperator.value = $table.filterOperators()
      if(PrintBtn.value)
        PrintBtn.value.addEventListener( 'click', function(event) { event.preventDefault();  $table.print(tabulator.value) })
      if(ExportBtn.value)
        ExportBtn.value.addEventListener('click', function(event) { event.preventDefault(); $table.exportCsv(tabulator.value, itemName.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()+'.csv') })
      if(SearchBtn.value){
        SearchBtn.value.addEventListener('click', async function(event) { event.preventDefault(); 
                          for (const k of Object.keys(filter)){ filter[k] = document.getElementById("tabulator-html-filter-"+k).value; }
                          tabulator.value = $table.filter(tabulator.value, filter)
                          await getStats()
                        })
      }      
      if(resetBtn.value)
        resetBtn.value.addEventListener('click', async function(event) { event.preventDefault(); 
                        DateRange.value = dayjs().subtract(1, "week").format('DD/MM/YYYY')+" - "+dayjs().format('DD/MM/YYYY')
                        await ResetFilters()
                       })  
    });

    return {
      Loading,
      tableRef,
      filter,
      filterOpt,
      filterOperator,
      tabulator,
      PrintBtn,
      ExportBtn,
      SearchBtn,
      resetBtn,
      tableData,
      itemDetails,
      LayoutOptions,
      Stats,
      itemName,
      DateRange,
      tags,
      ImageZoom,
      ResetFilters
    };
  }
});
</script>
<style> .StatsBox{ min-height: 170px; } .tabulator-col-title {  text-align: left !important; }</style>