//import { _firebase as $_firebase } from "@/model/firebase";
import { other as $other } from "@/model/db/other";
import * as rosepetalModel from "rosepetal-model";

var $_firebase = rosepetalModel._firebase;

const functions = $_firebase.functions(); 
import config from '@/etc/rosepetal.json';

const project = {
    getConfig() { return config },
    getApiHost() {
        let url    = { host: "" , name: "" }
        if(functions.emulatorOrigin){ 
            url.name   = "localhost"
            url.host   = functions.emulatorOrigin
            url.host   = functions.app_ && functions.app_.options && functions.app_.options.projectId ? url.host+'/'+functions.app_.options.projectId : url.host 
            url.host   = functions.region ? url.host+'/'+functions.region : url.host
            url.host  += "/"
        }else{ 
            url.name   = "online"
            url.host   = "https://" 
            url.host   = functions.region ? url.host+''+functions.region :  url.host
            url.host   = functions.app_ && functions.app_.options && functions.app_.options.projectId ?  url.host+'-'+functions.app_.options.projectId :  url.host 
            url.host  += ".cloudfunctions.net/"
        }
        return url
    },
    async getUsers(opt = false){
        let resp = { status: "error", error: false, count: 0, roleId: false, users: {}  }
        let list = await $other.httpsCallable('api/user/list')
        if(list.data){
            if(opt.roleId)resp.roleId = opt.roleId
            if(opt.uid)resp.uid = opt.uid
            if(list.data.length){
                for(let i=0 ; i < list.data.length ; i++){
                    let pushItem = true
                    if(opt.roleId && (list.data[i]?.role && list.data[i]?.role?.role_id!=opt.roleId))pushItem = false
                    if(opt.uid && (list.data[i]?.uid != opt.uid))pushItem = false
                    if(pushItem)resp.users[list.data[i].uid] = list.data[i]
                }
                resp.count  = Object.keys(resp.users).length
                if(resp.count){
                    resp.status = "success"
                }else{ resp.error = "not found users" } 
            }else{ resp.error = "The project does not have assigned users" }
        }else{ resp.error = "The operation could not be completed" }
        return resp
    },
}
const install = app => { app.config.globalProperties.$project = project; };

export { install as default, project as project };