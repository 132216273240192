const firebase = require('firebase/app');
require('firebase/firestore');
require('firebase/auth');
require('firebase/database');
require('firebase/functions');
require('firebase/storage');
//const other = require("./other");


const _firebase = { 
  config: {},
  setHost: function(config) { 
    if (location.hostname.includes(config.localhost)) {
      const functions = firebase.default.app().functions(config.firebaseRegion);
      functions.useEmulator("127.0.0.1", 5001);
    }
  },
  initializeApp: function(config = false) { 
    this.config = config
    if(!firebase.default || !firebase.default.apps.length){
      const firebaseApp = firebase.default.initializeApp(config);
      //firebaseApp.functions().settings({ timestampsInSnapshots: true, region: 'europe-west1' })
    }
    firebase.default.functions.timestampsInSnapshots = true;
    firebase.default.functions.region                = 'europe-west1';
    return { status: true, projectId: firebase.default.apps[0].options.projectId, msg: 'Rosepetal Model is ready with ' + firebase.default.apps[0].options.projectId + ' project' }
  },

  signInWithEmailAndPassword: function(email, password) { 
    firebase.auth().signInWithEmailAndPassword(email,password).then(async function() { console.log(email+ 'logged in rosepetal-model') }).catch(function(error) { console.log('error login:' + error) });
  },

  signInWithUid: function(uid) { 
    console.log('login signInWithUid')
  },

  getConfig: function() { 
    let config = firebase.default.apps[0] ? firebase.default.apps[0].options : { error: 'No firebase config found' }
    if(config.databaseURL)config.region = config.databaseURL.split('.')[1]
    return config
  },

  firebase: firebase.default,

  auth: firebase.default.auth,

  firestore: firebase.default.firestore,

  database: firebase.default.database,

  storage: firebase.default.storage,

  functions: firebase.default.functions, //.app().functions('europe-west1'),

  getApiHost: function() { 
    let url               = { host: "" , name: "online" }
    let firebaseFunctions = firebase.default.functions()
    if(firebaseFunctions.emulatorOrigin){ 
        url.name   = "localhost"
        url.host   = firebaseFunctions.emulatorOrigin
        url.host   = firebaseFunctions.app_ && firebaseFunctions.app_.options && firebaseFunctions.app_.options.projectId ? url.host+'/'+firebaseFunctions.app_.options.projectId : url.host 
        url.host   = firebaseFunctions.region ? url.host+'/'+firebaseFunctions.region : url.host
        url.host  += "/"
    }else{ 
        url.host   = "https://" 
        //url.host   = firebaseFunctions.region ? url.host+''+firebaseFunctions.region : url.host
        url.host   =  this.getConfig().region ? url.host+''+this.getConfig().region : url.host  
        url.host   = firebaseFunctions.app_ && firebaseFunctions.app_.options && firebaseFunctions.app_.options.projectId ?  url.host+'-'+firebaseFunctions.app_.options.projectId :  url.host 
        url.host  += ".cloudfunctions.net/"
    }
    return url
  },
} 

module.exports = _firebase