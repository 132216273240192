<template>
   <div>
      <div class="grid chat grid-cols-12 gap-6">
        <div class="col-span-12">
          <!-- Add Array item --> 
            <div :id="'add-tag'" class="modal" tabindex="-1" aria-hidden="true" >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body p-0">
                            <form ref="addTag" class="validate-form" @submit.prevent="addTag('addTag',$event)">
                              <div class="p-5 text-left">
                                  <div class="text-2xl mt-2">{{ $t('Add new label') }}</div>
                                  <div class="text-gray-600 mt-2">{{ $t('Device') }}: {{ props.monitor }}</div>
                                  <div class="p-5 pb-2 bg-theme-28 mt-2 pb-5">
                                      <input type="text" name="id" class="form-control" placeholder="ID"  required  />
                                      <input type="text" name="nametag" class="form-control mt-2" placeholder="Name"  required  />
                                      <input type="text" name="model_output" class="form-control mt-2" placeholder="Model Output"    />
                                      <input type="text" name="model_confidence" class="form-control mt-2" placeholder="Model confidence. Example: 0.5"     />
                                      <select name="model_otherwise" class="form-select cursor-pointer w-full mt-2">
                                        <option value="0">Model Otherwise - {{ $t('Inactive') }}</option>
                                        <option value="1">Model Otherwise - {{ $t('Active') }}</option>
                                      </select>
                                      <input type="text" name="color" class="form-control mt-2" placeholder="Label Color. Example: #071a50" value="#"  />
                                  </div>
                              </div>
                              <div class="px-5 pb-8 text-center p">
                                  <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                                  <button type="submit" class="btn bg-theme-28 text-white w-24">{{ $t('Save') }}</button>
                              </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> 
          <!-- End Add Array item --> 
          <!-- Edit Array item --> 
          <div class="edit-tag modal" tabindex="-1" aria-hidden="true" >
              <div class="modal-dialog">
                  <div class="modal-content">
                      <div class="modal-body p-0">
                          <form ref="updTag" class="validate-form" @submit.prevent="updTag('updTag',$event)">
                            <div class="p-5 text-left">
                                <div class="text-2xl mt-5">{{ $t('Edit label') }}</div>
                                <div class="text-gray-600 mt-2">{{ $t('Device') }}: <span class="font-bold">{{ props.monitor }}</span></div>
                                <div class="p-5 pb-2 bg-theme-28 mt-2 pb-8">
                                    <input type="hidden" name="id" :value="eTag.id">
                                    <div class="text-sm  text-white mb-2">ID</div>
                                    <input type="text" class="form-control mb-2" :value="eTag.id" disabled   />
                                    <div class="text-sm  text-white mb-2">Name</div>
                                    <input type="text" name="name" class="form-control" placeholder="Name" :value="eTag.name" required  />
                                    <div class="text-sm mt-2 text-white mb-2">Model Output</div>
                                    <input type="text" name="model_output" class="form-control mt-2" placeholder="Model Output" :value="eTag.model_output"   />
                                    <div class="text-sm mt-2 text-white mb-2">Model confidence</div>
                                    <input type="text" name="model_confidence" class="form-control mt-2" placeholder="Example: 0.5" :value="eTag.model_confidence" required  />
                                    <div class="text-sm mt-2 text-white">Model Otherwise</div>
                                    <select name="model_otherwise" class="form-select cursor-pointer w-full mt-2">
                                      <option value="0">{{ $t('Inactive') }}</option>
                                      <option value="1" :selected="eTag.model_otherwise">{{ $t('Active') }}</option>
                                    </select>
                                    <div class="text-sm mt-2 text-white">Color</div>
                                    <input type="text" name="color" class="form-control mt-2" placeholder="Example: #071a50" :value="eTag.color"  />
                                  </div>
                            </div>
                            <div class="px-5 pb-8 text-center p">
                                <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                                <button type="submit" class="btn bg-theme-28 text-white w-24">{{ $t('Save') }}</button>
                            </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div> 
          <!-- End Edit Array item --> 
          <!-- Tag list -->
          <div class="w-full xxl:w-3/4" :class="aTag || aTag ? 'hidden' : ''">
            <div class="intro-y block sm:flex items-center h-10 mb-3 mt-1">
              <span class="text-xl font-medium truncate mr-5">{{ $t('Device labeling') }}</span>
              <div class="grid grid-cols-12 gap-1">
              <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
                  <div class="dropdown">
                      <button class="dropdown-toggle btn px-1 box bg-theme-6 text-white pt-1 pb-1" aria-expanded="false" @click="AddTag()">
                          <span class="w-4 h-4 flex items-center justify-center"> <PlusIcon class="w-4 h-4" /> </span>
                      </button>
                  </div>
              </div>
            </div>
            </div>
            <table class="table table--sm mb-5 mt-3" v-if="Object.keys(MonTags).length">
              <tbody>
                  <tr>
                    <td class="border w-1/6">{{ $t('ID') }}</td>
                    <td class="border w-2/6">{{ $t('Name') }}</td>
                    <td class="border w-1/6">{{ $t('Model Output') }}</td>
                    <td class="border w-1/6">{{ $t('Model otherwise') }}</td>
                    <td class="border w-1/6">{{ $t('Model confidence') }}</td>
                    <td class="border w-1/6">{{ $t('Color') }}</td>
                    <td class="border w-1/6 text-center">{{ $t('Actions') }} </td>
                  </tr>
                  <tr v-for="(mt,indx) in MonTags" :key="mt">
                    <td class="border w-1/6 bg-theme-28 text-center text-white cursor-pointer" @click="EditTag(indx)">{{mt.id}}</td>
                    <td class="border w-2/6 cursor-pointer bg-theme-7  text-center" @click="EditTag(indx)">{{mt.name}}</td>
                    <td class="border w-1/6 cursor-pointer" @click="EditTag(indx)">{{mt.model_output}}</td>
                    <td class="border w-1/6 cursor-pointer" @click="EditTag(indx)">{{mt.model_otherwise}}</td>
                    <td class="border w-1/6 cursor-pointer" @click="EditTag(indx)">{{mt.model_confidence}}</td>
                    <td class="border w-1/6 cursor-pointer" @click="EditTag(indx)">
                      <div class="w-full h-5" v-if="mt.color" :style="'background-color:'+mt.color"></div>
                    </td>
                    <td class="border w-1/6 text-center">
                      <TrashIcon class="w-4 h-4 mr-2 cursor-pointer" @click="deleteTag(indx)" />
                      <!-- Delete Array item -->  
                      <div :id="'delete-tag-'+indx" class="modal" tabindex="-1" aria-hidden="true" >
                          <div class="modal-dialog">
                              <div class="modal-content">
                                  <div class="modal-body p-0">
                                      <div class="p-5 text-center">
                                          <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
                                          <div class="text-2xl mt-5">{{ $t('Delete label') }}</div>
                                          <div class="mt-2 text-2xl p-5 bg-theme-24 text-white">{{ mt.name }}</div>
                                          <div class="text-gray-600 mt-2">{{ $t('Device') }}: {{ props.monitor }}</div>
                                          <div class="text-gray-600 mt-2">{{ $t('Are you sure you want to delete the label?') }} <br />{{ $t('This action is irreversible') }} .</div>
                                      </div>
                                      <div class="px-5 pb-8 text-center">
                                          <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                                          <button type="button" class="btn btn-danger w-24" @click="delTag(indx)">{{ $t('Delete') }}</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> 
                      <!-- End Delete Array item -->  
                    </td>
                  </tr>
                </tbody>
            </table>
            <div v-else>{{ $t('No labels have been registered for this monitor') }}.</div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted , onBeforeMount, watch  } from "vue";
import { monitor as $monitor } from "@/model/monitor";
import { helper as $h } from "@/utils/helper";
import { tag as $tag } from "@/model/tag";
import cash from "cash-dom";

export default defineComponent({
  props: {
    monitor: {
      type: String,
      required: true
    }
  },
  created () {
    this.helper     = $h
  },  
  setup(props) {
    
    const MonRules      = ref({});
    const MonTags       = ref({});
    const rules         = ref([])
    const eTag          = ref(false);
    const aTag          = ref(false);
    const querybuilder  = ref(null)
    const tags          = ref([])

    const AddTag = async () => { cash('#add-tag').modal("show"); };

    const EditTag = async (t) => { eTag.value = MonTags.value[t]; cash('.edit-tag').modal("show"); };

    const deleteTag =  (r) => {  cash('#delete-tag-'+r).modal("show");  }; 

    onBeforeMount( async () => {
      MonRules.value = await $monitor.getRules(props.monitor)
      MonTags.value  = await $monitor.getTags(props.monitor)
    });

    onMounted( async () => {
      tags.value = await $tag.init()
      if(querybuilder.value)querybuilder.value.addEventListener('change', function () { rules.value = querybuilder.value.value  });
    });

    watch(() => props.monitor, async () => { MonRules.value = await $monitor.getRules(props.monitor) });

    return {
      props,
      rules,
      querybuilder,
      MonRules,
      EditTag,
      AddTag,
      deleteTag,
      eTag,
      aTag,
      tags,
      MonTags
    };
  },
  methods:{
    updTag: async function(formID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        let updTag = { monitor: this.props.monitor, data: {}}
        for (let i = 0; i < this.$refs[formID].length; i++) {  
         if(this.$refs[formID][i].name=="id")updTag['id'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="name")updTag.data['name'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="model_output")updTag.data['model_output'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="model_otherwise"){  updTag.data['model_otherwise'] = Boolean(false); if(this.$refs[formID][i].value=="1")updTag.data['model_otherwise'] = Boolean(true);  }
         if(this.$refs[formID][i].name=="model_confidence")updTag.data['model_confidence'] = Number(this.$refs[formID][i].value);
         if(this.$refs[formID][i].name=="color")updTag.data['color'] = this.$refs[formID][i].value;
        }  
        await $monitor.updTag(updTag)
        cash('.edit-tag').modal("hide");
        $h.NotificationTxt({text: this.$i18n.t('Label updated successfully') })
        this.eTag = false
        this.MonTags = await $monitor.getTags(this.props.monitor)
      }
    },
    addTag: async function(formID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        let addTag = { monitor: this.props.monitor, data: {} }
        for (let i = 0; i < this.$refs[formID].length; i++) {  
         if(this.$refs[formID][i].name=="id")addTag['id'] = this.$refs[formID][i].value; 
         if(this.$refs[formID][i].name=="nametag")addTag.data['name'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="model_output")addTag.data['model_output'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="model_otherwise"){  addTag.data['model_otherwise'] = Boolean(false); if(this.$refs[formID][i].value=="1")addTag.data['model_otherwise'] = Boolean(true);  }
         if(this.$refs[formID][i].name=="model_confidence")addTag.data['model_confidence'] = Number(this.$refs[formID][i].value);
         if(this.$refs[formID][i].name=="color")addTag.data['color'] = this.$refs[formID][i].value; 
        }  
        let newTagID = await $monitor.addTag(addTag)
        cash('#add-tag').modal("hide");
        $h.NotificationTxt({text: this.$i18n.t('New label created successfully') })
        this.aTag = false
        this.MonTags = await $monitor.getTags(this.props.monitor)
        if(newTagID)await this.EditTag(newTagID)
      }
    },
    delTag: async function(tagID){
      cash('#delete-tag-'+tagID).modal("hide"); cash('#delete-tag-'+tagID).removeClass('show');
      await $monitor.delTag(this.props.monitor,tagID)
      $h.NotificationTxt({text: this.$i18n.t('Label removed successfully')})
      this.MonTags = await $monitor.getTags(this.props.monitor)
    },
  }
});
</script>
<style>
    :root {
      --smart-query-builder-min-width: 100%;
      --smart-font-family: Roboto !important;
    }
</style>