'use strict';

const firebase  = require('firebase/app');
const project   = require("./project");
const axios     = require('axios').default;

const controller = { 
    getNodeConfig:  function() {
        let config = project.getConfig()
        return config.nodeConfig;
    },

    getStatus: async function() {
        let resp        = { status: "error", label: 'offline', error: false }
        let nodeConfig  = this.getNodeConfig()
        resp.host       = nodeConfig.host+'/nodered/flow/global'
        try {
            return axios.get(resp.host, {}, { headers: {} })
                .then(response => { 
                    if(response.status && response.status==200){ 
                        resp.status = "success";  
                        resp.label = "online"; 
                        return resp; 
                    } else {
                        resp.error = response.statusText;
                        return resp; 
                    }
                }).catch(error => {
                    resp.error = error;
                    return resp; 
                });
        } catch (error) {
            resp.error = error; 
            return resp; 
        }

    },

    loadPage: function(url) {
        let resp = { status: "error", error: false, render: false }
        if (url){
            let nav         = this.getNavMenu()
            let nodeConfig  = this.getNodeConfig()
            let navItem     = false
            if(nav.menu)
                for (const n of Object.keys(nav.menu)){  
                for (let sn = 0; sn < nav.menu[n].length; sn++) {
                if(nav.menu[n][sn].url==url)navItem = nav.menu[n][sn]
                }
                }
            if(navItem){
                resp.url = navItem.url
                if(navItem.load){
                    switch(navItem.load){
                        case 'AJ': 
                                resp.url = nodeConfig.host + "/ajenti"+url
                                if(useStore().state.main.User.uid)resp.url += "?uid="+useStore().state.main.User.uid
                            break;
                        case 'CP': 
                                var auth   = "root:123qwe123"
                                var xhr    = new XMLHttpRequest();
                                xhr.open("GET", nodeConfig.host + "/cockpitp/cockpit/login", false);
                                xhr.setRequestHeader("Authorization", "Basic " + btoa(auth));  
                                xhr.send();
                                resp.url = nodeConfig.host + "/cockpitp/cockpit/@localhost" + url
                            break;
                        case 'DH': 
                                resp.url = nodeConfig.host + ":4041" + url
                            break;
                        default: break;
                    }
                }
                resp.status  = "success" 
                resp.render  = "<iframe id='controllerFrame' style='width: 100%; height: 100%' src='" + resp.url + "'></iframe>"
            }else{ resp.error = "url not found" }
        }else{ resp.error = "url is required" }
        return resp
    },

    getNavMenu: function(opt = false) {
        let resp  = { status: "success", error: false}
        resp.menu = {
                    information: [ 
                                    { name: "Dashboard",        load: "AJ",     url: "/view/dashboard" },
                                    { name: "Metrics",          load: "CP",     url: "/metrics/index.html" },
                                    { name: "Logs",             load: "CP",     url: "/system/logs.html" },
                                    { name: "Hardware",         load: "CP",     url: "/system/hwinfo.html" },
                                ],
                    tools:       [ 
                                    { name: "IDE",              url: ":4040" },
                                    { name: "File Manager",     load: "CP",     url: "/navigator/index.html" },
                                    { name: "Terminal",         load: "CP",     url: "/system/terminal.html" },
                                    { name: "Power",            load: "AJ",     url: "/view/power" },
                                ],
                    services:    [ 
                                    { name: "Services",         load: "CP",     url: "/system/services.html" },
                                    { name: "Containers",       load: "CP",     url: "/podman/index.html" },
                                    { name: "Virtual",          load: "CP",     url: "/machines/index.html" },
                                    { name: "Updates",          load: "CP",     url: "/updates/index.html" },
                                    { name: "APT Packages",     load: "AJ",     url: "/view/packages/apt" },
                                    { name: "PIP Packages",     load: "AJ",     url: "/view/packages/pip" },
                                ],  
                    settings:    [ 
                                    { name: "System",           load: "CP",     url: "/system/index.html" },
                                    { name: "Storage",          load: "CP",     url: "/storage/index.html" },
                                    { name: "Network",          load: "CP",     url: "/network/index.html" },
                                    { name: "DHCP status",      load: "DH",     url: "/" },
                                    { name: "DHCP leases",      load: "DH",     url: "/dhcp_leases" },
                                    { name: "DHCP config",      load: "DH",     url: "/dhcp_config" },
                                    { name: "DHCP start/stop",  load: "DH",     url: "/dhcp_start_stop_restart" },
                                    { name: "Users",            load: "CP",     url: "/users/index.html" },
                                    { name: "Datetime",         load: "AJ",     url: "/view/datetime" },
                                ],            
                }
        if(opt.pageList){
            let pages = []
            for (const nav of Object.keys(resp.menu)){ 
                for (var n = 0; n < resp.menu[nav].length; n++) {
                    pages.push(resp.menu[nav][n])
                }
            }
            resp.menu = pages
        }
        return resp
    },
}

module.exports = controller