<template>
  <router-view />
</template>

<script>
//import { _firebase as $_firebase } from "@/model/firebase";
import * as rosepetalModel from "rosepetal-model";
import config from './etc/rosepetal.json';

const $_firebase     = rosepetalModel._firebase;
const firebaseConfig = config.firebaseConfig;
$_firebase.initializeApp(firebaseConfig);
$_firebase.setHost(config)

//import { event as $event } from "@/model/events";
import { useRouter, useRoute } from "vue-router";
import { user as $user } from "@/model/user";
import { onBeforeMount } from "vue";
import { useStore } from "@/store";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    onBeforeMount(async () => {
      let auth = $_firebase.auth();
      auth.onAuthStateChanged(async (user) => {
        /*let Menu = nestedMenu(store.state.sideMenu.menu, route)
        let keys = []; let roleok = false
        for (const [key, p] of Object.entries(Menu)) { keys.push(key); if(p.pageName){ let pth = router.resolve({ name: p.pageName }).path; if(pth==route.path)roleok = true } } 
        if(!roleok)router.replace('/'); //redirect no role*/
        //console.log('login event!!!');
        if (user) {
          user.role = await $user.getRole(user.email);
          console.log("config aws", await rosepetalModel.aws.initAws());
        }
        if (!user) {
          router.replace("/login");
        } else if (route.path == "/login" || route.path == "/register") {
          await rosepetalModel.event.saveEvent(
            "user.login",
            { uid: user.uid },
            false
          )((response) => {
            console.log(response);
            router.replace("/");
          });
        } else {
          store.dispatch("main/setUserLogged", user);
        }
      });
    });
  },
};
</script>